import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { APP_CONFIG } from '../app.config';
import { ProposalsTemplate } from '../models/proposals-template.model';

@Injectable({
  providedIn: 'root'
})
export class ProposalsTemplateService {
  onGetAll = new Subject();
  onGet = new Subject();
  onPost = new Subject();
  onUpdate = new Subject();
  onBuild = new Subject();
  onDelete = new Subject();
  onReplaceIcon = new Subject();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient
  ) { }

  httpGetAll(params: any = {}) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/proposalstemplates`, {
      params
    })
    .subscribe(
      (response: any) => {
        this.onGetAll.next(response);
      },
      (response: any) => {
        this.onGetAll.next(response.error);
      }
    );
  }

  httpGet(UID: string) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/proposalstemplates/${UID}`)
    .subscribe(
      (response: any) => {
        this.onGet.next(response);
      },
      (response: any) => {
        this.onGet.next(response.error);
      }
    );
  }

  httpPost(data: any) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/proposalstemplates`, data)
    .subscribe(
      (response: any) => {
        this.onPost.next(response);
      },
      (response: any) => {
        this.onPost.next(response.error);
      }
    );
  }

  httpUpdate(UID: string, data: ProposalsTemplate) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/proposalstemplates/${UID}`, data)
    .subscribe(
      (response: any) => {
        this.onUpdate.next(response);
      },
      (response: any) => {
        this.onUpdate.next(response.error);
      }
    );
  }

  httpDelete(UID: string) {
    return this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/proposalstemplates/${UID}`)
    .subscribe(
      (response: any) => {
        this.onDelete.next(response);
      },
      (response: any) => {
        this.onDelete.next(response.error);
      }
    );
  }

  httpBuild(UID: string, data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/proposalstemplates/${UID}/build`, data)
    .subscribe(
      (response: any) => {
        this.onBuild.next(response);
      },
      (response: any) => {
        this.onBuild.next(response.error);
      }
    );
  }

  httpReplaceIcon(UID: string, file: File) {
    const formData = new FormData;
    formData.append('file', file, file.name);

    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/proposalstemplates/${UID}/icon`, formData)
    .subscribe(
      (response: any) => {
        this.onReplaceIcon.next(response);
      },
      (response: any) => {
        this.onReplaceIcon.next(response.error);
      }
    );
  }
}
