<div class="homepage2 app-media">

    <div class="page-header header-bar">
        <h1 class="page-title" style="display: inline-block;">Subgroup Templates <span class="badge badge-info blue-background circle-badge">{{ template_count }}</span></h1>
        <div class="page-header-actions templates-search">
            <div class="">
              <div class="input-search">
                <input type="text" class="form-control" name="" placeholder="Search..." [(ngModel)]="term" (keyup)="onSearchKeyUp()">
  
              </div>
            </div>
        </div>
  
        <div class="page-header-actions">
            <ul class="list-inline">
  
              <!-- <li>
                  <div class="checkbox-custom checkbox-primary">
                    <span for="inputChecked" class="text-bold margin-right-25">Show Archived Templates</span>
  
                  </div>
              </li> -->
              <li class="filter-list">
  
                <select data-plugin="selectpicker" data-style="btn btn-link btn-filter" [(ngModel)]="dateFilter">
                  <option value="recent" selected>Sort by date desc</option>
                  <option value="date-asc" >Sort by date asc</option>
                  <option value="title-asc">Sort by Title ASC</option>
                  <option value="title-desc">Sort by Title DESC</option>
                </select>
  
                <select data-plugin="selectpicker" data-style="btn btn-link btn-filter" [(ngModel)]="templateFilter">
                  <option value="" selected disabled>Template Filter</option>
                  <option value="all" >All</option>
                  <option value="enewsletter">eMarketing</option>
                  <option value="buyer-match">Buyer Match</option>
                  <option value="quick-enquiry">Quick Enquiry</option>
                  <option value="brochure">Brochure</option>
                  <option value="property-guide">Property Guide</option>
                  <option value="social-media">Social Media</option>
                </select>
  
                <button type="button" class="btn btn-sm btn-icon btn-default btn-link active template-view" data-view="grid" data-toggle="tooltip" data-original-title="Grid view">
                  <i class="icon feather icon-grid" aria-hidden="true"></i>
                </button>
                <button type="button" class="btn btn-sm btn-icon btn-default btn-link template-view" data-view="list" data-toggle="tooltip" data-original-title="List View">
                  <i class="icon feather icon-menu" aria-hidden="true"></i>
                </button>
  
              </li>
  
            </ul>
        </div>
      </div>
  
    <div class="page-content your-templates">
        <!-- <a (click)="onFeedback()" id="my_feedback" class="btn-feedback">Send Feedback</a> -->
    <div class="media-list padding-bottom-50 is-grid">
      <ul class="blocks blocks-100 blocks-xlg-4 blocks-lg-3 blocks-md-3 blocks-ms-2 blocks-xs-2" data-plugin="animateList" data-child=">li">
  
        <li  *ngFor="let template of templates | filter : templateFilter : 'template' | filter : dateFilter : 'date'; let i = index" class="animation-scale-up" style="animation-fill-mode: backwards; animation-duration: 250ms; animation-delay: 0ms;">
          <div class="media-item">
  
            <div class="image-wrap">
              <figure class="overlay overlay-hover animation-hover">
                <img class="overlay-figure" [src]="S3_RESOURCES_URL + template.ID + '.jpg'" onerror="this.src='assets/img/template-placeholder.png'">
                <figcaption class="overlay-panel overlay-background overlay-fade text-center vertical-align">
                    <ul class="list-unstyled">
                      <li><button data-bs-target="#previewTemplate" data-bs-toggle="modal" class="btn btn-outline btn-round btn-inverse vertical-align-middle text-uppercase">View</button></li>
                    </ul>
  
                    <ul class="list-unstyled">
                        <li><button (click)="onDuplicate(template)" class="btn btn-outline btn-round btn-inverse vertical-align-middle text-uppercase">Duplicate</button></li>
                    </ul>
                  </figcaption>
              </figure>
            </div>
            <div class="info-wrap">
              <div class="template-type">
                <span class="badge badge-default">{{ template.category.title }}</span>
              </div>
              <div class="title" (click)="onEditTitle(template.ID, template.title)" data-bs-target="#editTitle" data-bs-toggle="modal">{{ template.title }}
                <span class="icon feather icon-edit pull-right"></span>
              </div>
              <div class="time">{{ convertDate(template.created) }}</div>
  
            </div>
  
            <table class="row-list">
                <tr>
                  <td class="col-md-2" style="width: 200px;">
                    <h4>{{ template.title }}</h4>
                  </td>
                  <td class="col-md-2" style="width: 20px;">
                    <span class="time">{{ convertDate(template.created) }}</span>
                  </td>
                  <td class="col-md-2" style="width: 30px;">
                    <span class="badge badge-default">{{ template.category.title }}</span>
                  </td>
                  <td class="col-md-2 text-right action-links">
                      <ul class="list-inline">
                          <li><a href="javascript:void(0)" data-bs-target="#previewTemplate" data-bs-toggle="modal" data-original-title="View"><i class="icon feather icon-eye" aria-hidden="true"></i></a></li>
                          <li><a href="javascript:void(0)" (click)="onDuplicate(template)" data-toggle="tooltip" data-original-title="Duplicate"><i class="icon feather icon-copy" aria-hidden="true"></i></a></li>
                      </ul>
                  </td>
                </tr>
              </table>
  
          </div>
  
        </li>
      </ul>
    </div>
    </div>
    </div>
  <div #previewModal id="previewTemplate" class="modal fade edit-box preview" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <button type="button" #previewTemplateModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">
        <img src="" class="img-responsive" onError="this.src='/assets/img/template-placeholder.png'" />
      </div>
    </div>
  </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
  