<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">Popups</h2>

    <button routerLink="/admin/popups/new" type="button" class="btn-admin">
        <i class="icon feather icon-plus"></i><span>Add Popup</span>
      </button>
  </div>
</div>
<div id="box" class="row">
  <div class="col-md-12">
    <div class="table-box">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Provider</th>
            <th>Image</th>
            <th style="text-align: right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let popup of popups | paginate: { itemsPerPage: 50, currentPage: page } ">
            <td>{{popup?.provider}}</td>
            <td>
              <img class="popup-preview" src="{{ adminService?.appConfig?.S3_URL + popup?.image }}">
            </td>
            <td style="text-align: right">
              <button routerLink="/admin/popups/{{ popup?.ID }}/edit" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
              <button (click)="onDelete(popup?.ID)" type="button" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination-controls (pageChange)="page = $event"></pagination-controls>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>