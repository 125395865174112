<div class="col-md-12 heading text-end">
    <h2 class="pull-left mt-0">Integrations</h2>
</div>

<div class="row mb-4">
    <div class="col-lg-12">
        <div class="panel panel-box-shadow p-4">
            <form [formGroup]="clientIntegrationsForm" (ngSubmit)="onSubmit()">
                <h3>Propps</h3>
                <hr />
                <div class="d-flex form-group">
                    <label for="propps_app_id" class="col-sm-1">App ID</label>
                    <div class="col-sm-5">
                        <input formControlName="propps_app_id" type="text" class="form-control" id="propps_app_id"
                            autocomplete="off" />
                    </div>
                </div>
                <h3>Pricefinder</h3>
                <hr />
                <div class="d-flex form-group">
                    <label for="pricefinder_username" class="col-sm-1">Username</label>
                    <div class="col-sm-5">
                        <input formControlName="pricefinder_username" type="text" class="form-control" id="pricefinder_username"
                            autocomplete="off" />
                    </div>
                </div>
                <div class="d-flex form-group">
                    <label for="pricefinder_password" class="col-sm-1">Password</label>
                    <div class="col-sm-5">
                        <input formControlName="pricefinder_password" type="password" class="form-control" id="pricefinder_password"
                            autocomplete="off" />
                    </div>
                </div>
                <h3>Google</h3>
                <hr />
                <div class="d-flex form-group">
                    <label for="google_maps_api_key" class="col-sm-1">Maps API Key</label>
                    <div class="col-sm-5">
                        <input formControlName="google_maps_api_key" type="text" class="form-control" id="google_maps_api_key"
                            autocomplete="off" />
                    </div>
                </div>
                <div class="d-flex form-group">
                    <label for="google_tag_id" class="col-sm-1">Tag Manager ID</label>
                    <div class="col-sm-5">
                        <input formControlName="google_tag_id" type="text" class="form-control" id="google_tag_id"
                            autocomplete="off" />
                    </div>
                </div>
                <div class="d-flex form-group">
                    <label class="col-sm-1">&nbsp;</label>
                    <div class="col-sm-10">
                        <button type="submit" class="btn-admin btn-admin-primary">Save</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>