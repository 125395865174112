import { Component, OnInit, OnDestroy } from '@angular/core';
import { FaqCategory } from '../../../models/faqcategory.model';
import { Subscription, Subject } from 'rxjs';
import { FaqCategoryService } from '../../../services/faqcategory.service';
import { AdminService } from '../../admin.service';
import swal from 'sweetalert2';

declare const $: any;
@Component({
  selector: 'app-faq-category-dashboard',
  templateUrl: './faq-category-dashboard.component.html',
  styleUrls: ['./faq-category-dashboard.component.scss']
})
export class FaqCategoryDashboardComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = true;
  faqsCategories: FaqCategory[] = [];

  constructor(
    private faqCategoryService: FaqCategoryService
  ) { }

  ngOnInit() {
    this.faqCategoryService.httpGetFaqsCategories();

    this.faqCategoryService.onGetFaqsCategories
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.faqsCategories = response.data;
              this.destroyDataTables();

              setTimeout(
                () => {
                  this.loading = false;
                  $('table').DataTable({
                    order: [],
                    pagingType: 'full_numbers',
                    language: {
                      search: '_INPUT_',
                      searchPlaceholder: 'Search',
                      paginate: {
                        first: '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
                        previous: '<i aria-hidden="true" class="fa fa-angle-left"></i>',
                        next: '<i aria-hidden="true" class="fa fa-angle-right"></i>',
                        last: '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
                      }
                    },
                    pageLength: 50,
                    retrieve: true
                  });

                  $('input[type=search]').addClass('btn-search');
                  $('input[type=search]').attr('placeholder','Search');
                  $('select').addClass('select_datatable');
                  $('select').append('<option selected value="-1">Filter</option>');
                }, 1000
              );
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.faqCategoryService.onDeleteFaqsCategory
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              swal('FAQ Category Deleted', '', 'success');
              this.faqCategoryService.httpGetFaqsCategories();
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );
  }

  onDelete(UID: string) {
    const $this = this;
    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.faqCategoryService.httpDeleteFaqsCategory(UID);
        $this.loading = true;
      }
    }).catch(swal.noop);
  }

  destroyDataTables(): void {
    const activeDataTables = $.fn.dataTable.tables();
    if (activeDataTables.length) {
      activeDataTables.forEach(element => {
        $(element).DataTable().destroy();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyDataTables();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
