import { Component, OnInit } from '@angular/core';
import { Menu } from '../../../models/menu.model';
import { AdminService } from '../../admin.service';
import { Icon } from '../../../models/icon.model';
import { IconService } from '../../../services/icon.service';
import { Subscription } from 'rxjs/Subscription';

declare var jQuery: any;

@Component({
  selector: 'app-icons-dashboard',
  templateUrl: './icons-dashboard.component.html',
  styleUrls: ['./icons-dashboard.component.scss']
})
export class IconsDashboardComponent implements OnInit {
  menus: Menu[] = [];
  icons: Icon[] = [];

  loading = false;

  subscription: Subscription;
  
  constructor(private adminService: AdminService, private iconService: IconService) { }

  ngOnInit() {
      this.adminService.loadMenu(this.menus);

      this.iconService.httpGetIcons();

      this.subscription = this.iconService.iconsChanged.subscribe(
        (icons: Icon[]) => {
          
          this.icons = icons;
          setTimeout(
            () => {
              this.loading = false;
              jQuery('table').DataTable({
                'pagingType': 'full_numbers',
                // 'oLanguage': { 'sSearch': '', 'sLengthMenu': '_MENU_' },
                language: {
                  search: '_INPUT_',
                  searchPlaceholder: 'Search',
                  'paginate': {
                    'first': '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
                    'previous': '<i aria-hidden="true" class="fa fa-angle-left"></i>',
                    'next': '<i aria-hidden="true" class="fa fa-angle-right"></i>',
                    'last': '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
                  }
                },
                "pageLength": 3,
                "retrieve": true
              });
      
              jQuery('input[type=search]').addClass('btn-search');
              jQuery('input[type=search]').attr('placeholder','Search');
              jQuery('select').addClass('select_datatable');
              jQuery('select').append('<option selected value="-1">Filter</option>');
            }, 1000
          );
        }
      )
  }

  onDelete(id: number) {
    this.loading = true;
    this.iconService.httpDeleteIcon(id);
  }

}
