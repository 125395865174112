<div class="row">
    <div class="col-md-12 heading">
      <h2 class="pull-left">Help Center - FAQ</h2>
  
      <button routerLink="new" type="button" class="btn-admin"> <i class="icon feather icon-plus"></i><span>Add FAQ</span>  </button>
    </div>
  </div>
  <div id="box" class="row">
    <div class="col-md-12">
      <div class="table-box">
        <table class="table table-responsive table-hover">
          <thead>
            <tr>
              <th>Provider</th>
              <th>Groups</th>
              <th>Excluded Groups</th>
              <th>Title</th>
              <th>Description</th>
              <th>Category</th>
              <th style="text-align: right">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let faq of faqs">
              <td>{{ faq?.provider | uppercase }}</td>
              <td>
                <span *ngIf="faq?.group_whitelist === 'all'">ALL</span>
                <ng-container *ngIf="faq?.group_whitelist === 'selected'">
                  <ng-container *ngFor="let group of faq?.groups">
                    <span class="badge badge-primary">{{group?.group?.title}}</span><br />
                  </ng-container>
                </ng-container>
              </td>
              <td>
                <span *ngIf="!faq?.excluded_groups?.length">None</span>
                <ng-container *ngIf="faq?.excluded_groups?.length">
                  <ng-container *ngFor="let group of faq?.excluded_groups">
                    <span class="badge badge-danger">{{group?.group?.title}}</span><br />
                  </ng-container>
                </ng-container>
              </td>
              <td>{{ faq?.title }}</td>
              <td>{{ cutString(faq?.description) }}..</td>
              <td>{{ faq?.category?.title }}</td>
              <td style="text-align: right">
                <button type="button" routerLink="{{ faq?.UID }}/edit" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
                <button type="button" (click)="onDelete(faq?.UID)" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>