<div class="row">
    <div class="col-md-12 heading">
        <h2 class="pull-left">{{product | titlecase}} Blocks</h2>

        <button routerLink="new" type="button" class="btn-admin">
            <i class="icon feather icon-plus"></i><span>Add New {{product | titlecase}} Block</span>
        </button>
    </div>
</div>
<div id="box" class="row">
    <div class="col-md-12">
        <div class="table-box">
            <app-admin-table-component [rowData]="proBlocks">
                <thead>
                    <tr>
                        <th>Whitelist</th>
                        <th>Title</th>
                        <th class="text-end">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let proBlock of proBlocks">
                        <td>
                            <span *ngIf="proBlock?.whitelist === 'all'">ALL</span>
                            <ng-container *ngIf="proBlock?.whitelist === 'selected'">
                                <ng-container *ngFor="let group of proBlock?.groups">
                                <span class="badge badge-primary">{{group?.title}}</span><br />
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>{{proBlock?.title}}</td>
                        <td class="text-end">
                            <a routerLink="/admin/pros/blocks/{{product}}/{{proBlock?.UID}}/edit" class="btn btn-primary btn-sm">Edit</a>
                        </td>
                    </tr>
                </tbody>
            </app-admin-table-component>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>