<div class="child-content-wrapper">
  <div class="col-md-12 heading" [ngClass]="is_builder ? 'heading-custom' : ''">
    <h2>My Image Library</h2>
    <!-- <div class="pull-right"><a class="btn btn-round btn-bordered btn-primary btn-3" (click)="startTour()" data-intro="Take a Tour - Help Portal. Provides a step-by-step guide to the Help Portal.">TAKE A TOUR <i class="icon-circle-question margin-left-10"></i></a></div> -->
  </div>
  <div #displayUpload id="uploadItem" class="modal fade file-library-custom" tabindex="-1" role="dialog" aria-labelledby="uploadItemLabel">
    <div class="modal-dialog modal-center" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close close-outside"><span (click)="closeModal('uploadItem')" aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="uploadItemLabel">Upload File</h4>
        </div>
        <form (ngSubmit)="onUpload()" class="form-horizontal">
          <div class="modal-body">
            <div class="form-group d-flex">
              <label for="medium" class="col-sm-2">File</label>
              <div class="col-sm-8">
                <input #itemFile class="form-control input_box_library" type="file" accept="image/*" (change)="checkFileSize($event)" multiple />
                <span>*You can select multiple files</span>
              </div>
            </div>
            <p>Maximum file size is 10 MB</p>
            <table class="table table-striped">
              <th>File Name</th>
              <tr *ngFor="let tempfile of tempFiles">
                <td>{{ tempfile.name }}</td>
              </tr>
            </table>
          </div>
          <div class="modal-footer justify-content-start">
            <button type="submit" class="btn-admin btn-admin-primary me-2">Upload</button>
            <button type="button" class="btn-admin" (click)="closeModal('uploadItem')">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div id="newFolder" class="modal fade folder-library" tabindex="-1" role="dialog" aria-labelledby="newFolderLabel">
    <div class="modal-dialog modal-center" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close close-outside"><span (click)="closeModal('newFolder')" aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="newFolderLabel">New Folder</h4>
        </div>
        <form (ngSubmit)="onSubmit()" class="form-horizontal">
          <div class="modal-body">
            <div class="form-group d-flex">
              <label for="medium" class="col-sm-2">Name</label>
              <div class="col-sm-8">
                <input #itemFolder class="form-control" type="text" value="" />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-start">
            <button type="submit" class="btn-admin btn-admin-primary me-2">Submit</button>
            <button type="button" class="btn-admin" (click)="closeModal('newFolder')">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div id="moveFile" class="modal fade folder-library" tabindex="-1" role="dialog" aria-labelledby="moveFileLabel">
    <div class="modal-dialog modal-center" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close close-outside"><span (click)="closeModal('moveFile')" aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="newFolderLabel">Move File</h4>
        </div>
        <div class="modal-body" id="move-img">
          <span class="badge badge-info" style="font-size: 15px">My Image Library {{ setBreadcrumb(movePath) }}</span>
          <br /><br />
          <div class="folder-wrapper">
            <table class="w-100 mb-3">
              <tr>
                <th>Name</th>
                <th><button *ngIf="move_base_path !== movePath" (click)="backMoveFolder()" class="btn btn-default btn-md" style="float: right">Back</button></th>
              </tr>
              <ng-container *ngFor="let folder of moveFiles">
                <tr *ngIf="folder.type == 'dir'">
                  <td>{{ folder.basename }}</td>
                  <td (click)="openMoveFolder(folder)" align="right"><button class="btn btn-info btn-md">Open</button></td>
                </tr>
              </ng-container>
            </table>
          </div>
          <button class="btn btn-info btn-lg" (click)="moveFile()">Move here</button>
        </div>
      </div>
    </div>
  </div>

  <div #displayLibrary id="displayLibrary" class="modal fade edit-box preview" data-backdrop="" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-center modal-dialog-custom" role="document">
      <div class="modal-content modal-content-custom">
        <ng-container *ngIf="is_builder || page === 'admin'">
          <button #imageLibraryModalClose type="button" class="btn image-library-modal-selector" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="icon feather icon-x"></i> Close</span>
          </button>
        </ng-container>
        <ng-container *ngIf="!is_builder && page !== 'admin'">
          <button type="button" (click)="goToTemplatesPage()" class="btn image-library-modal-selector">
            <span aria-hidden="true"><i class="icon feather icon-x"></i> Close</span>
          </button>
        </ng-container>

        <div class="modal-body modal-body-custom">
          <nav id="context-menu" class="context-menu">
            <ul class="context-menu__items">
              <li class="context-menu__item">
                <a (click)="onInsert()" [ngClass]="!is_builder ? 'hidden' : ''" class="context-menu__link" data-action="View"><span class="fa fa-plus" aria-hidden="true"></span> Insert</a>
                <a (click)="showLink()" class="context-menu__link" data-action="View"><span class="fa fa-eye" aria-hidden="true"></span> View Image</a>
                <a (click)="downloadImage()" class="context-menu__link"><span class="fa fa-download" aria-hidden="true"></span> Download Image</a>
                <a (click)="copyLink()" class="context-menu__link" data-action="View"><span class="fa fa-files-o" aria-hidden="true"></span> Copy Link to Clipboard</a>
                <a (click)="openMoveFile()" class="context-menu__link" data-action="View" data-bs-target="#moveFile" data-bs-toggle="modal"><span class="fa fa-arrows" aria-hidden="true"></span> Move Image</a>
                <a *ngIf="type === 'owned' || (type !== 'owned' && client?.is_account_master === 1)" (click)="onDelete()" class="context-menu__link"><span class="fa fa-trash-o" aria-hidden="true"></span> Delete</a>
              </li>
            </ul>
          </nav>
          <nav id="unsplash-context-menu" class="context-menu">
            <ul class="context-menu__items">
              <li class="context-menu__item">
                <a (click)="onUnsplashInsert(selectedUnsplashImage?.links)" [ngClass]="!is_builder ? 'hidden' : ''" class="context-menu__link" data-action="View"><span class="fa fa-plus" aria-hidden="true"></span> Insert</a>
                <a (click)="viewUnsplash(selectedUnsplashImage?.links?.html)" class="context-menu__link" data-action="View"><span class="fa fa-eye" aria-hidden="true"></span> View Image</a>
              </li>
            </ul>
          </nav>
          <div class="header">
            <ol id="breadcrumb-list" class="breadcrumb">
              <ng-container *ngIf="is_builder || page === 'admin'">
                <li *ngIf="authService.getApp() === 'MyDesign3'">
                  <p class="library-title">
                    <button type="button" data-bs-dismiss="modal" class="btn"><i class="icon feather icon-chevron-left"></i> Back</button> <span class="title"><i class="icon feather icon-image"></i> {{ currentPage }}</span>
                  </p>
                </li>
                <li *ngIf="authService.getApp() === 'Designly'">
                  <p class="library-title">
                    <button type="button" data-bs-dismiss="modal" class="btn"><i class="icon feather icon-chevron-left"></i> Back</button> <span class="title"><i class="icon feather icon-image"></i> {{ currentPage }}</span>
                  </p>
                </li>
              </ng-container>
              <ng-container *ngIf="!is_builder && page !== 'admin'">
                <li *ngIf="authService.getApp() === 'MyDesign3'">
                  <p class="library-title">
                    <button type="button" (click)="goToTemplatesPage()" class="btn"><i class="icon feather icon-chevron-left"></i> Back</button> <span class="title"><i class="icon feather icon-image"></i> {{ currentPage }}</span>
                  </p>
                </li>
                <li *ngIf="authService.getApp() === 'Designly'">
                  <p class="library-title">
                    <button type="button" (click)="goToTemplatesPage()" class="btn"><i class="icon feather icon-chevron-left"></i> Back</button> <span class="title"><i class="icon feather icon-image"></i> {{ currentPage }}</span>
                  </p>
                </li>
              </ng-container>
              <ng-container *ngIf="folder_name1">
                <li>
                  {{ folder_name1 }}
                </li>
              </ng-container>
              <ng-container *ngIf="folder_name2">
                <li>
                  {{ folder_name2 }}
                </li>
              </ng-container>
              <ng-container *ngIf="folder_name3">
                <li>
                  {{ folder_name3 }}
                </li>
              </ng-container>
              <ng-container *ngIf="folder_name4">
                <li>
                  {{ folder_name4 }}
                </li>
              </ng-container>
            </ol>

            <div class="options">
              <a></a>
            </div>
          </div>

          <div class="dir container-tree">
            <div class="dir contents images-library">
              <div id="my-library" class="library">
                <a class="library-link active" (click)="openOwnLibrary()"><i class="icon feather icon-image"></i> My Library</a>
                <div class="library-container">
                  <ng-container *ngIf="!isStockPage">
                    <ng-container *ngIf="authService.getApp() === 'Designly'">
                      <button *ngIf="!folder_name4" [ngClass]="{ hidden: type !== 'owned' && client.is_account_master === 0 }" data-bs-target="#newFolder" data-bs-toggle="modal" type="button" class="btn btn-link pull-right" data-intro="New Folder - create and name folders for organising your images." data-step="2"><i class="icon feather icon-plus"></i><span>Create new folder</span></button>
                    </ng-container>
                  </ng-container>
                  <div class="ImageScroller clear">
                    <ul id="folder-list" class="folders clear">
                      <ul class="file-list">
                        <li>
                          <a #defaultFolder (click)="openOwnLibrary()" class="folder-view" [ngClass]="folder_name1 === '' && isDefaultLoaded ? 'selected-folder-library' : ''"><span class="icon feather" [ngClass]="isDefaultLoaded ? 'icon-chevron-down' : 'icon-chevron-right'"></span> <span class="icon feather icon-folder"></span> Default</a>
                        </li>
                        <ng-container *ngFor="let file of files; let i = index">
                          <li *ngIf="file.type === 'dir'" class="second-level">
                            <a (click)="openFolder(file)" class="folder-view" [ngClass]="folder_name1 == file.basename && !folder_name2 ? 'selected-folder-library' : ''" data-toggle="tooltip" title="{{ file.basename }}"
                              ><span class="icon feather" [ngClass]="folder_name1 == file.basename ? 'icon-chevron-down' : 'icon-chevron-right'" aria-hidden="true"></span> <span class="icon feather icon-folder"></span> {{ file.basename }} <span *ngIf="type === 'owned' || (type !== 'owned' && client?.is_account_master === 1)" class="delete_folder" (click)="onDeleteFolder(file)"><span class="fa fa-trash-o" aria-hidden="true"></span></span
                            ></a>
                            <ng-container *ngIf="folder_name1 == file.basename">
                              <ul>
                                <ng-container *ngFor="let subfile of subFiles; let i = index">
                                  <li *ngIf="subfile.type === 'dir'">
                                    <a (click)="openFolder(subfile)" class="folder-view" [ngClass]="folder_name2 == subfile.basename && !folder_name3 ? 'selected-folder-library' : ''" data-toggle="tooltip" title="{{ subfile.basename }}"
                                      ><span class="icon feather" [ngClass]="folder_name2 == subfile.basename ? 'icon-chevron-down' : 'icon-chevron-right'" aria-hidden="true"></span> <span class="icon feather icon-folder"></span> {{ subfile.basename }} <span *ngIf="type === 'owned' || (type !== 'owned' && client?.is_account_master === 1)" class="delete_folder" (click)="onDeleteFolder(subfile)"><span class="fa fa-trash-o" aria-hidden="true"></span></span
                                    ></a>
                                    <ng-container *ngIf="folder_name2 == subfile.basename">
                                      <ul>
                                        <ng-container *ngFor="let sublevel3file of subLevel3Files; let i = index">
                                          <li *ngIf="sublevel3file.type === 'dir'">
                                            <a (click)="openFolder(sublevel3file)" class="folder-view" [ngClass]="folder_name3 == sublevel3file.basename && !folder_name4 ? 'selected-folder-library' : ''" data-toggle="tooltip" title="{{ sublevel3file.basename }}"
                                              ><span class="icon feather" [ngClass]="folder_name3 == sublevel3file.basename ? 'icon-chevron-down' : 'icon-chevron-right'" aria-hidden="true"></span> <span class="icon feather icon-folder"></span> {{ sublevel3file.basename }} <span *ngIf="type === 'owned' || (type !== 'owned' && client?.is_account_master === 1)" class="delete_folder" (click)="onDeleteFolder(sublevel3file)"><span class="fa fa-trash-o" aria-hidden="true"></span></span
                                            ></a>
                                            <ng-container *ngIf="folder_name3 == sublevel3file.basename">
                                              <ul>
                                                <ng-container *ngFor="let sublevel4file of subLevel4Files; let i = index">
                                                  <li *ngIf="sublevel4file.type === 'dir'">
                                                    <a (click)="openFolder(sublevel4file)" class="folder-view" [ngClass]="folder_name4 == sublevel4file.basename ? 'selected-folder-library' : ''" data-toggle="tooltip" title="{{ sublevel4file.basename }}">
                                                      <span class="icon feather icon-folder"></span> {{ sublevel4file.basename }} <span *ngIf="type === 'owned' || (type !== 'owned' && client?.is_account_master === 1)" class="delete_folder" (click)="onDeleteFolder(sublevel4file)"><span class="fa fa-trash-o" aria-hidden="true"></span></span
                                                    ></a>
                                                  </li>
                                                </ng-container>
                                              </ul>
                                            </ng-container>
                                          </li>
                                        </ng-container>
                                      </ul>
                                    </ng-container>
                                  </li>
                                </ng-container>
                              </ul>
                            </ng-container>
                          </li>
                        </ng-container>
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- <div id="favourite" class="library">
                  <a class="library-link" href="#"><i class="fa fa-share-alt"></i> Favourite</a>
                  <div class="library-container" style="display: none;">
                    <ul>
                      <li>Item 1</li>
                      <li>Item 2</li>
                      <li>Item 3</li>
                    </ul>
                  </div>
                </div> -->

              <div id="shared-library" class="library">
                <a class="library-link" (click)="openSharedLibrary()"><i class="icon feather icon-share-2"></i> Shared Library</a>
                <div class="library-container" style="display: none">
                  <ng-container *ngIf="!isStockPage">
                    <ng-container *ngIf="authService.getApp() === 'Designly'">
                      <button *ngIf="!folder_name4" [ngClass]="{ hidden: type !== 'owned' && client.is_account_master === 0 }" data-bs-target="#newFolder" data-bs-toggle="modal" type="button" class="btn btn-link pull-right" data-intro="New Folder - create and name folders for organising your images." data-step="2"><i class="icon feather icon-plus"></i><span>Create new folder</span></button>
                    </ng-container>
                  </ng-container>
                  <div class="ImageScroller clear">
                    <ul id="folder-list" class="folders clear">
                      <ul class="file-list">
                        <li>
                          <a (click)="openSharedLibrary()" class="folder-view" [ngClass]="folder_name1 === '' && isDefaultLoaded ? 'selected-folder-library' : ''"><span class="icon feather" [ngClass]="isDefaultLoaded ? 'icon-chevron-down' : 'icon-chevron-right'"></span> <span class="icon feather icon-folder"></span> Default</a>
                        </li>
                        <ng-container *ngFor="let file of files; let i = index">
                          <li *ngIf="file.type === 'dir'" class="second-level">
                            <a (click)="openFolder(file)" class="folder-view" [ngClass]="folder_name1 == file.basename && !folder_name2 ? 'selected-folder-library' : ''" data-toggle="tooltip" title="{{ file.basename }}"
                              ><span class="icon feather" [ngClass]="folder_name1 == file.basename ? 'icon-chevron-down' : 'icon-chevron-right'" aria-hidden="true"></span> <span class="icon feather icon-folder"></span> {{ file.basename }} <span *ngIf="type === 'owned' || (type !== 'owned' && client?.is_account_master === 1)" class="delete_folder" (click)="onDeleteFolder(file)"><span class="fa fa-trash-o" aria-hidden="true"></span></span
                            ></a>
                            <ng-container *ngIf="folder_name1 == file.basename">
                              <ul>
                                <ng-container *ngFor="let subfile of subFiles; let i = index">
                                  <li *ngIf="subfile.type === 'dir'">
                                    <a (click)="openFolder(subfile)" class="folder-view" [ngClass]="folder_name2 == subfile.basename && !folder_name3 ? 'selected-folder-library' : ''" data-toggle="tooltip" title="{{ subfile.basename }}"
                                      ><span class="icon feather" [ngClass]="folder_name2 == subfile.basename ? 'icon-chevron-down' : 'icon-chevron-right'" aria-hidden="true"></span> <span class="icon feather icon-folder"></span> {{ subfile.basename }} <span *ngIf="type === 'owned' || (type !== 'owned' && client?.is_account_master === 1)" class="delete_folder" (click)="onDeleteFolder(subfile)"><span class="fa fa-trash-o" aria-hidden="true"></span></span
                                    ></a>
                                    <ng-container *ngIf="folder_name2 == subfile.basename">
                                      <ul>
                                        <ng-container *ngFor="let sublevel3file of subLevel3Files; let i = index">
                                          <li *ngIf="sublevel3file.type === 'dir'">
                                            <a (click)="openFolder(sublevel3file)" class="folder-view" [ngClass]="folder_name3 == sublevel3file.basename && !folder_name4 ? 'selected-folder-library' : ''" data-toggle="tooltip" title="{{ sublevel3file.basename }}"
                                              ><span class="icon feather" [ngClass]="folder_name3 == sublevel3file.basename ? 'icon-chevron-down' : 'icon-chevron-right'" aria-hidden="true"></span> <span class="icon feather icon-folder"></span> {{ sublevel3file.basename }} <span *ngIf="type === 'owned' || (type !== 'owned' && client?.is_account_master === 1)" class="delete_folder" (click)="onDeleteFolder(sublevel3file)"><span class="fa fa-trash-o" aria-hidden="true"></span></span
                                            ></a>
                                            <ng-container *ngIf="folder_name3 == sublevel3file.basename">
                                              <ul>
                                                <ng-container *ngFor="let sublevel4file of subLevel4Files; let i = index">
                                                  <li *ngIf="sublevel4file.type === 'dir'">
                                                    <a (click)="openFolder(sublevel4file)" class="folder-view" [ngClass]="folder_name4 == sublevel4file.basename ? 'selected-folder-library' : ''" data-toggle="tooltip" title="{{ sublevel4file.basename }}">
                                                      <span class="icon feather icon-folder"></span> {{ sublevel4file.basename }} <span *ngIf="type === 'owned' || (type !== 'owned' && client?.is_account_master === 1)" class="delete_folder" (click)="onDeleteFolder(sublevel4file)"><span class="fa fa-trash-o" aria-hidden="true"></span></span
                                                    ></a>
                                                  </li>
                                                </ng-container>
                                              </ul>
                                            </ng-container>
                                          </li>
                                        </ng-container>
                                      </ul>
                                    </ng-container>
                                  </li>
                                </ng-container>
                              </ul>
                            </ng-container>
                          </li>
                        </ng-container>
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>

              <div *ngIf="is_admin" id="admin-library" class="library">
                <a class="library-link" (click)="openAdmin()"><i class="icon feather icon-lock"></i> Admin Library</a>
                <div class="library-container" style="display: none">
                  <ng-container *ngIf="!isStockPage">
                    <ng-container *ngIf="authService.getApp() === 'Designly'">
                      <button *ngIf="!folder_name4" [ngClass]="{ hidden: type !== 'owned' && client.is_account_master === 0 }" data-bs-target="#newFolder" data-bs-toggle="modal" type="button" class="btn btn-link pull-right" data-intro="New Folder - create and name folders for organising your images." data-step="2"><i class="icon feather icon-plus"></i><span>Create new folder</span></button>
                    </ng-container>
                  </ng-container>
                  <div class="ImageScroller clear">
                    <ul id="folder-list" class="folders clear">
                      <ul class="file-list">
                        <li>
                          <a (click)="openAdmin()" class="folder-view" [ngClass]="folder_name1 === '' && isDefaultLoaded ? 'selected-folder-library' : ''"><span class="icon feather" [ngClass]="isDefaultLoaded ? 'icon-chevron-down' : 'icon-chevron-right'"></span> <span class="icon feather icon-folder"></span> Default</a>
                        </li>
                        <ng-container *ngFor="let file of files; let i = index">
                          <li *ngIf="file.type === 'dir'" class="second-level">
                            <a (click)="openFolder(file)" class="folder-view" [ngClass]="folder_name1 == file.basename && !folder_name2 ? 'selected-folder-library' : ''" data-toggle="tooltip" title="{{ file.basename }}"
                              ><span class="icon feather" [ngClass]="folder_name1 == file.basename ? 'icon-chevron-down' : 'icon-chevron-right'" aria-hidden="true"></span> <span class="icon feather icon-folder"></span> {{ file.basename }} <span *ngIf="type === 'owned' || (type !== 'owned' && client?.is_account_master === 1)" class="delete_folder" (click)="onDeleteFolder(file)"><span class="fa fa-trash-o" aria-hidden="true"></span></span
                            ></a>
                            <ng-container *ngIf="folder_name1 == file.basename">
                              <ul>
                                <ng-container *ngFor="let subfile of subFiles; let i = index">
                                  <li *ngIf="subfile.type === 'dir'">
                                    <a (click)="openFolder(subfile)" class="folder-view" [ngClass]="folder_name2 == subfile.basename && !folder_name3 ? 'selected-folder-library' : ''" data-toggle="tooltip" title="{{ subfile.basename }}"
                                      ><span class="icon feather" [ngClass]="folder_name2 == subfile.basename ? 'icon-chevron-down' : 'icon-chevron-right'" aria-hidden="true"></span> <span class="icon feather icon-folder"></span> {{ subfile.basename }} <span *ngIf="type === 'owned' || (type !== 'owned' && client?.is_account_master === 1)" class="delete_folder" (click)="onDeleteFolder(subfile)"><span class="fa fa-trash-o" aria-hidden="true"></span></span
                                    ></a>
                                    <ng-container *ngIf="folder_name2 == subfile.basename">
                                      <ul>
                                        <ng-container *ngFor="let sublevel3file of subLevel3Files; let i = index">
                                          <li *ngIf="sublevel3file.type === 'dir'">
                                            <a (click)="openFolder(sublevel3file)" class="folder-view" [ngClass]="folder_name3 == sublevel3file.basename && !folder_name4 ? 'selected-folder-library' : ''" data-toggle="tooltip" title="{{ sublevel3file.basename }}"
                                              ><span class="icon feather" [ngClass]="folder_name3 == sublevel3file.basename ? 'icon-chevron-down' : 'icon-chevron-right'" aria-hidden="true"></span> <span class="icon feather icon-folder"></span> {{ sublevel3file.basename }} <span *ngIf="type === 'owned' || (type !== 'owned' && client?.is_account_master === 1)" class="delete_folder" (click)="onDeleteFolder(sublevel3file)"><span class="fa fa-trash-o" aria-hidden="true"></span></span
                                            ></a>
                                            <ng-container *ngIf="folder_name3 == sublevel3file.basename">
                                              <ul>
                                                <ng-container *ngFor="let sublevel4file of subLevel4Files; let i = index">
                                                  <li *ngIf="sublevel4file.type === 'dir'">
                                                    <a (click)="openFolder(sublevel4file)" class="folder-view" [ngClass]="folder_name4 == sublevel4file.basename ? 'selected-folder-library' : ''" data-toggle="tooltip" title="{{ sublevel4file.basename }}">
                                                      <span class="icon feather icon-folder"></span> {{ sublevel4file.basename }} <span *ngIf="type === 'owned' || (type !== 'owned' && client?.is_account_master === 1)" class="delete_folder" (click)="onDeleteFolder(sublevel4file)"><span class="fa fa-trash-o" aria-hidden="true"></span></span
                                                    ></a>
                                                  </li>
                                                </ng-container>
                                              </ul>
                                            </ng-container>
                                          </li>
                                        </ng-container>
                                      </ul>
                                    </ng-container>
                                  </li>
                                </ng-container>
                              </ul>
                            </ng-container>
                          </li>
                        </ng-container>
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>

              <div *ngIf="!raywhiteGroupIds.includes(authUser?.client?.group_id) || authUser?.is_master" id="stock-library" class="library">
                <a class="library-link" (click)="openStock()"><i class="icon feather icon-camera"></i> Stock Library</a>
              </div>
            </div>
          </div>

          <div class="file container-tree">
            <div class="library-controls">
              <ng-container *ngIf="isStockPage">
                <input type="text" [formControl]="stockSearchText" class="form-control library-search" placeholder="Search..." id="stockSearchText" />
              </ng-container>
              <ng-container *ngIf="!isStockPage && isDefaultLoaded">
                <input type="text" class="form-control library-search" name="" id="txt_search" placeholder="Search..." [(ngModel)]="term" />
                <ng-container *ngIf="authService.getApp() === 'MyDesign3'">
                  <button *ngIf="!folder_name2" [ngClass]="{ hidden: type !== 'owned' && client.is_account_master === 0 }" data-bs-target="#newFolder" data-bs-toggle="modal" type="button" class="btn-admin btn-left" data-intro="New Folder - create and name folders for organising your images." data-step="2"><i class="icon feather icon-plus"></i><span>New Folder</span></button>
                </ng-container>
                <button (click)="openUpload()" [class.hidden]="type !== 'owned' && !authUser?.is_master" data-bs-toggle="modal" type="button" class="btn-admin btn-left" id="{client.is_account_master}" data-intro="Upload - add images to your library for use in your templates." data-step="3"><i class="icon feather icon-upload"></i><span>Upload</span></button>
                <button [disabled]="!have_selected" (click)="onInsert()" [ngClass]="!is_builder ? 'hidden' : ''" class="btn-admin btn-left btn-insert"><i class="icon feather icon-upload"></i><span>Insert</span></button>
                <span id="builder_insert" [ngClass]="!is_builder ? 'hidden' : ''"> Click here to insert image or right click the image and click insert</span>
              </ng-container>
              <ng-container *ngIf="isStockPage">
                <button [disabled]="!have_selected" (click)="onUnsplashInsert(selectedUnsplashImage?.links)" [ngClass]="!is_builder ? 'hidden' : ''" class="btn-admin btn-left btn-insert"><i class="icon feather icon-upload"></i><span>Insert</span></button>
                <span id="builder_insert" [ngClass]="!is_builder ? 'hidden' : ''"> Click here to insert image or right click the image and click insert</span>
              </ng-container>
            </div>
            <ng-container *ngIf="isStockPage">
              <div class="file contents unsplash-list" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" [infiniteScrollContainer]="'.unsplash-list'" [fromRoot]="true" (scrolled)="onScroll()">
                <div class="row justify-content-md-center">
                  <ng-container *ngFor="let unsplashImage of unsplashImages">
                    <div class="col col-lg-3" (contextmenu)="displayOptions($event, unsplashImage, 'unsplash')">
                      <div data-id="" class="photo-wrapper" (click)="onImageClick($event, unsplashImage, 'unsplash')" (dblclick)="onUnsplashInsert(unsplashImage?.links)" [ngClass]="is_builder ? 'photo-wrapper-custom' : ''">
                        <div class="photo-box">
                          <img src="{{ unsplashImage?.urls?.small }}" />
                        </div>
                        <div class="photo-name">
                          <strong
                            >Photo by <a href="{{ unsplashImage?.user?.links?.html }}?utm_source=Designly&utm_medium=referral" target="_blank">{{ unsplashImage?.user?.name }}</a> on <a href="https://unsplash.com/?utm_source=Designly&utm_medium=referral" target="_blank">Unsplash</a></strong
                          >
                          <span style="float: right"
                            ><a (click)="displayOptions($event, unsplashImage, 'unsplash')" class="link-options"><span class="fa fa-cog" aria-hidden="true"></span></a
                          ></span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <ng-container *ngIf="unsplashImages.length === 0">
                  <h1>No stock images found</h1>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="!isStockPage">
              <div class="file contents">
                <div class="row justify-content-md-center">
                  <ng-container *ngFor="let image of images | library: term; let i = index">
                    <div class="col col-lg-3" (contextmenu)="displayOptions($event, image)">
                      <div data-id="" class="photo-wrapper" (click)="onImageClick($event, image)" (dblclick)="onInsert()" [ngClass]="is_builder ? 'photo-wrapper-custom' : ''">
                        <div class="photo-box">
                          <img src="{{ getLink(image) }}" />
                        </div>
                        <div class="photo-name">
                          <strong class="font-weight-normal">{{ truncateName(image.basename) }}</strong>
                          <span style="float: right"
                            ><a (click)="displayOptions($event, image)" class="link-options"><span class="fa fa-cog" aria-hidden="true"></span></a
                          ></span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <ng-container *ngIf="images.length === 0 && isDefaultLoaded">
                  <h1>No files found</h1>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
