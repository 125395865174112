<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">Showcase {{ formatTitle(type) }}s</h2>

    <button routerLink="/admin/websites/{{ type }}/new" type="button" class="btn-admin">
        <i class="icon feather icon-plus"></i><span>Add {{ formatTitle(type) }}</span>
      </button>
  </div>
</div>
<div id="box" class="row">
  <div class="col-md-12">
    <div class="table-box">
      <table class="table table-hover">
        <thead>
          <tr>
            <th width="100px"></th>
            <th>Client</th>
            <th>Sort</th>
            <th style="text-align: right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let website of websites">
            <td><img height="100" src="{{ website.image }}"></td>
            <td>{{ website.name }}</td>
            <td>{{ website.sort }}</td>
            <td style="text-align: right">
              <button type="button" routerLink="/admin/websites/{{ website.ID }}/edit" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
              <button type="button" (click)="onDelete(website.ID)" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div #previewModal id="previewElement" class="modal fade edit-box preview" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <button type="button" #previewElementModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">
        <iframe width="560" height="315" src="" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>