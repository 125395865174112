import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'library'
})
export class LibraryPipe implements PipeTransform {

  transform(value: any, term: any): any {
    
    if(term === undefined) return value;

    return value.filter(function(val){
        return val.basename.toLowerCase().includes(term.toLowerCase());
    });

  }

}
