import { Component, OnInit } from '@angular/core';
import { Menu } from '../../models/menu.model';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-admin-pros',
  templateUrl: './admin-pros.component.html',
  styleUrls: ['./admin-pros.component.scss']
})
export class AdminProsComponent implements OnInit {
  menus: Menu[];

  constructor(
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.menus = [
      new Menu('Proposals', '', [
        new Menu('Blocks', 'pros/blocks/proposals'),
        new Menu('Elements', 'pros/elements/proposals'),
        new Menu('Themes',  'pros/themes/proposals')
      ]),
      new Menu('WebBooks', '', [
        new Menu('Blocks', 'pros/blocks/webbooks'),
        new Menu('Elements', 'pros/elements/webbooks'),
        new Menu('Themes',  'pros/themes/webbooks')
      ]),
      new Menu('Owner Reports', '', [
        new Menu('Blocks', 'pros/blocks/owner-reports'),
        new Menu('Elements', 'pros/elements/owner-reports')
      ])
    ];

    this.adminService.loadMenu(this.menus);
  }

}
