import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { Menu } from '../../../models/menu.model';
import { AdminService } from '../../admin.service';
import { GroupService } from '../../../services/group.service';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';

import { ClientService } from '../../../services/client.service';
import { Subscription } from 'rxjs/Subscription';
import { Group } from '../../../models/group.model';
import { Client } from '../../../models/client.model';

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss']
})
export class GroupEditComponent implements OnInit, OnDestroy {
  loading = false;
  id: number;
  editMode = false;
  navigationType = 'link';
  navigationDefault = '';
  backUrl = '/admin/groups';

  subscription: Subscription;
  subscriptionClients: Subscription;
  subscriptionResponse: Subscription;

  group_title = '';

  group: Group;
  clients: Client[];

  groupForm: FormGroup;

  group_account_active = 0;

  @ViewChild('groupFormElement', { static: true }) groupFormElement: ElementRef;

  constructor(private adminService: AdminService,
              private clientService: ClientService,
              private groupService: GroupService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.clientService.httpGetClients({'is_account_master': 1}, 'list');

    this.route.params.subscribe(
      (params: Params) => {
        this.id = +params['id'];
        this.editMode = params['id'] != null;

        if (this.editMode) {
          this.loading = true;
          this.groupService.httpGetGroup(this.id);
        }
      }
    );

    this.subscriptionClients = this.clientService.clientsChanged.subscribe(
      (clients: Client[]) => {
        this.clients = clients;
      }
    );

    this.subscription = this.groupService.groupChanged.subscribe(
      (group: Group) => {
        this.group = group;

        if ( this.editMode && typeof group !== 'undefined' ) {
          this.loading = false;
          this.group_account_active = group.is_account_active;

          this.initFormEdit();
        }
      }
    );

    this.subscriptionResponse = this.groupService.groupOperation.subscribe(
      (group: Group) => {
        if ( typeof group.ID !== 'undefined' ) {
          this.router.navigate([this.backUrl], {relativeTo: this.route});
        }
      }
    );

    this.initForm();
  }

  private initForm() {
    this.groupForm = new FormGroup({
      'code': new FormControl('', Validators.required),
      'vault_code': new FormControl(''),
      'title': new FormControl('', Validators.required),
      'master_client_id': new FormControl('0', Validators.required),
      'vault_master_client_id': new FormControl('0', Validators.required),
      'domain_master_client_id': new FormControl('0', Validators.required),
      'type': new FormControl('', Validators.required),
      'provider': new FormControl('all', Validators.required),
      'admin_email': new FormControl(''),
      'setting_menu_logo': new FormControl('1', Validators.required),
      'setting_menu_color': new FormControl('1', Validators.required),
      'setting_menu_font': new FormControl('1', Validators.required),
      'setting_menu_icon': new FormControl('1', Validators.required),
      'setting_menu_link': new FormControl('1', Validators.required),
      'builder_ckeditor_font_family': new FormControl('1', Validators.required),
      'builder_ckeditor_font_and_background_color': new FormControl('1', Validators.required),
      'hide_create_diy': new FormControl('0', Validators.required),
      'hide_emails': new FormControl('0', Validators.required),
      'hide_printables': new FormControl('0', Validators.required),
      'hide_socials': new FormControl('0', Validators.required),
      'hide_signboards': new FormControl('0', Validators.required),
      'disable_latest_builder_styling': new FormControl('0', Validators.required),
      'pricefinder_username': new FormControl(''),
      'pricefinder_password': new FormControl(''),
      'pricefinder_template_id': new FormControl(0)
    });
  }

  private initFormEdit() {
    this.group_title = this.group.title;
    this.groupForm.patchValue({
      'code': this.group.code,
      'vault_code': this.group.vault_code,
      'title': this.group.title,
      'master_client_id': this.group.master_client_id,
      'vault_master_client_id': this.group.vault_master_client_id,
      'domain_master_client_id': this.group.domain_master_client_id,
      'type': this.group.type,
      'provider': this.group.provider,
      'admin_email': this.group.admin_email,
      'setting_menu_logo': this.group.setting_menu_logo,
      'setting_menu_color': this.group.setting_menu_color,
      'setting_menu_font': this.group.setting_menu_font,
      'setting_menu_icon': this.group.setting_menu_icon,
      'setting_menu_link': this.group.setting_menu_link,
      'builder_ckeditor_font_family': this.group.builder_ckeditor_font_family,
      'builder_ckeditor_font_and_background_color': this.group.builder_ckeditor_font_and_background_color,
      'hide_create_diy': this.group.hide_create_diy,
      'hide_emails': this.group.hide_emails,
      'hide_printables': this.group.hide_printables,
      'hide_socials': this.group.hide_socials,
      'hide_signboards': this.group.hide_signboards,
      'disable_latest_builder_styling': this.group.disable_latest_builder_styling,
      'pricefinder_username': this.group.pricefinder_username,
      'pricefinder_template_id': this.group.pricefinder_template_id
    });
  }

  onChange() {
    const master_client_id = this.groupFormElement.nativeElement.querySelector('#master_client_id').value;
    const type = this.groupFormElement.nativeElement.querySelector('#type').value;
    const code = this.groupFormElement.nativeElement.querySelector('#code');

    if (master_client_id && type) {
      const index = this.clients.findIndex(c => c.ID === +master_client_id);
      const client = this.clients[index];

      if (type === 'franchise') {
        this.groupForm.patchValue({code: client.franchise_code});
      } else {
        this.groupForm.patchValue({code: client.office_id});
      }
    }
  }

  onSubmit() {
    if (this.groupForm.valid) {
      this.loading = true;
      const groupValues = this.groupForm.value;

      if ( this.editMode) {
        this.groupService.httpPutGroup(this.id, groupValues);
      } else {
        this.groupService.httpPostGroup(groupValues);
      }
    }
  }

  onGroupActive() {
    const groupValues = this.groupForm.value;

    groupValues['is_account_active'] = (this.group.is_account_active === 1) ? 0 : 1;

    this.groupService.httpPutGroup(this.id, groupValues);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionClients.unsubscribe();
    this.subscriptionResponse.unsubscribe();
  }
}
