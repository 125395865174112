export const keys = {
    VAULT_CLIENT_ID: 'oRyua5WoiokFOBWscdZAWOnadZNoKoyPoegomcfdNXoiIKqR',
    SLACK_WEBHOOK: 'https://hooks.slack.com/services/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',
    PARTICA_PARTNER_ID: '670594C3-BBF9-4DEA-8DA1-1817398E1BE7',
    DOMAIN_CLIENT_ID: 'client_a58a38a242ed5173d2551e47662454ff',
    PARTICA_LIVE_PARTNER_ID: '2800CE7E-52BF-427D-896C-CFBF93784331',
    VAULT_UK_CLIENT_ID: 'qIJHghVqoapZcGgplC8utHYim6V8bJX2T2OwdOssx0LuU5vO',
    IDASHBOARD_CLIENT_ID: '3RlNTLIRERsrhwlj0NK1qjULrzlwv4K9tWLQBJb2'
};

