<div class="col-md-12 heading text-end">
    <h2 class="pull-left">{{ this?.title }}</h2>
    <button (click)="startTour()" type="button" class="btn-admin btn-admin-primary btn-tour"
        data-intro="Take a Tour - Settings - Provides a step-by-step guide to the Settings section."><span>SETTINGS
            TOUR</span> &nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i></button>
</div>
<!-- <a (click)="onFeedback()" id="my_feedback" class="btn-feedback">Send Feedback</a> -->
<div class="row">
    <div class="col-lg-12">
        <div class="panel panel-box-shadow">
            <div class="panel-body container-fluid">
                <div *ngIf="client?.allow_agent_settings === 0 && !authUser.is_admin">
                    <h3>You do not have permission to view settings.</h3>
                    <p>The requested page requires proper authorization to access.</p>
                    <p>We suggest to contact your administrator for help or report the issue via the <a
                            href="/help/support"> support portal</a>.</p>
                </div>
                <ng-container *ngIf="client?.allow_agent_settings === 1 || authUser.is_admin">


                    <div [hidden]="selectedSettingType !== 'logos'"
                        *ngIf="isGroupVisible(client.group.setting_menu_logo)"
                        [ngClass]="{ active: defaultTab === 'logo' }" class="tab-pane" id="logoUpload" role="tabpanel">
                        <p class="gray4-color">Upload a logo which is used by most templates. Primary Logo is used
                            on white backgrounds only and the secondary logo is used on full colour backgrounds.</p>
                        <div class="row">
                            <div class="col-sm-6">
                                <p class="text-center">Primary Logo</p>
                                <div class="upload-logo">
                                    <img class="img-responsive"
                                        src="{{ client?.primary_logo ? client.primary_logo : '/assets/img/add-image.png' }}"
                                        alt="..." id="mainImage" height="200" />
                                    <div class="btn_clearfix">
                                        <label class="btn btn-block w-100 left-file btn-upload"><i
                                                class="icon feather icon-upload"></i>
                                            Upload Logo
                                            <input type="file" name="logo" class="_LogoUpload" id="image-primary"
                                                data-action="#mainImage" style="display: none"
                                                (change)="uploadLogo($event)" accept="image/*" /></label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <p class="text-center">Secondary Logo</p>
                                <div class="upload-logo">
                                    <img class="img-responsive"
                                        src="{{ client?.secondary_logo ? client.secondary_logo : '/assets/img/add-image.png' }}"
                                        id="secondaryImage" alt="..." />
                                    <div class="btn_clearfix">
                                        <label class="btn btn-block w-100 left-file btn-upload"><i
                                                class="icon feather icon-upload"></i> Upload Logo <input type="file"
                                                name="logo" class="_LogoUpload" id="image-secondary"
                                                (change)="uploadSecondaryLogo($event)" data-action="#secondaryImage"
                                                style="display: none" accept="image/*" /></label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <br />
                                <p class="text-center text-pink">For optimum result please ensure your logo is at
                                    least 600 - 600 pixels wide.</p>
                            </div>
                        </div>
                    </div>

                    <div [hidden]="selectedSettingType !== 'color-palettes'"
                        *ngIf="isGroupVisible(client.group.setting_menu_color)"
                        [ngClass]="{ active: defaultTab === 'color' }" class="tab-pane" id="colorPalettes"
                        role="tabpanel">
                        <p class="gray4-color">Colour palettes allow you to save colours to use in the template
                            builder as well as change the theme of MyDesign, please note the first colour is only
                            used for the MyDesign theme.</p>

                        <form class="form-horizontal">
                            <div class="d-flex form-group">
                                <label class="col-sm-2 control-label text-left darkblue-color">Primary Colour
                                </label>
                                <div class="position-relative col-sm-10">
                                    <input type="text" class="asColorpicker1 form-control input-color" [value]="color1"
                                        readonly />
                                    <button [style.background]="color1" [value]="color1" [(colorPicker)]="color1"
                                        class="cp" id="cp1"></button>
                                    &nbsp; <button id="asColorpicker1" class="btn btn-primary btn-1"
                                        (click)="displayImageColorPicker(1)">Add Colour from Logo</button>
                                </div>
                            </div>

                            <div class="d-flex form-group">
                                <label class="col-sm-2 control-label text-left darkblue-color">Secondary Colour
                                </label>
                                <div class="position-relative col-sm-10">
                                    <input type="text" class="asColorpicker2 form-control input-color" [value]="color2"
                                        readonly />
                                    <button [style.background]="color2" [value]="color2" [(colorPicker)]="color2"
                                        class="cp" id="cp2"></button>
                                    &nbsp; <button class="btn btn-primary btn-1"
                                        (click)="displayImageColorPicker(2)">Add Colour from Logo</button>
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label class="col-sm-2 control-label text-left darkblue-color">Button Colour
                                </label>
                                <div class="position-relative col-sm-10">
                                    <input type="text" class="asColorpicker4 form-control input-color" [value]="color4"
                                        readonly />
                                    <button [style.background]="color4" [value]="color4" [(colorPicker)]="color4"
                                        class="cp" id="cp4"></button>
                                    &nbsp; <button class="btn btn-primary btn-1"
                                        (click)="displayImageColorPicker(4)">Add Colour from Logo</button>
                                </div>
                            </div>
                            <div class="colorpicker-btn text-end"><button class="btn btn-primary btn-1"
                                    (click)="saveColor()">Save Colour</button></div>
                        </form>
                    </div>

                    <div [hidden]="selectedSettingType !== 'fonts-colors'"
                        *ngIf="isGroupVisible(client.group.setting_menu_font)"
                        [ngClass]="{ active: defaultTab === 'font' }" class="tab-pane" id="fontColors" role="tabpanel">
                        <p class="gray4-color">Colour palettes allow you to save colours to use in the template
                            builder as well as change the theme of MyDesign, please note the first colour is only
                            used for the MyDesign theme.</p>

                        <form class="form-horizontal">
                            <div class="d-flex form-group">
                                <label class="col-sm-2 control-label text-left darkblue-color">Main Headings
                                </label>
                                <div class="position-relative col-sm-10">
                                    <input type="text" class="asColorpicker6 form-control input-color" [value]="color6"
                                        readonly />
                                    <button [style.background]="color6" [value]="color6" [(colorPicker)]="color6"
                                        class="cp" id="cp6"></button>
                                    &nbsp; <button class="btn btn-primary btn-1"
                                        (click)="displayImageColorPicker(6)">Add Colour from Logo</button>
                                </div>
                            </div>

                            <div class="d-flex form-group">
                                <label class="col-sm-2 control-label text-left darkblue-color">Sub Headings </label>
                                <div class="position-relative col-sm-10">
                                    <input type="text" class="asColorpicker7 form-control input-color" [value]="color7"
                                        readonly />
                                    <button [style.background]="color7" [value]="color7" [(colorPicker)]="color7"
                                        class="cp" id="cp7"></button>
                                    &nbsp; <button class="btn btn-primary btn-1"
                                        (click)="displayImageColorPicker(7)">Add Colour from Logo</button>
                                </div>
                            </div>

                            <div class="d-flex form-group">
                                <label class="col-sm-2 control-label text-left darkblue-color">Body Text </label>
                                <div class="position-relative col-sm-10">
                                    <input type="text" class="asColorpicker9 form-control input-color" [value]="color9" readonly />
                                    <button [style.background]="color9" [value]="color9" [(colorPicker)]="color9" class="cp" id="cp9"></button>
                                    &nbsp; <button class="btn btn-primary btn-1" (click)="displayImageColorPicker(9)">Add Colour from Logo</button>
                                </div>
                            </div>

                            <div class="d-flex form-group">
                                <label class="col-sm-2 control-label text-left darkblue-color">Inverted Body Text </label>
                                <div class="position-relative col-sm-10">
                                    <input type="text" class="asColorpicker10 form-control input-color" [value]="color10" readonly />
                                    <button [style.background]="color10" [value]="color10" [(colorPicker)]="color10" class="cp" id="cp10"></button>
                                    &nbsp; <button class="btn btn-primary btn-1" (click)="displayImageColorPicker(10)">Add Colour from Logo</button>
                                </div>
                            </div>
                            
                            <div class="colorpicker-btn text-end"><button class="btn btn-primary btn-1"
                                    (click)="saveColor()">Save Colour</button></div>
                        </form>
                    </div>


                    <div [hidden]="selectedSettingType !== 'icons'"
                        *ngIf="isGroupVisible(client.group.setting_menu_icon)"
                        [ngClass]="{ active: defaultTab === 'icon' }" class="tab-pane" id="icon" role="tabpanel">
                        <form class="form-horizontal">
                            <p class="icon-title">PRIMARY ICONS <span class="icon-sub">on white backgrounds</span>
                            </p>
                            <div class="d-flex form-group">
                                <label class="col-sm-2 control-label text-left darkblue-color">Bed Icon </label>
                                <div class="col-sm-10">
                                    <ng-container *ngFor="let icon of icons | icons">
                                        <ng-container
                                            *ngIf="icon.category_type == 'Primary' && icon.icon_type == 'Bed'">
                                            <div class="icon-table">
                                                <div [class.icon-holder-active]="icon.icon_url == client?.primary_icon_bed"
                                                    class="icon-holder"
                                                    (click)="changeIcon('primary_icon_bed', icon.icon_url, $event)">
                                                    <img class="icon-img primary_icon_bed" src="{{ icon.icon_url }}" />
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="d-flex form-group">
                                <label class="col-sm-2 control-label text-left darkblue-color">Bath Icon </label>
                                <div class="col-sm-10">
                                    <ng-container *ngFor="let icon of icons | icons">
                                        <ng-container
                                            *ngIf="icon.category_type == 'Primary' && icon.icon_type == 'Bath'">
                                            <div class="icon-table">
                                                <div [class.icon-holder-active]="icon.icon_url == client?.primary_icon_bath"
                                                    class="icon-holder"
                                                    (click)="changeIcon('primary_icon_bath', icon.icon_url, $event)">
                                                    <img class="icon-img primary_icon_bath" src="{{ icon.icon_url }}" />
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="d-flex form-group">
                                <label class="col-sm-2 control-label text-left darkblue-color">Car Icon </label>
                                <div class="col-sm-10">
                                    <ng-container *ngFor="let icon of icons | icons">
                                        <ng-container
                                            *ngIf="icon.category_type == 'Primary' && icon.icon_type == 'Car'">
                                            <div class="icon-table">
                                                <div [class.icon-holder-active]="icon.icon_url == client?.primary_icon_car"
                                                    class="icon-holder"
                                                    (click)="changeIcon('primary_icon_car', icon.icon_url, $event)">
                                                    <img class="icon-img icon-primary-car" src="{{ icon.icon_url }}" />
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>

                            <p class="icon-title">SECONDARY ICONS <span class="icon-sub">on coloured
                                    backgrounds</span></p>

                            <div class="d-flex form-group">
                                <label class="col-sm-2 control-label text-left darkblue-color">Bed Icon </label>
                                <div class="col-sm-10">
                                    <ng-container *ngFor="let icon of icons | icons">
                                        <ng-container
                                            *ngIf="icon.category_type == 'Secondary' && icon.icon_type == 'Bed'">
                                            <div class="icon-table">
                                                <div [class.icon-holder-active]="icon.icon_url == client?.secondary_icon_bed"
                                                    class="icon-holder-dark"
                                                    (click)="changeIcon('secondary_icon_bed', icon.icon_url, $event)">
                                                    <img class="icon-img secondary_icon_bed"
                                                        src="{{ icon.icon_url }}" />
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="d-flex form-group">
                                <label class="col-sm-2 control-label text-left darkblue-color">Bath Icon </label>
                                <div class="col-sm-10">
                                    <ng-container *ngFor="let icon of icons | icons">
                                        <ng-container
                                            *ngIf="icon.category_type == 'Secondary' && icon.icon_type == 'Bath'">
                                            <div class="icon-table">
                                                <div [class.icon-holder-active]="icon.icon_url == client?.secondary_icon_bath"
                                                    class="icon-holder-dark"
                                                    (click)="changeIcon('secondary_icon_bath', icon.icon_url, $event)">
                                                    <img class="icon-img secondary_icon_bath"
                                                        src="{{ icon.icon_url }}" />
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="d-flex form-group">
                                <label class="col-sm-2 control-label text-left darkblue-color">Car Icon </label>
                                <div class="col-sm-10">
                                    <ng-container *ngFor="let icon of icons | icons">
                                        <ng-container
                                            *ngIf="icon.category_type == 'Secondary' && icon.icon_type == 'Car'">
                                            <div class="icon-table">
                                                <div [class.icon-holder-active]="icon.icon_url == client?.secondary_icon_car"
                                                    class="icon-holder-dark"
                                                    (click)="changeIcon('secondary_icon_car', icon.icon_url, $event)">
                                                    <img class="icon-img secondary_icon_car"
                                                        src="{{ icon.icon_url }}" />
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div [hidden]="!isLinksSection(selectedSettingType)"
                        *ngIf="isGroupVisible(client.group.setting_menu_link)"
                        [ngClass]="{'active': defaultTab === 'link'}" class="tab-pane" id="links" role="tabpanel">
                        <form [formGroup]="linksForm" class="form-horizontal">
                            <div [hidden]="selectedSettingType !== 'website-links'">
                                <div class="d-flex form-group">
                                    <label for="form_website" class="col-sm-1">Website URL</label>
                                    <div class="col-sm-5">
                                        <input type="url" formControlName="link_website"
                                            [ngClass]="{ 'invalid-url': url.link_website.status === 'INVALID' }"
                                            class="form-control" id="form_website" autocomplete="off"
                                            value="{{ client?.link_website }}" />
                                    </div>
                                </div>
                                <div class="d-flex form-group">
                                    <label for="form_short_website" class="col-sm-1">Short Website URL</label>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="link_short_website"
                                            [ngClass]="{ 'invalid-url': url.link_short_website.status === 'INVALID' }"
                                            class="form-control" id="form_short_website" autocomplete="off"
                                            value="{{ client?.link_short_website }}" />
                                        <span class="shortWebsite">The short website URL field is used to display your
                                            website address in an easier to read format, for example instead of
                                            http://www.mywebsite.com you can use mywebsite.com</span>
                                    </div>
                                </div>
                                <div [class.hidden]="authUser?.provider === 'idashboard'">
                                    <div class="d-flex form-group">
                                        <label for="form_buy" class="col-sm-1">Buy</label>
                                        <div class="col-sm-5">
                                            <input type="text" formControlName="link_buy"
                                                [ngClass]="{ 'invalid-url': url.link_buy.status === 'INVALID' }"
                                                class="form-control" id="form_buy" autocomplete="off"
                                                value="{{ client?.link_buy }}" />
                                        </div>
                                    </div>
                                    <div class="d-flex form-group">
                                        <label for="form_sell" class="col-sm-1">Sell</label>
                                        <div class="col-sm-5">
                                            <input type="text" formControlName="link_sell"
                                                [ngClass]="{ 'invalid-url': url.link_sell.status === 'INVALID' }"
                                                class="form-control" id="form_sell" autocomplete="off"
                                                value="{{ client?.link_sell }}" />
                                        </div>
                                    </div>
                                    <div class="d-flex form-group">
                                        <label for="form_rent" class="col-sm-1">Rent</label>
                                        <div class="col-sm-5">
                                            <input type="text" formControlName="link_rent"
                                                [ngClass]="{ 'invalid-url': url.link_rent.status === 'INVALID' }"
                                                class="form-control" id="form_rent" autocomplete="off"
                                                value="{{ client?.link_rent }}" />
                                        </div>
                                    </div>
                                    <div class="d-flex form-group">
                                        <label for="form_home" class="col-sm-1">Home</label>
                                        <div class="col-sm-5">
                                            <input type="text" formControlName="link_home"
                                                [ngClass]="{ 'invalid-url': url.link_home.status === 'INVALID' }"
                                                class="form-control" id="form_home" autocomplete="off"
                                                value="{{ client?.link_home }}" />
                                        </div>
                                    </div>
                                    <div class="d-flex form-group">
                                        <label for="form_about" class="col-sm-1">About</label>
                                        <div class="col-sm-5">
                                            <input type="text" formControlName="link_about"
                                                [ngClass]="{ 'invalid-url': url.link_about.status === 'INVALID' }"
                                                class="form-control" id="form_about" autocomplete="off"
                                                value="{{ client?.link_about }}" />
                                        </div>
                                    </div>
                                    <div class="d-flex form-group">
                                        <label for="form_careers" class="col-sm-1">Careers</label>
                                        <div class="col-sm-5">
                                            <input type="text" formControlName="link_careers"
                                                [ngClass]="{ 'invalid-url': url.link_careers.status === 'INVALID' }"
                                                class="form-control" id="form_careers" autocomplete="off"
                                                value="{{ client?.link_careers }}" />
                                        </div>
                                    </div>
                                    <div class="d-flex form-group">
                                        <label for="form_blog" class="col-sm-1">Blog</label>
                                        <div class="col-sm-5">
                                            <input type="text" formControlName="link_blog"
                                                [ngClass]="{ 'invalid-url': url.link_blog.status === 'INVALID' }"
                                                class="form-control" id="form_blog" autocomplete="off"
                                                value="{{ client?.link_blog }}" />
                                        </div>
                                    </div>
                                    <div class="d-flex form-group">
                                        <label for="form_contact" class="col-sm-1">Contact</label>
                                        <div class="col-sm-5">
                                            <input type="text" formControlName="link_contact"
                                                [ngClass]="{ 'invalid-url': url.link_contact.status === 'INVALID' }"
                                                class="form-control" id="form_contact" autocomplete="off"
                                                value="{{ client?.link_contact }}" />
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="d-flex form-group">
                                        <label class="col-sm-1">&nbsp;</label>
                                        <div class="col-sm-10">
                                            <button type="submit" class="btn-admin btn-admin-primary"
                                                (click)="saveLinks()">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div [hidden]="selectedSettingType !== 'social-links'">
                                <div class="d-flex form-group">
                                    <label for="form_fb" class="col-sm-1">Facebook</label>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="link_facebook"
                                            [ngClass]="{ 'invalid-url': url.link_facebook.status === 'INVALID' }"
                                            class="form-control" id="form_fb" autocomplete="off"
                                            value="{{ client?.link_facebook }}" />
                                    </div>
                                </div>
                                <div class="d-flex form-group">
                                    <label for="form_tw" class="col-sm-1">Twitter</label>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="link_twitter"
                                            [ngClass]="{ 'invalid-url': url.link_twitter.status === 'INVALID' }"
                                            class="form-control" id="form_tw" autocomplete="off"
                                            value="{{ client?.link_twitter }}" />
                                    </div>
                                </div>
                                <div class="d-flex form-group">
                                    <label for="form_yt" class="col-sm-1">Youtube</label>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="link_youtube"
                                            [ngClass]="{ 'invalid-url': url.link_youtube.status === 'INVALID' }"
                                            class="form-control" id="form_yt" autocomplete="off"
                                            value="{{ client?.link_youtube }}" />
                                    </div>
                                </div>
                                <div class="d-flex form-group">
                                    <label for="form_ig" class="col-sm-1">Instagram</label>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="link_instagram"
                                            [ngClass]="{ 'invalid-url': url.link_instagram.status === 'INVALID' }"
                                            class="form-control" id="form_ig" autocomplete="off"
                                            value="{{ client?.link_instagram }}" />
                                    </div>
                                </div>
                                <div class="d-flex form-group">
                                    <label for="form_ig" class="col-sm-1">LinkedIn</label>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="link_linkedin"
                                            [ngClass]="{ 'invalid-url': url.link_linkedin.status === 'INVALID' }"
                                            class="form-control" id="form_linkedin" autocomplete="off"
                                            value="{{ client?.link_linkedin }}" />
                                    </div>
                                </div>
                                <div class="d-flex form-group">
                                    <label for="form_ig" class="col-sm-1">Pinterest</label>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="link_pinterest"
                                            [ngClass]="{ 'invalid-url': url.link_pinterest.status === 'INVALID' }"
                                            class="form-control" id="form_pinterest" autocomplete="off"
                                            value="{{ client?.link_pinterest }}" />
                                    </div>
                                </div>
                                <div class="d-flex form-group" [class.hidden]="frontService.authService.isUk()">
                                    <label for="form_ig" class="col-sm-1">Rate My Agent</label>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="link_ratemyagent"
                                            [ngClass]="{ 'invalid-url': url.link_ratemyagent.status === 'INVALID' }"
                                            class="form-control" id="form_ratemyagent" autocomplete="off"
                                            value="{{ client?.link_ratemyagent }}" />
                                    </div>
                                </div>
                                <hr />
                                <div class="d-flex form-group">
                                    <label class="col-sm-1">&nbsp;</label>
                                    <div class="col-sm-10">
                                        <button type="submit" class="btn-admin btn-admin-primary"
                                            (click)="saveLinks()">Save</button>
                                    </div>
                                </div>
                            </div>
                            <div [hidden]="selectedSettingType !== 'disclaimers'">
                                <h3>Disclaimer</h3>
                                <div class="d-flex form-group" [class.hidden]="authUser?.provider === 'idashboard'">
                                    <label for="form_ig" class="col-sm-1">Email</label>
                                    <div class="col-sm-5">
                                        <textarea rows="5" formControlName="disclaimer_email" class="form-control"
                                            id="form_disclaimer_email"
                                            autocomplete="off">{{ client?.disclaimer_email }}</textarea>
                                    </div>
                                </div>
                                <div class="d-flex form-group">
                                    <label for="form_ig" class="col-sm-1">Print</label>
                                    <div class="col-sm-5">
                                        <textarea rows="5" formControlName="disclaimer_print" class="form-control"
                                            id="form_disclaimer_print"
                                            autocomplete="off">{{ client?.disclaimer_print }}</textarea>
                                    </div>
                                </div>
                                <h3>Legal</h3>
                                <div class="d-flex form-group">
                                    <label for="form_ig" class="col-sm-1">Legal 1</label>
                                    <div class="col-sm-5">
                                        <textarea rows="5" formControlName="legal_1" class="form-control"
                                            id="form_legal_1" autocomplete="off">{{ client?.legal_1 }}</textarea>
                                    </div>
                                </div>
                                <div class="d-flex form-group">
                                    <label for="form_ig" class="col-sm-1">Legal 2</label>
                                    <div class="col-sm-5">
                                        <textarea rows="5" formControlName="legal_2" class="form-control"
                                            id="form_legal_2" autocomplete="off">{{ client?.legal_2 }}</textarea>
                                    </div>
                                </div>
                                <div class="d-flex form-group">
                                    <label for="form_ig" class="col-sm-1">Legal 3</label>
                                    <div class="col-sm-5">
                                        <textarea rows="5" formControlName="legal_3" class="form-control"
                                            id="form_legal_1" autocomplete="off">{{ client?.legal_3 }}</textarea>
                                    </div>
                                </div>
                                <hr />
                                <div class="d-flex form-group">
                                    <label class="col-sm-1">&nbsp;</label>
                                    <div class="col-sm-10">
                                        <button type="submit" class="btn-admin btn-admin-primary"
                                            (click)="saveLinks()">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div [hidden]="selectedSettingType !== 'sync-agents'" class="tab-pane" id="sync" role="tabpanel">
                        <h3>Sync</h3>
                        <p class="gray4-color">Sync account content to your office account.</p>
                        <hr />
                        <div class="row">
                            <div class="col-sm-12">
                                <h4>
                                    Office Agents &nbsp;
                                    <button id="sync_agents" class="btn btn-primary btn-1" (click)="onSyncAgents()">Sync
                                        Agents</button>
                                </h4>
                                <br />
                                Total imported agents: <strong>{{ users?.length }}</strong><br /><br />
                                <div class="d-flex flex-wrap">
                                    <p class="col-sm-3" *ngFor="let user of users">( {{ user.agent_id }} )
                                        {{ user.firstname + " " + user.lastname }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div [hidden]="selectedSettingType !== 'agency-details'" class="tab-pane" id="agency"
                        role="tabpanel">
                        <form [formGroup]="agencyForm" (ngSubmit)="onAgencySubmit()" class="form-horizontal">
                            <h3>Agency Details</h3>
                            <p>Your agency details are synced from your account on each login, after making any
                                changes make sure you lock the field to stop syncing this from your account</p>
                            <hr />
                            <div class="d-flex form-group">
                                <div class="col-sm-6">&nbsp;</div>
                                <div class="col-sm-2">Is Locked?</div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="franchise_name" class="col-sm-1">Franchise Name</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="franchise_name" class="form-control"
                                        id="franchise_name" autocomplete="off" value="{{ client?.franchise_name }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="franchise_name"
                                        checked="{{ isLocked('franchise_name') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group" [class.hidden]="authUser?.provider === 'idashboard'">
                                <label for="company_name" class="col-sm-1">Company Name</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="company_name" class="form-control"
                                        id="company_name" autocomplete="off" value="{{ client?.company_name }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="company_name"
                                        checked="{{ isLocked('company_name') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="name" class="col-sm-1">Office Name</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="name" class="form-control" id="name"
                                        autocomplete="off" value="{{ client?.name }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="name"
                                        checked="{{ isLocked('name') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group" [class.hidden]="authUser?.provider === 'idashboard'">
                                <label for="branch_name" class="col-sm-1">Branch Name</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="branch_name" class="form-control"
                                        id="branch_name" autocomplete="off" value="{{ client?.branch_name }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="branch_name"
                                        checked="{{ isLocked('branch_name') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="street" class="col-sm-1">Street</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="street" class="form-control" id="street"
                                        autocomplete="off" value="{{ client?.street }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="street"
                                        checked="{{ isLocked('street') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="suburb" class="col-sm-1">Suburb</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="suburb" class="form-control" id="suburb"
                                        autocomplete="off" value="{{ client?.suburb }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="suburb"
                                        checked="{{ isLocked('suburb') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="state" class="col-sm-1">State</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="state" class="form-control" id="state"
                                        autocomplete="off" value="{{ client?.state }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="state"
                                        checked="{{ isLocked('state') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="postcode" class="col-sm-1">Postcode</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="postcode" class="form-control" id="postcode"
                                        autocomplete="off" value="{{ client?.postcode }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="postcode"
                                        checked="{{ isLocked('postcode') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="country" class="col-sm-1">Country</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="country" class="form-control" id="country"
                                        autocomplete="off" value="{{ client?.country }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="country"
                                        checked="{{ isLocked('country') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="postal_street" class="col-sm-1">Postal Street</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="postal_street" class="form-control"
                                        id="postal_street" autocomplete="off" value="{{ client?.postal_street }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="postal_street"
                                        checked="{{ isLocked('postal_street') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="postal_suburb" class="col-sm-1">Postal Suburb</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="postal_suburb" class="form-control"
                                        id="postal_suburb" autocomplete="off" value="{{ client?.postal_suburb }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="postal_suburb"
                                        checked="{{ isLocked('postal_suburb') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="postal_state" class="col-sm-1">Postal State</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="postal_state" class="form-control"
                                        id="postal_state" autocomplete="off" value="{{ client?.postal_state }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="postal_state"
                                        checked="{{ isLocked('postal_state') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="postal_postcode" class="col-sm-1">Postal Postcode</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="postal_postcode" class="form-control"
                                        id="postal_postcode" autocomplete="off" value="{{ client?.postal_postcode }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="postal_postcode"
                                        checked="{{ isLocked('postal_postcode') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="phone" class="col-sm-1">Phone</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="phone" class="form-control" id="phone"
                                        autocomplete="off" value="{{ client?.phone }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="phone"
                                        checked="{{ isLocked('phone') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="fax" class="col-sm-1">Fax</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="fax" class="form-control" id="fax"
                                        autocomplete="off" value="{{ client?.fax }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="fax" checked="{{ isLocked('fax') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="abn" class="col-sm-1">ABN</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="abn" class="form-control" id="abn"
                                        autocomplete="off" value="{{ client?.abn }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="abn" checked="{{ isLocked('abn') }}" />
                                </div>
                            </div>
                            <div class="d-flex form-group">
                                <label for="email" class="col-sm-1">Email</label>
                                <div class="col-sm-5">
                                    <input type="text" formControlName="email" class="form-control" id="email"
                                        autocomplete="off" value="{{ client?.email }}" />
                                </div>
                                <div class="col-sm-3">
                                    <input type="checkbox" name="field[]" value="email"
                                        checked="{{ isLocked('email') }}" />
                                </div>
                            </div>
                            <hr />
                            <div class="d-flex form-group">
                                <label class="col-sm-1">&nbsp;</label>
                                <div class="col-sm-10">
                                    <button type="submit" class="btn-admin btn-admin-primary">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div [hidden]="selectedSettingType !== 'designs'" class="tab-pane" id="templates" role="tabpanel">
                        <h3>Delete All Designs</h3>
                        <p class="gray4-color">Sometimes you have too many Designs created which can take some time
                            to delete them all one by one, here you can delete all your Designs at once.</p>
                        <p class="gray4-color"><i>Please note this excludes Quick Designs or Designs shared.</i></p>
                        <hr>
                        <div class="row">
                            <div class="col-sm-12">
                                <h4>
                                    <button class="btn btn-primary btn-1" (click)="onDeleteTemplates()">Delete my
                                        Designs</button>
                                    <button class="btn btn-primary btn-1 delete-all"
                                        (click)="onDeleteAllTemplates()">Delete all Designs</button>
                                </h4>
                                <br>
                            </div>
                        </div>
                    </div>

                    <div [hidden]="selectedSettingType !== 'agent-access'" class="tab-pane" id="agents" role="tabpanel">
                        <h3>Agent Access</h3>
                        <p class="gray4-color">Allow or disallow all agents admin rights</p>
                        <hr>
                        <div class="row">
                            <div class="col-sm-12">
                                <form [formGroup]="agentSettingsForm" class="form-horizontal">
                                    <div class="d-flex form-group">
                                        <label for="email" class="col-sm-2">Allow all agents access to settings and
                                            view all templates within the office</label>
                                        <div class="col-sm-5">
                                            <label class="allowSettings"><input type="radio"
                                                    formControlName="allow_agent_settings" name="allow_agent_settings"
                                                    value="0" [checked]="(client?.allow_agent_settings === 0)"> No
                                                &emsp;</label>
                                            <label><input type="radio" formControlName="allow_agent_settings"
                                                    name="allow_agent_settings" value="1"
                                                    [checked]="(client?.allow_agent_settings === 1)"> Yes </label>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="form-group">
                                        <div class="col-sm-12">
                                            <button type="submit" class="btn-admin btn-admin-primary"
                                                (click)="onAgentSettingsSubmit()">Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal" id="modalImageCpicker" aria-hidden="true" aria-labelledby="modalImageCpicker" role="dialog"
    tabindex="-1" style="display: none">
    <div class="modal-dialog modal-center modal-large">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close close-outside" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title">Image Color Picker</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-7">
                        <div class="btn_clearfix">
                            <span id="error_msg_image" style="color: red; display: none">Please upload your own logo in
                                our Logo tab page</span>
                        </div>
                        <canvas #canvasEl id="colorPickercanvas" width="500" height="386"></canvas>
                    </div>
                    <div class="col-md-5 column2">
                        <div>Preview:</div>
                        <div id="preview" style="background-color: rgba(0, 0, 0, 0)"></div>
                        <div>Color:</div>
                        <div class="insertcolor">R: <input type="text" id="rVal" readonly="" class="color" /></div>
                        <div class="insertcolor">G: <input type="text" id="gVal" readonly="" class="color" /></div>
                        <div class="insertcolor">B: <input type="text" id="bVal" readonly="" class="color" /></div>
                        <div class="insertcolor">RGB: <input type="text" id="rgbVal" readonly="" class="color" /></div>
                        <div class="insertcolor">RGBA: <input type="text" id="rgbaVal" readonly="" class="color" />
                        </div>
                        <div class="insertcolor">HEX: <input type="text" id="hexVal" readonly="" class="color" /></div>
                    </div>
                    <div style="clear: both"></div>
                </div>
            </div>
            <div class="modal-footer justify-content-start">
                <button type="button" class="btn btn-1" data-bs-dismiss="modal">Add</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isSetup" class="modal fade" id="firstLoginVideo" tabindex="-1" role="dialog"
    aria-labelledby="firstLoginVideoTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <iframe width="900" height="600" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>

<!-- End Modal -->

<ngx-loading [show]="loading"></ngx-loading>