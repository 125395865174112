import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BlockService } from '../services/block.service';
import { ClientService } from '../services/client.service';
import { ElementService } from '../services/element.service';
import { GroupService } from '../services/group.service';
import { QuickDesignService } from '../services/quick-deisgn.service';
import { MediaAttributeService } from '../services/media-attribute.service';
import { DepartmentService } from '../services/department.service';
import { MasterService } from '../services/master.service';
import { StyleService } from '../services/style.service';

import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';

import { FrontComponent } from './front.component';
import { SharedModule } from '../shared/shared.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SettingsComponent } from './settings/settings.component';
import { CustomRequestComponent } from './custom-request/custom-request.component';
import { LibraryComponent } from './library/library.component';
import { TemplatesFrontComponent } from './templates-front/templates-front.component';
import { AnalyticsFrontComponent } from './analytics-front/analytics-front.component';
import { FrontRouteModule } from './front.routing.module';
import { FrontService } from './front.service';
import { Select2Module } from 'ng2-select2';
import { TemplateService } from '../services/template.service';
import { TemplateFrontBuildComponent } from './templates-front/template-front-build/template-front-build.component';
import { TemplateFrontDashboardComponent } from './templates-front/template-front-dashboard/template-front-dashboard.component';
import { TemplateFrontSharedComponent } from './templates-front/template-front-shared/template-front-shared.component';
import { TemplateFrontFoldersComponent } from './templates-front/template-front-folders/template-front-folders.component';

import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';
import { HelpComponent } from './help/help.component';
import { TrainingVideosComponent } from './help/training-videos/training-videos.component';
import { FaqComponent } from './help/faq/faq.component';
import { SupportComponent } from './help/support/support.component';

import { AnalyticsTopUsedTemplatesComponent } from './analytics-front/analytics-top-used-templates/analytics-top-used-templates.component';
import { AnalyticsLatestActivityComponent } from './analytics-front/analytics-latest-activity/analytics-latest-activity.component';
import { AnalyticsLoginReportComponent } from './analytics-front/analytics-login-report/analytics-login-report.component';
import { AnalyticsTemplateReportComponent } from './analytics-front/analytics-template-report/analytics-template-report.component';

import { TemplateFrontArchiveComponent } from './templates-front/template-front-archive/template-front-archive.component';

import { SelectModule } from 'ng2-select';
import { VideoService } from '../services/video.service';
import { FaqService } from '../services/faq.service';
import { SupportService } from '../services/support.service';

import { FilterPipe } from '../pipes/filter.pipe';
import { UserLogService } from '../services/user-log.service';
import { TemplateGroupComponent } from './templates-front/template-front-shared/template-group/template-group.component';
import { TemplateOfficeComponent } from './templates-front/template-front-shared/template-office/template-office.component';
import { TemplateFranchiseComponent } from './templates-front/template-front-shared/template-franchise/template-franchise.component';
import { TemplateAgentSharedComponent } from './templates-front/template-front-shared/template-agent-shared/template-agent-shared.component';
import { FaqCategoryService } from '../services/faqcategory.service';
import { AccordionPipe } from '../pipes/accordion.pipe';
import { IconsPipe } from '../pipes/icons.pipe';

// import { ImageLibraryService } from './imagelibrary.service';
import { UserService } from '../services/user.service';

import { ChangelogsFrontComponent } from './help/changelogs-front/changelogs-front.component';
import { PasteService } from '../services/paste.service';
import { TemplateSubgroupComponent } from './templates-front/template-front-shared/template-subgroup/template-subgroup.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FrontWebsitesComponent } from './front-websites/front-websites.component';
import { WebsiteService } from '../services/website.service';
import { FrontContentMarketplaceComponent } from './front-content-marketplace/front-content-marketplace.component';
import { FrontCampaignsComponent } from './front-campaigns/front-campaigns.component';
import { CampaignViewComponent } from './front-campaigns/campaign-view/campaign-view.component';
import { CampaignGenerateComponent } from './front-campaigns/campaign-generate/campaign-generate.component';
import { AgentPipe } from '../pipes/agent.pipe';
import { ClientPipe } from '../pipes/client.pipe';

import { SlickModule } from 'ngx-slick';
import { FrontCorporateComponent } from './front-corporate/front-corporate.component';
import { FolderService } from '../services/folder.service';

import { DndModule } from 'ngx-drag-drop';
import { ColorPickerModule } from 'ngx-color-picker';
import { PropertySearchService } from '../shared/property-search/property-search.service';


import { SubscriptionsComponent } from './settings/subscriptions/subscriptions.component';
import { BillingComponent } from './settings/billing/billing.component';
import { SettingsDefaultComponent } from './settings/settings-default/settings-default.component';
import { SearchFilterPipe } from '../pipes/search-filter.pipe';
import { SettingsIntegrationsComponent } from './settings/settings-integrations/settings-integrations.component';
@NgModule({
  declarations: [
    FrontComponent,
    SidebarComponent,
    SettingsComponent,
    CustomRequestComponent,
    LibraryComponent,
    TemplatesFrontComponent,
    AnalyticsFrontComponent,
    TemplateFrontBuildComponent,
    TemplateFrontDashboardComponent,
    TemplateFrontSharedComponent,
    TemplateFrontFoldersComponent,
    HelpComponent,
    TrainingVideosComponent,
    FaqComponent,
    SupportComponent,
    AnalyticsTopUsedTemplatesComponent,
    AnalyticsLatestActivityComponent,
    AnalyticsLoginReportComponent,
    AnalyticsTemplateReportComponent,
    FilterPipe,
    AccordionPipe,
    TemplateFrontArchiveComponent,
    TemplateGroupComponent,
    TemplateOfficeComponent,
    TemplateFranchiseComponent,
    TemplateAgentSharedComponent,
    IconsPipe,
    ChangelogsFrontComponent,
    TemplateSubgroupComponent,
    FrontWebsitesComponent,
    FrontContentMarketplaceComponent,
    FrontCampaignsComponent,
    CampaignViewComponent,
    CampaignGenerateComponent,
    AgentPipe,
    ClientPipe,
    FrontCorporateComponent,
    SubscriptionsComponent,
    BillingComponent,
    SettingsDefaultComponent,
    SearchFilterPipe,
    SettingsIntegrationsComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FrontRouteModule,
    SharedModule,
    BrowserAnimationsModule,
    Select2Module,
    InfiniteScrollModule,
    LoadingModule.forRoot({
      // animationType: ANIMATION_TYPES.rectangleBounce,
      animationType: ANIMATION_TYPES.circle,
      backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
      backdropBorderRadius: '3px',
      primaryColour: 'transparent',
      secondaryColour: '#fff',
      // tertiaryColour: '#FFF'
    }),
    SelectModule,
    MalihuScrollbarModule.forRoot(),
    SlickModule.forRoot(),
    DndModule,
    ColorPickerModule
  ],
  providers: [
    FrontService,
    ClientService,
    GroupService,
    ElementService,
    BlockService,
    QuickDesignService,
    MediaAttributeService,
    DepartmentService,
    MasterService,
    StyleService,
    TemplateService,
    VideoService,
    FaqService,
    SupportService,
    UserLogService,
    FaqCategoryService,
    UserService,
    PasteService,
    WebsiteService,
    FolderService,
    PropertySearchService
  ]
})
export class FrontModule { }
