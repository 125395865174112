import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ChangelogService } from '../../../services/changelogs.service';
import { Changelog } from '../../../models/changelog.model';
import { Subscription } from 'rxjs';
import { GroupService } from '../../../services/group.service';
import { Group } from '../../../models/group.model';

declare var CKEDITOR: any;
declare var jQuery: any;

@Component({
  selector: 'app-changelog-edit',
  templateUrl: './changelog-edit.component.html',
  styleUrls: ['./changelog-edit.component.scss']
})
export class ChangelogEditComponent implements OnInit {
  id: number;
  loading = false;
  editMode = false;
  backUrl: string;

  subscription: Subscription;
  subscriptionResponse: Subscription;
  subscriptionGroup: Subscription;

  changelog: Changelog;
  changelogForm: FormGroup;

  groups: Group[];

  constructor(private changelogService: ChangelogService, private route: ActivatedRoute,
    private router: Router, private groupService: GroupService) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        this.id = +params['id'];
        this.editMode = params['id'] != null;
        this.backUrl = this.editMode ? '../../' : '../';

        if (this.id) {
          this.loading = true;
          this.changelogService.httpGetChangelog(this.id);
        }
      }
    );

    CKEDITOR.replace('textarea', {
      toolbar: [
        { name: 'clipboard', items: [ 'Undo', 'Redo', '-',  ] },
        { name: 'basicstyles', groups: [ 'basicstyles' ], items: [ 'Bold', 'Italic', 'Underline', '-' ] },
        { name: 'paragraph', groups: [ 'list', 'align', 'justify' ], items: [ 'NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight'] },
        { name: 'styles', items: [ 'Format', 'Font', 'FontSize' ] },
        { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
        { name: 'links', items : [ 'Link', 'Unlink', '-', 'Sourcedialog' ] },
        { name: 'insert', items: [ 'Image'] },
      ]
    });

    this.subscription = this.changelogService.changelogChanged.subscribe(
      (changelog: Changelog) => {
        if ( typeof changelog.ID !== 'undefined' ) {
          this.changelog = changelog;
          this.loading = false;
          this.initFormEdit();
        }
      }
    );

    this.groupService.httpGetGroups();

    this.subscriptionResponse = this.changelogService.changelogOperation.subscribe(
      (changelog: Changelog) => {
        if ( typeof changelog.ID !== 'undefined' ) {
          this.loading = true;

          this.router.navigate([this.backUrl], {relativeTo: this.route});
        }
      }
    );

    this.subscriptionGroup = this.groupService.groupsChanged.subscribe(
      (groups: Group[]) => {
        this.groups = groups;
      }
    );

    this.initForm();
  }

  private initForm() {

    this.changelogForm = new FormGroup({
      'headline': new FormControl('A recent update has been made to MyDesign', Validators.required),
      'title': new FormControl('', Validators.required),
      'content': new FormControl(''),
      'provider': new FormControl('all', Validators.required),
      'group_id': new FormControl('', Validators.required),
      'is_hidden': new FormControl('', Validators.required),
      'is_button_hidden': new FormControl('', Validators.required),
      'reset_to_users': new FormControl('0')
    });
  }

  private initFormEdit() {

    CKEDITOR.instances.textarea.setData(this.changelog.content);

    this.changelogForm.patchValue({
      'headline': this.changelog.headline,
      'title': this.changelog.title,
      'content': this.changelog.content,
      'provider': this.changelog.provider,
      'group_id': this.changelog.group_id,
      'is_hidden': this.changelog.is_hidden,
      'is_button_hidden': this.changelog.is_button_hidden
    });
  }

  onSubmit() {
    const changelogValues = this.changelogForm.value;
    changelogValues['content'] = CKEDITOR.instances.textarea.getData();

    this.loading = true;
    if ( this.editMode ) {
      this.changelogService.httpPutChangelog(this.id, changelogValues);
    } else {
      this.changelogService.httpPostChangelog(changelogValues);
    }

  }

}
