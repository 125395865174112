import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRouteModule } from './app.routing.module';
import { AdminModule } from './admin/admin.module';

import { FrontModule } from './front/front.module';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';

import { APP_CONFIG, AppConfig } from './app.config';
import { LoadingModule } from 'ngx-loading';
import { MyDesktopService } from './services/mydesktop.service';
import { AuthService } from './services/auth.service';
import { CookieService } from 'ng2-cookies';
import { AuthGuard } from './services/auth-guard.service';
import { AuthBlockGuard } from './services/auth-block-guard.service';
import { AuthAdminGuard } from './services/auth-admin-guard.service';
import { OptionService } from './services/option.service';
import { MaintenanceGuard } from './services/maintenance-guard.service';

import { AuthInterceptor } from './shared/auth.interceptor';
import { ParticaService } from './services/partica.service';
import { VaultService } from './services/vault.service';
import { CampaignService } from './services/campaign.service';
import { GlobalService } from './services/global.service';
import { DomainService } from './services/domain.service';
import { AccountService } from './services/account.service';
import { CustomCategoryService } from './services/customcategory.service';
import { PopupService } from './services/popup.service';
import { ToastrModule } from 'ngx-toastr';
import { ThirdPartyService } from './services/thirdparty.service';
import { PricefinderService } from './services/pricefinder.service';
import { EnvironmentService } from './services/environment.service';
import { VendorReportService } from './services/vendorreport.service';
import { IdashboardService } from './services/idashboard.service';
import { WebBookService } from './services/webbook.service';
import { ProductService } from './services/product.service';
import { SubscriptionService } from './services/subscription.service';
import { ProposalsTemplateService } from './services/proposals-template.service';
import { ProposalService } from './services/proposal.service';
import { WebbooksTemplateService } from './services/webbooks-template.service';
import { ProBlockService } from './services/pro-block.service';
import { ProElementService } from './services/pro-element.service';
import { ProThemeService } from './services/pro-theme.service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    AdminModule,
    FrontModule,
    AppRouteModule,
    AuthModule,
    BrowserAnimationsModule,
    LoadingModule,
    ToastrModule.forRoot()
  ],
  providers: [
    GlobalService,
    MyDesktopService,
    AuthService,
    AuthGuard,
    AuthBlockGuard,
    AuthAdminGuard,
    CookieService,
    OptionService,
    MaintenanceGuard,
    ParticaService,
    VaultService,
    DomainService,
    CampaignService,
    AccountService,
    CustomCategoryService,
    PopupService,
    ThirdPartyService,
    PricefinderService,
    EnvironmentService,
    VendorReportService,
    IdashboardService,
    WebBookService,
    ProductService,
    SubscriptionService,
    ProposalsTemplateService,
    ProposalService,
    WebbooksTemplateService,
    ProBlockService,
    ProElementService,
    ProThemeService,
    {provide: APP_CONFIG, useValue: AppConfig},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
