import { Component, OnInit, OnDestroy } from '@angular/core';
import { FrontService } from '../../front.service';
import { Auth } from '../../../models/auth.model';
import { Subject } from 'rxjs';
import { SubscriptionService } from '../../../services/subscription.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;

  authUser: Auth;
  userInvoices = [];
  clientInvoices = [];

  nextDueDate = '';

  constructor(
    public frontService: FrontService,
    private subscriptionService: SubscriptionService,
    private router: Router
  ) { }

  ngOnInit() {
    this.nextDueDate = moment().add(1, 'M').startOf('month').format('DD/MM/YYYY');

    this.loading = true;
    this.authUser = this.frontService.authService.auth;
    this.subscriptionService.httpGetHealth();

    this.subscriptionService.onGetHealth
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.subscriptionService.httpGetInvoices();
            }

            if (response.status === 'error') {
              this.router.navigateByUrl('/help/support');
              swal('Our billing platform is offline at the moment', 'Please try again later.', 'error');
            }
          }
        }
      }
    );

    this.subscriptionService.onGetInvoices
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.userInvoices = response.data;

            this.subscriptionService.httpGetClientInvoices();
          }
        }
      }
    );

    this.subscriptionService.onGetClientInvoices
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.clientInvoices = response.data;
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
