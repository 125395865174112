import { Component, OnInit, OnDestroy } from '@angular/core';
import { MasterService } from '../../../services/master.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AdminService } from '../../admin.service';
import { Master } from '../../../models/master.model';
import { Subscription } from 'rxjs/Subscription';
import { GroupService } from '../../../services/group.service';
import { Group } from '../../../models/group.model';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

declare var jQuery: any;
declare const $: any;
declare var swal: any;

@Component({
  selector: 'app-masters',
  templateUrl: './masters.component.html',
  styleUrls: ['./masters.component.scss']
})
export class MastersComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject;
  loading = false;
  category: string;
  category_id: number;
  category_name = '';
  group_id = '';

  selectedGroup = '';

  dataTableHtml: any;

  masters: Master[];
  subscription: Subscription;

  categories = {
    'enewsletter': {
      id: 1,
      name: 'eMarketing'
    },
    'buyer-match': {
      id: 2,
      name: 'Buyer Match'
    },
    'quick-enquiry': {
      id: 3,
      name: 'Quick Enquiry'
    },
    'brochure': {
      id: 4,
      name: 'Brochure'
    },
    'property-guide': {
      id: 5,
      name: 'Property Guide'
    },
    'social-media': {
      id: 6,
      name: 'Social Media'
    },
    'email-signature': {
      id: 7,
      name: 'Email Signature'
    },
    'dl-card': {
      id: 8,
      name: 'DL Card'
    },
    'sign-board': {
      id: 9,
      name: 'Signboard'
    },
    'property-match': {
      id: 10,
      name: 'PDF Flyers'
    },
    'match-listings': {
      id: 11,
      name: 'Match Properties'
    },
    'buyer-match-vault': {
      id: 12,
      name: 'Buyer Match Vault'
    },
    'property-alerts': {
      id: 13,
      name: 'Property Alerts'
    },
    'pricefinder': {
      id: 14,
      name: 'Pricefinder'
    }
  };

  groups: Group[];
  groupsSubscription: Subscription;

  selectGroupForm: FormGroup;

  constructor(private adminService: AdminService,
    private masterService: MasterService,
    private route: ActivatedRoute,
    private router: Router,
    private groupService: GroupService
  ) {}

  ngOnInit() {
    this.groupService.httpGetGroups();

    this.route.params.subscribe(
      (params: Params) => {
        this.category = params['type'];
        this.category_id = this.categories[this.category].id;
        this.category_name = this.categories[this.category].name;

        if ( this.masterService.group_id !== 0 ) {
          this.loading = true;
          this.masterService.httpGetMasters(
            {
              category_id: this.category_id,
              group_id: this.masterService.group_id
            }
          );
        }

      }
    );

    this.groupsSubscription = this.groupService.groupsChanged.subscribe(
      (groups: any) => {
        this.groups = [];
        if ( groups.length > 0 ) {
          this.groups = groups;
        }
      }
    );

    this.subscription = this.masterService.mastersChanged.subscribe(
      (masters: any) => {
        if (  typeof masters !== 'undefined' && masters.status === 'success' ) {
          this.loading = false;
          this.masters = masters.data;

          if ($.fn.DataTable.isDataTable(this.dataTableHtml)) {
            this.dataTableHtml.destroy();
          }
          this.initDT();
        }
      }
    );

    this.masterService.masterDeletion
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.loading = false;

          swal(
            'Deleted!',
            'Your templates has been deleted.',
            'success'
          );

          if ( this.selectedGroup === '0' ) {
            this.masterService.httpGetMasters({
              category_id: this.category_id
            });
          } else {
            this.masterService.httpGetMasters(
              {
                category_id: this.category_id,
                group_id: this.selectedGroup
              }
            );
          }
        }
      }
    );

    this.initSelectGroupForm();
  }

  initSelectGroupForm() {
    this.selectGroupForm = new FormGroup({
      'group_id': new FormControl('')
    });

    if ( this.masterService.group_id !== 0 ) {
      this.selectGroupForm.patchValue({
        'group_id': this.masterService.group_id
      });
    }
  }

  initDT() {
    setTimeout(() => {
      this.dataTableHtml = $('#datatable-masters').DataTable({
        'responsive': true,
        'pagingType': 'full_numbers',
        'lengthMenu': [
          [50, 100, 200, -1],
          [50, 100, 200, 'All']
        ],
        'pageLength': 50,
        // responsive: true,
        stateSave: true,
        language: {
          search: '_INPUT_',
          searchPlaceholder: 'Search',
          'paginate': {
            'first': '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
            'previous': '<i aria-hidden="true" class="fa fa-angle-left"></i>',
            'next': '<i aria-hidden="true" class="fa fa-angle-right"></i>',
            'last': '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
          }
        },
        'fnDrawCallback': function (oSettings) {
          $('.dataTables_wrapper').find('input[type=search]').addClass('btn-search');
          $('.dataTables_wrapper').find('select').addClass('select_datatable');
        }
      });

    }, 500);
  }

  onChangeGroup(id) {
    this.masterService.group_id = id;
    this.selectedGroup = id;

    if (typeof this.masterService.group_id === 'undefined') {
      this.masterService.group_id = -1;
    }

    this.loading = true;
    if ( id === '0' ) {
      this.masterService.httpGetMasters({
        category_id: this.category_id
      });
    } else {
      this.masterService.httpGetMasters(
        {
          category_id: this.category_id,
          group_id: this.masterService.group_id
        }
      );
    }
  }

  onDelete(id: number) {
    const $this = this;
    swal({
      title: 'Are you sure you want to delete the template?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed'
    }).then(function () {
      $this.loading = true;
      
      $this.masterService.httpDeleteMaster(id);
    }).catch(swal.noop);
  }

  ngOnDestroy() {
    this.adminService.authService.cancelRequest();
    this.subscription.unsubscribe();
  }

}
