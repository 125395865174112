import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownDirective } from './dropdown.directive';
import { ImageLibraryService } from './imagelibrary.service';
import { LibraryPipe } from '../pipes/library.pipe';
import { ImageLibraryComponent } from '../image-library/image-library.component';
import { BrowserModule } from '@angular/platform-browser';
import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ParticaComponent } from './partica/partica.component';
import { SafeHtmlPipe } from '../pipes/safehtml.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PropertySearchComponent } from './property-search/property-search.component';
import { Select2Module } from 'ng2-select2';
import { ProgressLoaderComponent } from './progress-loader/progress-loader.component';
import { ProgressService } from './progress-loader/progress.service';
import { RouterModule } from '@angular/router';
import { PricefinderComponent } from './pricefinder/pricefinder.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ListingPipe } from '../pipes/listing.pipe';
import { SharedService } from './shared.service';
import { PropertySearchService } from './property-search/property-search.service';
import { DndModule } from 'ngx-drag-drop';
import { ClickOutsideDirective } from './click-outside.directive';
@NgModule({
  declarations: [
    DropdownDirective,
    ClickOutsideDirective,
    ImageLibraryComponent,
    LibraryPipe,
    ParticaComponent,
    SafeHtmlPipe,
    PropertySearchComponent,
    ProgressLoaderComponent,
    PricefinderComponent,
    PropertySearchComponent,
    ListingPipe
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    LoadingModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    Select2Module,
    RouterModule,
    DndModule,
  ],
  providers: [
    ImageLibraryService,
    ProgressService,
    SharedService,
    PropertySearchService,
  ],
  exports: [
    CommonModule,
    NgSelectModule,
    DropdownDirective,
    ClickOutsideDirective,
    ImageLibraryComponent,
    ParticaComponent,
    LibraryPipe,
    SafeHtmlPipe,
    InfiniteScrollModule,
    PropertySearchComponent,
    Select2Module,
    ProgressLoaderComponent,
    PricefinderComponent,
    PropertySearchComponent,
    ListingPipe,
  ],
  entryComponents: [
    ParticaComponent
  ]
})
export class SharedModule {}
