import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FrontComponent } from './front.component';
import { TemplatesFrontComponent } from './templates-front/templates-front.component';
import { AnalyticsFrontComponent } from './analytics-front/analytics-front.component';
import { TemplateFrontBuildComponent } from './templates-front/template-front-build/template-front-build.component';
import { TemplateFrontDashboardComponent } from './templates-front/template-front-dashboard/template-front-dashboard.component';
import { TemplateFrontSharedComponent } from './templates-front/template-front-shared/template-front-shared.component';
import { TemplateFrontFoldersComponent } from './templates-front/template-front-folders/template-front-folders.component';
import { LibraryComponent } from './library/library.component';
import { SettingsComponent } from './settings/settings.component';
import { CustomRequestComponent } from './custom-request/custom-request.component';
import { HelpComponent } from './help/help.component';
import { TrainingVideosComponent } from './help/training-videos/training-videos.component';
import { FaqComponent } from './help/faq/faq.component';
import { SupportComponent } from './help/support/support.component';
import { TemplateFrontArchiveComponent } from './templates-front/template-front-archive/template-front-archive.component';
import { AuthGuard } from '../services/auth-guard.service';
import { AuthBlockGuard } from '../services/auth-block-guard.service';
import { TemplateGroupComponent } from './templates-front/template-front-shared/template-group/template-group.component';
import { TemplateOfficeComponent } from './templates-front/template-front-shared/template-office/template-office.component';
import { TemplateFranchiseComponent } from './templates-front/template-front-shared/template-franchise/template-franchise.component';
import { TemplateAgentSharedComponent } from './templates-front/template-front-shared/template-agent-shared/template-agent-shared.component';
import { MaintenanceGuard } from '../services/maintenance-guard.service';

import { ChangelogsFrontComponent } from './help/changelogs-front/changelogs-front.component';

import { AuthResolver } from '../shared/auth.resolver';
import { TemplateSubgroupComponent } from './templates-front/template-front-shared/template-subgroup/template-subgroup.component';
import { FrontWebsitesComponent } from './front-websites/front-websites.component';
import { FrontContentMarketplaceComponent } from './front-content-marketplace/front-content-marketplace.component';
import { FrontCampaignsComponent } from './front-campaigns/front-campaigns.component';
import { CampaignViewComponent } from './front-campaigns/campaign-view/campaign-view.component';
import { PropertySearchComponent } from '../shared/property-search/property-search.component';
import { FrontCorporateComponent } from './front-corporate/front-corporate.component';

import { SubscriptionsComponent } from './settings/subscriptions/subscriptions.component';
import { BillingComponent } from './settings/billing/billing.component';
import { SettingsDefaultComponent } from './settings/settings-default/settings-default.component';
import { LatestBuilderComponent } from './latest-builder/latest-builder.component';
import { SettingsIntegrationsComponent } from './settings/settings-integrations/settings-integrations.component';

const frontRoutes: Routes = [
  { path: '', component: FrontComponent, canActivate: [AuthGuard, MaintenanceGuard, AuthBlockGuard],  children: [
    { path: '', redirectTo: '/templates', pathMatch: 'full' },
    { path: 'templates', children: [
      { path: '', component: TemplateFrontDashboardComponent },
      { path: ':id/build', component: TemplateFrontBuildComponent },
      { path: 'folders', component: TemplateFrontFoldersComponent },
      { path: 'folders/:type', component: TemplateFrontDashboardComponent }
    ] },
    { path: 'shared-templates', component: TemplateFrontSharedComponent, children: [
      { path: 'franchise', component: TemplateFranchiseComponent},
      { path: 'groups', component: TemplateGroupComponent},
      { path: 'office', component: TemplateOfficeComponent},
      { path: 'agent', component: TemplateAgentSharedComponent},
      { path: 'subgroup', component: TemplateSubgroupComponent }
      // { path: ':type', component: TemplateFrontSharedComponent},
      // { path: ':type/:gid', component: TemplateFrontSharedComponent},
    ]},
    { path: 'archive-templates', component: TemplateFrontArchiveComponent},
    { path: 'analytics', component: AnalyticsFrontComponent },
    { path: 'library', component: LibraryComponent },
    { path: 'campaigns', children: [
      { path: '', component: FrontCampaignsComponent },
      { path: ':type/:id/view', component: CampaignViewComponent }
    ] },
    { path: 'settings', component: SettingsComponent, children: [
      { path: '', redirectTo: 'logos', pathMatch: 'full' },
      { path: 'subscriptions', component: SubscriptionsComponent },
      { path: 'billing', component: BillingComponent },
      { path: 'integrations', component: SettingsIntegrationsComponent },
      { path: ':type', component: SettingsDefaultComponent }
    ] },
    { path: 'help', children: [
      { path: '', component: HelpComponent },
      { path: 'training-videos', component: TrainingVideosComponent },
      { path: 'faqs', component: FaqComponent },
      { path: 'support', component: SupportComponent },
      { path: 'changelogs/:group', component: ChangelogsFrontComponent }
    ] },
    { path: 'custom-request', component: CustomRequestComponent },
    { path: 'websites/:type', component: FrontWebsitesComponent },
    { path: 'content/marketplace', component: FrontContentMarketplaceComponent },
    { path: 'test/search', component: PropertySearchComponent },
    { path: 'corporate',  component: FrontCorporateComponent },
    {
      path: 'webbooks',
      loadChildren: () =>
        import('./front-webbooks/front-webbooks.module').then((m) => m.FrontWebbooksModule),
    },
    {
      path: 'owner-reports',
      loadChildren: () =>
        import('./front-owner-reports/front-owner-reports.module').then((m) => m.FrontOwnerReportsModule),
    },
    {
      path: 'proposals',
      loadChildren: () =>
        import('./front-proposals/front-proposals.module').then((m) => m.FrontProposalsModule),
    },
    {
      path: 'builder',
      loadChildren: () =>
        import('./latest-builder/latest-builder.module').then((m) => m.LatestBuilderModule),
    }
  ],
  resolve: { front: AuthResolver }
  }
];
@NgModule({
  imports: [RouterModule.forChild(frontRoutes)],
  exports: [RouterModule],
  providers: [AuthResolver]
})
export class FrontRouteModule { }
