import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Group } from '../../../../models/group.model';
import { GroupService } from '../../../../services/group.service';
import swal from 'sweetalert2';
import { ProBlockService } from '../../../../services/pro-block.service';
import { takeUntil } from 'rxjs/operators';
import { ProBlock } from '../../../../models/pro-block.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-pros-blocks-edit',
  templateUrl: './admin-pros-blocks-edit.component.html',
  styleUrls: ['./admin-pros-blocks-edit.component.scss']
})
export class AdminProsBlocksEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  editMode = false;

  proBlockForm: FormGroup;
  groups: Group[] = [];
  proBlock: ProBlock;

  product: string;

  constructor(
    private proBlockService: ProBlockService,
    private groupService: GroupService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.product = this.route.snapshot.paramMap.get('product');
    this.loading = true;

    this.groupService.httpGetGroupsNew();

    const proBlockId = this.route.snapshot.paramMap.get('id');

    if (proBlockId) {
      this.editMode = true;
    }

    this.groupService.onGetGroups
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.groups = response.data;

              if (this.editMode) {
                this.loading = true;
                this.proBlockService.httpGet(proBlockId);
              }
            }

            if (response.status === 'error') {
              swal('Error getting groups', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.proBlockService.onGet
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.proBlock = response.data;
              this.initFormEdit();
            }

            if (response.status === 'error') {
              swal({
                title: 'Error getting Pro Block',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.proBlockService.onPost
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.proBlock = response.data;
              this.toastrService.success('Pro Block created');
              this.router.navigateByUrl(`/admin/pros/blocks/${this.product}/${this.proBlock.UID}/edit`);
            }

            if (response.status === 'error') {
              swal({
                title: 'Error getting Pro Block',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.proBlockService.onPut
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.proBlock = response.data;
              this.toastrService.success('Pro Block Updated');
            }

            if (response.status === 'error') {
              swal({
                title: 'Error getting Pro Block',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.initForms();
  }

  initForms() {
    this.proBlockForm = new FormGroup({
      product: new FormControl(this.product, Validators.required),
      title: new FormControl('', Validators.required),
      group_id: new FormControl('', Validators.required)
    });
  }

  initFormEdit() {
    this.proBlockForm.patchValue({
      title: this.proBlock.title
    });

    if (this.proBlock.whitelist === 'all') {
      this.proBlockForm.patchValue({
        group_id: ['all']
      });
    } else {
      const groupUids = this.proBlock.groups.map(group => group.UID);

      this.proBlockForm.patchValue({
        group_id: groupUids
      });
    }
  }

  onSubmit() {
    const proBlockFormValues = this.proBlockForm.value;

    if (this.proBlockForm.valid) {
      if (this.editMode) {
        this.proBlockService.httpPut(this.proBlock.UID, proBlockFormValues);
      } else {
        this.proBlockService.httpPost(proBlockFormValues);
      }
      this.loading = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
