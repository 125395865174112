import { HttpParams,  HttpClient,   HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Group } from '../models/group.model';
import { ActivatedRoute } from '@angular/router';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';

@Injectable()
export class GroupService {
  groupsChanged = new Subject<Group[]>();
  groupChanged = new Subject<Group>();
  groupOperation = new Subject<Group>();

  onGetGroups = new Subject();

  private groups: Group[] = [];

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  setGroups(groups: Group[]) {
    this.groups = groups;
    this.groupsChanged.next(this.groups.slice());
  }

  getGroups() {
    return this.groups.slice();
  }

  getIdIndex(id: number) {
    return this.groups.findIndex(g => g.ID === id);
  }

  httpGetGroupsNew(params: any = {}) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/groups`, {
        params: httpParams
    })
    .subscribe(
      (response: any) => {
        this.onGetGroups.next(response);
      },
      (response: any) => {
        this.onGetGroups.next(response.error);
      }
    );
  }

  httpGetGroups(params: any = [], columns: string = '', order: string = '') {
    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (let p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/groups',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Group[]) => {
        this.setGroups(response);
      }
    );
  }

  httpGetGroup(id: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/groups/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Group) => {
        this.groupChanged.next(response);
      }
    );
  }

  httpPostGroup(data: Group) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/groups',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.groupOperation.next(response);
      }
    );
  }

  httpPutGroup(id: number, data: Group) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/groups/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.groupOperation.next(response);
      }
    );
  }

  httpDeleteGroup(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/groups/' + id
    );

    return this.httpClient.request<any>(req)
    .subscribe(
      (response: any) => {
        this.httpGetGroups();
      }
    );
  }

}
