import { Component, OnInit, OnDestroy } from '@angular/core';
import { Menu } from '../../../models/menu.model';
import { AdminService } from '../../admin.service';
import { SubgroupService } from '../../../services/subgroup.service';
import { Subscription } from 'rxjs/Subscription';
import { Subgroup } from '../../../models/subgroup.model';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-subgroups-dashboard',
  templateUrl: './subgroups-dashboard.component.html',
  styleUrls: ['./subgroups-dashboard.component.scss']
})
export class SubgroupsDashboardComponent implements OnInit, OnDestroy {
  loading = false;
  menus: Menu[] = [];
  page = 1;

  subgroups: Subgroup[];

  subgroupsSubscription: Subscription;
  subgroupDeleteSubscription: Subscription;

  constructor(private adminService: AdminService, private subgroupService: SubgroupService) { }

  ngOnInit() {
    this.loading = true;
    this.adminService.loadMenu(this.menus);

    this.subgroupService.httpGetSubgroups();

    this.subgroupsSubscription = this.subgroupService.subgroupGetAll.subscribe(
      (subgroups: any) => {
        if ( typeof subgroups !== 'undefined' && subgroups.status === 'success' ) {
          this.loading = false;
          this.subgroups = subgroups.data;
        }
      }
    );

    this.subgroupDeleteSubscription = this.subgroupService.subgroupDelete.subscribe(
      (subgroup: any) => {
        if ( typeof subgroup !== 'undefined' && subgroup.status === 'success' ) {
          this.subgroupService.httpGetSubgroups();
        }
      }
    );
  }

  onDelete(id) {
    const $this = this;
    Swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.subgroupService.httpDeleteSubgroup(id);
        $this.loading = true;
        Swal(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
      }
    }).catch(Swal.noop);
  }

  ngOnDestroy() {
    this.subgroupsSubscription.unsubscribe();
    this.subgroupDeleteSubscription.unsubscribe();
  }

}
