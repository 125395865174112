import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Group } from '../../../../models/group.model';
import { ProBlock } from '../../../../models/pro-block.model';
import { ProElement } from '../../../../models/pro-element.model';
import { GroupService } from '../../../../services/group.service';
import { ProBlockService } from '../../../../services/pro-block.service';
import { ProElementService } from '../../../../services/pro-element.service';
import swal from 'sweetalert2';
import { StorageService } from '../../../../services/storage.service';
import { AdminService } from '../../../../admin/admin.service';
import { ClientService } from '../../../../services/client.service';
import { Client } from '../../../../models/client.model';
import { ToastrService } from 'ngx-toastr';
import { ProThemeService } from '../../../../services/pro-theme.service';
import { ProTheme } from '../../../../models/pro-theme.model';

@Component({
  selector: 'app-admin-pros-elements-edit',
  templateUrl: './admin-pros-elements-edit.component.html',
  styleUrls: ['./admin-pros-elements-edit.component.scss']
})
export class AdminProsElementsEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  editMode = false;

  proElementForm: FormGroup;
  clients: Client[] = [];
  groups: Group[] = [];

  proBlocks: ProBlock[] = [];
  proElement: ProElement;
  proThemes: ProTheme[] = [];

  product: string;
  editorOptions = {theme: 'vs-dark', language: 'html'};
  code = '';

  constructor(
    public adminService: AdminService,
    private proElementService: ProElementService,
    private proBlockService: ProBlockService,
    private groupService: GroupService,
    private storageService: StorageService,
    private clientService: ClientService,
    private toastrService: ToastrService,
    private proThemeService: ProThemeService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.product = this.route.snapshot.paramMap.get('product');
    const proElementId = this.route.snapshot.paramMap.get('id');

    this.groupService.httpGetGroupsNew();

    if (proElementId) {
      this.editMode = true;
    }

    this.groupService.onGetGroups
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.groups = response.data;

              this.clientService.httpGetClientsNew({}, 'list');
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Error getting groups', 'Contact Dev Team', 'error');
              this.router.navigateByUrl(`/admin/pros/elements/${this.product}`);
            }
          }
        }
      }
    );

    this.clientService.onGetClients
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.clients = response.data;

              this.proThemeService.httpGetAll({
                product: this.product
              });
            }

            if (response.status === 'error') {
              this.loading = false;
              swal({
                title: 'Error getting Clients',
                type: 'error'
              });
              this.router.navigateByUrl(`/admin/pros/elements/${this.product}`);
            }
          }
        }
      }
    );

    this.proThemeService.onGetAll
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.proThemes = response.data;

              this.proBlockService.httpGetAll({
                product: this.product
              });
            }

            if (response.status === 'error') {
              swal({
                title: 'Error getting Themes',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.proBlockService.onGetAll
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.proBlocks = response.data;

              if (proElementId) {
                this.proElementService.httpGet(proElementId);
                this.loading = true;
              }
            }

            if (response.status === 'error') {
              swal({
                title: 'Error getting Pro Blocks',
                type: 'error'
              });
              this.router.navigateByUrl(`/admin/pros/elements/${this.product}`);
            }
          }
        }
      }
    );

    this.proElementService.onPost
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.proElement = response.data;
              this.toastrService.success('Pro Element created');
              this.router.navigateByUrl(`/admin/pros/elements/${this.product}/${this.proElement.UID}/edit`);
            }

            if (response.status === 'VALIDATION_ERROR') {
              swal({
                title: 'Validation Error',
                text: response.message,
                type: 'error'
              });
            }

            if (response.status === 'error') {
              swal({
                title: 'Error getting Pro Block',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.proElementService.onGet
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.proElement = response.data;
              this.initFormEdit();
            }

            if (response.status === 'error') {
              swal({
                title: 'Error getting Pro Element',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.proElementService.onPut
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.toastrService.success('Pro Element updated');
            }

            if (response.status === 'error') {
              swal({
                title: 'Error updating Element',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.storageService.onUpload
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.proElementForm.patchValue({
                icon: response.data
              });
            }

            if (response.status === 'error') {
              swal({
                title: 'Error uploading file. Try again.',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.initForms();
  }

  initForms() {
    this.proElementForm = new FormGroup({
      pro_block_id: new FormControl('', Validators.required),
      pro_theme_id: new FormControl([], Validators.required),
      group_id: new FormControl([], Validators.required),
      excluded_group_id: new FormControl([]),
      client_id: new FormControl([], Validators.required),
      title: new FormControl('', Validators.required),
      description: new FormControl(''),
      icon: new FormControl(),
      html: new FormControl('', Validators.required)
    });
  }

  initFormEdit() {
    this.proElementForm.patchValue({
      pro_block_id: this.proElement.pro_block.UID,
      title: this.proElement.title,
      description: this.proElement.description,
      icon: this.proElement.icon,
      html: this.proElement.html
    });

    if (this.proElement.whitelist === 'all') {
      this.proElementForm.patchValue({
        group_id: ['all']
      });
    } else {
      const groupIds = this.proElement.groups.map(group => group.UID);

      this.proElementForm.patchValue({
        group_id: groupIds
      });
    }

    if (this.proElement.client_whitelist === 'all') {
      this.proElementForm.patchValue({
        client_id: ['all']
      });
    } else {
      const clientIds = this.proElement.clients.map(client => client.ID);

      this.proElementForm.patchValue({
        client_id: clientIds
      });
    }

    if (this.proElement.pro_theme_whitelist === 'all') {
      this.proElementForm.patchValue({
        pro_theme_id: ['all']
      });
    } else {
      const proThemeIds = this.proElement.pro_themes.map(proTheme => proTheme.UID);

      this.proElementForm.patchValue({
        pro_theme_id: proThemeIds
      });
    }

    if (this.proElement.excluded_groups && this.proElement.excluded_groups.length) {
      const excludedGroupIds = this.proElement.excluded_groups.map(group => group.UID);

      this.proElementForm.patchValue({
        excluded_group_id: excludedGroupIds
      });
    }
  }

  onUpload(files: File[]) {
    if (files.length === 1) {
      this.storageService.httpUpload(files[0]);
      this.loading = true;
    }
  }

  onSubmit() {
    const proElementFormValues = this.proElementForm.value;

    if (this.proElementForm.valid) {
      if (this.editMode) {
        this.proElementService.httpPut(this.proElement.UID, proElementFormValues);
      } else {
        proElementFormValues.product = this.product;
        this.proElementService.httpPost(proElementFormValues);
      }

      this.loading = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
