<div class="homepage2 app-media">
<div class="page-header header-bar">
  <h1 class="page-title" style="display: inline-block;">Franchise Templates <span class="badge badge-info blue-background circle-badge">{{ template_count }}</span></h1>
  <div class="page-header-actions templates-search">
      <div class="">
        <div class="input-search">
          <input type="text" class="form-control" name="" placeholder="Search..." [(ngModel)]="term">
          
        </div>
      </div>
  </div>
  <a [(click)="onFeedback()" id="my_feedback" class="btn-feedback">Send Feedback</a>
  <div class="page-header-actions">
      <ul class="list-inline">

        <!-- <li>
            <div class="checkbox-custom checkbox-primary">
              <span for="inputChecked" class="text-bold margin-right-25">Show Archived Templates</span>
             
            </div>
        </li> -->
        <li class="filter-list">

          <select data-plugin="selectpicker" data-style="btn btn-link btn-filter" [(ngModel)]="dateFilter">
            <option value="recent" selected>Sort by date desc</option>
            <option value="date-asc" selected>Sort by date asc</option>
            <option value="title-asc">Sort by Title ASC</option>
            <option value="title-desc">Sort by Title DESC</option>
          </select>

          <select data-plugin="selectpicker" data-style="btn btn-link btn-filter" [(ngModel)]="templateFilter">
            <option value="" selected disabled>Template Filter</option>
            <option value="all" >All</option>
            <option value="enewsletter">eMarketing</option>
            <option value="buyer-match">Buyer Match</option>
            <option value="quick-enquiry">Quick Enquiry</option>
            <option value="brochure">Brochure</option>
            <option value="property-guide">Property Guide</option>
            <option value="social-media">Social Media</option>
          </select>

          <select data-plugin="selectpicker" data-style="btn btn-link btn-filter">
            <option value="" selected disabled>Agent Filter</option>
            <option value="john doe">John Doe</option>
            <option value="sam lam">Sam Lam</option>
            <option value="sally sudo">Sally Sudo</option>
            <option value="peter munro">Peter Munro</option>
          </select>

          <button type="button" class="btn btn-sm btn-icon btn-default btn-link active template-view" data-view="grid" data-toggle="tooltip" data-original-title="Grid view">
            <i class="icon feather icon-grid" aria-hidden="true"></i>
          </button> 
          <button type="button" class="btn btn-sm btn-icon btn-default btn-link template-view" data-view="list" data-toggle="tooltip" data-original-title="List View">
            <i class="icon feather icon-menu" aria-hidden="true"></i>
          </button>  
        </li>

      </ul>
  </div>
</div>
</div>