<div id="box" class="row">
  <div class="col-md-12">
    <div class="table-box">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 heading">
          <h4 class="pull-left">Media Attributes</h4>
          <button (click)="newMedia()" data-bs-target="#newMediaAttribute" data-bs-toggle="modal" type="button" class="btn-admin btn-admin-primary">
            <i class="icon feather icon-plus"></i><span>Add Media Attribute</span>
          </button>
        </div>
      </div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Medium</th>
            <th>Orientation</th>
            <th>Size</th>
            <th style="text-align: right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let mediaAttribute of mediaAttributes">
            <td *ngIf="mediaAttribute.medium === 'eMarketing'">Email</td>
            <td *ngIf="mediaAttribute.medium !== 'eMarketing'">{{ mediaAttribute.medium }}</td>
            
            <td *ngIf="mediaAttribute.orientation === 'eMarketing'">Email</td>
            <td *ngIf="mediaAttribute.orientation !== 'eMarketing'">{{ mediaAttribute.orientation }}</td>

            <td>{{ mediaAttribute.size }} <span *ngIf="mediaAttribute.size === 'custom'">{{ mediaAttribute.custom_width }}x{{ mediaAttribute.custom_height }}</span></td>
            <td style="text-align: right">
              <button type="button" data-bs-target="#newMediaAttribute" data-bs-toggle="modal" (click)="onEdit(mediaAttribute.ID)" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
              <button (click)="onDelete(mediaAttribute.ID)" type="button" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div id="newMediaAttribute" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="newMediaAttributeLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close close-outside" #newMediaAttributeModalClose data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="newMediaAttributeLabel">{{ (editMode ? 'Edit' : 'Add')}} Media Attribute</h4>
      </div>
      <form (ngSubmit)="onSubmit()" [formGroup]="mediaAttributeForm" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group">
            <label for="medium" class="col-sm-2">Medium</label>
            <div class="col-sm-6">
              <select formControlName="medium" class="form-control" id="medium" >
                <option value="" disabled>Select a Medium</option>
                <option *ngFor="let mValue of s2MediumData" value="{{ mValue.id }}">{{ mValue.text }}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="orientation" class="col-sm-2">Orientation</label>
            <div class="col-sm-6">
              <select formControlName="orientation" class="form-control" id="orientation" >
                <option value="" disabled>Select an Orientation</option>
                <option *ngFor="let mValue of s2OrientationData" value="{{ mValue.id }}">{{ mValue.text }}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="size" class="col-sm-2">Size</label>
            <div class="col-sm-6">
              <select #sizeSelect (change)="onChangeSize(sizeSelect.value)" *ngIf="mediaAttributeForm.value.orientation !== 'Email'" formControlName="size" class="form-control" id="size" >
                <option value="" disabled>Select a Size</option>
                <option *ngFor="let mValue of s2SizeData" value="{{ mValue.id }}">{{ mValue.text }}</option>
              </select>
              <input *ngIf="mediaAttributeForm.value.orientation === 'Email'" formControlName="size" class="form-control" id="size" value="" maxlength="10" >
            </div>
          </div>
          <div *ngIf="mediaAttributeForm.value.size === 'custom'" class="form-group">
            <label for="type" class="col-sm-2">Type</label>
            <div class="col-sm-6">
              <select formControlName="type" class="form-control" id="type" >
                <option value="px">Pixels</option>
                <option value="mm">Millimeteres</option>
              </select>
            </div>
          </div>
          <div *ngIf="mediaAttributeForm.value.size === 'custom'" class="form-group">
            <label for="custom_width" class="col-sm-2">Custom Width</label>
            <div class="col-sm-6">
              <input formControlName="custom_width" class="form-control" id="custom_width" value="" maxlength="10" >
            </div>
          </div>
          <div *ngIf="mediaAttributeForm.value.size === 'custom'" class="form-group">
            <label for="custom_height" class="col-sm-2">Custom Height</label>
            <div class="col-sm-6">
              <input formControlName="custom_height" class="form-control" id="custom_height" value="" maxlength="10" >
            </div>
          </div>
          <div *ngIf="mediaAttributeForm.value.size === 'custom'" class="form-group">
            <label for="type" class="col-sm-2">Custom Print Size</label>
            <div class="col-sm-6">
              <select formControlName="custom_print" class="form-control" id="type" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div *ngIf="mediaAttributeForm.value.size === 'custom' && mediaAttributeForm.value.custom_print == '1'" class="form-group">
            <label for="custom_print_width" class="col-sm-2">Print Width (mm)</label>
            <div class="col-sm-6">
              <input formControlName="custom_print_width" class="form-control" id="custom_print_width" value="" maxlength="10" >
            </div>
          </div>
          <div *ngIf="mediaAttributeForm.value.size === 'custom' && mediaAttributeForm.value.custom_print == '1'" class="form-group">
            <label for="custom_print_height" class="col-sm-2">Print Height (mm)</label>
            <div class="col-sm-6">
              <input formControlName="custom_print_height" class="form-control" id="custom_print_height" value="" maxlength="10" >
            </div>
          </div>
          <div *ngIf="mediaAttributeForm.value.size === 'custom' && mediaAttributeForm.value.custom_print == '1'" class="form-group">
            <label for="zoom" class="col-sm-2">Zoom (Decimal allowed)</label>
            <div class="col-sm-6">
              <input type="number" formControlName="zoom" class="form-control" id="zoom" value="" maxlength="10" >
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" [disabled]="!mediaAttributeForm.valid" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin " data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>