import { Component, OnInit} from '@angular/core';
import { FrontService } from '../front.service';
import { Menu } from '../../models/menu.model';
import { Auth } from '../../models/auth.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  authUser: Auth;
  menus: Menu[] = [];

  constructor(
    private frontService: FrontService
  ) { }

  ngOnInit() {
    this.authUser = this.frontService.authService.auth;

    let defaultMenus = [
      new Menu('Logos', '/settings/logos'),
      new Menu('Color Palettes', '/settings/color-palettes'),
      new Menu('Fonts Colors', '/settings/fonts-colors'),
      new Menu('Icons', '/settings/icons'),
      new Menu('Website Links', '/settings/website-links'),
      new Menu('Social Links', '/settings/social-links'),
      new Menu('Disclaimers & Legal', '/settings/disclaimers'),
      new Menu('Agency Details', '/settings/agency-details'),
      new Menu('Sync Agents', '/settings/sync-agents'),
      new Menu('Designs', '/settings/designs'),
      new Menu('Agent Access', '/settings/agent-access')
    ];

    if (this.authUser.client.enable_pro_ui) {
      defaultMenus = [...defaultMenus, ...[
        new Menu('Integrations', '/settings/integrations'),
        new Menu('Subscriptions', '/settings/subscriptions'),
        new Menu('Billing', '/settings/billing'),
      ]];
    }

    this.menus = [
      new Menu('Settings', '', defaultMenus)
    ];

    this.frontService.loadMenu(this.menus);
  }
}
