import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminService } from '../../../../admin/admin.service';
import { Client } from '../../../../models/client.model';
import { Group } from '../../../../models/group.model';
import { ProTheme } from '../../../../models/pro-theme.model';
import { ClientService } from '../../../../services/client.service';
import { GroupService } from '../../../../services/group.service';
import { ProThemeService } from '../../../../services/pro-theme.service';
import { StorageService } from '../../../../services/storage.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-admin-pros-themes-edit',
  templateUrl: './admin-pros-themes-edit.component.html',
  styleUrls: ['./admin-pros-themes-edit.component.scss']
})
export class AdminProsThemesEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  editMode = false;

  proThemeForm: FormGroup;
  clients: Client[] = [];
  groups: Group[] = [];

  proTheme: ProTheme;

  product: string;
  editorOptions = {theme: 'vs-dark', language: 'html'};
  code = '';

  constructor(
    public adminService: AdminService,
    private proThemeService: ProThemeService,
    private groupService: GroupService,
    private storageService: StorageService,
    private clientService: ClientService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.product = this.route.snapshot.paramMap.get('product');
    const proThemeId = this.route.snapshot.paramMap.get('id');

    this.groupService.httpGetGroupsNew();

    if (proThemeId) {
      this.editMode = true;
    }

    this.groupService.onGetGroups
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.groups = response.data;

              this.clientService.httpGetClientsNew({}, 'list');
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Error getting groups', 'Contact Dev Team', 'error');
              this.router.navigateByUrl(`/admin/pros/themes/${this.product}`);
            }
          }
        }
      }
    );

    this.clientService.onGetClients
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.clients = response.data;

              if (proThemeId) {
                this.proThemeService.httpGet(proThemeId);
                this.loading = true;
              }
            }

            if (response.status === 'error') {
              this.loading = false;
              swal({
                title: 'Error getting Clients',
                type: 'error'
              });
              this.router.navigateByUrl(`/admin/pros/themes/${this.product}`);
            }
          }
        }
      }
    );

    this.proThemeService.onPost
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.proTheme = response.data;
              this.toastrService.success('Pro Theme created');
              this.router.navigateByUrl(`/admin/pros/themes/${this.product}/${this.proTheme.UID}/edit`);
            }

            if (response.status === 'VALIDATION_ERROR') {
              swal({
                title: 'Validation Error',
                text: response.message,
                type: 'error'
              });
            }

            if (response.status === 'error') {
              swal({
                title: 'Error getting Pro Block',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.proThemeService.onGet
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.proTheme = response.data;
              this.initFormEdit();
            }

            if (response.status === 'error') {
              swal({
                title: 'Error getting Pro Theme',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.proThemeService.onPut
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.toastrService.success('Pro Theme updated');
            }

            if (response.status === 'error') {
              swal({
                title: 'Error updating Theme',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.storageService.onUpload
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.proThemeForm.patchValue({
                thumbnail: response.data
              });
            }

            if (response.status === 'error') {
              swal({
                title: 'Error uploading file. Try again.',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.initForms();
  }

  initForms() {
    this.proThemeForm = new FormGroup({
      group_id: new FormControl([], Validators.required),
      excluded_group_id: new FormControl([]),
      client_id: new FormControl([], Validators.required),
      title: new FormControl('', Validators.required),
      description: new FormControl(''),
      thumbnail: new FormControl(),
      html: new FormControl('', Validators.required),
      external_css: new FormControl(''),
      external_js: new FormControl(''),
      external_fonts: new FormControl('')
    });
  }

  initFormEdit() {
    this.proThemeForm.patchValue({
      title: this.proTheme.title,
      description: this.proTheme.description,
      thumbnail: this.proTheme.thumbnail,
      html: this.proTheme.html,
      external_css: this.proTheme.external_css,
      external_js: this.proTheme.external_js,
      external_fonts: this.proTheme.external_fonts
    });

    if (this.proTheme.whitelist === 'all') {
      this.proThemeForm.patchValue({
        group_id: ['all']
      });
    } else {
      const groupIds = this.proTheme.groups.map(group => group.UID);

      this.proThemeForm.patchValue({
        group_id: groupIds
      });
    }

    if (this.proTheme.client_whitelist === 'all') {
      this.proThemeForm.patchValue({
        client_id: ['all']
      });
    } else {
      const clientIds = this.proTheme.clients.map(client => client.ID);

      this.proThemeForm.patchValue({
        client_id: clientIds
      });
    }

    if (this.proTheme.excluded_groups && this.proTheme.excluded_groups.length) {
      const excludedGroupIds = this.proTheme.excluded_groups.map(group => group.UID);

      this.proThemeForm.patchValue({
        excluded_group_id: excludedGroupIds
      });
    }
  }

  onUpload(files: File[]) {
    if (files.length === 1) {
      this.storageService.httpUpload(files[0]);
      this.loading = true;
    }
  }

  onSubmit() {
    const proThemeFormValues = this.proThemeForm.value;

    if (this.proThemeForm.valid) {
      if (this.editMode) {
        this.proThemeService.httpPut(this.proTheme.UID, proThemeFormValues);
      } else {
        proThemeFormValues.product = this.product;
        this.proThemeService.httpPost(proThemeFormValues);
      }

      this.loading = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
