import { Component, OnInit, OnDestroy } from '@angular/core';
import { Group } from '../../../models/group.model';
import { Menu } from '../../../models/menu.model';
import { AdminService } from '../../admin.service';
import { GroupService } from '../../../services/group.service';
import { Subscription } from 'rxjs/Subscription';
import { ElementService } from '../../../services/element.service';
import { TransitiveCompileNgModuleMetadata } from '@angular/compiler';

declare var jQuery: any;
declare var swal: any;

@Component({
  selector: 'app-group-dashboard',
  templateUrl: './group-dashboard.component.html',
  styleUrls: ['./group-dashboard.component.scss']
})
export class GroupDashboardComponent implements OnInit, OnDestroy {
  loading = false;
  menus: Menu[] = [];
  groups: Group[];
  subscription: Subscription;

  subscriptionOperationElements: Subscription;

  group_name = '';
  group_id = '';
  filter;
  page = 1;

  constructor(private adminService: AdminService,
              private groupService: GroupService,
              private elementService: ElementService) {}

  ngOnInit() {
    this.adminService.loadMenu(this.menus);

    this.subscription = this.groupService.groupsChanged.subscribe(
      (groups: Group[]) => {
        if (typeof groups !== 'undefined') {
          this.loading = false;
          this.groups = groups;
        }
      }
    );

    this.groupService.httpGetGroups();

    setTimeout(
      () => {
        if ( ! jQuery.fn.DataTable.isDataTable( 'table' ) ) {

          jQuery('table:not(.static)').DataTable({
              searching: true,
              // paging: false,
              info: false,
              // ordering: false,
              responsive: true,
              'pagingType': 'full_numbers',
              'processing': true,
              'columnDefs': [ {
                'targets': [0],
              }],
              // 'oLanguage': { 'sSearch': '', 'sLengthMenu': '_MENU_' },
              'pageLength': 50
            });
          }
      }, 1000
    );

    this.subscriptionOperationElements = this.elementService.elementOperation.subscribe(
      (element: any) => {
        if(element == 'All Elements have been replicated'){
          this.loading = false;

          this.subscriptionOperationElements.unsubscribe();

          swal({
            position: 'top-end',
            type: 'success',
            title: 'Duplication Success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
    )

  }

  onDelete(id: number) {
    this.loading = true;
    this.groupService.httpDeleteGroup(id);
  }

  duplicateElement(group){
    jQuery('#duplicateElementModal').modal('show');

    this.group_name = group.title;
    this.group_id = group.ID;
  }

  duplicate(){
    jQuery('#duplicateElementModal').modal('hide');
    let new_group_id = jQuery( '#duplicate_select' ).val();

    this.loading = true;

    let replicate_data = {
      group_id: this.group_id,
      new_group_id: new_group_id
    }

    this.elementService.httpReplicateElement(replicate_data);
  
  }

  ngOnDestroy() {
    this.adminService.authService.cancelRequest();
    this.subscription.unsubscribe();
    this.subscriptionOperationElements.unsubscribe();
  }

}
