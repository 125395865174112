<div class="row">
    <div class="col-md-12 heading">
      <h2 class="pull-left">Help Center - FAQ Category</h2>
  
      <button routerLink="new" type="button" class="btn-admin"> <i class="icon feather icon-plus"></i><span>Add Category</span>  </button>
    </div>
  </div>
  <div id="box" class="row">
    <div class="col-md-12">
      <div class="table-box">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Title</th>
              <th style="text-align: right">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let faqsCategory of faqsCategories">
              <td>{{ faqsCategory?.title }}</td>
              <td style="text-align: right">
                <button type="button" routerLink="{{ faqsCategory?.UID }}/edit" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
                <button type="button" (click)="onDelete(faqsCategory?.UID)" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>