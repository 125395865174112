import { HttpParams,  HttpClient,   HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Master } from '../models/master.model';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';
import { AuthService } from './auth.service';

@Injectable()
export class MasterService {
  mastersChanged = new Subject<Master[]>();
  masterChanged = new Subject<Master>();
  masterOperation = new Subject<Master>();
  masterDeletion = new Subject<Master>();
  mastersCategoryComingSoon = new Subject<Master[]>();
  mastersCategoryEMarketing = new Subject<Master[]>();
  mastersCategoryBrochure = new Subject<Master[]>();
  mastersCategoryPropertyGuide = new Subject<Master[]>();
  mastersCategorySocialMedia = new Subject<Master[]>();
  mastersCategorySignboard = new Subject<Master[]>();
  mastersCategoryPropertyMatch = new Subject<Master[]>();
  mastersCategoryBeyondTheBricks = new Subject<Master[]>();
  mastersCategoryMarketingVault = new Subject<Master[]>();

  private masters: Master[] = [];

  public group_id = 0;

  // New Subscriptions
  onGetMaster = new Subject();

  constructor(@Inject(APP_CONFIG) private appConfig,
              private httpClient: HttpClient,
              private authService: AuthService) {}

  setMasters(masters: Master[]) {
    this.masters = masters;

    let tmpMasters = this.masters;

    if (Array.isArray(tmpMasters)) {
      tmpMasters = tmpMasters.slice();
    }
    this.mastersChanged.next(tmpMasters);
  }

  getMasters() {
    let tmpMasters = this.masters;

    if (Array.isArray(tmpMasters)) {
      tmpMasters = tmpMasters.slice();
    }
    return tmpMasters;
  }

  getIdIndex(id: number) {
    return this.masters.findIndex(g => g.ID === id);
  }

  httpGetMasters(params: any = [], columns: string = '', order: string = '', group: string = '', tags: string = '') {
    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }
    if ( group ) {
      httpParams = httpParams.append('group', group);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/masters',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if ( tags ) {
          if (response.body) {
            if (response.body.status === 'success') {
              return response.body.data;
            }
          }
        } else {
          if (typeof response.body !== 'undefined' && response.body != null) {
            return response.body;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        if ( tags ) {
          switch (tags) {
            case 'soon':
              this.mastersCategoryComingSoon.next(response);
            break;
            case 'enewsletter':
              this.mastersCategoryEMarketing.next(response);
            break;
            case 'brochure':
              this.mastersCategoryBrochure.next(response);
            break;
            case 'property-guide':
              this.mastersCategoryPropertyGuide.next(response);
            break;
            case 'social-media':
              this.mastersCategorySocialMedia.next(response);
            break;
            case 'sign-board':
              this.mastersCategorySignboard.next(response);
            break;
            case 'property-match':
              this.mastersCategoryPropertyMatch.next(response);
            break;
            case 'beyond-the-bricks':
              this.mastersCategoryBeyondTheBricks.next(response);
            break;
            case 'marketing-vault':
              this.mastersCategoryMarketingVault.next(response);
            break;
            default:
              this.setMasters(response);
          }
        } else {
          this.mastersChanged.next(response);
        }
      }
    );
  }

  httpGetMasterNew(id: string) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/masters/${id}`)
    .subscribe(
      (response: any) => {
        this.onGetMaster.next(response);
      },
      (response: any) => {
        this.onGetMaster.next(response.error);
      }
    );
  }

  httpGetMaster(id: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/masters/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Master) => {
        this.masterChanged.next(response);
      }
    );
  }

  httpPostMaster(data: Master) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/masters',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            const responseData = response.body.data;
            const id = responseData.ID;

            if ( typeof data['iconFile'] !== 'undefined' ) {
              const icon = data['iconFile'];
              const formData = new FormData();

              if ( typeof icon !== 'undefined' ) {
                formData.append('icon', icon, icon.name);
              }

              const uploadReq = new HttpRequest(
                'POST',
                this.appConfig.API_ENDPOINT + '/masters/upload/' + id,
                formData
              );

              this.httpClient.request<any>(uploadReq).toPromise();
            }

            return responseData;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.masterOperation.next(response);
      }
    );
  }

  httpPutMaster(id: number, data: Master) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/masters/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            if ( typeof data['iconFile'] !== 'undefined' ) {
              const icon = data['iconFile'];
              const formData = new FormData();

              if ( typeof icon !== 'undefined' ) {
                formData.append('icon', icon, icon.name);
              }

              const uploadReq = new HttpRequest(
                'POST',
                this.appConfig.API_ENDPOINT + '/masters/upload/' + id,
                formData
              );

              this.httpClient.request<any>(uploadReq).toPromise();
            }

            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.masterOperation.next(response);
      }
    );
  }

  httpDeleteMaster(id: number) {
    return this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/masters/${id}`)
    .subscribe(
        (response: any) => {
            this.masterDeletion.next(response);
        },
        (response: any) => {
            this.masterDeletion.next(response.error);
        }
    );
  }
}
