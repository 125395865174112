<div class="create-template clearfix" [class.hidden]="extraPages.includes(currentPageType)">
  <div class="col-lg-12 px-3">
    <div class="heading">
      <h1 class="page-title pull-left">Use Template</h1>

      <button (click)="startTour()" type="button" class="btn-admin btn-admin-primary btn-tour pull-right"><span>DASHBOARD TOUR</span> &nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i></button>
    </div>
    <div class="category-heading">
      <span>Category</span>
    </div>

    <div class="category-tab">
      <ul class="nav">
        <ng-container *ngFor="let quickdesign of quickdesigns">
          <li *ngIf="quickdesign?.slug !== 'signboards'" class="category-tab-tour-{{ quickdesign?.slug }}" [ngClass]="quickdesign?.templates?.length > 0 ? '' : 'hidden'">
            <div class="btn-group category-action">
              <button *ngIf="quickdesign?.custom && quickdesign?.client_id === authUser?.client?.ID" type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ellipsis-icon"></i></button>
              <div *ngIf="quickdesign?.custom" class="dropdown-menu">
                <a *ngIf="authUser?.is_master && !quickdesign?.is_shared" class="dropdown-item" href="javascript:void(0)" (click)="onShareCategory(quickdesign?.ID)">Share</a>
                <a *ngIf="authUser?.is_master && quickdesign?.is_shared" class="dropdown-item" href="javascript:void(0)" (click)="onUnShareCategory(quickdesign?.ID)">Unshare</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="onEditCategory(quickdesign?.ID, quickdesign?.title)">Edit category</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="onRemoveCustomCategory(quickdesign?.ID)">Remove category</a>
              </div>
            </div>
            <a href="#{{ quickdesign?.slug }}" role="tab" data-bs-toggle="tab" [attr.data-bs-target]="'#' + quickdesign.slug" onclick="return false;">
              <div class="category-container">
                <div class="img-container">
                  <img *ngIf="quickdesign?.custom" src="{{ frontService.appConfig.S3_URL + quickdesign?.icon }}" height="100%" />
                  <img *ngIf="!quickdesign?.custom" src="{{ quickdesign?.icon }}" height="100%" />
                </div>
              </div>
              <span class="category-name">{{ quickdesign?.title === 'Printables' && frontService?.appConfig?.SKIN === 'professionals' ? 'Print' : quickdesign?.title }}</span>
            </a>
          </li>
          <li *ngIf="quickdesign?.slug === 'signboards' && signboards?.length > 0 && quickdesign?.templates?.length > 0" class="category-tab-tour-{{ quickdesign?.slug }}">
            <div class="btn-group category-action">
              <button *ngIf="quickdesign?.custom && quickdesign?.client_id === authUser?.client?.ID" type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ellipsis-icon"></i></button>
              <div *ngIf="quickdesign?.custom" class="dropdown-menu">
                <a *ngIf="authUser?.is_master && !quickdesign?.is_shared" class="dropdown-item" href="javascript:void(0)" (click)="onShareCategory(quickdesign?.ID)">Share</a>
                <a *ngIf="authUser?.is_master && quickdesign?.is_shared" class="dropdown-item" href="javascript:void(0)" (click)="onUnShareCategory(quickdesign?.ID)">Unshare</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="onEditCategory(quickdesign?.ID, quickdesign?.title)">Edit category</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="onRemoveCustomCategory(quickdesign?.ID)">Remove category</a>
              </div>
            </div>
            <a href="#{{ quickdesign?.slug }}" role="tab" data-bs-toggle="tab" [attr.data-bs-target]="'#' + quickdesign.slug" onclick="return false;">
              <div class="category-container">
                <div class="img-container">
                  <img *ngIf="quickdesign?.custom" src="{{ frontService.appConfig.S3_URL + quickdesign?.icon }}" height="100%" />
                  <img *ngIf="!quickdesign?.custom" src="{{ quickdesign?.icon }}" height="100%" />
                </div>
              </div>
              <span class="category-name">{{ quickdesign?.title }}</span>
            </a>
          </li>
        </ng-container>
        <!-- <li>
            <div class="btn-group category-action">
              <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ellipsis-icon"></i></button>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#">Rename category</a>
                <a class="dropdown-item" href="#">Replace icon</a>
                <a class="dropdown-item" href="#">Remove category</a>
              </div>
            </div>
            <a href="#default-printables" role="tab" data-bs-toggle="tab">
              <div class="category-container">
                <div class="img-container">
                  <img src="assets/img/template-graphic2.svg" height="100%">
                </div>
              </div>
              <span class="category-name">Printables</span>
            </a>
          </li>
          <li>
            <div class="btn-group category-action">
              <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ellipsis-icon"></i></button>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#">Rename category</a>
                <a class="dropdown-item" href="#">Replace icon</a>
                <a class="dropdown-item" href="#">Remove category</a>
              </div>
            </div>
            <a href="#default-socialmedia" role="tab" data-bs-toggle="tab">
              <div class="category-container">
                <div class="img-container">
                  <img src="assets/img/template-graphic3.svg" height="100%">
                </div>
              </div>
              <span class="category-name">Social Media</span>
            </a>
          </li>
          <li>
            <div class="btn-group category-action">
              <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ellipsis-icon"></i></button>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#">Rename category</a>
                <a class="dropdown-item" href="#">Replace icon</a>
                <a class="dropdown-item" href="#">Remove category</a>
              </div>
            </div>
            <a href="#default-signboards" role="tab" data-bs-toggle="tab">
              <div class="category-container">
                <div class="img-container">
                  <img src="assets/img/template-graphic4.svg" height="100%">
                </div>
              </div>
              <span class="category-name">Signboards</span>
            </a>
          </li> -->
        <li class="category-tab-tour-create-yourself">
          <div class="btn-group category-action"></div>
          <a href="#default-diy" role="tab" data-bs-toggle="tab" data-bs-target="#default-diy" aria-controls="default-diy">
            <div class="category-container">
              <div class="img-container">
                <img *ngIf="frontService?.appConfig?.SKIN !== 'raywhite'" src="assets/img/template-graphic5.svg" height="100%" />
                <img *ngIf="frontService?.appConfig?.SKIN === 'raywhite'" src="assets/img/create_rw.png" height="100%" />
              </div>
            </div>
            <span *ngIf="frontService?.appConfig?.SKIN === 'raywhite'" class="category-name">Create yourself</span>
            <span *ngIf="frontService?.appConfig?.SKIN !== 'raywhite'" class="category-name">Start from blank</span>
          </a>
        </li>
        <li id="view-toggle" class="category-tab-tour-create">
          <a href="javascript:void(0)" (click)="createCustomCategory()">
            <div class="create-category">
              <img src="assets/img/plus.svg" />
            </div>
            <span class="category-name">Create custom Category</span>
          </a>
        </li>
      </ul>
    </div>

    <!-- <div class="category-tab">
        <ul>
          <li *ngFor="let category of categoryList" id="intro-{{ category.slug }}">
            <div class="btn-group category-action">
              <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ellipsis-icon"></i></button>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#">Rename category</a>
                <a class="dropdown-item" href="#">Replace icon</a>
                <a class="dropdown-item" href="#">Remove category</a>
              </div>
            </div>
            <a href="#{{ category.slug }}" role="tab" data-bs-toggle="tab" (click)="hideNotThis(category.slug)">
              <div class="category-container">
                <div class="img-container">
                  <img *ngIf="!category?.custom" [class.beyond-the-bricks]="category.slug === 'beyond-the-bricks'" src="{{ category.icon }}" height="100%">
                  <img *ngIf="category?.custom" src="{{ frontService.appConfig.S3_URL + category.icon }}" class="custom-category-icon">
                </div>
              </div>
              <span class="category-name">{{ category.name }}</span>
            </a>
          </li>
          <li *ngIf="!authUser?.is_master" id="view-toggle">
            <a href="javascript:void(0)" *ngIf="!pane_open" (click)="showAll()">
              <div class="create-category">
                <img src="assets/img/plus.svg">
              </div>
              <span class="category-name">View All</span>
            </a>
            <a href="javascript:void(0)" *ngIf="pane_open" (click)="hideAll()">
              <div class="create-category">
                <img src="assets/img/minus.svg">
              </div>
              <span class="category-name">Hide All</span>
            </a>
          </li>
          <li *ngIf="authUser?.is_master" id="view-toggle">
            <a href="javascript:void(0)" (click)="createCustomCategory()">
              <div class="create-category">
                <img src="assets/img/plus.svg">
              </div>
              <span class="category-name">Create custom Category</span>
            </a>
          </li>
        </ul>
      </div> -->
    <div #categoryContent class="row tab-content category-content">
      <ng-container *ngFor="let quickdesign of quickdesigns">
        <div *ngIf="quickdesign?.custom" id="{{ quickdesign?.slug }}" role="tabpanel" class="tab-pane">
          <div class="col-md-12">
            <h3>{{ quickdesign?.title }}</h3>
            <h4>
              Emails <button *ngIf="quickdesign?.client_id === authUser?.client?.ID" type="button" (click)="onCreateQuickDesignGroup(quickdesign?.ID, 'emails')" class="btn btn-link"><i class="plus-circle-icon"></i> Create template folder</button>
            </h4>
            <div class="master-templates">
              <ng-container *ngIf="quickdesign?.categories?.emails > 0">
                <ng-container *ngFor="let item of quickdesign?.templates">
                  <ng-container *ngIf="item?.type === 'group' && item?.category === 'emails'">
                    <div class="master-templates__template">
                      <div *ngIf="item?.client_id === authUser?.client?.ID" class="btn-group category-action">
                        <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ellipsis-icon"></i></button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" href="javascript:void(0)" (click)="onEditQuickDesignGroup(item?.ID, item?.name)">Edit folder</a>
                          <a class="dropdown-item" (click)="onReplaceQuickDesignIcon('group', item?.ID)">Replace icon</a>
                          <a class="dropdown-item" href="javascript:void(0)" (click)="onDeleteQuickDesignGroup(item?.ID)">Remove folder</a>
                        </div>
                      </div>
                      <a href="javascript:void(0)" (click)="getGroupTemplates(item?.ID, item?.category)">
                        <div class="template-container">
                          <span class="badge template-counter">{{ item?.quickdesigns_count }}</span>
                          <div class="img-container">
                            <img src="{{ frontService.appConfig.S3_URL + item?.icon }}" onerror="this.src='assets/img/no-preview-folder.svg'" />
                          </div>
                        </div>
                        <div class="template-info">
                          <p>{{ item?.name }}</p>
                        </div>
                      </a>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="showToCategory('emails', item?.template) && item?.type === 'template'">
                    <div *ngIf="!item?.quick_design_group_id" class="master-templates__template">
                      <div *ngIf="item?.client_id === authUser?.client?.ID" class="btn-group category-action">
                        <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ellipsis-icon"></i></button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" routerLink="/templates/{{ item?.template?.ID }}/build">Edit template</a>
                          <a class="dropdown-item" href="javascript:void(0)" (click)="onEditTitle(item?.template?.ID, item?.template?.title)">Rename template</a>
                          <a class="dropdown-item" (click)="onReplaceQuickDesignIcon('template', item?.ID)">Replace icon</a>
                          <a class="dropdown-item" href="javascript:void(0)" (click)="removeFromQuickDesign(item?.ID)">Remove template</a>
                        </div>
                      </div>
                      <a href="javascript:void(0)" (click)="onOpenSingleTemplate(item?.template, item?.client_id, item?.description, item?.ID)">
                        <div class="template-container">
                          <div class="img-container">
                            <img *ngIf="item?.icon" src="{{ frontService.appConfig.S3_URL + item?.icon }}" alt="" onerror="this.src='assets/img/no-preview-template.svg'" />
                            <img *ngIf="!item?.icon" src="{{ TEMPLATE_THUMB_S3_URL + item?.template?.thumbnail }}" onerror="this.src='assets/img/no-preview-template.svg'" alt="" />
                            <!-- <img *ngIf="!item?.icon" src="{{TEMPLATE_THUMB_S3_URL + item?.template?.thumbnail}}"
                                alt=""> -->
                          </div>
                        </div>
                        <div class="template-info">
                          <p>{{ item?.template?.title }}</p>
                          <p class="text-uppercase template-name">{{ item?.template?.category?.title }}</p>
                        </div>
                      </a>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="quickdesign?.categories?.emails === 0">
                <h3 class="no-template-available">There are currently no templates available</h3>
              </ng-container>
            </div>
          </div>
          <div class="col-md-12">
            <h4>
              {{ frontService?.appConfig?.SKIN === 'professionals' ? 'Print' : 'Printables' }}
              <button type="button" *ngIf="quickdesign?.client_id === authUser?.client?.ID" (click)="onCreateQuickDesignGroup(quickdesign?.ID, 'printables')" class="btn btn-link"><i class="plus-circle-icon"></i> Create template folder</button>
            </h4>
            <div class="master-templates">
              <ng-container *ngIf="quickdesign?.categories?.printables > 0">
                <ng-container *ngFor="let item of quickdesign?.templates">
                  <ng-container *ngIf="item?.type === 'group' && item?.category === 'printables'">
                    <div class="master-templates__template">
                      <div *ngIf="item?.client_id === authUser?.client?.ID" class="btn-group category-action">
                        <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ellipsis-icon"></i></button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" href="javascript:void(0)" (click)="onEditQuickDesignGroup(item?.ID, item?.name)">Edit folder</a>
                          <a class="dropdown-item" (click)="onReplaceQuickDesignIcon('group', item?.ID)">Replace icon</a>
                          <a class="dropdown-item" href="javascript:void(0)" (click)="onDeleteQuickDesignGroup(item?.ID)">Remove folder</a>
                        </div>
                      </div>
                      <a href="javascript:void(0)" (click)="getGroupTemplates(item?.ID, item?.category)">
                        <div class="template-container">
                          <span class="badge template-counter">{{ item?.quickdesigns_count }}</span>
                          <div class="img-container">
                            <img src="{{ frontService.appConfig.S3_URL + item?.icon }}" onerror="this.src='assets/img/no-preview-folder.svg'" />
                          </div>
                        </div>
                        <div class="template-info">
                          <p>{{ item?.name }}</p>
                        </div>
                      </a>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="showToCategory('printables', item?.template) && item?.type === 'template'">
                    <div *ngIf="!item?.quick_design_group_id" class="master-templates__template">
                      <div *ngIf="item?.client_id === authUser?.client?.ID" class="btn-group category-action">
                        <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ellipsis-icon"></i></button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" routerLink="/templates/{{ item?.template?.ID }}/build">Edit template</a>
                          <a class="dropdown-item" href="javascript:void(0)" (click)="onEditTitle(item?.template?.ID, item?.template?.title)">Rename template</a>
                          <a class="dropdown-item" (click)="onReplaceQuickDesignIcon('template', item?.ID)">Replace icon</a>
                          <a class="dropdown-item" href="javascript:void(0)" (click)="removeFromQuickDesign(item?.ID)">Remove template</a>
                        </div>
                      </div>
                      <a href="javascript:void(0)" (click)="onOpenSingleTemplate(item?.template, item?.client_id, item?.description, item?.ID)">
                        <div class="template-container">
                          <div class="img-container">
                            <img *ngIf="item?.icon" src="{{ frontService.appConfig.S3_URL + item?.icon }}" alt="" onerror="this.src='assets/img/no-preview-template.svg'" />
                            <img *ngIf="!item?.icon" src="{{ TEMPLATE_THUMB_S3_URL + item?.template?.thumbnail }}" onerror="this.src='assets/img/no-preview-template.svg'" alt="" />
                            <!-- <img *ngIf="!item?.icon" src="{{TEMPLATE_THUMB_S3_URL + item?.template?.thumbnail}}"
                                alt=""> -->
                          </div>
                        </div>
                        <div class="template-info">
                          <p>{{ item?.template?.title }}</p>
                          <p class="text-uppercase template-name">{{ item?.template?.category?.title }}</p>
                        </div>
                      </a>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="quickdesign?.categories?.printables === 0">
                <h3 class="no-template-available">There are currently no templates available</h3>
              </ng-container>
            </div>
          </div>
          <div class="col-md-12">
            <h4>
              Social Media <button *ngIf="quickdesign?.client_id === authUser?.client?.ID" type="button" (click)="onCreateQuickDesignGroup(quickdesign?.ID, 'socialmedia')" class="btn btn-link"><i class="plus-circle-icon"></i> Create template folder</button>
            </h4>
            <div class="master-templates">
              <ng-container *ngIf="quickdesign?.categories?.socialmedia > 0">
                <ng-container *ngFor="let item of quickdesign?.templates">
                  <ng-container *ngIf="item?.type === 'group' && item?.category === 'socialmedia'">
                    <div class="master-templates__template">
                      <div *ngIf="item?.client_id === authUser?.client?.ID" class="btn-group category-action">
                        <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ellipsis-icon"></i></button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" href="javascript:void(0)" (click)="onEditQuickDesignGroup(item?.ID, item?.name)">Edit folder</a>
                          <a class="dropdown-item" (click)="onReplaceQuickDesignIcon('group', item?.ID)">Replace icon</a>
                          <a class="dropdown-item" href="javascript:void(0)" (click)="onDeleteQuickDesignGroup(item?.ID)">Remove folder</a>
                        </div>
                      </div>
                      <a href="javascript:void(0)" (click)="getGroupTemplates(item?.ID, item?.category)">
                        <div class="template-container">
                          <span class="badge template-counter">{{ item?.quickdesigns_count }}</span>
                          <div class="img-container">
                            <img src="{{ frontService.appConfig.S3_URL + item?.icon }}" onerror="this.src='assets/img/no-preview-folder.svg'" />
                          </div>
                        </div>
                        <div class="template-info">
                          <p>{{ item?.name }}</p>
                        </div>
                      </a>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="showToCategory('socialmedia', item?.template) && item?.type === 'template'">
                    <div *ngIf="!item?.quick_design_group_id" class="master-templates__template">
                      <div *ngIf="item?.client_id === authUser?.client?.ID" class="btn-group category-action">
                        <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ellipsis-icon"></i></button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" routerLink="/templates/{{ item?.template?.ID }}/build">Edit template</a>
                          <a class="dropdown-item" href="javascript:void(0)" (click)="onEditTitle(item?.template?.ID, item?.template?.title)">Rename template</a>
                          <a class="dropdown-item" (click)="onReplaceQuickDesignIcon('template', item?.ID)">Replace icon</a>
                          <a class="dropdown-item" href="javascript:void(0)" (click)="removeFromQuickDesign(item?.ID)">Remove template</a>
                        </div>
                      </div>
                      <a href="javascript:void(0)" (click)="onOpenSingleTemplate(item?.template, item?.client_id, item?.description, item?.ID)">
                        <div class="template-container">
                          <div class="img-container">
                            <img *ngIf="item?.icon" src="{{ frontService.appConfig.S3_URL + item?.icon }}" alt="" onerror="this.src='assets/img/no-preview-template.svg'" />
                            <img *ngIf="!item?.icon" src="{{ TEMPLATE_THUMB_S3_URL + item?.template?.thumbnail }}" onerror="this.src='assets/img/no-preview-template.svg'" alt="" />
                            <!-- <img *ngIf="!item?.icon" src="{{TEMPLATE_THUMB_S3_URL + item?.template?.thumbnail}}"
                                alt=""> -->
                          </div>
                        </div>
                        <div class="template-info">
                          <p>{{ item?.template?.title }}</p>
                          <p class="text-uppercase template-name">{{ item?.template?.category?.title }}</p>
                        </div>
                      </a>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="quickdesign?.categories?.socialmedia === 0">
                <h3 class="no-template-available">There are currently no templates available</h3>
              </ng-container>
            </div>
          </div>
          <div class="col-md-12">
            <h4>
              Signboards <button *ngIf="quickdesign?.client_id === authUser?.client?.ID" type="button" (click)="onCreateQuickDesignGroup(quickdesign?.ID, 'signboards')" class="btn btn-link"><i class="plus-circle-icon"></i> Create template folder</button>
            </h4>
            <div class="master-templates">
              <ng-container *ngIf="quickdesign?.categories?.signboards > 0">
                <ng-container *ngFor="let item of quickdesign?.templates">
                  <ng-container *ngIf="item?.type === 'group' && item?.category === 'signboards'">
                    <div class="master-templates__template">
                      <div *ngIf="item?.client_id === authUser?.client?.ID" class="btn-group category-action">
                        <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ellipsis-icon"></i></button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" href="javascript:void(0)" (click)="onEditQuickDesignGroup(item?.ID, item?.name)">Edit folder</a>
                          <a class="dropdown-item" (click)="onReplaceQuickDesignIcon('group', item?.ID)">Replace icon</a>
                          <a class="dropdown-item" href="javascript:void(0)" (click)="onDeleteQuickDesignGroup(item?.ID)">Remove folder</a>
                        </div>
                      </div>
                      <a href="javascript:void(0)" (click)="getGroupTemplates(item?.ID, item?.category)">
                        <div class="template-container">
                          <span class="badge template-counter">{{ item?.quickdesigns_count }}</span>
                          <div class="img-container">
                            <img src="{{ frontService.appConfig.S3_URL + item?.icon }}" onerror="this.src='assets/img/no-preview-folder.svg'" />
                          </div>
                        </div>
                        <div class="template-info">
                          <p>{{ item?.name }}</p>
                        </div>
                      </a>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="showToCategory('signboards', item?.template) && item?.type === 'template'">
                    <div *ngIf="!item?.quick_design_group_id" class="master-templates__template">
                      <div *ngIf="item?.client_id === authUser?.client?.ID" class="btn-group category-action">
                        <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ellipsis-icon"></i></button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" routerLink="/templates/{{ item?.template?.ID }}/build">Edit template</a>
                          <a class="dropdown-item" href="javascript:void(0)" (click)="onEditTitle(item?.template?.ID, item?.template?.title)">Rename template</a>
                          <a class="dropdown-item" (click)="onReplaceQuickDesignIcon('template', item?.ID)">Replace icon</a>
                          <a class="dropdown-item" href="javascript:void(0)" (click)="removeFromQuickDesign(item?.ID)">Remove template</a>
                        </div>
                      </div>
                      <a href="javascript:void(0)" (click)="onOpenSingleTemplate(item?.template, item?.client_id, item?.description, item?.ID)">
                        <div class="template-container">
                          <div class="img-container">
                            <img *ngIf="item?.icon" src="{{ frontService.appConfig.S3_URL + item?.icon }}" alt="" onerror="this.src='assets/img/no-preview-template.svg'" />
                            <img *ngIf="!item?.icon" src="{{ TEMPLATE_THUMB_S3_URL + item?.template?.thumbnail }}" onerror="this.src='assets/img/no-preview-template.svg'" alt="" />
                            <!-- <img *ngIf="!item?.icon" src="{{TEMPLATE_THUMB_S3_URL + item?.template?.thumbnail}}"
                                alt=""> -->
                          </div>
                        </div>
                        <div class="template-info">
                          <p>{{ item?.template?.title }}</p>
                          <p class="text-uppercase template-name">{{ item?.template?.category?.title }}</p>
                        </div>
                      </a>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="quickdesign?.categories?.signboards === 0">
                <h3 class="no-template-available">There are currently no templates available</h3>
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngIf="!quickdesign?.custom" id="{{ quickdesign?.slug }}" role="tabpanel" class="tab-pane fade">
          <div class="col-md-12">
            <h3>
              {{ quickdesign?.title === 'Printables' && frontService?.appConfig?.SKIN === 'professionals' ? 'Print' : quickdesign?.title }}
              <button type="button" (click)="onCreateQuickDesignGroup(quickdesign?.slug, quickdesign?.slug)" class="btn btn-link"><i class="plus-circle-icon"></i> Create template folders</button>
              <span *ngIf="authUser?.is_master" class="reorder-toggle"
                ><label class="switch"><input type="checkbox" (change)="onCheckReorder($event)" /><span class="slider round"></span></label><span class="title">Re Order</span></span
              >
            </h3>

            <div class="master-templates" dndDropzone [dndHorizontal]="true" dndEffectAllowed="copyMove" (dndDrop)="onDrop($event, quickdesign?.templates)">
              <div dndPlaceholderRef class="master-templates__template dndPlaceholder"></div>

              <ng-container *ngIf="quickdesign?.templates?.length > 0">
                <ng-container *ngFor="let item of quickdesign?.templates">
                  <div *ngIf="item?.type === 'group' && item?.category === quickdesign?.slug" class="master-templates__template" [class.is-draggable]="is_reorder" [dndDraggable]="item" dndEffectAllowed="move" [dndDisableIf]="!is_reorder" (dndMoved)="onDragged(item, quickdesign?.templates, 'move')">
                    <span class="move-icon" *ngIf="is_reorder">
                      <i class="icon feather icon-move"></i>
                    </span>
                    <div *ngIf="item?.client_id === authUser?.client?.ID && !is_reorder" class="btn-group category-action">
                      <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ellipsis-icon"></i></button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="javascript:void(0)" (click)="onEditQuickDesignGroup(item?.ID, item?.name)">Edit folder</a>
                        <a class="dropdown-item" (click)="onReplaceQuickDesignIcon('group', item?.ID)">Replace icon</a>
                        <a class="dropdown-item" href="javascript:void(0)" (click)="onDeleteQuickDesignGroup(item?.ID)">Remove folder</a>
                      </div>
                    </div>
                    <a href="javascript:void(0)" (click)="getGroupTemplates(item?.ID, item?.category)">
                      <div class="template-container">
                        <span *ngIf="!is_reorder" class="badge template-counter">{{ item?.quickdesigns_count }}</span>
                        <div class="img-container">
                          <img src="{{ frontService.appConfig.S3_URL + item?.icon }}" onerror="this.src='assets/img/no-preview-folder.svg'" />
                        </div>
                      </div>
                      <div class="template-info">
                        <p>{{ item?.name }}</p>
                      </div>
                    </a>
                  </div>
                  <div *ngIf="!item?.quick_design_group_id && item?.type === 'template'" class="master-templates__template" [class.is-draggable]="is_reorder" [dndDraggable]="item" dndEffectAllowed="move" [dndDisableIf]="!is_reorder" (dndMoved)="onDragged(item, quickdesign?.templates, 'move')">
                    <span class="move-icon" *ngIf="is_reorder">
                      <i class="icon feather icon-move"></i>
                    </span>
                    <div *ngIf="item?.client_id === authUser?.client?.ID && !is_reorder" class="btn-group category-action">
                      <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ellipsis-icon"></i></button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" routerLink="/templates/{{ item?.template?.ID }}/build">Edit template</a>
                        <a class="dropdown-item" href="javascript:void(0)" (click)="onEditTitle(item?.template?.ID, item?.template?.title)">Rename template</a>
                        <a class="dropdown-item" (click)="onReplaceQuickDesignIcon('template', item?.ID)">Replace icon</a>
                        <a class="dropdown-item" href="javascript:void(0)" (click)="removeFromQuickDesign(item?.ID)">Remove template</a>
                      </div>
                    </div>
                    <a href="javascript:void(0)" (click)="onOpenSingleTemplate(item?.template, item?.client_id, item?.description, item?.ID)">
                      <div class="template-container">
                        <div class="img-container">
                          <img *ngIf="item?.icon" src="{{ frontService.appConfig.S3_URL + item?.icon }}" alt="" onerror="this.src='assets/img/no-preview-template.svg'" />
                          <img *ngIf="!item?.icon" src="{{ TEMPLATE_THUMB_S3_URL + item?.template?.thumbnail }}" alt="" onerror="this.src='assets/img/no-preview-template.svg'" />
                        </div>
                      </div>
                      <div class="template-info">
                        <p>{{ item?.template?.title }}</p>
                        <p class="text-uppercase template-name">{{ item?.template?.category?.title }}</p>
                      </div>
                    </a>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="quickdesign?.templates?.length === 0">
                <h3 class="no-template-available">There are currently no templates available</h3>
              </ng-container>
            </div>

            <!-- <div class="master-templates" [dndList]="{
                disabled: false,
                effectAllowed: 'move',
                allowedTypes: ['folder']}" [dndModel]="quickdesign?.groups">
                <ng-container *ngIf="quickdesign?.templates?.length > 0 || quickdesign?.groups?.length > 0">
                  <ng-container *ngFor="let folder of quickdesign?.groups">
                    <ng-container *ngIf="folder?.category === quickdesign?.slug">
                      <div class="master-templates__template" [dndDraggable] [dndType]="'folder'" (dndMoved)="removeItem(folder, quickdesign?.groups)" [dndObject]="folder">
                        <div *ngIf="folder?.client_id === authUser?.client?.ID" class="btn-group category-action">
                          <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false"><i class="ellipsis-icon"></i></button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:void(0)"
                              (click)="onEditQuickDesignGroup(folder?.ID, folder?.name)">Edit folder</a>
                            <a class="dropdown-item" (click)="onReplaceQuickDesignIcon('group', folder?.ID)">Replace
                              icon</a>
                            <a class="dropdown-item" href="javascript:void(0)"
                              (click)="onDeleteQuickDesignGroup(folder?.ID)">Remove folder</a>
                          </div>
                        </div>
                        <a href="javascript:void(0)" (click)="getGroupTemplates(folder?.ID, folder?.category)">
                          <div class="template-container">
                            <span class="badge template-counter">{{folder?.quickdesigns_count}}</span>
                            <div class="img-container">
                              <img src="{{frontService.appConfig.S3_URL + folder?.icon}}"
                                onerror="this.src='assets/img/no-preview-folder.svg'">
                            </div>
                          </div>
                          <div class="template-info">
                            <p>{{folder?.name}}</p>
                          </div>
                        </a>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngFor="let template of quickdesign?.templates">
                    <div *ngIf="!template?.quick_design_group_id" class="master-templates__template"  [dndDraggable] [dndType]="'folder'" (dndMoved)="removeItem(folder, quickdesign?.groups)" [dndObject]="folder">
                      <div *ngIf="template?.client_id === authUser?.client?.ID" class="btn-group category-action">
                        <button type="button" class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false"><i class="ellipsis-icon"></i></button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" *ngIf="authUser?.is_admin" routerLink="/templates/{{ template?.template?.ID }}/build">Edit template</a>
                          <a class="dropdown-item" href="javascript:void(0)"
                            (click)="onEditTitle(template?.template?.ID, template?.template?.title)">Rename template</a>
                          <a class="dropdown-item" (click)="onReplaceQuickDesignIcon('template', template?.ID)">Replace
                            icon</a>
                          <a class="dropdown-item" href="javascript:void(0)"
                            (click)="removeFromQuickDesign(template?.ID)">Remove template</a>
                        </div>
                      </div>
                      <a href="javascript:void(0)" (click)="onOpenSingleTemplate(template?.template, template?.client_id)">
                        <div class="template-container">
                          <div class="img-container">
                            <img *ngIf="template?.icon" src="{{frontService.appConfig.S3_URL + template?.icon}}" alt=""
                              onerror="this.src='assets/img/no-preview-template.svg'">
                            <img *ngIf="!template?.icon" src="{{TEMPLATE_THUMB_S3_URL + template?.template?.thumbnail}}"
                              alt="" onerror="this.src='assets/img/no-preview-template.svg'">
                          </div>
                        </div>
                        <div class="template-info">
                          <p>{{template?.template?.title}}</p>
                          <p class="text-uppercase template-name">{{template?.template?.category?.title}}</p>
                        </div>
                      </a>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="quickdesign?.templates?.length === 0 && quickdesign?.groups?.length === 0">
                  <h3 class="no-template-available">There are currently no templates available</h3>
                </ng-container>
              </div> -->
          </div>
        </div>
      </ng-container>

      <div id="default-pricefinder" role="tabpanel" class="tab-pane fade">
        <div class="col-md-12">
          <h3>Pricefinder</h3>
          <div class="master-templates">
            <div class="master-templates__template">
              <a (click)="onModal('pricefinder')" data-bs-target="#newTemplate" data-bs-toggle="modal">
                <div class="template-container no-template">
                  <div class="img-container">
                    <img src="assets/img/no-preview-template.svg" />
                  </div>
                </div>
                <div class="template-info">
                  <p>Market Snapshot</p>
                  <p class="text-uppercase template-name">SUBURB REPORT</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="default-diy" role="tabpanel" class="tab-pane fade">
        <div class="col-md-12">
          <h3 *ngIf="frontService?.appConfig?.SKIN !== 'raywhite'">Start from blank</h3>
          <h3 *ngIf="frontService?.appConfig?.SKIN === 'raywhite'">Create yourself</h3>
        </div>
        <ng-container *ngIf="authUser?.client?.group?.ID === 5 || authUser?.client?.group?.ID === 11">
          <div *ngIf="wywfTemplates?.length > 0" class="col-md-12">
            <h4>What are you waiting for?</h4>
            <div class="master-templates">
              <div *ngFor="let wywf of wywfTemplates" class="master-templates__template">
                <a (click)="onModal(wywf)" data-bs-target="#newTemplate" data-bs-toggle="modal">
                  <div class="template-container" [class.no-template]="!wywf?.icon">
                    <div class="img-container">
                      <img *ngIf="wywf?.icon" src="{{ wywf?.icon }}" />
                      <img *ngIf="!wywf?.icon" src="assets/img/no-preview-template.svg" />
                    </div>
                  </div>
                  <div class="template-info">
                    <p>{{ wywf?.title }}</p>
                    <p class="text-uppercase template-name">{{ wywf?.category?.title }}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div *ngIf="pmTemplates?.length > 0" class="col-md-12">
            <h4>Property Management</h4>
            <div class="master-templates">
              <div *ngFor="let pmTemplate of pmTemplates" class="master-templates__template">
                <a (click)="onModal(pmTemplate)" data-bs-target="#newTemplate" data-bs-toggle="modal">
                  <div class="template-container" [class.no-template]="!pmTemplate?.icon">
                    <div class="img-container">
                      <img *ngIf="pmTemplate?.icon" src="{{ pmTemplate?.icon }}" />
                      <img *ngIf="!pmTemplate?.icon" src="assets/img/no-preview-template.svg" />
                    </div>
                  </div>
                  <div class="template-info">
                    <p>{{ pmTemplate?.title }}</p>
                    <p class="text-uppercase template-name">{{ pmTemplate?.category?.title }}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </ng-container>
        <div *ngIf="emails?.length > 0 && !authUser?.client?.group?.hide_emails" class="col-md-12">
          <h4>Emails</h4>
          <div class="master-templates">
            <div *ngFor="let email of emails" class="master-templates__template">
              <a (click)="onModal(email)" data-bs-target="#newTemplate" data-bs-toggle="modal">
                <div class="template-container" [class.no-template]="!email?.icon">
                  <div class="img-container">
                    <img *ngIf="email?.icon" src="{{ email?.icon }}" />
                    <img *ngIf="!email?.icon" src="assets/img/no-preview-template.svg" />
                  </div>
                </div>
                <div class="template-info">
                  <p>{{ email?.title }}</p>
                  <p class="text-uppercase template-name">{{ email?.category?.title }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="printables?.length > 0 && !authUser?.client?.group?.hide_printables" class="col-md-12">
          <h4>Printables</h4>
          <div class="master-templates">
            <div *ngFor="let printable of printables" class="master-templates__template">
              <a (click)="onModal(printable)" data-bs-target="#newTemplate" data-bs-toggle="modal">
                <div class="template-container">
                  <div class="img-container">
                    <img src="{{ printable?.icon }}" />
                  </div>
                </div>
                <div class="template-info">
                  <p>{{ printable?.title }}</p>
                  <p class="text-uppercase template-name">{{ printable?.category?.title }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="socialmedias?.length > 0 && !authUser?.client?.group?.hide_socials" class="col-md-12">
          <h4>Social Media</h4>
          <div class="master-templates">
            <div *ngFor="let socialmedia of socialmedias" class="master-templates__template">
              <a (click)="onModal(socialmedia)" data-bs-target="#newTemplate" data-bs-toggle="modal">
                <div class="template-container">
                  <div class="img-container">
                    <img src="{{ socialmedia?.icon }}" />
                  </div>
                </div>
                <div class="template-info">
                  <p>{{ socialmedia?.title }}</p>
                  <p class="text-uppercase template-name">{{ socialmedia?.category?.title }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="signboards?.length > 0 && !authUser?.client?.group?.hide_signboards" class="col-md-12">
          <h4>Signboards</h4>
          <div class="master-templates">
            <div *ngFor="let signboard of signboards" class="master-templates__template">
              <a (click)="onModal(signboard)" data-bs-target="#newTemplate" data-bs-toggle="modal">
                <div class="template-container">
                  <div class="img-container">
                    <img src="{{ signboard?.icon }}" />
                  </div>
                </div>
                <div class="template-info">
                  <p>{{ signboard?.title }}</p>
                  <p class="text-uppercase template-name">{{ signboard?.category?.title }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- <div *ngFor="let category of categoryList; let i = index" id="{{ category.slug }}" role="tabpanel"
          class="tab-pane"
          [ngClass]="(category.slug == 'dl-card' || category.slug == 'quick-enquiry' || category.slug == 'buyer-match' || category.slug == 'email-signature' )  ? 'hidden' : ''">
          <div class="col-md-12">
            <h3>{{ category.name }} <button *ngIf="category?.custom" (click)="deleteCustomCategory(category?.category_id)" class="delete-custom-category"><i title="Delete template category" class="icon feather icon-trash"></i></button></h3>
            
            <ng-container *ngIf="category?.custom">
              <ng-container *ngFor="let _category of category.masters">
                <h4>{{ transformTitle(_category?.ID, _category?.title) }}</h4>
                <div class="master-templates">
                  <ng-container *ngFor="let list of _category.templates">
                    <div *ngIf="category.slug !== 'soon' && !list.static && (category.slug !== 'beyond-the-bricks' && list.department_id !== 22)" class="master-templates__template">
                      <a  (click)="onModal(list)" data-bs-target="#newTemplate" data-bs-toggle="modal">
                        <img *ngIf="!list?.custom" [src]="list.icon ? list.icon : 'assets/img/blank.svg'">
                        <img *ngIf="list?.custom && list.icon" [src]="frontService.appConfig.S3_URL + list.icon">
                        <img *ngIf="list?.custom && !list.icon" [src]="list.master_icon">
                        <p>{{ list.title }}</p>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!category?.custom">
              <div class="master-templates">
                <ng-container *ngFor="let list of category.masters">
                    <div *ngIf="category.slug !== 'soon' && !list.static && (category.slug !== 'beyond-the-bricks' && list.department_id !== 22)" class="master-templates__template">
                      <a  (click)="onModal(list)" data-bs-target="#newTemplate" data-bs-toggle="modal">
                        <img *ngIf="!list?.custom" [src]="list.icon ? list.icon : 'assets/img/blank.svg'">
                        <img *ngIf="list?.custom && list.icon" [src]="frontService.appConfig.S3_URL + list.icon">
                        <img *ngIf="list?.custom && !list.icon" [src]="list.master_icon">
                        <p>{{ list.title }}</p>
                      </a>
                    </div>
      
                    <div *ngIf="category.slug !== 'soon' && !list.static && (category.slug === 'beyond-the-bricks' && list.department_id === 22)" class="master-templates__template">
                      <a  (click)="onModal(list)" data-bs-target="#newTemplate" data-bs-toggle="modal">
                        <img [src]="list.icon ? list.icon : 'assets/img/blank.svg'">
                        <p>{{ list.title }}</p>
                      </a>
                    </div>
      
                    <div *ngIf="category.slug !== 'soon' && list.static" class="master-templates__template">
                      <a  (click)="onModal(list)" data-bs-target="#newTemplate" data-bs-toggle="modal">
                        <img [src]="list.icon ? list.icon : 'assets/img/blank.svg'">
                        <p>{{ list.title }}</p>
                      </a>
                    </div>
      
                    <div  *ngIf="category.slug === 'soon'" class="master-templates__template">
                        <a  (click)="onModal(list)" data-bs-target="#newTemplate" data-bs-toggle="modal">
                        <img [src]="list.icon ? list.icon : 'assets/img/blank.svg'">
                        <p>{{ list.title }}</p>
                      </a>
                    </div>
                  
                </ng-container>
                <h3 *ngIf="!category.masters.length">There are currently no templates available</h3>
              </div>
            </ng-container>
          </div>
        </div> -->
    </div>
  </div>
</div>

<div class="homepage2 app-media">
  <div class="page-header header-bar" [class.page-header-campaign]="page === 'campaign'">
    <div class="row templates-header">
      <div class="col-md-12 template-title-search">
        <h3 *ngIf="page === 'campaign'" class="campaign-title-holder">Campaign</h3>

        <nav aria-label="breadcrumb" *ngIf="page !== 'campaign' && currentPageType !== 'dashboard'" class="folder-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/templates/folders/">Folders</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ pageTitle }}</li>
          </ol>
        </nav>

        <h1 *ngIf="page !== 'campaign'" class="page-title intro-template" style="display: inline-block">
          {{ pageTitle }} <span class="badge badge-info blue-background circle-badge">{{ template_count }}</span>
        </h1>
        <p *ngIf="page === 'campaign'">{{ listing?.displayaddress }} (Property ID {{ listing?.id }})</p>
      </div>
      <div [ngClass]="page === 'campaign' ? 'col-lg-7' : !authUser?.is_master && page !== 'campaign' ? 'col-lg-9' : 'col-lg-12'">
        <div class="page-header-actions">
          <ul class="list-inline">
            <li class="filter-list">
              <span class="intro-view-option">
                <button type="button" class="btn btn-sm btn-icon btn-default btn-link template-view" [class.active]="!isList" data-view="grid" data-bs-toggle="tooltip" data-original-title="Grid view">
                  <i class="icon feather icon-grid" aria-hidden="true"></i>
                </button>
                <button type="button" class="btn btn-sm btn-icon btn-default btn-link template-view" [class.active]="isList" data-view="list" data-bs-toggle="tooltip" data-original-title="List View">
                  <i class="icon feather icon-menu" aria-hidden="true"></i>
                </button>
              </span>

              <div class="input-search">
                <input type="text" class="form-control" name="" placeholder="Search..." [formControl]="templateTitleSearchInput" />
              </div>
              <select #changeSort class="intro-date" data-plugin="selectpicker" data-style="btn btn-link btn-filter" [(ngModel)]="current_sort_by" (change)="onChangeSort(changeSort.value)">
                <option value="created_DESC">Sort by date desc</option>
                <option value="created_ASC">Sort by date asc</option>
                <option value="title_ASC">Sort by Title ASC</option>
                <option value="title_DESC">Sort by Title DESC</option>
              </select>

              <select #changeTemplateFilter class="intro-template-filter" data-plugin="selectpicker" data-style="btn btn-link btn-filter" [(ngModel)]="current_template_filter" (change)="onChangeTemplateFilter(changeTemplateFilter.value)">
                <option value="" selected disabled>Template Filter</option>
                <option value="all">All</option>
                <option value="quick-design">Quick Designs</option>
                <option *ngIf="authUser?.provider !== 'idashboard'" value="1">eMarketing</option>
                <option *ngIf="authUser?.provider !== 'idashboard'" value="2">Buyer Match</option>
                <option value="4">Brochure</option>
                <option value="5">Property Guide</option>
                <option value="6">Social Media</option>
                <option *ngIf="authUser?.provider !== 'idashboard'" value="7">Email Signature</option>
                <option value="8">DL Card</option>
                <option value="9">Signboards</option>
                <option *ngIf="authUser?.provider === 'vaultre'" value="10">PDF Flyers</option>
                <option *ngIf="authUser?.provider === 'vaultre'" value="11">Match Properties</option>
                <option *ngIf="authUser?.provider === 'vaultre'" value="13">Property Alerts</option>
              </select>

              <select #changeAgentFilter class="intro-agent" *ngIf="(authUser?.client?.allow_agent_settings === 1 || authUser.is_admin || authUser.client.group_id === 12 || authUser.client.group_id === 19) && page !== 'campaign'"
                data-plugin="selectpicker" data-style="btn btn-link btn-filter" [(ngModel)]="current_agent_filter"
                (change)="onChangeAgentFilter(changeAgentFilter.value)"
                [class.hidden]="extraPages.includes(currentPageType)">
                <option value="" selected disabled>Agent Filter</option>
                <option value="all">All</option>
                <option *ngFor="let agentUser of agentUsers" [value]="agentUser.agent_id">{{ agentUser.firstname + ' ' + agentUser.lastname }} ({{ agentUser.agent_id }})</option>
              </select>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="page === 'campaign'" class="col-lg-5 text-right">
        <ul *ngIf="page === 'campaign'" class="campaign-buttons">
          <li class="list-inline text-right">
            <button class="btn-admin btn-admin-primary btn-refresh" type="button" (click)="refreshTemplates()"><i class="icon feather icon-repeat" aria-hidden="true"></i> &nbsp; Refresh Templates</button>
            <button class="btn-admin btn-admin-primary btn-generate" type="button" (click)="getTemplates()"><i class="icon feather icon-repeat" aria-hidden="true"></i> &nbsp; Generate Campaign</button>
            <button (click)="exportCampaign()" type="button" class="btn-admin btn-admin-primary btn-campaign-export btn-export">
              <i class="icon feather icon-download" aria-hidden="true"></i>
            </button>
          </li>
        </ul>
      </div>
      <div *ngIf="!authUser?.is_master && page !== 'campaign' && frontService?.appConfig?.SKIN !== 'raywhite' && currentPageType === 'dashboard'" class="col-lg-3 text-right">
        <button class="btn-admin btn-admin-primary btn-load-master" type="button" (click)="loadMasterTemplates()">
          <i class="icon feather icon-download" aria-hidden="true"></i> &nbsp; Load Master Templates
          <span class="master-tooltip">Load a range of pre made templates to use</span>
        </button>
      </div>
    </div>
  </div>

  <div class="page-content your-templates" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="800" (scrolled)="onScroll()">
    <!-- <a (click)="onFeedback()" id="my_feedback" class="btn-feedback">Send Feedback</a> -->
    <div class="media-list padding-bottom-50" [class.is-grid]="!isList" [class.is-list]="isList">
      <ul class="blocks blocks-100 blocks-xlg-6 blocks-lg-4 blocks-md-4 blocks-ms-2 blocks-xs-2" data-plugin="animateList" data-child=">li">
        <li *ngIf="templates.length > 0" class="table-header-row">
          <div class="media-item">
            <table class="row-list">
              <tr class="table-heading">
                <td></td>
                <td class="col-md-2"><small class="media-heading gray4-color">Title</small></td>
                <td class="col-md-2"><small class="media-heading gray4-color">Template Type</small></td>
                <td class="col-md-2"><small class="media-heading gray4-color">Created by</small></td>
                <td class="col-md-2"><small class="media-heading gray4-color">Created on</small></td>
                <td class="col-md-2"><small class="media-heading gray4-color">Last Modified</small></td>
                <td></td>
              </tr>
            </table>
          </div>
        </li>
        <li *ngFor="let template of templates; let i = index" class="animation-scale-up" [attr.data-template]="template.ID" style="animation-fill-mode: backwards; animation-duration: 250ms; animation-delay: 0ms">
          <div class="media-item" [style.cursor]="authUser.client.ID !== template.client_id ? 'auto' : 'pointer'">
            <div class="image-wrap">
              <div class="dropup dropup-grid">
                <button type="button" data-bs-toggle="dropdown" data-id="{{ template.ID }}" class="btn vertical-align-middle text-uppercase intro-view btn-viewmore">
                  <i class="icon feather icon-more-horizontal" aria-hidden="true" title="View"></i>
                </button>

                <ul class="dropdown-menu">
                  <li class="dropdown-submenu">
                    <a href="javascript:;"><span class="icon feather icon-layout"></span> Templates <span class="icon feather icon-chevron-right float-right"></span></a>
                    <ul class="dropdown-menu">
                      <li><a href="javascript:void(0)" (click)="onPreview(template)">View</a></li>
                      <ng-container *ngIf="currentPageType === 'dashboard' || currentPageType === 'favourite' || isUuid(currentPageType)">
                        <li><a routerLink="/templates/{{ template.ID }}/build">Edit</a></li>
                        <li><a href="javascript:void(0)" (click)="onEditTitle(template.ID, template.title)">Rename</a></li>
                        <li *ngIf="!template?.is_favourite"><a href="javascript:void(0)" (click)="favourite(template.ID, 1)">Mark as Favourite</a></li>
                        <li *ngIf="template?.is_favourite"><a href="javascript:void(0)" (click)="favourite(template.ID, 0)">Remove as Favourite</a></li>
                        <li *ngIf="authUser.provider === 'designly'"><a href="javascript:void(0)" (click)="sendDesignly(template)">Send</a></li>
                        <li><a href="javascript:void(0)" (click)="onDuplicate(template)">Duplicate</a></li>
                        <li><a href="javascript:void(0)" (click)="onShare(template)">Share</a></li>
                        <li><a href="javascript:void(0)" (click)="onUpdateStatus(template.ID)">Archive</a></li>
                      </ng-container>
                      <li *ngIf="currentPageType === 'group' || currentPageType === 'client'"><a href="javascript:void(0)" (click)="onDuplicate(template)">Duplicate</a></li>
                      <li *ngIf="currentPageType === 'archived'"><a href="javascript:void(0)" (click)="onUpdateStatus(template.ID, 1)">Activate</a></li>
                    </ul>
                  </li>
                  <ng-container *ngIf="currentPageType === 'dashboard' || currentPageType === 'favourite' || isUuid(currentPageType)">
                    <li class="dropdown-submenu">
                      <a href="javascript:;"><span class="icon feather icon-folder"></span> Folders <span class="icon feather icon-chevron-right float-right"></span></a>
                      <ul class="dropdown-menu">
                        <li *ngIf="!template?.folder_templates?.folder_id"><a href="javascript:void(0)" (click)="addToFolder(template)">Add to Folder</a></li>
                        <li *ngIf="template?.folder_templates?.folder_id"><a href="javascript:void(0)" (click)="moveToFolder(template)">Move to Folder</a></li>
                        <li *ngIf="isUuid(currentPageType)"><a href="javascript:void(0)" (click)="removeFromFolder(template)">Remove from Folder</a></li>
                      </ul>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="currentPageType === 'dashboard' || currentPageType === 'favourite' || isUuid(currentPageType)">
                    <li *ngIf="page !== 'campaign' && !template?.quickdesign"><a href="javascript:void(0)" (click)="saveAsQuickDesign(template)"><span class="icon feather icon-save"></span> Save as Quick Design</a></li>
                    <li *ngIf="page !== 'campaign' && template?.quickdesign"><a href="javascript:void(0)" (click)="removeFromQuickDesign(template?.quickdesign?.ID, true, template?.ID)"><span class="icon feather icon-minus-square"></span> Remove as Quick Design</a></li>
                    <li *ngIf="page !== 'campaign' && authUser.provider !== 'designly' && template.category.slug !== 'buyer-match' && template.category.slug !== 'match-listings' && template.category.slug !== 'property-match' && authUser?.brandid !== '6' && authUser?.brandid !== '4'">
                      <a href="javascript:void(0)" (click)="addTemplateToCampaign(template.ID)"><span class="icon feather icon-plus-square"></span> Add to Campaign</a>
                    </li>
                    <li *ngIf="page === 'campaign'"><a href="javascript:void(0)" (click)="removeFromCampaign(template)"><span class="icon feather icon-minus-square"></span> Remove from Campaign</a></li>
                  </ng-container>
                  <li *ngIf="currentPageType === 'dashboard' || currentPageType === 'archived'" class="delete-action"><a href="javascript:void(0)" (click)="onDelete(template.ID, template.category.ID)"><span class="icon feather icon-trash-2"></span> Delete</a></li>
                  <!-- <li><a href="javascript:void(0)" (click)="onPreview(template)">View</a></li>
                  <ng-container *ngIf="currentPageType === 'dashboard' || currentPageType === 'favourite' || isUuid(currentPageType)">
                    <li><a routerLink="/templates/{{ template.ID }}/build">Edit</a></li>
                    <li><a href="javascript:void(0)" (click)="onEditTitle(template.ID, template.title)">Rename</a></li>
                    <li *ngIf="!template?.folder_templates?.folder_id"><a href="javascript:void(0)" (click)="addToFolder(template)">Add to Folder</a></li>
                    <li *ngIf="template?.folder_templates?.folder_id"><a href="javascript:void(0)" (click)="moveToFolder(template)">Move to Folder</a></li>
                    <li *ngIf="isUuid(currentPageType)"><a href="javascript:void(0)" (click)="removeFromFolder(template)">Remove from Folder</a></li>
                    <li *ngIf="!template?.is_favourite"><a href="javascript:void(0)" (click)="favourite(template.ID, 1)">Mark as Favourite</a></li>
                    <li *ngIf="template?.is_favourite"><a href="javascript:void(0)" (click)="favourite(template.ID, 0)">Remove as Favourite</a></li>
                    <li *ngIf="authUser.provider === 'designly'"><a href="javascript:void(0)" (click)="sendDesignly(template)">Send</a></li>
                    <li><a href="javascript:void(0)" (click)="onDuplicate(template)">Duplicate</a></li>
                    <li><a href="javascript:void(0)" (click)="onShare(template)">Share</a></li>
                    <li *ngIf="page !== 'campaign' && authUser.provider !== 'designly' && template.category.slug !== 'buyer-match' && template.category.slug !== 'match-listings' && template.category.slug !== 'property-match'">
                      <a href="javascript:void(0)" (click)="addTemplateToCampaign(template.ID)">Add to Campaign</a>
                    </li>
                    <li *ngIf="page !== 'campaign' && !template?.quickdesign"><a href="javascript:void(0)" (click)="saveAsQuickDesign(template)">Save as Quick Design</a></li>
                    <li *ngIf="page !== 'campaign' && template?.quickdesign"><a href="javascript:void(0)" (click)="removeFromQuickDesign(template?.quickdesign?.ID, true, template?.ID)">Remove as Quick Design</a></li>
                    <li><a href="javascript:void(0)" (click)="onUpdateStatus(template.ID)">Archive</a></li>
                    <li *ngIf="page === 'campaign'"><a href="javascript:void(0)" (click)="removeFromCampaign(template)">Remove from Campaign</a></li>
                  </ng-container>
                  <li *ngIf="currentPageType === 'group' || currentPageType === 'client'"><a href="javascript:void(0)" (click)="onDuplicate(template)">Duplicate</a></li>
                  <li *ngIf="currentPageType === 'archived'"><a href="javascript:void(0)" (click)="onUpdateStatus(template.ID, 1)">Activate</a></li>
                  <li *ngIf="currentPageType === 'dashboard' || currentPageType === 'archived'"><a href="javascript:void(0)" (click)="onDelete(template.ID,template.category.ID)">Delete</a></li> -->
                </ul>
              </div>
              <div class="disabled template-{{ template.ID }}">
                <p class="disabled-text-{{ template.ID }}">Deleted</p>
              </div>
              <figure class="overlay overlay-hover animation-hover" (click)="goToEdit(template)">
                <img class="overlay-figure" *ngIf="!template.thumbnail" [src]="TEMPLATE_THUMB_S3_URL + template.ID + '.jpg'" onerror="this.src='assets/img/template-placeholder.png'" />
                <img class="overlay-figure" *ngIf="template.thumbnail" [src]="TEMPLATE_THUMB_S3_URL + template.thumbnail" onerror="this.src='assets/img/template-placeholder.png'" />
                <figcaption class="overlay-panel overlay-background overlay-fade text-center vertical-align"></figcaption>
              </figure>
            </div>
            <div class="info-wrap">
              <div class="template-type">
                <span class="badge badge-default intro-tag">{{ template.category.slug == 'quick-enquiry' || template.category.slug == 'buyer-match' || template.category.slug == 'email-signature' ? 'EMARKETING' : template.category.slug == 'dl-card' ? 'BROCHURE' : template.category.title }}</span>
              </div>
              <div class="intro-info">
                <div class="title intro-title">
                  {{ template.title }}
                </div>

                <div class="time">Edited {{ convertDate(template.modified) }}</div>

                <div class="media media-xs">
                  <div class="media-left">
                    <!-- <a href="javascript:void(0)">
                        <img class="media-object template-agent-photo"
                          [src]="getAgentData(template.user_agent_id, 'photo')">
                      </a> -->
                  </div>
                  <div class="media-body">
                    <small class="media-heading gray4-color">{{ getAgentData(template.user_agent_id, 'firstname') + ' ' + getAgentData(template.user_agent_id, 'lastname') }}</small>
                  </div>
                </div>
              </div>
              <span class="category-dots">
                <span *ngIf="template?.is_master_template" class="dot master" [class.full]="template?.is_master_template && !template?.quickdesign"><em class="tool-tip">Master Template</em></span>
                <span *ngIf="template?.quickdesign" class="dot custom-category-dot quickdesign" [class.full]="!template?.is_master_template && template?.quickdesign"><em class="tool-tip">Saved as Quick Design</em></span>
              </span>
            </div>

            <table class="row-list">
              <tr>
                <td (click)="goToEdit(template)">
                  <ng-container *ngIf="!template?.is_master_template && !template?.quickdesign">&nbsp;</ng-container>
                  <span *ngIf="template?.is_master_template" class="dot master" [class.full]="template?.is_master_template && !template?.quickdesign"><em class="tool-tip">Master Template</em></span>
                  <span *ngIf="template?.quickdesign" class="dot custom-category-dot quickdesign" [class.full]="!template?.is_master_template && template?.quickdesign"><em class="tool-tip">Saved as Quick Design</em></span>
                </td>
                <td class="col-md-2" (click)="goToEdit(template)">
                  <h4>
                    {{ template.title }} <span *ngIf="template?.share_clients.length || template?.share_users.length || template?.share_subgroups.length" class="shared-list" data-tooltip="Design Shared" data-tooltip-pos="top"><i class="icon feather icon-share-2" aria-hidden="true"></i></span>
                  </h4>
                </td>
                <td class="col-md-2" (click)="goToEdit(template)">
                  <small class="media-heading gray4-color">{{ template.category.title }}</small>
                </td>
                <td class="col-md-2" (click)="goToEdit(template)">
                  <div class="media media-xs">
                    <div class="media-body">
                      <small class="media-heading gray4-color">{{ getAgentData(template.user_agent_id, 'firstname') + ' ' + getAgentData(template.user_agent_id, 'lastname') }}</small>
                    </div>
                  </div>
                </td>
                <td class="col-md-2" (click)="goToEdit(template)">
                  <span class="time">{{ convertDate(template.created) }}</span>
                </td>
                <td class="col-md-2" (click)="goToEdit(template)">
                  <span class="time">{{ convertDate(template.modified) }}</span>
                </td>
                <td class="text-right action-links actions-{{ template.ID }}">
                  <ul class="list-inline">
                    <li>
                      <div class="dropup dropup-list">
                        <button type="button" data-bs-toggle="dropdown" data-id="{{ template.ID }}" class="btn btn-outline btn-inverse vertical-align-middle text-uppercase intro-view btn-viewmore">
                          <i class="icon feather icon-more-horizontal" aria-hidden="true" title="View"></i>
                        </button>

                        <ul class="dropdown-menu">
                          <li class="dropdown-submenu">
                            <a href="javascript:;"><span class="icon feather icon-layout"></span> Templates <span class="icon feather icon-chevron-right float-right"></span></a>
                            <ul class="dropdown-menu">
                              <li><a href="javascript:void(0)" (click)="onPreview(template)">View</a></li>
                              <ng-container *ngIf="currentPageType === 'dashboard' || currentPageType === 'favourite' || isUuid(currentPageType)">
                                <li><a routerLink="/templates/{{ template.ID }}/build">Edit</a></li>
                                <li><a href="javascript:void(0)" (click)="onEditTitle(template.ID, template.title)">Rename</a></li>
                                <li *ngIf="!template?.is_favourite"><a href="javascript:void(0)" (click)="favourite(template.ID, 1)">Mark as Favourite</a></li>
                                <li *ngIf="template?.is_favourite"><a href="javascript:void(0)" (click)="favourite(template.ID, 0)">Remove as Favourite</a></li>
                                <li *ngIf="authUser.provider === 'designly'"><a href="javascript:void(0)" (click)="sendDesignly(template)">Send</a></li>
                                <li><a href="javascript:void(0)" (click)="onDuplicate(template)">Duplicate</a></li>
                                <li><a href="javascript:void(0)" (click)="onShare(template)">Share</a></li>
                                <li><a href="javascript:void(0)" (click)="onUpdateStatus(template.ID)">Archive</a></li>
                              </ng-container>
                              <li *ngIf="currentPageType === 'group' || currentPageType === 'client'"><a href="javascript:void(0)" (click)="onDuplicate(template)">Duplicate</a></li>
                              <li *ngIf="currentPageType === 'archived'"><a href="javascript:void(0)" (click)="onUpdateStatus(template.ID, 1)">Activate</a></li>
                            </ul>
                          </li>
                          <ng-container *ngIf="currentPageType === 'dashboard' || currentPageType === 'favourite' || isUuid(currentPageType)">
                            <li class="dropdown-submenu">
                              <a href="javascript:;"><span class="icon feather icon-folder"></span> Folders <span class="icon feather icon-chevron-right float-right"></span></a>
                              <ul class="dropdown-menu">
                                <li *ngIf="!template?.folder_templates?.folder_id"><a href="javascript:void(0)" (click)="addToFolder(template)">Add to Folder</a></li>
                                <li *ngIf="template?.folder_templates?.folder_id"><a href="javascript:void(0)" (click)="moveToFolder(template)">Move to Folder</a></li>
                                <li *ngIf="isUuid(currentPageType)"><a href="javascript:void(0)" (click)="removeFromFolder(template)">Remove from Folder</a></li>
                              </ul>
                            </li>
                          </ng-container>
                          <ng-container *ngIf="currentPageType === 'dashboard' || currentPageType === 'favourite' || isUuid(currentPageType)">
                            <li *ngIf="page !== 'campaign' && !template?.quickdesign"><a href="javascript:void(0)" (click)="saveAsQuickDesign(template)"><span class="icon feather icon-save"></span> Save as Quick Design</a></li>
                            <li *ngIf="page !== 'campaign' && template?.quickdesign"><a href="javascript:void(0)" (click)="removeFromQuickDesign(template?.quickdesign?.ID, true, template?.ID)"><span class="icon feather icon-minus-square"></span> Remove as Quick Design</a></li>
                            <li *ngIf="page !== 'campaign' && authUser.provider !== 'designly' && template.category.slug !== 'buyer-match' && template.category.slug !== 'match-listings' && template.category.slug !== 'property-match' && authUser?.brandid !== '6' && authUser?.brandid !== '4'">
                              <a href="javascript:void(0)" (click)="addTemplateToCampaign(template.ID)"><span class="icon feather icon-plus-square"></span> Add to Campaign</a>
                            </li>
                            <li *ngIf="page === 'campaign'"><a href="javascript:void(0)" (click)="removeFromCampaign(template)"><span class="icon feather icon-minus-square"></span> Remove from Campaign</a></li>
                          </ng-container>
                          <li *ngIf="currentPageType === 'dashboard' || currentPageType === 'archived'" class="delete-action"><a href="javascript:void(0)" (click)="onDelete(template.ID, template.category.ID)"><span class="icon feather icon-trash-2"></span> Delete</a></li>
                          <!-- <li><a href="javascript:void(0)" (click)="onPreview(template)">View</a></li>
                          <ng-container *ngIf="currentPageType === 'dashboard' || currentPageType === 'favourite' || isUuid(currentPageType)">
                            <li><a routerLink="/templates/{{ template.ID }}/build">Edit</a></li>
                            <li><a href="javascript:void(0)" (click)="onEditTitle(template.ID, template.title)">Rename</a></li>
                            <li *ngIf="!template?.folder_templates?.folder_id"><a href="javascript:void(0)" (click)="addToFolder(template)">Add to Folder</a></li>
                            <li *ngIf="template?.folder_templates?.folder_id"><a href="javascript:void(0)" (click)="moveToFolder(template)">Move to Folder</a></li>
                            <li *ngIf="isUuid(currentPageType)"><a href="javascript:void(0)" (click)="removeFromFolder(template)">Remove from Folder</a></li>
                            <li *ngIf="!template?.is_favourite"><a href="javascript:void(0)" (click)="favourite(template.ID, 1)">Mark as Favourite</a></li>
                            <li *ngIf="template?.is_favourite"><a href="javascript:void(0)" (click)="favourite(template.ID, 0)">Remove as Favourite</a></li>
                            <li *ngIf="authUser.provider === 'designly'"><a href="javascript:void(0)" (click)="sendDesignly(template)">Send</a></li>
                            <li><a href="javascript:void(0)" (click)="onDuplicate(template)">Duplicate</a></li>
                            <li><a href="javascript:void(0)" (click)="onShare(template)">Share</a></li>
                            <li *ngIf="page !== 'campaign' && authUser.provider !== 'designly' && template.category.slug !== 'buyer-match' && template.category.slug !== 'match-listings' && template.category.slug !== 'property-match' && authUser?.brandid !== '6' && authUser?.brandid !== '4'">
                              <a href="javascript:void(0)" (click)="addTemplateToCampaign(template.ID)">Add to Campaign</a>
                            </li>
                            <li *ngIf="page !== 'campaign' && !template?.quickdesign"><a href="javascript:void(0)" (click)="saveAsQuickDesign(template)">Save as Quick Design</a></li>
                            <li *ngIf="page !== 'campaign' && template?.quickdesign"><a href="javascript:void(0)" (click)="removeFromQuickDesign(template?.quickdesign?.ID, true, template?.ID)">Remove as Quick Design</a></li>
                            <li><a href="javascript:void(0)" (click)="onUpdateStatus(template.ID)">Archive</a></li>
                            <li *ngIf="page === 'campaign'"><a href="javascript:void(0)" (click)="removeFromCampaign(template)">Remove from Campaign</a></li>
                          </ng-container>
                          <li *ngIf="currentPageType === 'group' || currentPageType === 'client'"><a href="javascript:void(0)" (click)="onDuplicate(template)">Duplicate</a></li>
                          <li *ngIf="currentPageType === 'archived'"><a href="javascript:void(0)" (click)="onUpdateStatus(template.ID, 1)">Activate</a></li>
                          <li *ngIf="currentPageType === 'dashboard' || currentPageType === 'archived'"><a href="javascript:void(0)" (click)="onDelete(template.ID, template.category.ID)">Delete</a></li> -->
                        </ul>
                      </div>
                    </li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div #newTemplate id="newTemplate" class="modal fade edit-box" role="dialog" aria-labelledby="newTemplateLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #newTemplateModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="newTemplateLabel">Use Template</h4>
      </div>
      <form (ngSubmit)="onSubmit()" [formGroup]="templateAddForm" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group d-flex">
            <label for="title" class="col-sm-2">Title</label>
            <div class="col-sm-8">
              <input formControlName="title" type="text" class="form-control" id="addTemplateTitle" autocomplete="off" autofocus />
            </div>
          </div>
          <div class="form-group d-flex">
            <label for="title" class="col-sm-2">Owner</label>
            <div class="col-sm-8">
              <select formControlName="user_agent_id" class="form-control">
                <option value="" selected disabled>Select an Agent</option>
                <option *ngFor="let agentUser of agentUsers" [value]="agentUser.agent_id">
                  {{ agentUser.firstname + ' ' + agentUser.lastname }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="submit" [disabled]="!templateAddForm.valid" class="btn-admin btn-admin-primary me-2">Create</button>&nbsp;
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div #duplicateTemplate id="duplicateTemplate" class="modal fade edit-box" role="dialog" aria-labelledby="duplicateTemplateLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #duplicateTemplateModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 *ngIf="!duplicateFromQuickDesigns" class="modal-title" id="duplicateTemplateLabel">Duplicate Template</h4>
        <h4 *ngIf="duplicateFromQuickDesigns" class="modal-title" id="duplicateTemplateLabel">Use {{ duplicateTemplateTitle }} template</h4>
      </div>
      <form (ngSubmit)="onSubmitDuplicate()" [formGroup]="templateDuplicateForm" class="form-horizontal">
        <input formControlName="ID" type="hidden" [(ngModel)]="edit_title_id" class="form-control" id="duplicateId" autocomplete="off" />
        <div class="modal-body">
          <div class="form-group d-flex">
            <label for="title" class="col-sm-2">Title</label>
            <div class="col-sm-8">
              <input formControlName="title" type="text" class="form-control" id="duplicateTemplateTitle" autocomplete="off" autofocus />
            </div>
          </div>
          <div *ngIf="authUser.is_admin" class="form-group d-flex">
            <label for="title" class="col-sm-2">Agent</label>
            <div class="col-sm-8">
              <select formControlName="user_agent_id" class="form-control">
                <option value="" selected disabled>Select an Agent</option>
                <option *ngFor="let agentUser of agentUsers" [value]="agentUser.agent_id">
                  {{ globalService.cleanAgentName(agentUser.firstname + ' ' + agentUser.lastname) }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="submit" [disabled]="!templateDuplicateForm.valid" class="btn-admin btn-admin-primary me-2">Create</button>&nbsp;
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div #editTitle id="editTitle" class="modal fade edit-box" tabindex="-1" role="dialog" aria-labelledby="editTitleLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #editTitleModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="editTitleLabel">Update Template Title</h4>
      </div>
      <form (ngSubmit)="onSubmitUpdate()" [formGroup]="templateUpdateForm" class="form-horizontal">
        <input formControlName="ID" type="hidden" [(ngModel)]="edit_title_id" class="form-control" id="templateUpdateId" autocomplete="off" />
        <div class="modal-body">
          <div class="form-group d-flex">
            <label for="title" class="col-sm-2">Title</label>
            <div class="col-sm-8">
              <input formControlName="title" type="text" [(ngModel)]="edit_title_name" class="form-control" id="title" autocomplete="off" />
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="submit" [disabled]="!templateUpdateForm.valid" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div #editDescription id="editDescription" class="modal fade edit-box" tabindex="-1" role="dialog" aria-labelledby="editDescriptionLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #editDescriptionModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="editDescriptionLabel">Update Template Description</h4>
      </div>
      <form (ngSubmit)="onSubmitDescription()" [formGroup]="templateDescriptionForm" class="form-horizontal">
        <input formControlName="ID" type="hidden" [(ngModel)]="edit_title_id" class="form-control" id="templateUpdateId" autocomplete="off" />
        <div class="modal-body">
          <div class="form-group">
            <label for="title" class="col-sm-2">Description</label>
            <div class="col-sm-8">
              <textarea formControlName="description" type="text" [(ngModel)]="edit_description" class="form-control" id="modalEditDescription"></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" [disabled]="!templateDescriptionForm.valid" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div #shareToClient id="shareTo" class="modal fade edit-box" tabindex="-1" role="dialog" aria-labelledby="shareToClientLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #shareToModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="shareToClientLabel">
          Share to
          <select (change)="onChangeShare($event)">
            <option *ngIf="authUser.can_share && authUser.is_master" value="offices">Offices</option>
            <option *ngIf="authUser.is_subgroup" value="subgroup">Offices</option>
            <option *ngIf="authUser.can_share && !authUser.is_master" value="agent">Agent</option>
          </select>
        </h4>

        <div class="d-flex">
          <button type="button" (click)="onSelectAll()" class="btn-admin btn-admin-primary pull-right me-2">Select All</button>
          <button type="button" (click)="onClear()" class="btn-admin pull-right">Clear</button>
        </div>
      </div>
      <form *ngIf="selected_share === 'offices'" (ngSubmit)="onSaveCheck()" [formGroup]="shareToClientForm" class="form-horizontal">
        <input formControlName="ID" type="hidden" [(ngModel)]="shared_template_id" class="form-control" id="shareClientId" autocomplete="off" />
        <div class="modal-body">
          <input type="text" class="form-control search-office" placeholder="Search an office" [(ngModel)]="filter" [ngModelOptions]="{ standalone: true }" />

          <div class="panel-group" id="accordion1">
            <div class="panel panel-default" *ngFor="let sharedClient of shareListClients | client: filter">
              <div class="panel-heading accordion-toggle collapsed" data-bs-toggle="collapse" data-parent="#accordion1" [attr.data-bs-target]="'#collapse' + sharedClient.ID" (click)="showUsers(sharedClient.ID)">
                <h4 class="panel-title">
                  <input type="checkbox" [checked]="selectedSharedClient.indexOf(sharedClient.ID) > -1" class="officeClient" [value]="sharedClient.ID" (click)="selectOffice($event, sharedClient.ID)" id="office{{ sharedClient.ID }}" /> &emsp;{{ sharedClient.name }}
                  <span *ngIf="selectedSharedClient.indexOf(sharedClient.ID) > -1 || selectedClients.indexOf(sharedClient.ID) > -1" class="shared-list-client pull-right" data-tooltip="Design Shared" data-tooltip-pos="top"><i class="icon feather icon-share-2" aria-hidden="true"></i></span>
                </h4>
              </div>
              <div id="collapse{{ sharedClient.ID }}" class="panel-collapse collapse">
                <div class="panel-body" id="client{{ sharedClient.ID }}">
                  <div *ngFor="let user of clientUsers" class="col-sm-6">
                    <label class="share-agents"><input type="checkbox" id="users-{{ sharedClient.ID }}-{{ user.ID }}" (click)="selectUser($event, user.ID, sharedClient.ID)" [checked]="selectedUsers.indexOf(user.ID) > -1 || selectedSharedClient.indexOf(sharedClient.ID) > -1 || selectSharedClient.indexOf(sharedClient.ID) > -1" [value]="user.ID" class="users{{ sharedClient.ID }}" /> &emsp;{{ user.firstname }} {{ user.lastname }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-block">
          <button type="submit" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
      <form *ngIf="selected_share === 'subgroup'" (ngSubmit)="onSaveCheck()" [formGroup]="shareToSubgroupForm" class="form-horizontal">
        <input formControlName="ID" type="hidden" [(ngModel)]="shared_template_id" class="form-control" id="shareSubgroupid" autocomplete="off" />
        <div class="modal-body">
          <div class="form-group">
            <div *ngFor="let subgroup of subgroups" class="col-sm-6">
              <div class="checkbox">
                <label> 
                  <input type="checkbox" [value]="subgroup.ID" /> {{ subgroup.name }} 
                  <span *ngIf="selectedSubGroup.indexOf(subgroup.ID) > -1" class="shared-list-client pull-right" data-tooltip="Design Shared" data-tooltip-pos="top"><i class="icon feather icon-share-2" aria-hidden="true"></i></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-block">
          <button type="submit" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>

          <button type="button" (click)="onClear()" class="btn-admin pull-right">Clear</button>
          <button type="button" (click)="onSelectAll()" class="btn-admin btn-admin-primary pull-right me-2">Select All</button>
        </div>
      </form>
      <form *ngIf="selected_share === 'agent'" (ngSubmit)="onSaveCheck()" [formGroup]="shareToUserForm" class="form-horizontal">
        <input formControlName="ID" type="hidden" [(ngModel)]="shared_template_id" class="form-control" id="shareTemplateId" autocomplete="off" />
        <div class="modal-body">
          <div class="form-group">
            <div *ngFor="let sharedUser of shareListUsers" class="col-sm-6">
              <div class="checkbox">
                <label> 
                  <input type="checkbox" [value]="sharedUser.ID" [checked]="selectedSharedUser.indexOf(sharedUser.ID) > -1"/> {{ sharedUser.firstname + ' ' + sharedUser.lastname }} 
                  <span *ngIf="selectedSharedUser.indexOf(sharedUser.ID) > -1" class="shared-list-client pull-right" data-tooltip="Design Shared" data-tooltip-pos="top"><i class="icon feather icon-share-2" aria-hidden="true"></i></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-block">
          <button type="submit" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>

          <button type="button" (click)="onClear()" class="btn-admin pull-right">Clear</button>
          <button type="button" (click)="onSelectAll()" class="btn-admin btn-admin-primary pull-right me-2">Select All</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div #previewModal id="previewTemplate" class="modal fade edit-box preview" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <button type="button" #previewTemplateModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">
        <img src="" class="img-responsive" onError="this.src='/assets/img/template-placeholder.png'" />
      </div>
    </div>
  </div>
</div>
<div #previewTour id="previewTour" class="modal fade edit-box preview" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center modal-lg modal-video">
    <div class="modal-content">
      <button type="button" #previewTourModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">Take a tour Modal</div>
    </div>
  </div>
</div>
<div #propertySearch id="propertySearchModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <button type="button" #propertySearchModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">
        <app-property-search></app-property-search>
      </div>
    </div>
  </div>
</div>

<div class="view-more-content">
  <a href="javascript:void(0)" onclick="onTemplate('view')">View</a>
  <a href="javascript:void(0)" onclick="onTemplate('edit')">Edit</a>
  <a href="javascript:void(0)" onclick="onTemplate('rename')">Rename</a>
  <a href="javascript:void(0)" onclick="onTemplate('send')" class="btn-send">Send</a>
  <a href="javascript:void(0)" onclick="onTemplate('duplicate')">Duplicate</a>
  <a href="javascript:void(0)" onclick="onTemplate('share')">Share</a>
  <a href="javascript:void(0)" onclick="onTemplate('archive')">Archive</a>
  <a href="javascript:void(0)" onclick="onTemplate('delete')">Delete</a>
</div>

<div #generateCampaignModal id="generateCampaign" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <app-campaign-generate></app-campaign-generate>
      </div>
    </div>
  </div>
</div>

<div #addTemplate id="addTemplate" class="modal fade edit-box" role="dialog" aria-labelledby="addTemplateLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #addTemplateodalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="addTemplateLabel">Add Template to Campaign</h4>
      </div>
      <form (ngSubmit)="onaddTemplate()" [formGroup]="campaignTemplateForm" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group d-flex">
            <label for="name" class="col-sm-2">Campaigns</label>
            <div class="col-sm-8">
              <select formControlName="category" class="form-control">
                <option value="">Select Campaign</option>
                <ng-container *ngIf="authUser.provider === 'domain'">
                  <option value="Sales">Sales</option>
                  <option value="Rental">Rental</option>
                  <option value="Share">Share</option>
                  <option value="Sold">Sold</option>
                  <option value="NewHomes">New Homes</option>
                </ng-container>
                <ng-container *ngIf="authUser.provider !== 'domain'">
                  <option value="Sales">Sales</option>
                  <option value="Rental">Rental</option>
                  <option value="Sold">Sold</option>
                  <option value="Leased">Leased</option>
                  <option value="Commercial">Commercial</option>
                  <option value="Commercial Lease">Commercial Lease</option>
                  <option value="Rural">Rural</option>
                  <option value="Land">Land</option>
                </ng-container>
                <option *ngFor="let campaign of campaigns" value="custom_{{ campaign.ID }}">{{ campaign.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="submit" [disabled]="!campaignTemplateForm.valid" class="btn-admin btn-admin-primary me-2">Save</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div #newCustomCategory id="newCustomCategory" class="modal fade edit-box" role="dialog" aria-labelledby="newCustomCategoryLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #newCustomCategoryModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 *ngIf="!customCategory?.ID" class="modal-title" id="newCustomCategoryLabel">Create Category</h4>
        <h4 *ngIf="customCategory?.ID" class="modal-title" id="newCustomCategoryLabel">Edit Category</h4>
      </div>
      <form (ngSubmit)="onSubmitCustomCategory()" [formGroup]="customCategoryForm" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group d-flex">
            <label for="newCustomCategoryName" class="col-sm-2">Name</label>
            <div class="col-sm-8">
              <input formControlName="name" type="text" class="form-control" id="newCustomCategoryName" autocomplete="off" autofocus />
            </div>
          </div>
          <div class="form-group d-flex">
            <label for="newCustomCategoryIcon" class="col-sm-2">Icon</label>
            <div class="col-sm-8">
              <input #customCategoryIcon type="file" accept="image/*" class="form-control" id="newCustomCategoryIcon" autocomplete="off" autofocus />
              <p>Maximum file size: 500KB</p>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="submit" [disabled]="!customCategoryForm.valid" class="btn-admin btn-admin-primary me-2">Save</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div #createQuickDesignGroup id="createQuickDesignGroup" class="modal fade edit-box" role="dialog" aria-labelledby="createQuickDesignGroupLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #createQuickDesignGroupModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 *ngIf="!quickDesignGroup?.ID" class="modal-title" id="createQuickDesignGroupLabel">Create Template Folder</h4>
        <h4 *ngIf="quickDesignGroup?.ID" class="modal-title" id="createQuickDesignGroupLabel">Edit Template Folder</h4>
      </div>
      <form (ngSubmit)="onSubmitQuickDesignGroup()" [formGroup]="quickDesignGroupForm" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group d-flex">
            <label for="createQuickDesignGroupName" class="col-sm-2">Name</label>
            <div class="col-sm-8">
              <input formControlName="name" type="text" class="form-control" id="createQuickDesignGroupName" autocomplete="off" autofocus />
            </div>
          </div>
          <div class="form-group d-flex">
            <label for="createQuickDesignGroupIcon" class="col-sm-2">Icon</label>
            <div class="col-sm-8">
              <input #quickDesignGroupIcon type="file" accept="image/*" class="form-control" id="createQuickDesignGroupIcon" autocomplete="off" autofocus />
              <p>Maximum file size: 500KB</p>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="submit" [disabled]="!quickDesignGroupForm.valid" class="btn-admin btn-admin-primary me-2">Save</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Tenapp Modal -->
<div id="popupModal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-bs-dismiss="modal" (click)="onDisablePopupModal()">&times;</button>
        <img src="{{ frontService?.appConfig?.S3_URL + popup?.image }}" />
      </div>
      <div class="modal-footer">
        <div class="pull-left footer-switch">
          <label class="switch">
            <input type="checkbox" id="showCheckBoxPopupModal" />
            <span class="slider round"></span>
          </label>
          <span class="dont-show-again">Don't show again</span>
        </div>
        <div class="pull-right">
          <button type="button" class="btn btn-default btn-upgrade" data-bs-dismiss="modal" (click)="onPopupModalFindOutMore()">Find out more</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="createTemplate" class="modal create-template-modal fade edit-box" role="dialog" aria-labelledby="createTemplateLabel">
  <div class="modal-dialog modal-lg modal-center modal-custom-center" role="document">
    <div class="modal-content">
      <button type="button" class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">
        <div *ngIf="quickDesignGroupTemplates.length" class="col-sm-7 content">
          <ngx-slick class="carousel" [class.multiple]="quickDesignGroupTemplates.length > 1" #slickModal="slick-modal" [config]="slideConfig" (afterChange)="onTemplateScroll($event)">
            <div ngxSlickItem *ngFor="let quickDesignGroupTemplate of quickDesignGroupTemplates" class="slide">
              <img src="{{ frontService.appConfig.S3_URL + quickDesignGroupTemplate?.thumbnail }}" alt="&nbsp;" width="100%" (error)="onImgError($event)" />
            </div>
          </ngx-slick>
        </div>
        <div class="col-sm-5 sidebar position-relative">
          <h3>
            <strong>{{ selectedQuickDesignGroupTemplate?.title }}</strong>
          </h3>
          <p class="category">{{ selectedQuickDesignGroupTemplate?.master?.title }}</p>
          <!-- <p><small><span class="created">Created by</span><br />{{selectedQuickDesignGroupTemplate?.agent}}</small></p> -->
          <p *ngIf="selectedQuickDesignGroupTemplate?.description !== null && selectedQuickDesignGroupTemplate?.description !== ''">
            <small><span class="updated">Description</span><br />{{ selectedQuickDesignGroupTemplate?.description }}</small>
          </p>
          <p>
            <small><span class="updated">Last Updated</span><br />{{ selectedQuickDesignGroupTemplate?.modified | date: 'd MMM y' }}</small>
          </p>

          <button type="button" (click)="onDuplicate(selectedQuickDesignGroupTemplate, 'yes')" class="btn btn-primary btn-block btn-lg"><small>Use this template</small></button>

          <div *ngIf="selectedQuickDesignGroupTemplate?.client_id === authUser?.client?.ID && authUser?.is_admin" class="quickdesign-options">
            <a (click)="editTemplateQuickDesign(selectedQuickDesignGroupTemplate?.ID)" class="editTemplate"><i class="fa fa-pencil-square-o"></i> Edit quick design template</a>
            <a (click)="onEditTitle(selectedQuickDesignGroupTemplate?.ID, selectedQuickDesignGroupTemplate?.title)"><i class="fa fa-pencil"></i> Rename template</a>
            <a (click)="onReplaceQuickDesignIcon('template', selectedQuickDesignGroupTemplate?.quickdesign_id)"><i class="fa fa-file-image-o"></i> Replace Icon</a>
            <a (click)="onEditDescription(selectedQuickDesignGroupTemplate?.quickdesign_id, selectedQuickDesignGroupTemplate?.description)"><i class="fa fa-pencil"></i> Edit description</a>
            <a (click)="removeFromQuickDesign(selectedQuickDesignGroupTemplate?.quickdesign_id)"><i class="fa fa-trash"></i> Remove quick design template</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #saveToCategory id="saveToCategory" class="modal fade edit-box" role="dialog" aria-labelledby="saveToCategoryLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #saveToCategoryModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="saveToCategoryLabel">Save as Quick Design</h4>
      </div>
      <form [formGroup]="quickDesignForm" (ngSubmit)="onSaveToQuickDesign()" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group d-flex">
            <label for="saveToCategoryName" class="col-sm-4">Category<i class="text-danger">*</i></label>
            <div class="col-sm-8">
              <select #selectCustomCategory formControlName="reference" class="form-control" (change)="onSelectedCustomCategories(selectCustomCategory.value)">
                <option *ngIf="showReferenceOption('emails')" value="emails">Emails</option>
                <option *ngIf="showReferenceOption('printables') && frontService?.appConfig?.SKIN !== 'professionals'" value="printables">Printables</option>
                <option *ngIf="showReferenceOption('printables') && frontService?.appConfig?.SKIN === 'professionals'" value="printables">Print</option>
                <option *ngIf="showReferenceOption('socialmedia')" value="socialmedia">Social Media</option>
                <option *ngIf="showReferenceOption('signboards')" value="signboards">Signboards</option>
                <option *ngIf="showReferenceOption('pricefinder')" value="pricefinder">Pricefinder</option>
                <option *ngFor="let customCategory of customCategories" [value]="customCategory.ID">
                  {{ customCategory?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group d-flex" [class.hidden]="quickDesignGroups?.length === 0">
            <label for="saveToCategoryName" class="col-sm-4">Folder</label>
            <div class="col-sm-8">
              <select formControlName="quick_design_group_id" class="form-control">
                <option value="">Select Folder</option>
                <option *ngFor="let quickDesignGroup of quickDesignGroups" value="{{ quickDesignGroup?.ID }}">
                  {{ quickDesignGroup?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group d-flex">
            <label for="saveToCategoryIcon" class="col-sm-4">Icon</label>
            <div class="col-sm-8">
              <input #quickDesignIcon type="file" accept="image/*" class="form-control" id="quickDesignIcon" autocomplete="off" autofocus />
              <p>Maximum file size: 500KB</p>
            </div>
          </div>
          <div class="form-group d-flex">
            <label for="saveToCategoryDescription" class="col-sm-4">Description</label>
            <div class="col-sm-8">
              <textarea name="" id="saveToCategoryDescription" rows="30" class="form-control" formControlName="description"></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="submit" [disabled]="!quickDesignForm.valid" class="btn-admin btn-admin-primary me-2">Save</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div #replaceQuickDesignIcon id="replaceQuickDesignIcon" class="modal fade edit-box" role="dialog" aria-labelledby="saveToCategoryLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #replaceQuickDesignIconModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="replaceQuickDesignIconLabel">Replace Icon</h4>
      </div>
      <form>
        <div class="modal-body">
          <div class="form-group d-flex">
            <label for="replaceQuickDesignIconInput" class="col-sm-2">Icon</label>
            <div class="col-sm-10">
              <input #replaceQuickDesignIconInput type="file" accept="image/*" class="form-control" id="replaceQuickDesignIconInput" autocomplete="off" autofocus />
              <p>Maximum file size: 500KB</p>
            </div>
          </div>
        </div>
      </form>
      <div class="modal-footer justify-content-start">
        <button type="button" (click)="saveReplaceQuickDesignIcon()" class="btn-admin btn-admin-primary me-2">Save</button>
        <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="firstLoginVideo" tabindex="-1" role="dialog" aria-labelledby="firstLoginVideoTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <iframe width="900" height="600" src="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div id="addFolder" class="modal fade edit-box" role="dialog" aria-labelledby="addFolderLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="addFolderLabel">Add to Folder</h4>
      </div>
      <form [formGroup]="addFolderForm" (ngSubmit)="onAddToFolder()" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group">
            <label for="addFolderUuid" class="col-sm-2">Name</label>
            <div class="col-sm-8">
              <select formControlName="uuid" class="form-control" id="addFolderUuid">
                <option value="">Select Folder</option>
                <option *ngFor="let folder of folders" [value]="folder?.UID">{{ folder?.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" [disabled]="!addFolderForm.valid" class="btn-admin btn-admin-primary me-2">Save</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div id="moveFolder" class="modal fade edit-box" role="dialog" aria-labelledby="moveFolderLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="moveFolderLabel">Move to Folder</h4>
      </div>
      <form [formGroup]="moveFolderForm" (ngSubmit)="onMoveToFolder()" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group">
            <label for="addFolderUuid" class="col-sm-2">Name</label>
            <div class="col-sm-8">
              <select formControlName="uuid" class="form-control" id="addFolderUuid">
                <option value="">Select Folder</option>
                <option *ngFor="let folder of folders" [value]="folder?.UID">{{ folder?.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" [disabled]="!moveFolderForm.valid" class="btn-admin btn-admin-primary">Save</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
