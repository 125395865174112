<div class="col-md-12 heading text-end">
    <h2 class="pull-left mt-0">Billing</h2>
</div>

<div class="row mb-4">
    <div class="col-lg-12">
      <div class="panel panel-box-shadow">
        <div class="panel-body container-fluid">
          <div *ngIf="!authUser.is_admin">
            <h3>You do not have permission to view settings.</h3>
            <p>The requested page requires proper authorization to access.</p>
            <p>We suggest to contact your administrator for help or report the issue via the <a href="/help/support"> support portal</a>.</p>
          </div>

          <div class="billing-wrapper mb-3">
            <div class="d-flex flex-wrap justify-content-between billing-heading mb-2">
                <div>
                    <p class="mb-3"><strong>My Invoice History</strong></p>
                    <p class="m-0">Next Bill Date: <strong>{{nextDueDate}}</strong></p>
                </div>
                <div>
                    <button class="btn-admin btn-admin-primary">Edit Billing Information</button>
                </div>
            </div>

            <div class="billing-table-box">
                <table id="datatable-my-invoice" class="datatable-billing table">
                    <thead>
                      <tr>
                        <th scope="col">Invoice #</th>
                        <th scope="col">Date</th>
                        <th scope="col">Price</th>
                        <th scope="col">Status</th>
                        <th scope="col">Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let userInvoice of userInvoices">
                        <th scope="row">{{userInvoice?.id}}</th>
                        <td>{{userInvoice?.created_at}}</td>
                        <td>${{userInvoice?.total}}</td>
                        <td>{{userInvoice?.status}}</td>
                        <td class="text-center"><button class="btn btn-link"><i class="icon feather icon-download"></i></button></td>
                      </tr>
                    </tbody>
                </table>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-12">
      <div class="panel panel-box-shadow">
        <div class="panel-body container-fluid">
            <div class="billing-wrapper mb-3">
                <div class="d-flex flex-wrap justify-content-between billing-heading mb-2">
                    <div>
                        <p class="mb-3"><strong>Office Invoice History</strong></p>
                        <p class="m-0">Next Bill Date: <strong>{{nextDueDate}}</strong></p>
                    </div>
                    <div>
                        <button class="btn-admin btn-admin-primary">Edit Billing Information</button>
                    </div>
                </div>

                <div class="billing-table-box">
                    <table id="datatable-office-invoice" class="datatable-office-invoice table">
                        <thead>
                        <tr>
                            <th scope="col">Invoice #</th>
                            <th scope="col">Date</th>
                            <th scope="col">Price</th>
                            <th scope="col">Status</th>
                            <th scope="col">Download</th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let clientInvoice of clientInvoices">
                            <th scope="row">{{clientInvoice?.id}}</th>
                            <td>{{clientInvoice?.created_at}}</td>
                            <td>${{clientInvoice?.total}}</td>
                            <td>{{clientInvoice?.status}}</td>
                            <td class="text-center"><button class="btn btn-link"><i class="icon feather icon-download"></i></button></td>
                          </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
     </div>
    </div>
</div>

<ngx-loading [show]="loading"></ngx-loading>