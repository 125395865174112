<div class="homepage2 app-media">
  <div class="page-header header-bar">
    <h1 class="page-title" style="display: inline-block">
      Archived <span class="badge badge-info blue-background circle-badge">{{ template_count }}</span>
    </h1>
    <div class="page-header-actions templates-search">
      <div class="">
        <div class="input-search">
          <input type="text" class="form-control" name="" placeholder="Search..." [(ngModel)]="term" (keyup)="onSearchKeyUp()" />
        </div>
      </div>
    </div>

    <div class="page-header-actions pull-right">
      <ul class="list-inline">
        <!-- <li>
                <div class="checkbox-custom checkbox-primary">
                  <span for="inputChecked" class="text-bold margin-right-25">Show Archived Templates</span>

                </div>
            </li> -->
        <li class="filter-list">
          <select data-plugin="selectpicker" data-style="btn btn-link btn-filter" [(ngModel)]="dateFilter">
            <option value="recent" selected>Sort by date desc</option>
            <option value="date-asc">Sort by date asc</option>
            <option value="title-asc">Sort by Title ASC</option>
            <option value="title-desc">Sort by Title DESC</option>
          </select>

          <select data-plugin="selectpicker" data-style="btn btn-link btn-filter" [(ngModel)]="templateFilter">
            <option value="" selected disabled>Template Filter</option>
            <option value="all">All</option>
            <option value="enewsletter">eMarketing</option>
            <option value="buyer-match">Buyer Match</option>
            <option value="quick-enquiry">Quick Enquiry</option>
            <option value="brochure">Brochure</option>
            <option value="property-guide">Property Guide</option>
            <option value="social-media">Social Media</option>
          </select>

          <button type="button" class="btn btn-sm btn-icon btn-default btn-link template-view" [class.active]="!isList" data-view="grid" data-toggle="tooltip" data-original-title="Grid view">
            <i class="icon feather icon-grid" aria-hidden="true"></i>
          </button>
          <button type="button" class="btn btn-sm btn-icon btn-default btn-link template-view" [class.active]="isList" data-view="list" data-toggle="tooltip" data-original-title="List View">
            <i class="icon feather icon-menu" aria-hidden="true"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>

  <div class="page-content your-templates">
    <!-- <a (click)="onFeedback()" id="my_feedback" class="btn-feedback">Send Feedback</a> -->
    <div class="media-list padding-bottom-50" [class.is-grid]="!isList" [class.is-list]="isList">
      <ul class="blocks blocks-100 blocks-xlg-6 blocks-lg-4 blocks-md-4 blocks-ms-2 blocks-xs-2" data-plugin="animateList" data-child=">li">
        <li *ngFor="let template of templates | filter: templateFilter:'template' | filter: dateFilter:'date'; let i = index" class="animation-scale-up" style="animation-fill-mode: backwards; animation-duration: 250ms; animation-delay: 0ms">
          <div class="media-item">
            <div class="image-wrap">
              <div class="disabled template-{{ template.ID }}">
                <p class="disabled-text-{{ template.ID }}">Deleted</p>
              </div>
              <figure class="overlay overlay-hover animation-hover" routerLink="/templates/{{ template.ID }}/build">
                <img class="overlay-figure" *ngIf="!template.thumbnail" [src]="TEMPLATE_THUMB_S3_URL + template.ID + '.jpg'" onerror="this.src='assets/img/template-placeholder.png'" />
                <img class="overlay-figure" *ngIf="template.thumbnail" [src]="TEMPLATE_THUMB_S3_URL + template.thumbnail" onerror="this.src='assets/img/template-placeholder.png'" />
                <figcaption class="overlay-panel overlay-background overlay-fade text-center vertical-align"></figcaption>
              </figure>
            </div>
            <div class="info-wrap">
              <div class="template-type">
                <span class="badge badge-default intro-tag">{{ template.category.slug == 'quick-enquiry' || template.category.slug == 'buyer-match' || template.category.slug == 'email-signature' ? 'EMARKETING' : template.category.slug == 'dl-card' ? 'BROCHURE' : template.category.title }}</span>
              </div>
              <div class="intro-info">
                <div class="title intro-title">
                  {{ template.title }}
                </div>
                <div class="time">{{ convertDate(template.created) }}</div>
                <div class="dropup dropup-grid">
                  <button type="button" data-bs-toggle="dropdown" data-id="{{ template.ID }}" class="btn btn-outline btn-inverse vertical-align-middle text-uppercase intro-view btn-viewmore">
                    <i class="icon feather icon-more-horizontal" aria-hidden="true" title="View"></i>
                  </button>

                  <ul class="dropdown-menu">
                    <li><a href="javascript:void(0)" (click)="onPreview(template)">View</a></li>
                    <li><a href="javascript:void(0)" (click)="onActivate(template.ID)">Activate</a></li>
                  </ul>
                </div>
                <div class="media media-xs">
                  <div class="media-left">
                    <a href="javascript:void(0)">
                      <img class="media-object template-agent-photo" [src]="getAgentData(template.user_agent_id, 'photo')" />
                    </a>
                  </div>
                  <div class="media-body">
                    <small class="media-heading gray4-color">{{ getAgentData(template.user_agent_id, 'firstname') + ' ' + getAgentData(template.user_agent_id, 'lastname') }}</small>
                  </div>
                </div>
              </div>
            </div>

            <table class="row-list">
              <tr>
                <td class="col-md-2" (click)="goToEdit(template.ID)">
                  <h4>{{ template.title }}</h4>
                </td>
                <td class="col-md-2" (click)="goToEdit(template.ID)">
                  <span class="time">{{ convertDate(template.created) }}</span>
                </td>
                <td class="col-md-2" (click)="goToEdit(template.ID)">
                  <span class="badge badge-default">{{ template.category.title }}</span>
                </td>
                <td class="col-md-2" (click)="goToEdit(template.ID)">
                  <div class="media media-xs">
                    <div class="media-body">
                      <small class="media-heading gray4-color">{{ getAgentData(template.user_agent_id, 'firstname') + ' ' + getAgentData(template.user_agent_id, 'lastname') }}</small>
                    </div>
                  </div>
                </td>
                <td class="col-md-2 text-right action-links actions-{{ template.ID }}">
                  <ul class="list-inline">
                    <li>
                      <div class="dropup dropup-list">
                        <button type="button" data-bs-toggle="dropdown" data-id="{{ template.ID }}" class="btn btn-outline btn-inverse vertical-align-middle text-uppercase intro-view btn-viewmore">
                          <i class="icon feather icon-more-horizontal" aria-hidden="true" title="View"></i>
                        </button>

                        <ul class="dropdown-menu">
                          <li><a href="javascript:void(0)" (click)="onPreview(template)">View</a></li>
                          <li><a href="javascript:void(0)" (click)="onActivate(template.ID)">Activate</a></li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<div #previewModal id="previewTemplate" class="modal fade edit-box preview" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <button type="button" #previewTemplateModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">
        <img src="" class="img-responsive" onError="this.src='/assets/img/template-placeholder.png'" />
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
