import { Component, OnInit, OnDestroy } from '@angular/core';
import { FrontService } from '../../front.service';
import { Auth } from '../../../models/auth.model';
import { Subject } from 'rxjs';
import { ProductService } from '../../../services/product.service';
import { Product } from '../../../models/product.model';
import swal from 'sweetalert2';
import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { SubscriptionService } from '../../../services/subscription.service';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user.model';
import { ClientService } from '../../../services/client.service';
import { Client } from '../../../models/client.model';
import * as moment from 'moment';

declare const bootstrap: any;
declare const eCrypt: any;
@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  authUser: Auth;
  user: User;
  client: Client;

  products: Product[] = [];

  billingForm: FormGroup;
  creditcardForm: FormGroup;

  agentSubscriptionPrice = 0;
  agentSubscriptionForm: FormGroup;
  agentNewProducts = [];

  agencySubscriptionPrice = 0;
  agencySubscriptionForm: FormGroup;
  agencyNewProducts = [];

  updateProductsPromoCode = '';

  isAgentSubscription = false;
  isAgencySubscription = false;

  billingModal: any;
  creditCardModal: any;

  ewayClientKey = '';

  ccExpiryYears = [];
  userProducts = [];
  agencyProducts = [];

  nextDueDate = '';

  constructor(
    public frontService: FrontService,
    private productService: ProductService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private userService: UserService,
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.authUser = this.frontService.authService.auth;

    this.subscriptionService.httpGetHealth();

    let currentYear = new Date().getFullYear();
    this.ccExpiryYears.push(currentYear);

    for (let i = 1; i < 7; i++) {
      currentYear += 1;
      this.ccExpiryYears.push(currentYear);
    }

    this.nextDueDate = moment().add(1, 'M').startOf('month').format('DD/MM/YYYY');

    this.subscriptionService.onGetHealth
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.userService.httpGetUser(this.authUser.user.UID);
            }

            if (response.status === 'error') {
              this.router.navigateByUrl('/help/support');
              swal('Our billing platform is offline at the moment', 'Please try again later.', 'error');
            }
          }
        }
      }
    );

    this.productService.onGetAll
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.products = response.data;

            if (this.user.subscription) {
              this.user.subscription.products.forEach(subscriptionProduct => {
                this.userProducts.push(subscriptionProduct.product.UID);

                const formArray = this.agentSubscriptionForm.get('products') as FormArray;
                formArray.push(new FormControl(subscriptionProduct.product.UID));
              });
            }
            this.updateAgentPricingView(this.userProducts);

            this.subscriptionService.httpGetEwayClientKey();
            this.clientService.httpGetClientNew(this.authUser.client.ID);

            this.initTooltip();
          }
          if (typeof response.status !== 'undefined' && response.status === 'error') {
            swal('Internal Server Error', 'Contact Dev Team', 'error');
          }
        }
      }
    );

    this.subscriptionService.onGetEwayClientKey
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.ewayClientKey = response.data;
          }

          if (typeof response.status !== 'undefined' && response.status === 'error') {
            this.router.navigateByUrl('/help/support');
            swal('Internal Server Error', 'Contact Dev Team', 'error');
          }
        }
      }
    );

    this.userService.onGet
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.user = response.data;

            this.billingForm.patchValue({
              firstname: this.user.firstname,
              lastname: this.user.lastname,
              email: this.user.email
            });

            this.productService.httpGetAll();
          }
        }
      }
    );

    this.subscriptionService.onCreateClientProfile
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.billingModal.hide();

              this.creditcardForm.patchValue({
                order_id: response.data
              });

              this.creditCardModal.show();
            }

            if (response.status === 'VALIDATION_ERROR') {
              swal(response.message, '', 'error');
            }

            if (response.status === 'EMPTY_PRODUCTS') {
              swal('Communication with the billing platform failed', 'Please try again', 'error');
            }

            if (response.status === 'CREATE_ORDER_FAILED') {
              swal('Communication with the billing platform failed', 'Please try again', 'error');
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.subscriptionService.onSubscribe
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              swal('Welcome to Designly Pro!', 'Please wait while the page reloads', 'success');

              setTimeout(() => {
                window.location.reload();
              }, 2500);
            }

            if (response.status === 'VALIDATION_ERROR') {
              swal(response.message, '', 'error');
            }

            if (response.status === 'CREATE_ORDER_FAILED') {
              swal('Communication with the billing platform failed', 'Please try again', 'error');
            }

            if (response.status === 'EWAY_FAILED') {
              swal('Invalid Credit Card', 'Please try a different credit card.', 'error');
            }

            if (response.status === 'WHMCS_PAYMENT_FAILED') {
              swal('Payment Failed.', 'Please try a different credit card.', 'error');
            }

            if (response.status === 'WHMCS_ADD_CARD_FAILED') {
              swal('Communication with the billing platform failed', 'Please try again', 'error');
            }

            if (response.status === 'WHMCS_FETCH_ORDER_FAILED') {
              swal('Communication with the billing platform failed', 'Please try again', 'error');
            }

            if (response.status === 'WHMCS_EMPTY') {
              swal('Communication with the billing platform failed', 'Please try again', 'error');
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.subscriptionService.onGetWhmcsDetails
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.billingForm.patchValue({
                firstname: response.data.firstname,
                lastname: response.data.lastname,
                email: response.data.email,
                phonenumber: response.data.phonenumber,
                address1: response.data.address1,
                address2: response.data.address2,
                city: response.data.city,
                state: response.data.state,
                postcode: response.data.postcode,
                country: response.data.country
              });

              this.billingModal.show();
            }

            if (response.status === 'WHMCS_EMPTY') {
              this.billingForm.patchValue({
                firstname: this.user.firstname,
                lastname: this.user.lastname,
                email: this.user.email
              });

              this.billingModal.show();
            }

            if (response.status === 'WHMCS_API_FAILED') {
              this.router.navigateByUrl('/help/support');
              swal('Our billing platform is currently having issues.', 'Please try again later.', 'error');
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.subscriptionService.onUpdateSubscrition
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              swal('Designly Pro subscription updated.', 'Please wait while the page reloads', 'success');

              setTimeout(() => {
                window.location.reload();
              }, 2500);
            }

            if (response.status === 'VALIDATION_ERROR') {
              swal(response.message, '', 'error');
            }

            if (response.status === 'EMPTY_PRODUCTS') {
              swal('No services detected', 'Please try again.', 'error');
            }

            if (response.status === 'WHMCS_PAYMENT_FAILED') {
              swal('Payment Failed.', 'Please try a different credit card.', 'error');
            }

            if (response.status === 'WHMCS_ADD_CARD_FAILED') {
              swal('Communication with the billing platform failed', 'Please try again', 'error');
            }

            if (response.status === 'WHMCS_FETCH_ORDER_FAILED') {
              swal('Communication with the billing platform failed', 'Please try again', 'error');
            }

            if (response.status === 'WHMCS_EMPTY') {
              swal('Communication with the billing platform failed', 'Please try again', 'error');
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.subscriptionService.onCancelSubscription
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.loading = false;
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              swal('Designly Pro subscription ended.', 'Please wait while the page reloads', 'success');

              setTimeout(() => {
                window.location.reload();
              }, 2500);
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.clientService.onGetClient
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.client = response.data;

            if (this.client.subscription) {
              this.client.subscription.products.forEach(subscriptionProduct => {
                this.agencyProducts.push(subscriptionProduct.product.UID);

                const formArray = this.agencySubscriptionForm.get('products') as FormArray;
                formArray.push(new FormControl(subscriptionProduct.product.UID));
              });
            }
            this.updateAgencyPricingView(this.agencyProducts);
          }
        }
      }
    );

    this.billingModal = new bootstrap.Modal(document.getElementById('billingModal'));
    this.creditCardModal = new bootstrap.Modal(document.getElementById('creditCardModal'));
    this.initForms();
  }

  initForms(): void {
    this.billingForm = new FormGroup({
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      phonenumber: new FormControl('', Validators.required),
      address1: new FormControl('', Validators.required),
      address2: new FormControl(''),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      postcode: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      promocode: new FormControl('')
    });

    this.creditcardForm = new FormGroup({
      order_id: new FormControl('', Validators.required),
      ccname: new FormControl('', Validators.required),
      ccnumber: new FormControl('', Validators.required),
      ccMonthExpiry: new FormControl('', Validators.required),
      ccYearExpiry: new FormControl('', Validators.required),
      ccCvn: new FormControl('', Validators.required)
    });

    this.agentSubscriptionForm = new FormGroup({
      products: new FormArray([], Validators.required)
    });

    this.agencySubscriptionForm = new FormGroup({
      products: new FormArray([], Validators.required)
    });
  }

  onAgentCheckChange(event: any): void {
    const formArray: FormArray = this.agentSubscriptionForm.get('products') as FormArray;
    const productIndex = this.products.findIndex((product: Product) => product.UID === event.target.value);

    if (event.target.checked) {
      if (this.products[productIndex].bundle) {
        document.querySelectorAll('.agent-checkbox').forEach((el: HTMLInputElement) => {
          if (el.value !== this.products[productIndex].UID) {
            el.checked = false;
          }
        });

        formArray.clear();
      } else {
        const productBundle = document.querySelector('.d-agent-product-bundle') as HTMLInputElement;

        if (productBundle) {
          productBundle.checked = false;

          const bundleIndex = formArray.controls.findIndex((formControl: FormControl) => formControl.value === productBundle.value);

          if (bundleIndex !== -1) {
            formArray.removeAt(bundleIndex);
          }
        }

      }
      formArray.push(new FormControl(event.target.value));

      if (formArray.length === 3) {
        const productBundle = document.querySelector('.d-agent-product-bundle') as HTMLInputElement;

        productBundle.click();
      }
    } else {
      const formArrayIndex = formArray.controls.findIndex((formControl: FormControl) => formControl.value === event.target.value);

      if (formArrayIndex !== -1) {
        formArray.removeAt(formArrayIndex);
      }
    }

    if (this.user.subscription && this.user.subscription.status === 'active') {
      this.agentNewProducts = formArray.value;
    }

    this.updateAgentPricingView(this.agentSubscriptionForm.get('products').value);
  }

  onAgentSubscribe(): void {
    const agentSubscriptionForm = this.agentSubscriptionForm.value;

    if (!agentSubscriptionForm.products.length) {
      swal('At least 1 module required to upgrade to Designly Pro', '', 'error');
      return;
    }

    this.subscriptionService.httpGetWhmcsDetails({
      type: 'agent'
    });
    this.loading = true;

    this.isAgentSubscription = true;
    this.isAgencySubscription = false;
  }

  updateAgentPricingView(products = []) {
    let totalPricing = 0;
    products.forEach((productUid: string) => {
      const productIndex = this.products.findIndex((product: Product) => product.UID === productUid);

      if (productIndex !== -1) {
        totalPricing += this.products[productIndex].price;
      }
    });

    this.agentSubscriptionPrice = totalPricing;
  }

  onAgencyCheckChange(event: any): void {
    const formArray: FormArray = this.agencySubscriptionForm.get('products') as FormArray;
    const productIndex = this.products.findIndex((product: Product) => product.UID === event.target.value);

    if (event.target.checked) {
      if (this.products[productIndex].bundle) {
        document.querySelectorAll('.agency-checkbox').forEach((el: HTMLInputElement) => {
          if (el.value !== this.products[productIndex].UID) {
            el.checked = false;
          }
        });

        formArray.clear();
      } else {
        const productBundle = document.querySelector('.d-agency-product-bundle') as HTMLInputElement;

        if (productBundle) {
          productBundle.checked = false;

          const bundleIndex = formArray.controls.findIndex((formControl: FormControl) => formControl.value === productBundle.value);

          if (bundleIndex !== -1) {
            formArray.removeAt(bundleIndex);
          }
        }
      }

      formArray.push(new FormControl(event.target.value));

      if (formArray.length === 3) {
        const productBundle = document.querySelector('.d-agency-product-bundle') as HTMLInputElement;

        productBundle.click();
      }
    } else {
      const formArrayIndex = formArray.controls.findIndex((formControl: FormControl) => formControl.value === event.target.value);

      if (formArrayIndex !== -1) {
        formArray.removeAt(formArrayIndex);
      }
    }

    if (this.client.subscription && this.client.subscription.status === 'active') {
      this.agencyNewProducts = formArray.value;
    }

    this.updateAgencyPricingView(this.agencySubscriptionForm.get('products').value);
  }

  onAgencySubscribe(): void {
    const agencySubscriptionForm = this.agencySubscriptionForm.value;

    if (!agencySubscriptionForm.products.length) {
      swal('At least 1 module required to upgrade to Designly Pro', '', 'error');
      return;
    }

    this.subscriptionService.httpGetWhmcsDetails({
      type: 'agency'
    });
    this.loading = true;

    this.isAgentSubscription = false;
    this.isAgencySubscription = true;
  }

  updateAgencyPricingView(products = []) {
    let totalPricing = 0;
    products.forEach((productUid: string) => {
      const productIndex = this.products.findIndex((product: Product) => product.UID === productUid);

      if (productIndex !== -1) {
        totalPricing += this.products[productIndex].price;
      }
    });

    this.agencySubscriptionPrice = totalPricing;
  }

  subscribe(): void {
    const creditcardFormValues = this.creditcardForm.value;

    if (this.creditcardForm.valid) {
      creditcardFormValues.ccnumber = eCrypt.encryptValue(creditcardFormValues.ccnumber, this.ewayClientKey);
      creditcardFormValues.ccCvn = eCrypt.encryptValue(creditcardFormValues.ccCvn, this.ewayClientKey);

      if (this.isAgencySubscription) {
        creditcardFormValues.type = 'agency';
        creditcardFormValues.products = this.agencySubscriptionForm.get('products').value;
      }

      if (this.isAgentSubscription) {
        creditcardFormValues.type = 'agent';
        creditcardFormValues.products = this.agentSubscriptionForm.get('products').value;
      }

      this.subscriptionService.httpSubscribe(creditcardFormValues);
      this.loading = true;
    }
  }

  createWhmcsProfile(): void {
    const billingFormValues = this.billingForm.value;

    if (this.billingForm.valid) {
      if (this.isAgencySubscription) {
        billingFormValues.products = this.agencySubscriptionForm.get('products').value;
        billingFormValues.type = 'agency';
      }

      if (this.isAgentSubscription) {
        billingFormValues.products = this.agentSubscriptionForm.get('products').value;
        billingFormValues.type = 'agent';
      }

      this.subscriptionService.httpCreateClientProfile(billingFormValues);
      this.loading = true;
    }
  }

  product(UID: string) {
    const productIndex = this.products.findIndex((product: Product) => product.UID === UID);

    if (productIndex !== -1) {
      return this.products[productIndex];
    }

    return null;
  }

  updateSubscription() {
    let updatedProducts = null;

    if (this.isAgencySubscription && this.agencySubscriptionForm.valid) {
      updatedProducts = {
        products: this.agencySubscriptionForm.get('products').value,
        promocode: this.updateProductsPromoCode,
        type: 'agency'
      };
    }

    if (this.isAgentSubscription && this.agentSubscriptionForm.valid) {
      updatedProducts = {
        products: this.agentSubscriptionForm.get('products').value,
        promocode: this.updateProductsPromoCode,
        type: 'agent'
      };
    }

    if (updatedProducts) {
      this.subscriptionService.httpUpdateSubscription(updatedProducts);
      this.loading = true;
    }
  }

  cancelSubscription(type: 'agent' | 'agency') {
    const $this = this;
    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel subscription.'
    }).then((result) => {
      if (result) {
        $this.loading = true;
        $this.subscriptionService.httpCancelSubscription({
          type
        });
      }
    }).catch(swal.noop);
  }

  openUpdateSubscription(type: 'agent' | 'agency') {
    if (type === 'agency') {
      this.isAgentSubscription = false;
      this.isAgencySubscription = true;
    } else {
      this.isAgentSubscription = true;
      this.isAgencySubscription = false;
    }
  }

  initTooltip() {
    setTimeout(() => {
      let timeout;
      $('[data-tooltip]').on({
        'mouseenter': function () {
          timeout = setTimeout(() => {
              $('[data-tooltip]').removeClass('hovered');
              $(this).addClass('hovered');
            }, 350);
        },
        'mouseleave' : function () {
          clearTimeout(timeout);
          $(this).removeClass('hovered');
        }
      });
    }, 300);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
