<div class="row">
    <div class="col-md-12 heading">
        <h2 *ngIf="!editMode" class="pull-left">Add {{product | titlecase}} Block</h2>
        <h2 *ngIf="editMode" class="pull-left">Edit {{product | titlecase}} Block</h2>

        <button routerLink="/admin/pros/blocks/{{product}}" type="button" class="btn-admin">
            <i class="icon feather icon-arrow-left"></i><span>Back</span>
        </button>
    </div>
</div>
<div class="edit-box">
    <div class="row">
        <div class="col-md-12">
            <div class="edit-box-content">
                <h4>Details</h4>
                <form [formGroup]="proBlockForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="title" class="col-sm-2">Title</label>
                        <div class="col-sm-6">
                            <input formControlName="title" type="text" class="form-control" id="title"
                                autocomplete="off">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="provider" class="col-sm-2">Group</label>
                        <div class="col-sm-6">
                          <ng-select formControlName="group_id" [clearable]="false" [searchable]="false" [multiple]="true">
                            <ng-option value="" disabled>Select a Group</ng-option>
                            <ng-option value="all">All</ng-option>
                            <ng-option *ngFor="let group of groups" [value]="group?.UID">{{group?.title}}</ng-option>
                          </ng-select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-2">&nbsp;</label>
                        <div class="col-sm-10">
                            <button [disabled]="!proBlockForm.valid" class="btn-admin btn-admin-primary">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>