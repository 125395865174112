import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject, ChangeDetectorRef, Input, AfterViewInit } from '@angular/core';
import { ClientService } from '../services/client.service';
import { Params, ActivatedRoute,  Router } from '@angular/router';
import { Client } from '../models/client.model';
import { Subscription } from 'rxjs/Subscription';
import { StorageService } from '../services/storage.service';
import { Auth } from '../models/auth.model';
import { ImageLibraryService } from '../shared/imagelibrary.service';
import * as copy from 'copy-to-clipboard';
import { APP_CONFIG } from '../app.config';
import { AuthService } from '../services/auth.service';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ThirdPartyService } from '../services/thirdparty.service';
import { MalihuScrollbarModule, MalihuScrollbarService } from 'ngx-malihu-scrollbar';

declare var $: any;
declare var swal: any;
declare var introJs: any;
declare var _bugHerd: any;

@Component({
  selector: 'app-image-library',
  templateUrl: './image-library.component.html',
  styleUrls: ['./image-library.component.scss']
})
export class ImageLibraryComponent implements OnInit, OnDestroy, AfterViewInit {
  $destroy: Subject<boolean> = new Subject();

  loading = false;
  sid: number;
  id: number;
  gid: number;
  type = 'owned';
  subscription: Subscription;
  dragItem: any;
  dragItemElem: any;
  files = [];
  subFiles = [];
  subLevel3Files = [];
  subLevel4Files = [];
  images = [];
  selected_image = [];

  moveFiles = [];
  tempFiles = [];
  tempImages = [];

  navigationType = 'link';
  navigationDefault = 'owned';

  title = 'My Library';

  client: Client;

  path = '';
  base_path = '';
  move_base_path = '';
  folder = '';
  level = 0;

  folder_name1 = '';
  folder_name2 = '';
  folder_name3 = '';
  folder_name4 = '';

  current_folder = '';

  movePath = '';

  is_new_folder = false;
  is_new_image = false;
  is_on_level3 = false;

  term;

  image_dimension;

  is_builder = false;
  is_admin = false;

  have_selected = false;

  authUser: Auth;
  user = {
    ID: 21,
    client_id: 5,
    name: 'John Doe',
    photo: 'assets/img/user_photo.jpg',
    email: 'johndoe@websiteblue.com',
    mobile: '+6112345678',
    telephone: '1234 5678',
    fax: '9876 5432'
  };

  supportLink = '';
  isStockPage = false;

  stockSearchText: FormControl;
  unsplashCurrentPage = 1;

  unsplashImages = [];
  selectedUnsplashImage: any;
  currentPage = 'My Library';

  isDefaultLoaded = false;

  raywhiteGroupIds = [5, 11, 31, 32];

  @ViewChild('itemFile', { static: true }) itemFile: ElementRef;
  @ViewChild('itemFolder', { static: true }) itemFolder: ElementRef;
  @ViewChild('uploadItemModalClose', { static: false }) uploadItemModalClose: ElementRef;
  @ViewChild('newFolderModalClose', { static: false }) newFolderModalClose: ElementRef;
  @ViewChild('imageLibraryModalClose', { static: false }) imageLibraryModalClose: ElementRef;
  @ViewChild('displayLibrary', { static: true }) displayLibrary: ElementRef;
  @ViewChild('displayUpload', { static: true }) displayUpload: ElementRef;
  @ViewChild('defaultFolder', { static: true }) defaultFolder: ElementRef;
  @Input() page: string;

  constructor(
      private storageService: StorageService,
      private route: ActivatedRoute,
      private router: Router,
      public authService: AuthService,
      private imageService: ImageLibraryService,
      private ref: ChangeDetectorRef,
      private thirdPartyService: ThirdPartyService,
      private mScrollbarService: MalihuScrollbarService,
      @Inject(APP_CONFIG) public appConfig
    ) {
      this.stockSearchText = new FormControl('real estate');
    }


  ngOnInit() {
    this.files = [];

    if (this.page === 'admin') {
      this.is_admin = true;
    }

    if (this.imageService.is_builder) {
      this.is_builder = true;
      this.authUser = this.authService.auth;
      this.client = this.authUser.client;
      this.id = this.authUser.client.ID;
      this.gid = this.authUser.client.group_id;
    }

    if (!this.imageService.is_builder && !this.imageService.is_admin) {
      this.authUser = this.authService.auth;
      this.client = this.authUser.client;
      this.id = this.authUser.client.ID;
      this.gid = this.authUser.client.group_id;
    }

    if (!this.imageService.is_builder && this.page !== 'admin') {
      $(this.displayLibrary.nativeElement).modal('show');
      this.openOwnLibrary();
    } else if (this.imageService.is_builder || this.is_admin) {
      var $this = this;
      $('#displayLibrary').on('shown.bs.modal', function () {
        $this.defaultFolder.nativeElement.click();
      });
    }

    this.imageService.onBuilderOpen
    .takeUntil(this.$destroy)
    .subscribe(
      (open: boolean) => {
        if (open) {
          $(this.displayLibrary.nativeElement).modal('show');
        }
      }
    );

    this.imageService.adminChanged
    .takeUntil(this.$destroy)
    .subscribe(
      (client: any) => {
        if (typeof client !== 'undefined') {
          this.is_admin = true;
          this.id = client.ID;
          this.gid = client.group_id;

          this.openOwnLibrary();
        }
      }
    );

    this.storageService.storagesChanged
    .takeUntil(this.$destroy)
    .subscribe(
      (storages: any[]) => {
        if (typeof storages !== 'undefined') {

          this.loading = false;

          if (this.level === 0) {
            this.tempFiles = [];
            if (this.is_new_image === false) {
              this.files = [];
              this.files = storages;
            }
            this.images = [];
            if (this.is_new_folder === false) {
              for (let i = 0; i < storages.length; i++) {
                if (storages[i].type === 'file') {
                  this.images.push(storages[i]);
                }
              }
            }

            this.ref.detectChanges();
          }

          this.initTooltip(300);
        }
      }
    );

    this.storageService.storagesSubChanged
    .takeUntil(this.$destroy)
    .subscribe(
      (storages: any[]) => {
        if (typeof storages !== 'undefined') {
          this.loading = false;
          this.ref.detectChanges();

          if (this.is_new_image === false) {
            if (this.level === 2) {
              this.subFiles = [];
              this.subFiles = storages;
            }

            if (this.level === 3) {
              this.subLevel3Files = [];
              this.subLevel3Files = storages;
            }

            if (this.level === 4) {
              this.subLevel4Files = [];
              this.subLevel4Files = storages;
            }
          }

          this.images = [];
          for (let i = 0; i < storages.length; i++) {
            if (storages[i].type === 'file') {
              this.images.push(storages[i]);
            }
          }

          this.is_new_image = false;
          this.is_new_folder = false;

          this.initTooltip(300);
        }
      }
    );

    this.storageService.movestoragesChanged
    .takeUntil(this.$destroy)
    .subscribe(
      (storages: any[]) => {
        if (typeof storages !== 'undefined') {
          this.loading = false;
          this.ref.detectChanges();

          this.moveFiles = storages;
        }
      }
    );

    this.storageService.storageOperation
    .takeUntil(this.$destroy)
    .subscribe(
      (storages: any[]) => {
        this.storageService.httpGetStorages(this.type, this.sid);

        if (this.is_new_image) {
          // tslint:disable-next-line:max-line-length
          this.storageService.httpGetStorages(this.type, this.sid, {folder: this.folder_name1 + (this.folder_name2 !== '' ? '/' + this.folder_name2 : '')});
        }

        if (this.is_new_folder) {
          this.storageService.httpGetStorages(this.type, this.sid, {folder: (this.folder_name1 !== '' ? this.folder_name1 : '/')});
        }

        if (this.level === 2) {
          this.storageService.httpGetSubStorages(this.type, this.sid, {folder: this.folder_name1 });
        }

        let folder = '';

        if (this.level === 3 || this.level === 4 || this.level === 5) {

          if (this.level === 3) {
            folder = '/' + this.folder_name2 + '/' + this.folder_name3;
          } else if (this.level === 4 || this.level === 5) {
            folder = '/' + this.folder_name2 + '/' + this.folder_name3 + '/' + this.folder_name4;
          }

          this.storageService.httpGetSubStorages(this.type, this.sid, {folder: this.folder_name1 + folder });
        }

        this.tempImages = [];
        this.initTooltip(300);
      }
    );

    $('.modal-content').on('click', function(e) {
      const container = $('.link-options');

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $('#context-menu').removeClass('context-menu--active');
        $('#unsplash-context-menu').removeClass('context-menu--active');
      }
    });

    this.stockSearchText.valueChanges
    .takeUntil(this.$destroy)
    .debounceTime(500)
    .subscribe(searchText => {
      if (typeof searchText !== 'undefined') {
        this.unsplashImages = [];
        this.unsplashCurrentPage = 1;

        this.thirdPartyService.httpUnsplashSearch({
          page: this.unsplashCurrentPage,
          query: searchText,
          perPage: 50
        });
    
        this.loading = true;
      }
    });

    this.thirdPartyService.onUnsplashSearch
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        this.loading = false;
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.unsplashImages = [ ...this.unsplashImages, ...response.data];
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          this.unsplashImages = [];
        }
      }
    );

    this.initTooltip();
  }

  showLink() {
    const $this = this;
    let item;
    item = this.selected_image;
    const default_extension = ['jpeg', 'jpg', 'png', 'gif', 'bmp'];
    if (item.type === 'file') {
      if ($.inArray(item.extension, default_extension) === -1) {
        swal({
          title: item.filename + ' - ' + (item.size / 1000000).toFixed(2) + 'MB',
          text: 'Dimensions:' + $this.image_dimension,
          imageUrl: '/assets/img/file-icon.png',
          customClass: "viewImage",
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          animation: true
        });
      } else {
        swal({
          title: item.filename + ' - ' + (item.size / 1000000).toFixed(2) + 'MB',
          text: 'Dimensions: ' + $this.image_dimension,
          imageUrl: this.appConfig.S3_URL + item.path,
          imageHeight: 200,
          customClass: "viewImage",
          imageAlt: 'Custom image',
          animation: true
        });
      }

    }
  }

  copyLink() {
    let item;
    item = this.selected_image;
    copy(this.appConfig.S3_URL + item.path);
  }

  getLink(item: any) {
    if (item.type === 'file') {
      return this.appConfig.S3_URL + item.path;
    }
  }

  openFolder(item: any) {
    if (item.type === 'dir') {
      this.folder_name1 = item.path.split('/')[2];
      this.loading = true;
      this.ref.detectChanges();

      if (item.path.split('/')[3] === undefined) {
       this.subFiles = [];
      }
      if (item.path.split('/')[4] === undefined) {
        this.subLevel3Files = [];
      }
      if (item.path.split('/')[5] === undefined) {
        this.subLevel4Files = [];
      }

      if (this.folder_name1 !== item.filename) {
        if (item.path.split('/')[4]) {

          let _folder_name = item.path.split('/')[3];

          if (item.path.split('/')[5]) {
           _folder_name += '/' + item.path.split('/')[4];
          }

          // tslint:disable-next-line: max-line-length
          this.storageService.httpGetSubStorages(this.type, this.sid, { folder: this.folder_name1 + '/' + _folder_name + '/' + item.filename });
        } else {
          this.storageService.httpGetSubStorages(this.type, this.sid, { folder: this.folder_name1 + '/' + item.filename });
        }
      } else {
        this.storageService.httpGetSubStorages(this.type, this.sid, { folder: item.filename });
      }

      this.path = item.path;
      this.folder = item.filename;
      this.current_folder = item.path.split('/')[2];

      this.is_on_level3 = false;

      if (item.path.split('/')[2] !== undefined) {
        this.level = 2;
        this.folder_name2 = '';
        this.folder_name3 = '';
        this.folder_name4 = '';
      }

      if (item.path.split('/')[3] !== undefined) {
        this.level = 3;
        this.folder_name2 = item.path.split('/')[3];
      }

      if (item.path.split('/')[4] !== undefined) {
        this.level = 4;
        this.folder_name3 = item.path.split('/')[4];
      }

      if (item.path.split('/')[5] !== undefined) {
        this.level = 5;
        this.folder_name4 = item.path.split('/')[5];
      }
    }
  }

  openRoot() {
    this.loading = true;
    this.ref.detectChanges();

    this.path = this.base_path;
    this.folder = '';
    this.folder_name1 = '';
    this.folder_name2 = '';
    this.folder_name3 = '';
    this.folder_name4 = '';
    this.subFiles = [];
    this.subLevel3Files = [];
    this.subLevel4Files = [];
    this.files = [];
    this.storageService.httpGetStorages(this.type, this.sid, this.path);
  }

  onUpload() {

    this.loading = true;
    this.ref.detectChanges();

    this.is_new_image = true;

    const fileSingle = this.itemFile.nativeElement;

    const values = [];

    for (let i = 0; i < fileSingle.files.length; i++) {
      this.tempImages.push(fileSingle.files[i]);
    }

    values['file'] = this.tempImages;
    values['type'] = 'file';
    values['path'] = this.path;

    this.storageService.httpPostStorage(values);

    setTimeout(
      () => {
        $('#uploadItem').modal('hide');
        this.itemFile.nativeElement.value = '';
      }, 100
    );

  }
  checkFileSize(event) {
    if (event.target.files[0].size > 10000000) {
      swal(
        'File is too big',
        'Maximum file size is 10 MB',
        'error'
      );
    }

    const temp_files_length = event.target.files.length;

    for (let i = 0; i < temp_files_length; i++) {
      this.tempFiles.push(event.target.files[i]);
    }
  }

  onSubmit() {
    const values = [];

    if (this.itemFolder.nativeElement.value.trim() === '') {
      swal(
        'Error',
        'Invalid Folder Name',
        'error'
      ).catch(swal.noop);

    }

    if (this.itemFolder.nativeElement.value.trim() !== '') {
      this.loading = true;
      this.ref.detectChanges();

      const _folder_name = this.itemFolder.nativeElement.value;

      values['name'] = _folder_name.split(' ').join('-');
      values['type'] = 'dir';
      values['path'] = this.path;

      this.is_new_folder = true;
      this.storageService.httpPostStorage(values);

      setTimeout(
        () => {
          $('#newFolder').modal('hide');
          this.itemFolder.nativeElement.value = '';
        }, 200
      );
    }
  }

  onInsert() {
    let item;
    item = this.selected_image;
    this.have_selected = false;

    const $this = this;

    const size = Number((item.size / 1000000).toFixed(2));

    if ( size >= 1 ) {
      swal({
        title: 'Warning',
        // tslint:disable-next-line: max-line-length
        text: 'Please be aware you are trying to insert a large image and can cause problems when inserting into emails or result in large PDF\'s',
        type: 'warning',
      }).then(function () {
        $this.imageService.onInsert(item);
        $this.imageLibraryModalClose.nativeElement.click();
      });
    } else {
      $this.imageService.onInsert(item);
      $this.imageLibraryModalClose.nativeElement.click();
    }
  }

  onDelete() {
    let item;
    item = this.selected_image;
    const $this = this;

    const path = String(item.path).split('/');

    // tslint:disable-next-line:radix
    if ( !this.authUser.is_master && this.authUser.client.ID !== parseInt(path[1]) ) {
      swal(
        'Error deleting',
        'You don\'t have enough permission to delete this ' + item.type,
        'error'
      );
      return false;
    }

    swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(function () {
        swal(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );

        const values = [];
        $this.loading = true;
        $this.ref.detectChanges();

        if (item.path) {
          values['type'] = item.type;
          values['path'] = item.path;

          $this.storageService.httpDeleteStorage(values);
        }
      }).catch(swal.noop);

  }

  startTour() {
    introJs().start();
  }

  onFeedback() {
    swal({
      title: 'We would love to hear from you about the new MyDesign',
      imageUrl: 'https://mydesign3.wbclients.com/mydesign/assets/images/message-square.svg',
      imageWidth: 300,
      imageHeight: 100,
      showConfirmButton: false,
      html: '<p>Please choose to provide either Feedback or log a Support Ticket</p>' +
      // tslint:disable-next-line:max-line-length
      '<a class="btn btn-success btn-bh-feedback">Send Feedback</a> &nbsp; <a href="' + this.supportLink + '" target="_blank" class="btn btn-info btn-support-log">Log Support Ticket</a>'
      } );

      $('.btn-bh-feedback').click(function() {
        swal.close();
        _bugHerd.win.bugherd.applicationView.anonymousbar.toggleOptions();
      });

  }

  displayOptions(event, file, type = 'default') {
    const $this = this;

    if (type === 'default') {
      const img = new Image();
      img.onload = function() {
        $this.image_dimension = (img.width + ' x ' + img.height).toString();
      };
  
      img.src = this.appConfig.S3_URL + file.path;
      this.selected_image = file;
    }

    let contextMenuId = 'context-menu';

    if (type === 'unsplash') {
      contextMenuId = 'unsplash-context-menu'

      this.selectedUnsplashImage = file;
    }

    if (event.pageY > ($(document).height() - 100)) {
      $(`#${contextMenuId}`).css({
        'left': event.pageX - 150,
        'top': event.pageY - 150
      });
    } else if (event.pageX > ($(document).width() - 100)) {
      $(`#${contextMenuId}`).css({
        'left': event.pageX - 150,
        'top': event.pageY
      });
    } else {
      $(`#${contextMenuId}`).css({
        'left': event.pageX,
        'top': event.pageY
      });
    }

    $(`#${contextMenuId}`).addClass('context-menu--active');
    return false;
  }

  openOwnLibrary(client_id = 0) {
    this.currentPage = 'My Library';

    this.isDefaultLoaded = true;
    this.isStockPage = false;
    this.files = [];
    this.images = [];
    this.unsplashImages = [];
    $(this.displayLibrary.nativeElement).modal('show');

    if (this.imageService.is_builder) {
      this.is_builder = true;
      this.authUser = this.authService.auth;
      this.client = this.authUser.client;
      this.id = this.authUser.client.ID;
      this.gid = this.authUser.client.group_id;
    }

    if ( client_id !== 0 ) {
      this.sid = client_id;
    }

    this.loading = true;
    this.have_selected = false;
    this.sid = this.id;
    this.path = 'clients/' + this.id;
    this.type = 'owned';

    this.level = 0;

    // this.path = this.base_path;
    this.folder = '';
    this.folder_name1 = '';
    this.folder_name2 = '';
    this.folder_name3 = '';
    this.folder_name4 = '';
    // this.folder
    this.base_path = this.path;

    if (this.id) {
      this.storageService.httpGetStorages(this.type, this.sid);
    } else {
      this.storageService.httpGetStorages(this.type, this.sid, this.path);
    }

    this.imageService.onAdminClose();
    this.ref.detectChanges();
  }

  openSharedLibrary(group_id = 0) {
    this.currentPage = 'Shared Library';

    this.isDefaultLoaded = true;
    this.isStockPage = false;
    this.files = [];
    this.images = [];
    this.unsplashImages = [];
    $(this.displayLibrary.nativeElement).modal('show');

    if (this.imageService.is_builder) {
      this.is_builder = true;
      this.authUser = this.authService.auth;
      this.client = this.authUser.client;
      this.id = this.authUser.client.ID;
      this.gid = this.authUser.client.group_id;
    }

    if ( group_id !== 0 ) {
      this.gid = group_id;
    }

    this.loading = true;
    this.ref.detectChanges();

    this.sid = this.gid;
    this.have_selected = false;
    this.path = 'groups/'  + this.gid;
    this.type = 'shared';

    this.level = 0;

    // this.path = this.base_path;
    this.folder = '';
    this.folder_name1 = '';
    this.folder_name2 = '';
    this.folder_name3 = '';
    this.folder_name4 = '';
    this.base_path = this.path;

    if (this.gid) {
      this.storageService.httpGetStorages(this.type, this.sid);
    } else {
      this.storageService.httpGetStorages(this.type, this.sid, this.path);
    }

    this.imageService.onAdminClose();
  }

  openAdmin() {
    this.currentPage = 'Admin Library';

    this.isDefaultLoaded = true;
    this.isStockPage = false;
    this.files = [];
    this.images = [];
    this.unsplashImages = [];
    $(this.displayLibrary.nativeElement).modal('show');

    this.loading = true;
    this.ref.detectChanges();

    this.is_admin = true;
    this.path = 'admin/' + this.id;
    this.type = 'admin';

    this.level = 0;

    // this.path = this.base_path;
    this.folder = '';
    this.folder_name1 = '';
    this.folder_name2 = '';
    this.folder_name3 = '';
    this.folder_name4 = '';

    this.base_path = this.path;

    this.sid = this.id;

    if (this.id) {
      this.storageService.httpGetStorages(this.type, this.sid);
    }

    this.imageService.onAdminClose();
  }

  openMoveFile() {
    this.loading = true;
    this.ref.detectChanges();

    this.movePath = this.base_path;

    this.storageService.httpGetMoveStorages(this.type, this.sid);
  }

  openMoveFolder(folder) {
    this.loading = true;
    this.ref.detectChanges();

    this.movePath = folder.path;

    this.storageService.httpGetMoveStorages(this.type, this.sid, { folder: folder.basename });
  }

  setBreadcrumb(path) {
    if (path.split('/')[2] !== undefined && path.split('/')[3] === undefined) {
      return ' > ' + path.split('/')[2];
    }

    if (path.split('/')[3] !== undefined) {
      return ' > ' + path.split('/')[2] + ' > ' + path.split('/')[3];
    }

    return '';
  }

  backMoveFolder() {
    this.loading = true;
    this.ref.detectChanges();

    this.movePath = this.base_path;

    this.storageService.httpGetMoveStorages(this.type, this.sid);
  }

  moveFile() {
    const $this = this;

    swal({
      title: 'Are you sure you want to move this image?',
      text: 'If this image has been used in a template, the image will no longer display',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Move'
    }).then(function () {
      swal(
        'Image Moved!',
        'Your file has been moved.',
        'success'
      );

      $this.loading = true;
      $this.ref.detectChanges();

      const values = [];

      let item;
      item = $this.selected_image;

      values['type'] = item.type;
      values['path'] = item.path;
      values['move_to_path'] = $this.movePath + '/' + item.basename;

      $this.storageService.httpMoveStorage(values);
      $('#moveFile').modal('hide');

    }).catch(swal.noop);

  }

  truncateName(str) {
    if (str.length < 20) {
      return str;
    } else {
      return str.substring(0, 20) + '...';
    }
  }

  onImageClick(event, image, type = 'default') {
    if (this.is_builder === true) {
      this.have_selected = true;

      if (type === 'default') {
        this.selected_image = image;
      }

      if (type === 'unsplash') {
        this.selectedUnsplashImage = image;
      }

      $('.builder_selected').css({'background-color': '', 'border-radius': '0'});
      $('.builder_selected').removeClass('builder_selected');
      $(event.target).closest('.photo-wrapper').addClass('builder_selected');
      $(event.target).closest('.photo-wrapper').css({'background-color': '#36ab7a', 'border-radius': '5px'});
    }

    this.ref.detectChanges();
  }

  onDeleteFolder(file) {
    const $this = this;

    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(function () {
      swal(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      );

      $this.path = $this.base_path;
      $this.folder = '';
      $this.folder_name1 = '';
      $this.folder_name2 = '';
      $this.folder_name3 = '';
      $this.folder_name4 = '';
      $this.subFiles = [];
      $this.subLevel3Files = [];
      $this.subLevel4Files = [];
      $this.files = [];
      const values = [];
      $this.loading = true;
      $this.ref.detectChanges();

        values['type'] = file.type;
        values['path'] = file.path;

        $this.storageService.httpDeleteStorage(values);
    });


    const e = window.event;
    e.stopPropagation();
  }

  closeModal(id) {
    $('#' + id).modal('hide');
  }

  closeLibrary() {
    this.imageService.onCloseLibrary.next(true);
  }

  openUpload() {
    $(this.displayUpload.nativeElement).modal('show');
  }

  downloadImage() {
    let item;
    item = this.selected_image;

    const pdf_url = this.appConfig.S3_URL + item.path;

    // const blob = new Blob([img], { type: 'image/jpg' });
    // const data = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = pdf_url;
    link.download = item.basename;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  openStock() {
    this.currentPage = 'Stock Library';
    this.folder_name1 = '';
    this.folder_name2 = '';
    this.folder_name3 = '';
    this.folder_name4 = '';

    this.isStockPage = true;
    this.files = [];
    this.images = [];
    this.unsplashImages = [];
    $(this.displayLibrary.nativeElement).modal('show');

    this.unsplashCurrentPage = 1;
    this.unsplashImages = [];
    this.images = [];

    this.thirdPartyService.httpUnsplashSearch({
      page: this.unsplashCurrentPage,
      query: this.stockSearchText.value,
      perPage: 50
    });
    this.loading = true;
  }

  onScroll() {
    this.unsplashCurrentPage += 1;

    this.thirdPartyService.httpUnsplashSearch({
      page: this.unsplashCurrentPage,
      query: this.stockSearchText.value,
      perPage: 50
    });

    this.loading = true;
  }

  viewUnsplash(link: string) {
    window.open(link, '_blank');
  }

  onUnsplashInsert(links: any) {
    this.imageService.insertUnsplash(links);
    this.imageLibraryModalClose.nativeElement.click();
  }

  goToTemplatesPage() {
    window.location.href = '/templates';
  }

  initTooltip(timer=3000) {
      setTimeout(
        () => {
          $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover', 'delay': { show: 1500 } });
          $('.tooltip.show').remove();
        }, timer
      );
  }

  ngAfterViewInit() {
    $("a.library-link").click(function(e) {
      e.preventDefault();
      
      var $div = $(this).next('.library-container');

      $('a.library-link').removeClass('active');
      $(this).addClass('active');

      $(".library-container").not($div).slideUp('fast');
        if ($div.is(":visible")) {
            $div.slideUp('fast');
        }  else {
           $div.slideDown('fast')
        }
    });

    this.mScrollbarService.initScrollbar('.ImageScroller', { axis: 'y', theme: 'minimal-dark', scrollInertia: 600 });
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
    this.mScrollbarService.destroy('.ImageScroller');
  }
}
