<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ !editMode ? 'Add Element': 'Element - ' + element_title }}</h2>

    <button [routerLink]="[backUrl]" type="button" class="btn-admin">
      <i class="icon feather icon-arrow-left"></i><span>Back</span>
    </button>
  </div>
</div>
<div class="edit-box-new">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <h4>Details</h4>
        <form [formGroup]="elementForm" (ngSubmit)="onSubmit()" class="form-horizontal">
          <div class="form-group">
            <label for="title" class="col-sm-2">Title</label>
            <div class="col-sm-6">
              <input formControlName="title" type="text" class="form-control" id="title" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label for="description" class="col-sm-2">Description</label>
            <div class="col-sm-6">
              <textarea formControlName="description" rows="3" class="form-control" id="description"></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="provider" class="col-sm-2">Provider</label>
            <div class="col-sm-6">
              <ng-select formControlName="provider" [clearable]="false" [searchable]="false" [multiple]="true">
                <ng-option value="" disabled>Select a Provider</ng-option>
                <ng-option value="all">All</ng-option>
                <ng-option value="mydesktop">MyDesktop</ng-option>
                <ng-option value="vaultre">VaultRE</ng-option>
                <ng-option value="domain">Domain</ng-option>
                <ng-option value="idashboard">iDashboard</ng-option>
                <ng-option value="designly">Designly</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="group_id" class="col-sm-2">Group</label>
            <div class="col-sm-6">
              <ng-select formControlName="group_id" [clearable]="false" [searchable]="true" [multiple]="false">
                <ng-option value="" disabled>Select a Group</ng-option>
                <ng-option *ngFor="let group of groups" value="{{group?.ID}}">{{group?.title}}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="client_id" class="col-sm-2">Client</label>
            <div class="col-sm-6">
              <ng-select formControlName="client_id" [clearable]="false" [searchable]="true" [multiple]="false">
                <ng-option value="" disabled>Select a Client</ng-option>
                <ng-option value="0">All</ng-option>
                <ng-option *ngFor="let client of clients" value="{{client?.ID}}">{{client?.name ? client?.name : client?.company_name}} ({{client?.office_id}})</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="media_attribute_id" class="col-sm-2">Media Attribute</label>
            <div class="col-sm-6">
              <ng-select formControlName="media_attribute_id" [clearable]="false" [searchable]="false" [multiple]="false">
                <ng-option value="" disabled>Select a Media Attribute</ng-option>
                <ng-option *ngFor="let mediaAttribute of mediaAttributes" value="{{mediaAttribute?.ID}}">{{mediaAttribute?.slug | uppercase}}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="department_id" class="col-sm-2">Department</label>
            <div class="col-sm-6">
              <ng-select formControlName="department_id" [clearable]="false" [searchable]="false" [multiple]="false">
                <ng-option value="" disabled>Select a Department</ng-option>
                <ng-option *ngFor="let department of departments" value="{{department?.ID}}">{{department?.title}}</ng-option>
              </ng-select>
            </div>
          </div>

          <div class="form-group">
            <label for="icon" class="col-sm-2">Icon</label>
            <div class="col-sm-6">
              <input #fileIcon type="file" class="form-control" id="icon" accept="image/*">
              <input #iconName formControlName="icon" type="hidden" autocomplete="off">
              <img class="form-img-icon" [src]="iconName.value">
            </div>
          </div>
          <div class="form-group">
            <label for="thumbnail" class="col-sm-2">Thumbnail</label>
            <div class="col-sm-6">
              <input #fileThumbnail type="file" class="form-control" id="thumbnail" accept="image/*">
              <input #thumbnailName formControlName="thumbnail" type="hidden" autocomplete="off">
              <img class="form-img-thumbnail" [src]="thumbnailName.value">
            </div>
          </div>
          <div class="form-group">
            <label for="is_active" class="col-sm-2">Type</label>
            <div class="col-sm-3">
              <select #elementType formControlName="type" class="form-control" id="type">
                <option value="" disabled>Select a Type</option>
                <option value="default">Default</option>
                <option value="listing">Listing Box</option>
                <option value="listing-group">Listing Group</option>
              </select>
            </div>
          </div>
          <div *ngIf="elementType.value !== 'default'" class="form-group">
            <label for="is_active" class="col-sm-2">Parent Element</label>
            <div class="col-sm-3">
              <ng-select formControlName="parent_id" [clearable]="false" [searchable]="true" [multiple]="false">
                <ng-option value="" disabled>Select a Parent Element</ng-option>
                <ng-option value="0">None</ng-option>
                <ng-option *ngFor="let el of elements" value="{{el?.ID}}">{{ el.group.title}} - {{ el.title }} ({{ el.ID }})</ng-option>
              </ng-select>
            </div>
          </div>
          <div *ngIf="elementType.value !== 'default'" class="form-group">
            <label for="is_active" class="col-sm-2">Listing Category</label>
            <div class="col-sm-3">
              <select formControlName="listing_category" class="form-control" id="listing_category">
                <option value="" disabled>Select a Listing Category</option>
                <option value="">Default</option>
                <option value="residential">Residential (All)</option>
                <option value="commercial">Commercial (All)</option>
                <option value="rural">Rural (All)</option>
                <option value="business">Business (All)</option>
                <option value="stocklist">Livestock (MyDesktop/Vault)</option>
                <option value="clearingsale">Clearing Sale (MyDesktop/Vault)</option>
                <option value="land">Land (Vault)</option>
                <option value="holidayrental">Holiday Rental (Vault)</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="is_active" class="col-sm-2">Status</label>
            <div class="col-sm-3">
              <select formControlName="is_active" class="form-control" id="is_active">
                <option value="" disabled>Select a Status</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
                <option value="2">Hidden</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="sort" class="col-sm-2">Sort #</label>
            <div class="col-sm-3">
              <input formControlName="sort" type="number" class="form-control" id="sort" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2">&nbsp;</label>
            <div class="col-sm-10">
              <button [disabled]="!elementForm.valid" class="btn-admin btn-admin-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>