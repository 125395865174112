import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { AdminService } from '../../admin.service';
import { Faq } from '../../../models/faq.model';
import { FaqService } from '../../../services/faq.service';
import { FaqCategoryService } from '../../../services/faqcategory.service';
import { FaqCategory } from '../../../models/faqcategory.model';
import { Menu } from '../../../models/menu.model';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';

declare const $: any;

@Component({
  selector: 'app-faq-dashboard',
  templateUrl: './faq-dashboard.component.html',
  styleUrls: ['./faq-dashboard.component.scss']
})
export class FaqDashboardComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = true;

  faqs: Faq[] = [];

  constructor(
    private faqService: FaqService
  ) { }

  ngOnInit() {
    this.faqService.httpGetFaqs();

    this.faqService.onGetFaqs
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.faqs = response.data;

              this.destroyDataTables();

              setTimeout(
                () => {
                  this.loading = false;
                  $('table').DataTable({
                    order: [],
                    pagingType: 'full_numbers',
                    language: {
                      search: '_INPUT_',
                      searchPlaceholder: 'Search',
                      paginate: {
                        first: '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
                        previous: '<i aria-hidden="true" class="fa fa-angle-left"></i>',
                        next: '<i aria-hidden="true" class="fa fa-angle-right"></i>',
                        last: '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
                      }
                    },
                    pageLength: 50,
                    retrieve: true
                  });

                  $('input[type=search]').addClass('btn-search');
                  $('input[type=search]').attr('placeholder','Search');
                  $('select').addClass('select_datatable');
                  $('select').append('<option selected value="-1">Filter</option>');
                }, 1000
              );
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.faqService.onDeleteFaq
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.faqService.httpGetFaqs();
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );
  }

  onDelete(UID: string) {
    this.faqService.httpDeleteFaq(UID);
    this.loading = true;
  }

  cutString(title: string){
    return title.slice(0, 80);
  }

  destroyDataTables(): void {
    const activeDataTables = $.fn.dataTable.tables();
    if (activeDataTables.length) {
      activeDataTables.forEach(element => {
        $(element).DataTable().destroy();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyDataTables();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
