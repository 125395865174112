<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">Groups</h2>

    <button routerLink="new" type="button" class="btn-admin">
        <i class="icon feather icon-plus"></i><span>Add Group</span>
      </button>
  </div>
</div>
<div id="box" class="row">
  <div class="col-md-12">
    <div class="table-box">
      <!-- <label class="control">
          <input type="text" class="form-control admin-input" placeholder="Search..." [(ngModel)]="filter">
      </label> -->
      <table class="table table-striped table-borderless table-hover">
        <thead>
          <tr>
            <th data-priority="1">Provider</th>
            <th data-priority="3">Group Title</th>
            <th data-priority="4">MyDesktop Master Account</th>
            <th data-priority="5">Vault Master Account</th>
            <th data-priority="6">Domain Master Account</th>
            <th data-priority="7">Type</th>
            <th style="text-align: right" data-priority="2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr *ngFor="let group of groups | stringFilter: filter : 'groups' | paginate: { itemsPerPage: 50, currentPage: page } "> -->
          <tr *ngFor="let group of groups | stringFilter: filter : 'groups' ">
            <td>{{ group?.provider | uppercase }}</td>
            <td>{{ group?.title }}</td>
            <td>{{ group?.master?.company_name }}</td>
            <td>{{ group?.vault_master?.company_name }}</td>
            <td>{{ group?.domain_master?.company_name }}</td>
            <td>{{ group?.type | uppercase }}</td>
            <td style="text-align: right">
              <button routerLink="{{ group.ID }}/edit" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
              <button routerLink="{{ group.ID }}/vendorreports" type="button" class="btn-table" title="Vendor Reports"><i class="icon feather icon-codepen"></i></button>
              <button type="button" (click)="duplicateElement(group)" class="btn-table" title="Duplicate"><i class="icon feather icon-copy"></i></button>
              <button (click)="onDelete(group.ID)" type="button" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <pagination-controls (pageChange)="page = $event"></pagination-controls> -->
    </div>
  </div>
</div>
<!-- Modal -->
<div id="duplicateElementModal" class="modal" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Duplicating {{ group_name }} Elements & Master Templates</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="warning">This will also duplicate the Master Templates of {{ group_name }}</p>
        <table class="w-100 static">
          <tr> 
            <td>
              Select where to duplicate: 
            </td>
            <td>
              <select id="duplicate_select" class="form-control">
                <ng-container *ngFor="let group of groups"> 
                  <option value="{{ group.ID }}"> {{ group.title }} </option>
                </ng-container>
                </select>
            </td>
          </tr>
          <tr>
            <td align="right" colspan="2"><button (click)="duplicate()" class="btn btn-success mt-2">Duplicate</button></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>