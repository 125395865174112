import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { PopupService } from '../../../services/popup.service';
import { AdminService } from '../../admin.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-admin-popups-dashboard',
  templateUrl: './admin-popups-dashboard.component.html',
  styleUrls: ['./admin-popups-dashboard.component.scss']
})
export class AdminPopupsDashboardComponent implements OnInit, OnDestroy {
  $destroy: Subject<boolean> = new Subject();
  loading = false;
  page = 1;

  popups = [];

  constructor(
    public adminService: AdminService,
    private popupService: PopupService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.popupService.httpGetAll();

    this.popupService.onGetAll
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response !== 'undefined' && response.status === 'success') {
            this.loading = false;
            this.popups = response.data;
          }
        }
      }
    );

    this.popupService.onDelete
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response !== 'undefined' && response.status === 'success') {
            this.popupService.httpGetAll();
            swal('Popup deleted successfully', '', 'success');
          }
        }
      }
    );
  }

  onDelete(id: number) {
    this.loading = true;
    this.popupService.httpDelete(id);
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
