import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../admin.service';
import { MediaAttributeService } from '../../../services/media-attribute.service';
import { MediaAttribute } from '../../../models/media-attribute.model';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import slugify from 'slugify';
import { Subscription } from 'rxjs/Subscription';

declare var jQuery: any;

@Component({
  selector: 'app-setting-admin-media-attributes',
  templateUrl: './media-attributes.component.html',
  styleUrls: ['./media-attributes.component.scss']
})

export class MediaAttributesComponent implements OnInit, OnDestroy {
  loading = false;
  ID: number;
  mediaAttribute: MediaAttribute;
  mediaAttributes: MediaAttribute[] = [];

  mediaAttributeForm: FormGroup;

  subscription: Subscription;
  subscriptionResponse: Subscription;

  mediaAttributeSubscription: Subscription;

  @ViewChild('newMediaAttributeModalClose', { static: true }) newMediaAttributeModalClose: ElementRef;

  s2MediumData = [];
  s2OrientationData = [];
  s2SizeData = [];

  editMode = false;

  constructor(private adminService: AdminService,
              private mediaAttributeService: MediaAttributeService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.loading = true;
    this.s2MediumData = [
      {id: 'Print', text: 'Print'},
      {id: 'Email', text: 'Email'}
    ];
    this.s2OrientationData = [
      {id: 'Portrait', text: 'Portrait'},
      {id: 'Landscape', text: 'Landscape'},
      {id: 'Email', text: 'Email'}
    ];
    this.s2SizeData = [
      {id: 'A3', text: 'A3'},
      {id: 'A4', text: 'A4'},
      {id: '1200x1800', text: '1200x1800'},
      {id: '1200x2400', text: '1200x2400'},
      {id: '1800x1200', text: '1800x1200'},
      {id: '1750x1130', text: '1750x1130'},
      {id: 'custom', text: 'Custom'}
    ];

    this.subscription = this.mediaAttributeService.mediaAttributesChanged.subscribe(
      (mediaAttributes: MediaAttribute[]) => {
        if (typeof mediaAttributes !== 'undefined') {
          this.loading = false;
          this.mediaAttributes = mediaAttributes;

          setTimeout(
            () => {
              if ( ! jQuery.fn.DataTable.isDataTable( 'table' ) ) {
                
                jQuery('table').DataTable({
                  'pagingType': 'full_numbers',
                  // 'oLanguage': { 'sSearch': '', 'sLengthMenu': '_MENU_' },
                  language: {
                    search: '_INPUT_',
                    searchPlaceholder: 'Search',
                    'paginate': {
                      'first': '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
                      'previous': '<i aria-hidden="true" class="fa fa-angle-left"></i>',
                      'next': '<i aria-hidden="true" class="fa fa-angle-right"></i>',
                      'last': '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
                    }
                  },
                  "pageLength": 50
                });
          
                jQuery('input[type=search]').addClass('btn-search');
                jQuery('input[type=search]').attr('placeholder','Search');
                jQuery('#box select').addClass('select_datatable');
                jQuery('select').append('<option selected value="-1">Filter</option>');
              }
            }, 1000
          );
        }
      }
    );

    this.subscriptionResponse = this.mediaAttributeService.mediaAttributeOperation.subscribe(
      (mediaAttribute: MediaAttribute) => {
        if ( typeof mediaAttribute.ID !== 'undefined' ) {
          this.loading = true;
          this.mediaAttributeService.httpGetMediaAttributes();
        }
      }
    );

    this.mediaAttributeSubscription = this.mediaAttributeService.mediaAttributeChanged.subscribe(
      (mediaAttribute: MediaAttribute) => {
        if ( typeof mediaAttribute.ID !== 'undefined' ) {
          this.loading = false;
          this.mediaAttribute = mediaAttribute;

          this.initEditForm();
        }
      }
    );

    this.initForm();

    this.mediaAttributeService.httpGetMediaAttributes();
  }

  private initForm() {
    this.mediaAttributeForm = new FormGroup({
      'medium': new FormControl('', Validators.required),
      'orientation': new FormControl('', Validators.required),
      'size': new FormControl('', Validators.required),
      'type': new FormControl('px'),
      'custom_width': new FormControl(''),
      'custom_height': new FormControl(''),
      'custom_print': new FormControl(0),
      'custom_print_width': new FormControl(''),
      'custom_print_height': new FormControl(''),
      'zoom': new FormControl(0)
    });
  }

  private initEditForm() {
    const type = ( String(this.mediaAttribute.custom_width).includes('px') ? 'px' : 'mm' );
    this.mediaAttributeForm.patchValue({
      'medium': this.mediaAttribute.medium,
      'orientation': this.mediaAttribute.orientation,
      'size': this.mediaAttribute.size,
      'type': type,
      'custom_width': String(this.mediaAttribute.custom_width).replace('px', '').replace('mm', ''),
      'custom_height': String(this.mediaAttribute.custom_height).replace('px', '').replace('mm', ''),
      'custom_print': this.mediaAttribute.custom_print,
      'custom_print_width': String(this.mediaAttribute.custom_print_width).replace('px', '').replace('mm', ''),
      'custom_print_height': String(this.mediaAttribute.custom_print_height).replace('px', '').replace('mm', ''),
      'zoom': this.mediaAttribute.zoom
    });

    if ( this.mediaAttribute.size === 'custom' ) {
      this.updateValidation(true);
    } else {
      this.updateValidation(false);
    }
  }

  onSubmit() {
    if (this.mediaAttributeForm.valid) {
      this.loading = true;
      const mediaAttributeValues = this.mediaAttributeForm.value;

      mediaAttributeValues['slug'] = slugify((mediaAttributeValues['medium']
                                     + ' ' + mediaAttributeValues['orientation']
                                     + ' ' + mediaAttributeValues['size']).trim());

      if ( mediaAttributeValues['size'] === 'custom' ) {
        mediaAttributeValues['custom_width'] = mediaAttributeValues['custom_width'] + mediaAttributeValues['type'];
        mediaAttributeValues['custom_height'] = mediaAttributeValues['custom_height'] + mediaAttributeValues['type'];
        mediaAttributeValues['slug'] = slugify(mediaAttributeValues['slug'] + ' ' + mediaAttributeValues['custom_width'] + ' ' +
          mediaAttributeValues['custom_height']);
      }

      // tslint:disable-next-line:radix
      if ( parseInt(mediaAttributeValues['custom_print']) === 1 ) {
        mediaAttributeValues['custom_print_width'] = mediaAttributeValues['custom_print_width'] + 'mm';
        mediaAttributeValues['custom_print_height'] = mediaAttributeValues['custom_print_height'] + 'mm';
      } else {
        mediaAttributeValues['custom_print_width'] = '';
        mediaAttributeValues['custom_print_height'] = '';
      }

      if ( this.editMode ) {
        this.mediaAttributeService.httpPutMediaAttribute(this.ID, mediaAttributeValues);
      } else {
        this.mediaAttributeService.httpPostMediaAttribute(mediaAttributeValues);
      }

      setTimeout(
        () => {
          this.mediaAttributeForm.reset();
          this.newMediaAttributeModalClose.nativeElement.click();
        }, 200
      );
    }
  }

  onDelete(id: number) {
    this.loading = true;
    this.mediaAttributeService.httpDeleteMediaAttribute(id);
  }

  onChangeSize(val) {
    if ( val === 'custom' ) {
      this.updateValidation(true);
    } else {
      this.updateValidation(false);
    }
  }

  updateValidation(custom = false) {
    if ( custom ) {
      this.mediaAttributeForm.controls['type'].setValidators(Validators.required);
      this.mediaAttributeForm.controls['type'].updateValueAndValidity();

      this.mediaAttributeForm.controls['custom_width'].setValidators(Validators.required);
      this.mediaAttributeForm.controls['custom_width'].updateValueAndValidity();

      this.mediaAttributeForm.controls['custom_height'].setValidators(Validators.required);
      this.mediaAttributeForm.controls['custom_height'].updateValueAndValidity();
    } else {
      this.mediaAttributeForm.controls['type'].clearValidators();
      this.mediaAttributeForm.controls['type'].updateValueAndValidity();

      this.mediaAttributeForm.controls['custom_width'].clearValidators();
      this.mediaAttributeForm.controls['custom_width'].updateValueAndValidity();

      this.mediaAttributeForm.controls['custom_height'].clearValidators();
      this.mediaAttributeForm.controls['custom_height'].updateValueAndValidity();
    }
  }

  onEdit(id) {
    this.editMode = true;
    this.ID = id;
    this.mediaAttributeService.httpGetMediaAttribute(id);
    this.loading = true;
  }

  newMedia() {
    this.editMode = false;
    this.mediaAttributeForm.reset();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionResponse.unsubscribe();
  }

}
