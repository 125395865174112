import { Component, OnInit, OnDestroy } from '@angular/core';
import { Style } from '../../../models/style.model';
import { Menu } from '../../../models/menu.model';
import { AdminService } from '../../admin.service';
import { StyleService } from '../../../services/style.service';
import { Subscription } from 'rxjs/Subscription';

import Swal from 'sweetalert2';

import slugify from 'slugify';
import { FormGroup, FormControl } from '@angular/forms';
import { GroupService } from '../../../services/group.service';
import { Group } from '../../../models/group.model';

declare var $: any;

@Component({
  selector: 'app-style-dashboard',
  templateUrl: './style-dashboard.component.html',
  styleUrls: ['./style-dashboard.component.scss']
})
export class StyleDashboardComponent implements OnInit, OnDestroy {
  loading = false;
  menus: Menu[] = [];
  styles: Style[];
  subscription: Subscription;

  

  page = 1;

  dataTableHtml: any;
  selectGroupForm: FormGroup;

  groups: Group[];
  groupsSubscription: Subscription;

  constructor(private adminService: AdminService,
              private styleService: StyleService,
              private groupService: GroupService
            ) {}

  ngOnInit() {
    this.adminService.loadMenu(this.menus);
    this.groupService.httpGetGroups();

    if ( this.styleService.group_id !== 0 ) {
      this.loading = true;
      this.styleService.httpGetStyles(
        {
          group_id: this.styleService.group_id
        }
      );
    }

    this.subscription = this.styleService.stylesChanged.subscribe(
      (styles: any) => {
        if ( typeof styles !== 'undefined' && styles.status === 'success') {
          this.loading = false;
          this.styles = styles.data;

          if ($.fn.DataTable.isDataTable(this.dataTableHtml)) {
            this.dataTableHtml.destroy();
          }
          this.initDT();
        }
      }
    );

    this.groupsSubscription = this.groupService.groupsChanged.subscribe(
      (groups: any) => {
        this.groups = [];
        if ( groups.length > 0 ) {
          this.groups = groups;
        }
      }
    );

    this.initSelectGroupForm();
  }

  initSelectGroupForm() {
    this.selectGroupForm = new FormGroup({
      'group_id': new FormControl('')
    });

    if ( this.styleService.group_id !== 0 ) {
      this.selectGroupForm.patchValue({
        'group_id': this.styleService.group_id
      });
    }
  }

  slugTitle(title: string) {
    return slugify((title).trim());
  }

  initDT() {
    setTimeout(() => {
      this.dataTableHtml = $('#datatable-styles').DataTable({
        'pagingType': 'full_numbers',
        'lengthMenu': [
          [50, 100, 200, -1],
          [50, 100, 200, 'All']
        ],
        'pageLength': 50,
        // responsive: true,
        stateSave: true,
        language: {
          search: '_INPUT_',
          searchPlaceholder: 'Search',
          'paginate': {
            'first': '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
            'previous': '<i aria-hidden="true" class="fa fa-angle-left"></i>',
            'next': '<i aria-hidden="true" class="fa fa-angle-right"></i>',
            'last': '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
          }
        },
        'fnDrawCallback': function (oSettings) {
          $('.dataTables_wrapper').find('input[type=search]').addClass('btn-search');
          $('.dataTables_wrapper').find('select').addClass('select_datatable');
        }
      });

    }, 500);
  }

  onDelete(id: number, group) {
    const $this = this;
    Swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.styleService.httpDeleteStyle(id, group);
        $this.loading = true;
        Swal(
          'Deleted!',
          'Style successfully deleted.',
          'success'
        ).then((result1) => {
          if (result1) {
            if ( group !== 0 ) {
              this.styleService.httpGetStyles(
                {
                  group_id: this.styleService.group_id
                }
              );
            }else {
              this.styleService.httpGetStyles();
            }
          }
        });
      }
    }).catch(Swal.noop);
  }

  onChangeGroup(id) {
    this.styleService.group_id = id;

    this.loading = true;
    if ( id === '0' ) {
      this.styleService.httpGetStyles();
    } else {
      this.styleService.httpGetStyles({
          group_id: id
        }
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
