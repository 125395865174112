import { Title } from '@angular/platform-browser';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core';
import { FrontService } from '../../front.service';
import { Menu } from '../../../models/menu.model';
import { MasterService } from '../../../services/master.service';
import { Master } from '../../../models/master.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TemplateService } from '../../../services/template.service';
import { Template } from '../../../models/template.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Auth } from '../../../models/auth.model';

import * as moment from 'moment';
import { ClientService } from '../../../services/client.service';
import { Client } from '../../../models/client.model';
import { User } from '../../../models/user.model';
import { SubgroupService } from '../../../services/subgroup.service';
import { VaultService } from '../../../services/vault.service';
import { Subject } from 'rxjs';
import { CampaignService } from '../../../services/campaign.service';
import { GlobalService } from '../../../services/global.service';
import { DomainService } from '../../../services/domain.service';
import { MyDesktopService } from '../../../services/mydesktop.service';
import { CustomCategoryService } from '../../../services/customcategory.service';
import { PopupService } from '../../../services/popup.service';
import { UserService } from '../../../services/user.service';

import { SlickComponent } from 'ngx-slick';
import { QuickDesignService } from '../../../services/quick-deisgn.service';
import { ProgressService } from '../../../shared/progress-loader/progress.service';
import { FolderService } from '../../../services/folder.service';

import { DndDropEvent, DropEffect } from 'ngx-drag-drop';

declare var _bugHerd: any;

declare var jquery: any;
declare var $: any;
declare var swal: any;
declare var introJs: any;

@Component({
  selector: 'app-template-front-dashboard',
  templateUrl: './template-front-dashboard.component.html',
  styleUrls: ['./template-front-dashboard.component.scss'],
})
export class TemplateFrontDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  ID: number;
  loading = false;
  menus: Menu[] = [];

  master: any;
  masters: Master[] = [];
  master_id: number;

  template: Template;
  templates = [];
  template_count = 0;
  templateFilter;
  dateFilter;
  agentFilter;

  selected_share = '';

  results: any[] = [];
  type = '';

  campaigns = [];

  templateAddForm: FormGroup;
  templateUpdateForm: FormGroup;
  templateDescriptionForm: FormGroup;
  templateDuplicateForm: FormGroup;
  shareToClientForm: FormGroup;
  shareToUserForm: FormGroup;
  shareToSubgroupForm: FormGroup;

  pane_open = false;

  first_template_id = '';

  public edit_index: number;
  public edit_title_id: number;
  public edit_title_name: string;
  public edit_description: string;

  TEMPLATE_THUMB_S3_URL = '';
  excludedGroups = [5, 6, 11];

  listing: any;

  templateId = 0;

  duplicateTemplateTitle = '';

  @ViewChild('categoryContent', { static: true }) categoryContent: ElementRef;
  @ViewChild('newTemplate', { static: true }) newTemplate: ElementRef;
  @ViewChild('newTemplateModalClose', { static: true }) newTemplateModalClose: ElementRef;
  @ViewChild('previewModal', { static: true }) previewModal: ElementRef;
  @ViewChild('editTitleModalClose', { static: true }) editTitleModalClose: ElementRef;
  @ViewChild('editDescriptionModalClose', { static: true }) editDescriptionModalClose: ElementRef;
  @ViewChild('saveToCategoryModalClose', { static: true }) saveToCategoryModalClose: ElementRef;
  @ViewChild('editTitle', { static: true }) editTitle: ElementRef;
  @ViewChild('previewTour', { static: true }) previewTour: ElementRef;
  @ViewChild('duplicateTemplate', { static: true }) duplicateTemplate: ElementRef;
  @ViewChild('duplicateTemplateModalClose', { static: true }) duplicateTemplateModalClose: ElementRef;

  @ViewChild('shareToModalClose', { static: true }) shareToModalClose: ElementRef;
  @ViewChild('shareToUserModalClose', { static: false }) shareToUserModalClose: ElementRef;

  @ViewChild('customCategoryIcon', { static: true }) customCategoryIcon: ElementRef;
  @ViewChild('quickDesignGroupIcon', { static: true }) quickDesignGroupIcon: ElementRef;
  @ViewChild('quickDesignIcon', { static: true }) quickDesignIcon: ElementRef;
  @ViewChild('slickModal', { static: false }) slickModal: SlickComponent;
  @ViewChild('replaceQuickDesignIconInput', { static: true }) replaceQuickDesignIconInput: ElementRef;

  authUser: Auth;
  agentUsers = [];

  mdLink = '';

  categoryList = [];

  supportLink = '';
  shareListClients = [];
  shareListUsers = [];
  clientUsers = [];
  selectedSharedClient = [];
  selectSharedClient = [];
  selectedSharedUser = [];
  selectedUsers = [];
  selectedClients = [];
  selectedSubGroup = [];
  shared_template_id: number;

  subgroups: Client[];

  _tmp_template: any;

  offset = 0;
  is_archive = false;

  current_sort_by = 'created_DESC';
  current_template_filter = '';
  current_agent_filter = '';

  isCampaignDashboard = false;

  campaign: any;
  isList = false;

  campaignTemplateForm: FormGroup;
  customCategoryForm: FormGroup;

  customCategories = [];

  currentCategorySelected = '';
  popup: any;

  slideConfig = {
    dots: true,
    nextArrow: '<a href="#next" class="mouse smoothscroll"><span class="mouse-icon"><span class="mouse-wheel"></span></span><span class="bounce"><span class="mouse-arrow down"></span></span></a>',
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    adaptiveHeight: true,
  };

  emails = [];
  printables = [];
  socialmedias = [];
  signboards = [];

  wywfTemplates = [];
  pmTemplates = [];

  quickdesigns = [];
  duplicateFromQuickDesigns = false;

  quickDesignGroupForm: FormGroup;
  temporaryQuickDesignGroupData = {
    reference: null,
    category: null,
  };

  quickDesignGroupTemplates = [];

  selectedQuickDesignGroupTemplate = {
    ID: null,
    title: null,
    master: null,
    client_id: null,
    agent: null,
    modified: null,
    description: null,
    quickdesign_id: null
  };

  selectedGroupCategory = 'Emails';

  customCategory = {
    ID: null,
    title: null,
  };

  quickDesignGroup = {
    ID: null,
    title: null,
  };

  quickDesignForm: FormGroup;
  quickDesignGroups = [];
  quickDesignOptionLoaded = false;

  replaceQuickDesignIconType: string;
  replaceQuickDesignIconID: number;

  templateTitleSearchInput: FormControl;
  currentPageType = 'dashboard';
  extraPages = ['group', 'client', 'subgroup', 'archived', 'favourite', 'quickdesign'];
  pageTitle = 'Your Designs';
  searchKeyword = '';
  folder: any;
  folders = [];
  addFolderForm: FormGroup;
  moveFolderForm: FormGroup;

  is_reorder = false;
  loadedMasterTemplates: Template[] = [];
  loadedMasterTemplatesCount = 0;

  constructor(
    public frontService: FrontService,
    private clientService: ClientService,
    private masterService: MasterService,
    private templateService: TemplateService,
    private subgroupService: SubgroupService,
    private router: Router,
    private vaultService: VaultService,
    private campaignService: CampaignService,
    public globalService: GlobalService,
    private changeDetector: ChangeDetectorRef,
    private domainService: DomainService,
    private mydesktopService: MyDesktopService,
    private customCategoryService: CustomCategoryService,
    private popupService: PopupService,
    private userService: UserService,
    private quickDesignService: QuickDesignService,
    private progressService: ProgressService,
    private route: ActivatedRoute,
    private folderService: FolderService
  ) {
    this.templateTitleSearchInput = new FormControl('');

    const $this = this;
    let selectedColor = '';
    this.authUser = this.frontService.authService.auth;

    if (this.authUser.brandid === '6') {
      selectedColor = '-orange';
    }

    if (this.authUser.provider !== 'domain' && this.authUser.provider !== 'designly' && this.authUser.provider !== 'idashboard') {
      this.categoryList.push({
        id: [1, 2, 3, 7, 11, 13],
        slug: 'enewsletter',
        name: 'eMarketing',
        icon: `assets/img/temp1${selectedColor}.svg`,
        masters: [],
      });
    }

    if (this.authUser.provider === 'domain' && this.authUser.client.crm_access_enabled) {
      this.categoryList.push({
        id: [1, 2, 3, 7, 11],
        slug: 'enewsletter',
        name: 'eMarketing',
        icon: `assets/img/temp1${selectedColor}.svg`,
        masters: [],
      });
    }

    if (this.authUser.provider === 'designly') {
      this.categoryList.push({
        id: [1],
        slug: 'enewsletter',
        name: 'eMarketing',
        icon: `assets/img/temp1${selectedColor}.svg`,
        masters: [],
      });
    }

    if (this.authUser.provider !== 'designly') {
      this.categoryList.push(
        {
          id: [4, 8, 14],
          slug: 'brochure',
          name: 'Brochures / Window Cards / DL’s',
          icon: `assets/img/brochure${selectedColor}.svg`,
          masters: [],
        },
        {
          id: [5, 10],
          slug: 'property-guide',
          name: 'Property Guide / MyGuide / Stocklist',
          icon: 'assets/img/temp7.svg',
          masters: [],
        },
        {
          id: 6,
          slug: 'social-media',
          name: 'Social Media',
          icon: 'assets/img/social-media.svg',
          masters: [],
        },
        {
          id: 9,
          slug: 'sign-board',
          name: 'Signboard',
          icon: 'assets/img/signboard.svg',
          masters: [],
        }
      );

      window['onClickSend'] = function (link, val) {
        $this.onClickSend(link, val);
      };
    }
  }

  @ViewChild('propertySearchModalClose', { static: true }) mdPropSearchModalClose: ElementRef;
  @Input() page: string;
  ngOnInit() {
    this.supportLink = this.frontService.supportLink;
    this.shareListUsers = this.authUser.client.users;

    this.quickDesignService.httpGetAll();
    this.customCategoryService.httpGetAll();

    this.route.params.subscribe((params: Params) => {
      const currentPageType = typeof params.type !== 'undefined' ? params.type : 'dashboard';
      this.currentPageType = currentPageType;

      if (this.isUuid(currentPageType)) {
        this.extraPages.push(currentPageType);

        this.folderService.httpGet(this.currentPageType);
      }

      switch (this.currentPageType) {
        case 'group':
          this.pageTitle = 'Group Shared Templates';
          break;
        case 'client':
          this.pageTitle = 'Office Shared Templates';
          break;
        case 'subgroup':
          this.pageTitle = 'Subgroup Shared Templates';
          break;
        case 'archived':
          this.pageTitle = 'Archived Templates';
          break;
        case 'quickdesign':
          this.pageTitle = 'Quick Design Templates';
          break;
        case 'favourite':
          this.pageTitle = 'Favourite Templates';
          break;

        default:
          this.pageTitle = 'Your Designs';
          break;
      }
    });

    if (this.frontService.authService.getApp() === 'Designly' && !this.extraPages.includes(this.currentPageType)) {
      this.isList = true;
    }

    this.current_agent_filter = this.authUser.agentid;
    this.templateTitleSearchInput.setValue('', {
      emitEvent: false,
    });

    try {
      const cachedFilters = localStorage.getItem(`${this.currentPageType}-filter`);
      const parsedCachedFilters = JSON.parse(cachedFilters);

      if (parsedCachedFilters && typeof parsedCachedFilters.template !== 'undefined') {
        this.current_agent_filter = parsedCachedFilters.agent;
        this.current_sort_by = parsedCachedFilters.sort;
        this.current_template_filter = parsedCachedFilters.template;
        this.templateTitleSearchInput.setValue(parsedCachedFilters.keyword, {
          emitEvent: false,
        });
        this.searchKeyword = parsedCachedFilters.keyword;
      }
    } catch (e) {
      console.error(e);
    }

    if (this.page === 'campaign') {
      this.isCampaignDashboard = true;
      this.isList = false;

      this.currentPageType = 'dashboard';
    } else {
      localStorage.removeItem('backUrl');
      this.campaignService.backUrl = null;
    }

    if (this.authUser.is_master) {
      this.selected_share = 'offices';
    } else if (this.authUser.is_subgroup) {
      this.selected_share = 'subgroup';
    } else {
      this.selected_share = 'agent';
    }

    if (this.authUser.is_subgroup) {
      this.clientService.httpGetClientsOfSubGroupID(
        {
          subgroup_id: this.authUser.client.subgroup_id,
        },
        'list'
      );
      // this.subgroupService.httpGetSubgroups({
      //   'ID': this.authUser.client.subgroup_id,
      //   is_account_active: '1'
      // });
    }

    if (this.authUser.can_share && this.authUser.is_master) {
      this.clientService.httpGetClientsOfGroupID(
        {
          group_id: this.authUser.client.group_id,
          is_account_active: '1',
          provider: this.authUser.provider,
        },
        'list'
      );
    }

    if (typeof this.authUser.user.hide_popup !== 'undefined' && !this.authUser.user.hide_popup) {
      this.popupService.httpGetLatest();
    }

    this.popupService.onGetLatest.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          if (typeof response.data.image !== 'undefined') {
            this.popup = response.data;

            setTimeout(() => {
              $('#popupModal').modal('show');
            }, 1000);
          }
        }
      }
    });

    this.domainService.onGetDomainListing.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        this.loading = false;
        this.listing = response.data;

        let campaignTemplateQuery = {};

        if (this.campaignService.isCustom) {
          campaignTemplateQuery = {
            campaign_id: this.campaignService.campaignId,
            category: `custom_${this.campaignService.campaignId}`,
          };
          // tslint:disable-next-line:max-line-length
          this.campaignService.backUrl = `/campaigns/${this.campaignService.campaignId}/${this.listing.id}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`;
          this.listing['campaign_id'] = this.campaignService.campaignId;
        } else {
          campaignTemplateQuery = {
            campaign_id: this.listing.campaign_id,
            category: this.listing.campaign_category,
          };
          // tslint:disable-next-line:max-line-length
          this.campaignService.backUrl = `/campaigns/listing/${this.listing.id}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`;
        }

        this.templateService.httpGetTemplates(campaignTemplateQuery, 'dashboard', this.current_sort_by);
      }
    });

    this.mydesktopService.myDesktopGetNewListing.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        this.loading = false;
        this.listing = response.data;

        let campaignTemplateQuery = {};

        if (this.campaignService.isCustom) {
          campaignTemplateQuery = {
            campaign_id: this.campaignService.campaignId,
            category: `custom_${this.campaignService.campaignId}`,
          };
          // tslint:disable-next-line:max-line-length
          this.campaignService.backUrl = `/campaigns/${this.campaignService.campaignId}/${this.listing.id}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`;
          this.listing['campaign_id'] = this.campaignService.campaignId;
        } else {
          campaignTemplateQuery = {
            campaign_id: this.listing.campaign_id,
            category: this.listing.campaign_category,
          };
          // tslint:disable-next-line:max-line-length
          this.campaignService.backUrl = `/campaigns/listing/${this.listing.id}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`;
        }

        this.templateService.httpGetTemplates(campaignTemplateQuery, 'dashboard', this.current_sort_by);
      }
    });

    this.vaultService.onVaultGetListing.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        this.loading = false;
        this.listing = response.data;

        let campaignTemplateQuery = {};

        if (this.campaignService.isCustom) {
          campaignTemplateQuery = {
            campaign_id: this.campaignService.campaignId,
            category: `custom_${this.campaignService.campaignId}`,
          };
          // tslint:disable-next-line:max-line-length
          this.campaignService.backUrl = `/campaigns/${this.campaignService.campaignId}/${this.listing.id}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`;
          this.listing['campaign_id'] = this.campaignService.campaignId;
        } else {
          campaignTemplateQuery = {
            campaign_id: this.listing.campaign_id,
            category: this.listing.campaign_category,
          };
          // tslint:disable-next-line:max-line-length
          this.campaignService.backUrl = `/campaigns/listing/${this.listing.id}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`;
        }

        this.templateService.httpGetTemplates(campaignTemplateQuery, 'dashboard', this.current_sort_by);
      }
      if (typeof response !== 'undefined' && response.status === 'error') {
        this.loading = false;
        swal('Vault API Error', response.message, 'error');
      }
    });

    this.clientService.ClientsOfSubGroupID.takeUntil(this.destroy$).subscribe((subgroups: Client[]) => {
      if (typeof subgroups !== 'undefined' && subgroups.length > 0) {
        this.subgroups = subgroups;
      }
    });

    this.clientService.ClientsOfGroupID.takeUntil(this.destroy$).subscribe((clients: Client[]) => {
      this.shareListClients = clients;
    });

    const tempAgentUsers = this.shareListUsers;
    this.agentUsers = tempAgentUsers.sort(function (a, b) {
      const x = a.firstname.toLowerCase();
      const y = b.firstname.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });

    this.loading = true;
    this.frontService.loadMenu(this.menus);

    for (const category of this.categoryList) {
      if (category.id === 0) {
        this.masterService.httpGetMasters(
          {
            department_id: 22,
            group_id: this.authUser.client.group_id,
            is_active: this.authUser.is_master ? '1,3' : '1',
            client_id: [0, this.authUser.client.ID],
            subgroup_id: this.authUser.client.subgroup_id,
            provider: ['all', this.authUser.provider],
          },
          '',
          '',
          '',
          category.slug
        );
      } else {
        this.masterService.httpGetMasters(
          {
            category_id: category.id,
            group_id: this.authUser.client.group_id,
            is_active: this.authUser.is_master ? '1,3' : '1',
            client_id: [0, this.authUser.client.ID],
            subgroup_id: this.authUser.client.subgroup_id,
            provider: ['all', this.authUser.provider],
          },
          '',
          '',
          '',
          category.slug
        );
      }
    }

    this.masterService.mastersChanged.takeUntil(this.destroy$).subscribe((masters: Master[]) => {
      if (typeof masters !== 'undefined' && masters.length > 0) {
        this.masters = masters;
      }
    });

    this.masterService.mastersCategoryBeyondTheBricks.takeUntil(this.destroy$).subscribe((masters: Master[]) => {
      if (typeof masters !== 'undefined' && masters.length > 0) {
        const masterBeyondTheBricksIndex = this.categoryList.findIndex((g) => g.slug === 'beyond-the-bricks');

        this.categoryList[masterBeyondTheBricksIndex].masters = masters;
        $('#intro-beyond-the-bricks').css('display', 'table');
      }
    });

    this.masterService.mastersCategoryComingSoon.takeUntil(this.destroy$).subscribe((masters: Master[]) => {
      if (typeof masters !== 'undefined' && masters.length > 0) {
        const masterComingSoonIndex = this.categoryList.findIndex((g) => g.slug === 'soon');

        this.categoryList[masterComingSoonIndex].masters = masters;
      }
    });

    this.masterService.mastersCategoryEMarketing.takeUntil(this.destroy$).subscribe((masters: Master[]) => {
      if (typeof masters !== 'undefined' && masters.length > 0) {
        if (this.authUser.client.group.ID === 5 || this.authUser.client.group.ID === 11) {
          const wywf = masters.filter((element) => element.department.ID === 28 || element.department.ID === 29);
          this.wywfTemplates = [...this.wywfTemplates, ...wywf];

          const pm = masters.filter((element) => element.department.ID === 19);
          this.pmTemplates = [...this.pmTemplates, ...pm];

          masters = masters.filter((element) => element.department.ID !== 28 && element.department.ID !== 29 && element.department.ID !== 19);
        }

        this.emails = masters;
      }
    });

    this.masterService.mastersCategoryBrochure.takeUntil(this.destroy$).subscribe((masters: Master[]) => {
      if (typeof masters !== 'undefined' && masters.length > 0) {
        if (this.authUser.client.group.ID === 5 || this.authUser.client.group.ID === 11) {
          const wywf = masters.filter((element) => element.department.ID === 28 || element.department.ID === 29);
          this.wywfTemplates = [...this.wywfTemplates, ...wywf];

          const pm = masters.filter((element) => element.department.ID === 19);
          this.pmTemplates = [...this.pmTemplates, ...pm];

          masters = masters.filter((element) => element.department.ID !== 28 && element.department.ID !== 29 && element.department.ID !== 19);
        }

        this.printables = [...masters, ...this.printables];
      }
    });

    this.masterService.mastersCategoryPropertyGuide.takeUntil(this.destroy$).subscribe((masters: Master[]) => {
      if (typeof masters !== 'undefined' && masters.length > 0) {
        if (this.authUser.client.group.ID === 5 || this.authUser.client.group.ID === 11) {
          const wywf = masters.filter((element) => element.department.ID === 28 || element.department.ID === 29);
          this.wywfTemplates = [...this.wywfTemplates, ...wywf];

          const pm = masters.filter((element) => element.department.ID === 19);
          this.pmTemplates = [...this.pmTemplates, ...pm];

          masters = masters.filter((element) => element.department.ID !== 28 && element.department.ID !== 29 && element.department.ID !== 19);
        }

        this.printables = [...masters, ...this.printables];
      }
    });

    this.masterService.mastersCategorySocialMedia.takeUntil(this.destroy$).subscribe((masters: Master[]) => {
      if (typeof masters !== 'undefined' && masters.length > 0) {
        if (this.authUser.client.group.ID === 5 || this.authUser.client.group.ID === 11) {
          const wywf = masters.filter((element) => element.department.ID === 28 || element.department.ID === 29);
          this.wywfTemplates = [...this.wywfTemplates, ...wywf];

          const pm = masters.filter((element) => element.department.ID === 19);
          this.pmTemplates = [...this.pmTemplates, ...pm];

          masters = masters.filter((element) => element.department.ID !== 28 && element.department.ID !== 29 && element.department.ID !== 19);
        }

        this.socialmedias = [...masters, ...this.socialmedias];
      }
    });

    this.masterService.mastersCategorySignboard.takeUntil(this.destroy$).subscribe((masters: Master[]) => {
      if (typeof masters !== 'undefined' && masters.length > 0) {
        if (this.authUser.client.group.ID === 5 || this.authUser.client.group.ID === 11) {
          const wywf = masters.filter((element) => element.department.ID === 28 || element.department.ID === 29);
          this.wywfTemplates = [...this.wywfTemplates, ...wywf];

          const pm = masters.filter((element) => element.department.ID === 19);
          this.pmTemplates = [...this.pmTemplates, ...pm];

          masters = masters.filter((element) => element.department.ID !== 28 && element.department.ID !== 29 && element.department.ID !== 19);
        }

        this.signboards = [...masters, ...this.signboards];
      }
    });

    this.templateService.templateShare.takeUntil(this.destroy$).subscribe((template: any) => {
      if (typeof template !== 'undefined' && template.status === 'success') {
        this.loading = false;
        swal({
          title: 'Success',
          text: 'Template Sharing preferences updated. Page will automatically reload in few seconds',
          type: 'success',
          showConfirmButton: false,
          confirmButtonText: 'OK',
        });
        setTimeout(() => {
          window.location.href = '/templates';
        }, 3000);
      }
    });

    if (!this.isCampaignDashboard) {
      this.queryTemplates();
    }

    this.templateService.templateDeletion
    .takeUntil(this.destroy$)
    .subscribe(
      (template: Template) => {
        if ( typeof template.ID !== 'undefined' ) {
          this.loading = false;
          this.quickDesignService.httpGetAll();
          this.template_count = this.template_count - 1;

        $(`.media-list ul li[data-template="${template.ID}"]`).fadeOut('normal', function () {
          $(this).remove();
        });

        // $(`.template-${template.ID}`).css('display', 'block');
        // $(`.actions-${template.ID}`).html('<strong>Deleted</strong>');
      }
    });

    this.templateService.templateDuplicate.takeUntil(this.destroy$).subscribe((template: Template) => {
      if (typeof template.ID !== 'undefined') {
        if (this.duplicateFromQuickDesigns) {
          if (this.page === 'campaign') {
            if (this.campaignService.isCustom) {
              this.campaignService.backUrl = `/campaigns/${this.campaignService.campaignId}/${this.listing.id}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`;
            } else {
              this.campaignService.backUrl = `/campaigns/listing/${this.listing.id}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`;
            }
          }

          if (template.category_id === 14) {
            return this.router.navigateByUrl(`/templates/${template.ID}/build?pricefinder=new`);
          }

          // Accomodate Replica Lag
          setTimeout(() => {
            this.router.navigate([`/templates/${template.ID}/build`]);
          }, 2000);
        } else {
          this.offset = 0;
          this.queryTemplates();
          this.changeDetector.detectChanges();
        }
      }
    });

    this.templateService.templateGetAll.takeUntil(this.destroy$).subscribe((templates: any) => {
      if (typeof templates !== 'undefined' && templates.status === 'success') {
        this.loading = false;
        if (this.offset === 0) {
          this.templates = templates.data;
        } else {
          this.templates = this.templates.concat(templates.data);
        }

        this.template_count = templates.total_count;
        this.templateFilter = 'all';
        this.dateFilter = 'recent';
        this.agentFilter = 'all';

        if (this.frontService.authService.auth.first === '1') {
          setTimeout(() => {
            this.startTour();
            this.frontService.authService.updateAuthCookieField('first', '0');

            // set settings, builder tour to 1
            localStorage.setItem('first_builder', '1');
            localStorage.setItem('first_settings', '1');
            this.loading = false;
          }, 500);
        }

        this.changeDetector.detectChanges();
      }
    });

    this.templateService.templateOperation.takeUntil(this.destroy$).subscribe((template: Template) => {
      if (typeof template.ID !== 'undefined') {
        this.loading = false;

        swal('Updated!', 'Template has been updated.', 'success');

        if (this.is_archive) {
          this.is_archive = false;
          $('.template-' + template.ID).css('display', 'block');

          const statusText = this.currentPageType === 'archived' ? 'Activated' : 'Archived';

          $(`.disabled-text-${template.ID}`).text(statusText);
          $(`.actions-${template.ID}`).html(`<strong>${statusText}</strong>`);
        } else {
          this.offset = 0;

          this.queryTemplates();
          this.quickDesignService.httpGetAll();
        }

        this.changeDetector.detectChanges();
      }
    });

    this.templateService.templateCreation.takeUntil(this.destroy$).subscribe((template: Template) => {
      if (typeof template.ID !== 'undefined') {
        if (this.page === 'campaign') {
          if (this.campaignService.isCustom) {
            this.campaignService.backUrl = `/campaigns/${this.campaignService.campaignId}/${this.listing.id}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`;
          } else {
            this.campaignService.backUrl = `/campaigns/listing/${this.listing.id}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`;
          }
        }

        // Accomodate Replica Lag
        setTimeout(() => {
          this.loading = false;
          this.router.navigate([`/templates/${template.ID}/build`]);
        }, 2000);
      }
    });

    this.vaultService.onVaultSendTemplate.takeUntil(this.destroy$).subscribe((vault: any) => {
      if (typeof vault !== 'undefined' && vault.status === 'success') {
        this.loading = false;
        swal({
          title: 'Template has been added/saved to your VaultRE account',
          type: 'success',
          html:
            // tslint:disable-next-line:max-line-length
            '<a target="_blank" href="https://login.vaultre.com.au/cgi-bin/clientvault/emarketing/bulk.cgi?messagetype=designly&designlytemplateid=' + vault.data.id + '" class="btn btn-primary">View</a>',
          showCancelButton: false,
          showConfirmButton: false,
          focusConfirm: false,
        });

        this.changeDetector.detectChanges();
      }
    });

    this.globalService.onSelectListing.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        this.loading = true;
        this.mdPropSearchModalClose.nativeElement.click();
        this.changeDetector.detectChanges();
      }
    });

    this.campaignService.campaignBuildTemplates.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        window.location.reload();

        this.loading = false;
        this.changeDetector.detectChanges();
      }
    });

    this.campaignService.campaignExport.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        this.loading = false;

        swal('Success', 'You can now download the zip file', 'success');

        const link = document.createElement('a');
        link.download = `${this.listing.id}.zip`;
        link.href = response.file;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.remove();

        this.changeDetector.detectChanges();
      }
      if (typeof response !== 'undefined' && response.status === 'okempty') {
        this.loading = false;
        swal('Unable to export campaign', 'Make sure you have generated this campaign', 'warning');
      }
    });

    this.campaignService.campaignOnGenerateFinish.takeUntil(this.destroy$).subscribe((response: boolean) => {
      if (response) {
        window.location.reload();

        this.loading = false;
        this.changeDetector.detectChanges();
      }
      if (!response) {
        swal('Unable to generate campaign', 'Make sure you have saved templates to this campaign', 'warning');
        this.loading = false;
      }
    });

    this.campaignService.campaignRemoveListingFromCampaign.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        $(`.template-${this.ID}`).css('display', 'block');
        $(`.disabled-text-${this.ID}`).text('Removed');
        $(`.actions-${this.ID}`).html('<strong>Removed</strong>');

        this.loading = false;
      }
    });

    this.campaignService.campaignGetAll.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        this.loading = false;
        this.campaigns = response.data;
        $('#addTemplate').modal('show');
      }
    });

    this.campaignService.campaignPostTemplate.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        this.loading = false;
        swal('Saved to Selected Categories', '', 'success');
        this.campaignTemplateForm.reset();
        $('#addTemplate').modal('hide');
      }
    });

    this.templateService.templateRefresh.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        this.loading = false;
        window.location.reload();
      }
    });

    this.templateService
    .templateLoadMasterTemplates
    .takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined' && response.status === 'success') {
        this.loadedMasterTemplates = response.data;
        this.loading = false;

        if (this.loadedMasterTemplates.length) {
          this.progressService.initProgress({
            type: 'masters'
          });

          this.loadedMasterTemplates.forEach(template => {
            const templateValues = {
              html_raw: this.globalService.fillMergeText(this.authUser, template.html_raw, 'subtitute', 'template', {}),
              masterUpdate: 1
            } as any;

            this.templateService.httpPutTemplateNew(template.ID, templateValues);
          });
        } else {
          swal('All Master Templates are already loaded in your account', '', 'success');
        }
      }

      if (typeof response !== 'undefined' && response.status === 'notemplates') {
        this.loading = false;
        swal('Looks like there are no Master Templates available', '', 'warning');
      }

      if (typeof response !== 'undefined' && response.status === 'error') {
        this.loading = false;
        swal('Error', 'Contact Designly', 'error');
      }
    });

    this.customCategoryService.onAdd.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        this.loading = false;

        if (typeof response !== 'undefined' && response.status === 'success') {
          $('#newCustomCategory').hide();
          this.customCategoryForm.reset();
          this.customCategoryIcon.nativeElement.value = '';

          swal('Custom Category Added', 'Please wait while we reload the page', 'success');
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Error', response.message, 'error');
        }
      }
    });

    this.customCategoryService.onGetAll.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.customCategories = response.data;
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Error loading custom categories', 'Contact Designly', 'error');
        }
      }
    });

    this.customCategoryService.onDuplicateTemplate.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.router.navigate(['/templates/' + response.data.ID + '/build']);
          // this.router.navigateByUrl() `/templates/${response.data.ID}/build`;

          if (this.page === 'campaign') {
            if (this.campaignService.isCustom) {
              this.campaignService.backUrl = `/campaigns/${this.campaignService.campaignId}/${this.listing.id}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`;
            } else {
              this.campaignService.backUrl = `/campaigns/listing/${this.listing.id}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`;
            }
          }

          this.changeDetector.detectChanges();
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Error creating template from custom categories', 'Contact Designly', 'error');
        }
      }
    });

    this.customCategoryService.onDelete.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          swal('Custom Category deleted', 'Please wait while we reload the page', 'success');
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Error deleting custom category', 'Contact Designly', 'error');
        }
      }
    });

    this.quickDesignService.onGetAll.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.quickdesigns = response.data;

          this.loading = false;
        }
      }
    });

    this.quickDesignService.onDelete.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.quickDesignService.httpGetAll();
          this.queryTemplates();
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Error deleting quick design', 'Contact Designly', 'error');
          this.loading = false;
        }
      }
    });

    this.quickDesignService.onAddGroup.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.quickDesignService.httpGetAll();
          swal('Quick Design Folder created', '', 'success');
          $('#createQuickDesignGroup').modal('hide');

          this.quickDesignGroupIcon.nativeElement.value = '';
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Error processing', 'Contact Designly', 'error');
          this.loading = false;
          if ( typeof response !== 'undefined' && response.status === 'success' ) {
            this.quickDesignGroupTemplates = response.data;

            if (this.quickDesignGroupTemplates.length > 0) {
              $('#createTemplate').modal('show');

              this.selectedQuickDesignGroupTemplate = {
                ID: this.quickDesignGroupTemplates[0]['ID'],
                title: this.quickDesignGroupTemplates[0]['title'],
                master: this.quickDesignGroupTemplates[0]['master'],
                client_id: this.quickDesignGroupTemplates[0]['client_id'],
                agent: this.quickDesignGroupTemplates[0]['created_by'],
                modified: this.quickDesignGroupTemplates[0]['updated_at'],
                description: this.quickDesignGroupTemplates[0]['description'],
                quickdesign_id: this.quickDesignGroupTemplates[0]['quickdesign_id']
              };

              setTimeout(() => {
                this.slickModal.slickGoTo(0);
              }, 100);
            } else {
              swal('No templates found inside the folder', '', 'error');
            }
          }
          if ( typeof response !== 'undefined' && response.status === 'error' ) {
            swal('Internal Server Error', 'Contact Designly', 'error');
          }
        }
      }
    });

    this.quickDesignService.onGetGroupTemplates.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        this.loading = false;
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.quickDesignGroupTemplates = response.data;

          if (this.quickDesignGroupTemplates.length > 0) {
            $('#createTemplate').modal('show');

            this.selectedQuickDesignGroupTemplate = {
              ID: this.quickDesignGroupTemplates[0]['ID'],
              title: this.quickDesignGroupTemplates[0]['title'],
              master: this.quickDesignGroupTemplates[0]['master'],
              client_id: this.quickDesignGroupTemplates[0]['client_id'],
              agent: this.quickDesignGroupTemplates[0]['created_by'],
              modified: this.quickDesignGroupTemplates[0]['updated_at'],
              description: this.quickDesignGroupTemplates[0]['description'],
              quickdesign_id: this.quickDesignGroupTemplates[0]['quickdesign_id']
            };

            setTimeout(() => {
              this.slickModal.slickGoTo(0);
            }, 100);
          } else {
            swal('No templates found inside the folder', '', 'error');
          }
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Internal Server Error', 'Contact Designly', 'error');
        }
      }
    });

    this.customCategoryService.onPut.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        this.loading = false;
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.quickDesignService.httpGetAll();
          swal('Custom Category Updated', '', 'success');

          this.customCategory = {
            ID: null,
            title: null,
          };
          this.customCategoryForm.reset();

          $('#newCustomCategory').modal('hide');
          this.customCategoryIcon.nativeElement.value = '';
        }

        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Internal Server Error', 'Contact Designly', 'error');
        }
      }
    });

    this.quickDesignService.onUpdateGroup.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          swal('Folder Updated', '', 'success');
          this.quickDesignService.httpGetAll();
          $('#createQuickDesignGroup').modal('hide');

          this.quickDesignGroupIcon.nativeElement.value = '';
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          this.loading = false;
          swal(response.message, 'Contact Designly', 'error');
        }
      }
    });

    this.quickDesignService.onDeleteGroup.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          swal('Folder Deleted', '', 'success');
          this.quickDesignService.httpGetAll();
          $('#createQuickDesignGroup').modal('hide');
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          this.loading = false;
          swal(response.message, 'Contact Designly', 'error');
        }
      }
    });

    this.quickDesignService.onGetGroupsByCategory.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        this.loading = false;
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.quickDesignGroups = response.data;
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal('Internal Server Error', 'Contact Designly', 'error');
        }
      }
    });

    this.quickDesignService.onAdd.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          swal('Design saved as Quick Design', 'This design can now be used within the <b>Use Template</b> section, also this design has been moved into the Quick Design template folder for further use.', 'success');
          $('#saveToCategory').modal('hide');

          const templateIndex = this.templates.findIndex((g) => g.ID === this.template.ID);
          this.templates[templateIndex].quickdesign = response.data;

          this.quickDesignForm.reset();
          this.quickDesignIcon.nativeElement.value = '';

          this.quickDesignService.httpGetAll();
          this.queryTemplates();
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          this.loading = false;
          swal('Error', response.message, 'error');
        }
      }
    });

    this.customCategoryService.onShare.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          swal('Category sharing preference updated', '', 'success');
          this.quickDesignService.httpGetAll();
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          this.loading = false;
          swal('Error', response.message, 'error');
        }
      }
    });

    this.quickDesignService.onReplaceIcon.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          swal('Icon replaced successfully', '', 'success');
          this.quickDesignService.httpGetAll();
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          this.loading = false;
          swal('Error replacing icon', '', 'error');
        }
      }
    });

    this.folderService.onFolderGet.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined') {
        if (response.status === 'success') {
          this.folder = response.data;

          this.pageTitle = `${this.folder.name} Templates`;
        }
        if (response.status === 'error') {
          swal('There is a problem getting the selected Folder', 'Contact Designly', 'error');
        }
      }
    });

    this.folderService.onFolderGetAll.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined') {
        this.loading = false;
        if (response.status === 'success') {
          this.folders = response.data;
        }
        if (response.status === 'error') {
          swal('There is a problem getting your folders', 'Contact Designly', 'error');
        }
      }
    });

    this.folderService.onFolderAddTemplate.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined') {
        this.loading = false;
        if (response.status === 'success') {
          swal('Template added to folder successfully', '', 'success');
          this.queryTemplates();
          $('#addFolder').modal('hide');
          this.addFolderForm.reset();
        }
        if (response.status === 'error') {
          swal('There is a problem adding template to your folder', 'Contact Designly', 'error');
        }
      }
    });

    this.folderService.onFolderMoveTemplate.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined') {
        this.loading = false;
        if (response.status === 'success') {
          swal('Template move to folder successfully', '', 'success');
          this.queryTemplates();
          $('#moveFolder').modal('hide');
          this.moveFolderForm.reset();
        }
        if (response.status === 'error') {
          swal('There is a problem adding template to your folder', 'Contact Designly', 'error');
        }
      }
    });

    this.folderService.onFolderDeleteTemplate.takeUntil(this.destroy$).subscribe((response: any) => {
      if (typeof response !== 'undefined') {
        this.loading = false;
        if (response.status === 'success') {
          swal('Template removed from folder successfully', '', 'success');

          this.queryTemplates();
          this.loading = true;
        }
        if (response.status === 'error') {
          swal('There is a problem removing template from your folder', 'Contact Designly', 'error');
        }
      }
    });

    this.templateTitleSearchInput.valueChanges
      .takeUntil(this.destroy$)
      .debounceTime(500)
      .subscribe((searchText) => {
        if (typeof searchText !== 'undefined') {
          this.offset = 0;
          this.searchKeyword = searchText;

          this.cacheFilters();
          this.queryTemplates();
        }
      });

    this.quickDesignService.onPostSort.takeUntil(this.destroy$).subscribe((response: any) => {
      if (response && typeof response !== 'undefined') {
        if (typeof response.status !== 'undefined' && response.status === 'success') {
          this.loading = false;
        }
      }
    });

    this.TEMPLATE_THUMB_S3_URL = this.frontService.appConfig.S3_URL + 'templates/thumbnails/';
    this.initForm();

    $(document).on('shown.bs.dropdown', '.dropup', function () {
      const offsetTop = $(this).offset().top + $(this).height() - $(window).scrollTop();
      const offsetBottom = $(window).height() - $(this).height() - $(this).offset().top + $(window).scrollTop();
      const ulHeight = $(this).find('ul').height();

      if (offsetBottom < ulHeight && offsetTop > ulHeight) {
        $(this).addClass('dropup');
      } else {
        $(this).removeClass('dropup');
      }
    });

    this.clientService.clientsChanged.takeUntil(this.destroy$).subscribe((clients: Client[]) => {
      this.shareListClients = clients;
    });

    this.quickDesignService.onUpdateDescription
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response && typeof response !== 'undefined') {
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.loading = false;

            swal(
              'Updated!',
              'QuickDesign description has been updated.',
              'success'
            );
            this.quickDesignService.httpGetAll();
          }
        }
      }
    );

    this.userService.usersChanged
      .takeUntil(this.destroy$)
      .subscribe(
        (response: any) => {
          if (typeof response !== 'undefined' && response.status === 'success') {
            this.clientUsers = response.data;
            $('#client' + response.data[0].client_id).removeClass('hidden');
            this.loading = false;
          }
        }
    );

    this.templateService.onPutTemplate
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (typeof response.masterUpdate !== 'undefined' && response.masterUpdate) {
              this.loadedMasterTemplatesCount += 1;
              if (response.status === 'success') {
                this.progressService.progress(this.loadedMasterTemplatesCount / this.loadedMasterTemplates.length);
              }

              if (response.status === 'error') {
                this.progressService.progress(this.loadedMasterTemplatesCount / this.loadedMasterTemplates.length);
              }

              if (this.loadedMasterTemplatesCount === this.loadedMasterTemplates.length) {
                window.location.reload();
              }
            }
          }
        }
      }
    );
  }

  private initForm() {
    this.templateAddForm = new FormGroup({
      title: new FormControl('', Validators.required),
      user_agent_id: new FormControl(this.authUser.agentid, Validators.required),
    });

    this.templateDuplicateForm = new FormGroup({
      ID: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      user_agent_id: new FormControl(this.authUser.agentid, Validators.required),
    });

    this.templateUpdateForm = new FormGroup({
      ID: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
    });

    this.templateDescriptionForm = new FormGroup({
      'ID': new FormControl('', Validators.required),
      'description': new FormControl('')
    });

    this.shareToClientForm = new FormGroup({
      ID: new FormControl('', Validators.required),
      client_id: new FormControl('', Validators.required),
    });

    this.shareToUserForm = new FormGroup({
      ID: new FormControl('', Validators.required),
      user_id: new FormControl('', Validators.required),
    });

    this.shareToSubgroupForm = new FormGroup({
      ID: new FormControl('', Validators.required),
      subgroup_id: new FormControl('', Validators.required),
    });

    this.campaignTemplateForm = new FormGroup({
      category: new FormControl('', Validators.required),
    });

    this.customCategoryForm = new FormGroup({
      name: new FormControl('', Validators.required),
    });

    this.quickDesignGroupForm = new FormGroup({
      name: new FormControl('', Validators.required),
    });

    this.quickDesignForm = new FormGroup({
      'reference': new FormControl('', Validators.required),
      'quick_design_group_id': new FormControl(''),
      'template_id': new FormControl('', Validators.required),
      'description': new FormControl('')
    });

    this.addFolderForm = new FormGroup({
      uuid: new FormControl('', Validators.required),
    });

    this.moveFolderForm = new FormGroup({
      uuid: new FormControl('', Validators.required),
    });
  }

  onModal(item) {
    if (item !== 'pricefinder') {
      this.master = item;
      this.newTemplate.nativeElement.querySelector('#newTemplateLabel').innerHTML = 'Create ' + item.title + ' template';
    } else {
      this.newTemplate.nativeElement.querySelector('#newTemplateLabel').innerHTML = 'Create Market Snapshot';
    }
  }

  onSubmit() {
    if (this.templateAddForm.valid) {
      const templateAddValues = this.templateAddForm.value;
      this.loading = true;

      if (typeof this.master.custom !== 'undefined' && this.master.custom) {
        this.customCategoryService.httpDuplicateTemplate(this.master.ID, templateAddValues);
        return true;
      }

      templateAddValues['user_id'] = this.authUser.user.ID;
      templateAddValues['client_id'] = this.authUser.client.ID;
      templateAddValues['master_id'] = this.master.ID;
      templateAddValues['category_id'] = this.master.category_id;
      templateAddValues['group_id'] = this.master.group_id;
      templateAddValues['media_attribute_id'] = this.master.media_attribute_id;
      templateAddValues['department_id'] = this.master.department_id;
      templateAddValues['is_active'] = 1;

      if (this.page === 'campaign' && typeof this.listing.campaign_id !== 'undefined') {
        templateAddValues['campaign_id'] = this.listing.campaign_id;
      }

      this.templateService.httpPostTemplate(templateAddValues);

      setTimeout(() => {
        this.templateAddForm.reset();
        this.newTemplateModalClose.nativeElement.click();
        // this.router.navigate(['/templates/'+lastInserted.ID+'/build']);
      }, 500);
    }
  }

  onSubmitDuplicate() {
    if (this.templateDuplicateForm.valid) {
      this.loading = true;

      const templateAddValues = this.templateDuplicateForm.value;

      if (this.page === 'campaign' && typeof this.listing.campaign_id !== 'undefined') {
        templateAddValues['campaign_id'] = this.listing.campaign_id;
      }

      this.templateService.httpDuplicateTemplate(templateAddValues.ID, templateAddValues);

      setTimeout(() => {
        this.templateDuplicateForm.reset();
        this.duplicateTemplateModalClose.nativeElement.click();
      }, 500);
    }
  }
  onSubmitUpdate() {
    if (this.templateUpdateForm.valid) {
      this.loading = true;

      const templateAddValues = this.templateUpdateForm.value;

      this.templateService.httpPutTemplate(templateAddValues.ID, templateAddValues);

      setTimeout(() => {
        this.templateUpdateForm.reset();
        this.editTitleModalClose.nativeElement.click();
      }, 500);
    }
  }

  onSubmitDescription() {
    if (this.templateDescriptionForm.valid) {
      this.loading = true;

      const templateAddValues = this.templateDescriptionForm.value;

      this.quickDesignService.httpUpdateDescription(templateAddValues.ID, templateAddValues);
      setTimeout(
        () => {
          this.templateDescriptionForm.reset();
          this.editDescriptionModalClose.nativeElement.click();
        }, 500
      );

    }
  }

  onDuplicate(item, fromQuickDesign = 'no') {
    this.duplicateFromQuickDesigns = false;

    if (fromQuickDesign === 'yes') {
      this.duplicateFromQuickDesigns = true;
    }

    this.templateDuplicateForm.patchValue({
      ID: item.ID,
      title: item.title,
    });

    this.duplicateTemplateTitle = item.title;

    if (this.duplicateFromQuickDesigns) {
      $('#createTemplate').modal('hide');
    }

    $('#duplicateTemplate').modal('show');
  }

  onShare(template: any) {
    this._tmp_template = template;

    if (this.selected_share === 'offices') {
      this.onShareToClient();
    }

    if (this.selected_share === 'subgroup') {
      this.onShareToSubGroup();
    }

    if (this.selected_share === 'agent') {
      this.onShareToUser();
    }

    $('#shareTo').modal('show');
  }

  onShareToClient() {
    const template = this._tmp_template;

    this.selectedSharedClient = [];
    this.selectSharedClient = [];
    this.selectedSharedUser = [];
    this.shared_template_id = template.ID;

    for (const share_client of template.share_clients) {
      this.selectedSharedClient.push(share_client.client_id);

      jQuery('#shareTo')
        .find('.users' + share_client.client_id)
        .prop('checked', true);
    }

    for (const share_user of template.share_users) {
      this.selectedSharedUser.push(share_user.user_id);
      this.selectedUsers.push(share_user.user_id);

      if (jQuery.inArray(share_user.client_id, this.selectedClients) === -1) {
        this.selectedClients.push(share_user.client_id);
      }
    }
  }

  onShareToSubGroup() {
    const template = this._tmp_template;

    this.selectedSubGroup = [];
    this.shared_template_id = template.ID;

    jQuery('#shareTo').find('input[type="checkbox"]').prop('checked', false);

    for (const share_subgroup of template.share_subgroups) {
      this.selectedSubGroup.push(share_subgroup.client_id);
      jQuery('#shareTo')
        .find('input[value="' + share_subgroup.client_id + '"]')
        .prop('checked', true);
    }
  }

  onClear() {
    jQuery('#shareTo').find('input[type="checkbox"]').prop('checked', false);

    this.selectedSharedClient = [];
  }

  onSelectAll() {
    jQuery('#shareTo').find('input[type="checkbox"]').prop('checked', true);

    const elValues = jQuery('#shareToClient input:checkbox:checked')
      .map(function () {
        return this.getAttribute('value');
      })
      .get();

    this.selectedSharedClient = elValues;
  }

  hasShareable(template): boolean {
    if (template.share_clients !== 'undefined') {
      // tslint:disable-next-line:curly
      if (template.share_clients.length > 0) return true;
    }

    if (template.share_subgroups !== 'undefined') {
      // tslint:disable-next-line:curly
      if (template.share_subgroups.length > 0) return true;
    }

    if (template.share_users !== 'undefined') {
      // tslint:disable-next-line:curly
      if (template.share_users.length > 0) return true;
    }

    return false;
  }

  onCheckSharedClient(el: any) {
    const elCheckbox = el.target;
    const elIsChecked = elCheckbox.checked;
    const elValue = +elCheckbox.value;
    const elExist = this.selectedSharedClient.indexOf(elValue);

    if (elIsChecked) {
      if (elExist === -1) {
        this.selectedSharedClient.push(elValue);
      }
    } else {
      if (elExist !== -1) {
        this.selectedSharedClient.splice(elExist, 1);
      }
    }
  }

  onSubmitShareToClient() {
    this.loading = true;
    const sharedClientValues: Object = {};

    sharedClientValues['share_type'] = 'client';
    sharedClientValues['share_ids'] = this.selectedSharedClient;

    this.templateService.httpPutTemplate(this.shared_template_id, <any>sharedClientValues);

    setTimeout(() => {
      this.templateUpdateForm.reset();
      this.shareToModalClose.nativeElement.click();
    }, 500);
  }

  onShareToUser() {
    const template = this._tmp_template;

    this.selectedSharedUser = [];
    this.shared_template_id = template.ID;

    jQuery('#shareTo').find('input[type="checkbox"]').prop('checked', false);

    for (const share_user of template.share_users) {
      this.selectedSharedUser.push(share_user.user_id);
      jQuery('#shareTo')
        .find('input[value="' + share_user.user_id + '"]')
        .prop('checked', true);
    }
  }

  onClearShareToUser() {
    jQuery('#shareToUser').find('input[type="checkbox"]').prop('checked', false);
  }

  onSelectAllShareToUser() {
    jQuery('#shareToUser').find('input[type="checkbox"]').prop('checked', true);
  }

  onCheckSharedUser(el: any) {
    const elCheckbox = el.target;
    const elIsChecked = elCheckbox.checked;
    const elValue = +elCheckbox.value;
    const elExist = this.selectedSharedUser.indexOf(elValue);

    if (elIsChecked) {
      if (elExist === -1) {
        this.selectedSharedUser.push(elValue);
      }
    } else {
      if (elExist !== -1) {
        this.selectedSharedUser.splice(elExist, 1);
      }
    }
  }

  onSubmitShareToUser() {
    this.loading = true;
    const sharedUserValues: Object = {};

    sharedUserValues['share_type'] = 'user';
    sharedUserValues['share_ids'] = this.selectedSharedUser;

    this.templateService.httpPutTemplate(this.shared_template_id, <any>sharedUserValues);

    setTimeout(() => {
      this.templateUpdateForm.reset();
      this.shareToUserModalClose.nativeElement.click();
    }, 500);
  }

  onShareOffice(item: Template) {
    this.loading = true;
    const templateValues: Object = {};

    const shareValue = 2;

    templateValues['is_shared'] = item.is_shared ? 0 : shareValue;
    // this.templateService.httpPutTemplate(item.ID, <Template>templateValues);
  }

  onUpdateStatus(id: number, status = 0) {
    this.loading = true;

    const templateValues: Object = {};
    templateValues['is_active'] = status;
    this.templateService.httpPutTemplate(id, <Template>templateValues);
    this.is_archive = true;
  }

  onDelete(id: number, category: number) {
    const $this = this;
    let subtext = '';

    if (category === 13) {
      subtext = 'Make sure this Property Alert is not in use before deleting!';
    } else {
      subtext = "You won't be able to revert this!";
    }

    swal({
      title: 'Are you sure you want to delete this template?',
      text: subtext,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(function () {
      $this.templateService.httpDeleteTemplate(id);
      $this.loading = true;
      swal(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      );
    });
  }

  onPreview(template: any) {
    let img_src = '';
    if (template.thumbnail === '') {
      img_src = template.ID + '.jpg';
    } else {
      img_src = template.thumbnail;
    }

    this.previewModal.nativeElement.querySelector('.modal-body img').src = this.frontService.appConfig.S3_URL + 'templates/thumbnails/' + img_src;

    $('#previewTemplate').modal('show');
  }

  onEditTitle($id: number, $name: string) {
    $('#createTemplate').modal('hide');
    this.edit_title_id = $id;
    this.edit_title_name = $name;
    $('#editTitle').modal('show');
  }

  ngOnDestroy() {
    this.frontService.authService.cancelRequest();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    this.offset = 0;
  }

  onTour() {
    /*
     * Proposed - not  yet working
     */
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterContentInit() {
    jQuery(document).on('click', '.template-view', function (e) {
      e.preventDefault();
      const view = jQuery(this).attr('data-view');

      jQuery('.template-view').removeClass('active');
      jQuery(this).addClass('active');

      if (view === 'list') {
        jQuery('#arrangement-grid').removeClass('active');
        jQuery('.media-list').removeClass('is-grid').addClass('is-list');
      } else {
        jQuery('#arrangement-list').removeClass('active');
        jQuery('.media-list').removeClass('is-list').addClass('is-grid');
      }
    });

    const $this = this;
    $('#createTemplate').on('shown.bs.modal', function () {
      $('ngx-slick.carousel').on('wheel', function (e) {
        e.preventDefault();
        if (e.originalEvent.deltaY < 0) {
          $this.slickModal.slickPrev();
        } else {
          $this.slickModal.slickNext();
        }
      });
    });

    // $(document).on('click', '.category-tab li.active a', function(){
    //   const href = $(this).attr('href').substring(1);
    //   $(this).parent('li').removeClass('active');
    //   $('.tab-pane[id="' + href + '"]').removeClass('active');
    // });

    $(document).on('shown.bs.tab', 'a[data-bs-toggle="tab"]', function (e) {
      const ref = $(this).attr('href');
      const template = $(ref).find('.master-templates .master-templates__template');
      $(template).each(function (index) {
        const template_container = $(this).find('.template-container');
        const el = $(this).find('.template-container .img-container');
        if (el.height() < 125) {
          $(template_container).addClass('align-middle');
        }
      });

      const isChecked = $('.reorder-toggle input[type="checkbox"]:checked').length > 0;
      if (isChecked) {
        $('.reorder-toggle input[type="checkbox"]:checked').trigger('click');
      }

      $this.is_reorder = false;
    });

    if (this.authUser.provider !== 'mydesktop' && typeof this.authUser.user !== 'undefined' && this.authUser.user.template_popup !== 'undefined' && !this.authUser.user.template_popup) {
      setTimeout(() => {
        let introVideo = 'https://www.youtube-nocookie.com/embed/dPl_Y4QtV-I?autoplay=1&rel=0';

        if (this.frontService.appConfig.SKIN === 'raywhite') {
          introVideo = 'https://www.youtube-nocookie.com/embed/l9PaLItkg_w?autoplay=1&rel=0';
        }

        $('#firstLoginVideo iframe').attr('src', introVideo);
        $('#firstLoginVideo').modal('show');

        $('#firstLoginVideo').on('hidden.bs.modal', function (e) {
          $this.startTour();

          $this.userService.httpPutUserByAgentID($this.frontService.authService.auth.agentid, <any>{
            template_popup: 1,
          });
          $('#firstLoginVideo iframe').attr('src', '');
        });
      }, 100);
    }
  }

  getAgentData(id, type) {
    const agentID = this.shareListUsers.findIndex((a) => a.id === id);
    if (typeof this.shareListUsers[agentID] !== 'undefined') {
      return this.shareListUsers[agentID][type];
    }

    return ' ';
  }

  startTour() {
    const $this = this;
    const intro = introJs();

    intro.setOption('disableInteraction', true);
    intro.setOption('exitOnOverlayClick', false);
    intro.setOption('hidePrev', true);
    intro.setOption('hideNext', true);
    intro.setOption('overlayOpacity', '0.5');

    if (localStorage.getItem('md3_first_template_id') !== null) {
      intro.setOption('doneLabel', 'Proceed to Builder');
      this.first_template_id = localStorage.getItem('md3_first_template_id');

      // remove
      localStorage.removeItem('md3_first_template_id');
    }

    intro.addSteps(
      [
        {
          element: document.querySelector('.btn-tour'),
          intro: 'Take a Tour - Dashboard. Provides a step-by-step guide to the home page including Templates.',
        },
        {
          element: document.querySelector('.category-tab'),
          intro: 'Template Categories - Here you will see all the different types of templates that can be created.',
        },
        {
          element: document.querySelector('.category-tab-tour-emails'),
          intro: 'Emails - Any template that is emailed you will find here from Market Reports, Newsletters, Email signatures and more',
        },
        {
          element: document.querySelector('.category-tab-tour-printables'),
          // tslint:disable-next-line: max-line-length
          intro: 'Printables - Here you will find Brochures, Window Cards or anything that could be printed like Property Booklets or Stocklist.',
        },
        {
          element: document.querySelector('.category-tab-tour-socialmedia'),
          // tslint:disable-next-line: max-line-length
          intro: 'Social Media - Here you can create an social media post which can be uploaded to either Instagram, Twitter or Facebook at your own leisure.',
        },
        {
          element: document.querySelector('.category-tab-tour-create-yourself'),
          // tslint:disable-next-line: max-line-length
          intro: "Create yourself - Can't find a template you like? creating a template using the drag and drop template builder with your preferred elements.",
        },
        {
          element: document.querySelector('.category-tab-tour-create'),
          // tslint:disable-next-line: max-line-length
          intro: 'Create custom category - If you created a range of templates for Christmas for example and need a quick way to group them together try creating a custom category for Christmas and saving all your Christmas related templates to it so all your colleagues can use.',
        },
        {
          element: document.querySelector('.intro-template'),
          // tslint:disable-next-line:max-line-length
          intro: 'Your Designs - Displays all of the Templates that you have created or have access to, simply click the template to edit',
        },
        // {
        //   element: document.querySelector('#intro-soon'),
        //   intro: 'Coming Soon - Here you can see what is currently in the works and will eventually be implemented as a Template.'
        // },
        // {
        //   element: document.querySelector('#intro-enewsletter'),
        //   intro: 'eMarketing - Here you can create a Market Report, Include Email Signatures, design eCards and create an eNewsletter.'
        // },
        // {
        //   element: document.querySelector('#intro-brochure'),
        //   // tslint:disable-next-line:max-line-length
        //   intro: 'Printable Templates - Here you can create a Brochure or a Window Card/DL, you can choose a layout for the brochure in 2 different page sizes and 2 different orientations.'
        // },
        // {
        //   element: document.querySelector('#intro-property-guide'),
        //   intro: 'Property Guide - Here you can create either a Property Guide or a Stocklist.'
        // },
        // {
        //   element: document.querySelector('#intro-social-media'),
        //   // tslint:disable-next-line:max-line-length
        //   intro: 'Social Media - Here you can create an Image post which can be uploaded to either Instagram, Twitter or Facebook at your own leisure.'
        // },
        // {
        //   element: document.querySelector('#view-toggle'),
        //   // tslint:disable-next-line:max-line-length
        //   intro: 'View All - Click here to expand all categories and display all available Templates.'
        // },
        // {
        //   element: document.querySelector('.intro-view'),
        //   // tslint:disable-next-line:max-line-length
        //   intro: 'View - Displays a preview of the template layout that allows you to view the Template without having to edit it.'
        // },
        // {
        //   element: document.querySelector('.intro-edit'),
        //   intro: 'Edit - Opens the Template in the builder for you to make changes'
        // },
        // {
        //   element: document.querySelector('.intro-send'),
        //   // tslint:disable-next-line:max-line-length
        //   intro: 'Send - Links to MyDesktop for you to send to your database (Note: this can only be done with any Template made within the eMarketing category).'
        // },
        // {
        //   element: document.querySelector('.intro-share'),
        //   intro: 'Share - Allows you to share a Template with all agents in your office and can be undone at any time.'
        // },
        // {
        //   element: document.querySelector('.intro-duplicate'),
        //   intro: 'Duplicate - Creates a copy of the Template and all elements within the Template.'
        // },
        // {
        //   element: document.querySelector('.intro-archive'),
        //   // tslint:disable-next-line:max-line-length
        //   intro: 'Archive - Removes the Template from the list and places it into the Archive Templates tab on the sidebar and this can be undone at any time.'
        // },
        // {
        //   element: document.querySelector('.intro-delete'),
        //   intro: 'Delete - Deletes the Template and CANNOT BE UNDONE.'
        // },
        // {
        //   element: document.querySelector('.intro-info'),
        //   intro: 'Template Info - This displays the Template name, the date it was created and the agent who created it.'
        // },
        // {
        //   element: document.querySelector('.intro-tag'),
        //   intro: 'Template Tag - This shows the category that the Template is from, helpful when wanting to search for specific Templates.'
        // },
        // {
        //   element: document.querySelector('.input-search'),
        //   intro: 'Search - Here you can search for a particular Template by typing here in the search bar.'
        // },
        // {
        //   element: document.querySelector('.intro-date'),
        //   intro: 'Sort - Here you can sort your Templates by either date or title.'
        // },
        {
          element: document.querySelector('.intro-template-filter'),
          intro: 'Template Filter - Here you can filter your Templates by type',
        },
        {
          element: document.querySelector('.intro-agent'),
          intro: 'Agent Filter - Here you can filter your Templates by which agents created them.',
        },
        {
          element: document.querySelector('.intro-view-option'),
          intro: 'View Options - Here you can choose to display the Templates in either Grid or List view.',
        },
        {
          element: document.querySelector('.intro-side-template'),
          intro: 'Templates - This is the homepage where you can access your Templates.',
          position: 'right',
        },
        {
          element: document.querySelector('.intro-side-templates'),
          intro: 'Templates - This is the homepage where you can access your Templates.',
          position: 'right',
        },
        {
          element: document.querySelector('.intro-side-shared'),
          // tslint:disable-next-line:max-line-length
          intro: 'Shared Templates - Here you can view Templates that you have shared, Templates that your group has access to and Templates that your office has access to.',
          position: 'right',
        },
        {
          element: document.querySelector('.intro-side-archive'),
          // tslint:disable-next-line:max-line-length
          intro: 'Archive Templates - Here you can access templates that you have archived and choose to either view or activate (Unarchive) any Template.',
          position: 'right',
        },
        // {
        //   element: document.querySelector('.intro-side-analytics'),
        //   // tslint:disable-next-line:max-line-length
        //   intro: 'Analytics - Here you can access information such as recent activity, top used Templates, Template Reports and Login Reports.',
        //   position: 'right'
        // },
        {
          element: document.querySelector('.intro-side-library'),
          // tslint:disable-next-line:max-line-length
          intro: 'My Image Library - Here you can view either your Image Library, which is your personal images you can use, or Shared Library, which is images shared with you by other agents in your office/group.',
          position: 'right',
        },
        {
          element: document.querySelector('.intro-side-setting'),
          // tslint:disable-next-line:max-line-length
          intro: 'Settings - Here you can view and change settings regarding Templates and other various resources. You can also Take a Tour in the Settings tab to learn more about Settings.',
          position: 'right',
        },
        {
          element: document.querySelector('.intro-side-support'),
          // tslint:disable-next-line:max-line-length
          intro: 'Support Portal - Here you can view Training videos on how to operate MyDesign, Read FAQs that might be able to assist you if you are having difficulties or Submit a support ticket.”   End tour for Dashboard.',
          position: 'right',
        },
      ].filter(function (obj) {
        return $(obj.element).length;
      })
    );

    intro
      .start()
      .onbeforechange(function (element) {
        const el = $(element);
        const step = this._currentStep + 1;
        const sidebar = $('#sidebar-wrapper');

        const sidebar_steps = [12, 13, 14, 15, 16, 17];

        if (sidebar_steps.includes(step)) {
          sidebar.addClass('hovered');
          sidebar.find('.intro-side-templates ul.ul__sub-menu').css({ display: 'block' });
        } else {
          sidebar.removeClass('hovered');
          sidebar.find('.intro-side-templates ul.ul__sub-menu').css({ display: '' });
        }
      })
      .onchange(function (element) {
        const el = $(element);
        const step = this._currentStep;

        const template_steps = [8, 9, 10];
        const bg_89a2ae_steps = [1, 2, 3, 4, 5, 6, 7];
        const sidebar_steps = [11, 12, 13, 14, 15, 16, 17, 18];
        const custom_position_steps = [19, 20, 21, 22];

        if (template_steps.includes(step)) {
          $('.overlay-panel').css('opacity', '1');
          setTimeout(() => {
            $('.introjs-helperLayer').css('background-color', '#89a2ae');
          });
        } else {
          $('.overlay-panel').css('opacity', '0');
        }

        if (bg_89a2ae_steps.includes(step)) {
          setTimeout(() => {
            $('.introjs-helperLayer').css('background-color', '#89a2ae');
          });
        }

        if (custom_position_steps.includes(step)) {
          setTimeout(() => {
            $('.introjs-helperLayer').css('height', '75px');
          });
          setTimeout(() => {
            $('.introjs-tooltip').css('top', '90px');
          }, 500);
        }

        if (sidebar_steps.includes(step)) {
          $('#sidebar-wrapper').attr('style', 'z-index: 9999999 !important');
          setTimeout(() => {
            if (el.hasClass('intro-side-templates') && step === 11) {
              $('.introjs-helperLayer').css({
                width: '295.45px',
              });
            }
            $('.introjs-helperLayer').css({
              border: '1px solid black',
              'z-index': '100000000',
              'background-color': 'transparent',
            });
          });
          setTimeout(() => {
            $('.introjs-tooltip').attr('style', 'background: #fff !important; border: 1px solid black !important; color: #000');
          }, 300);
        }
      })
      .oncomplete(function () {
        console.log('complete');
        if ($this.first_template_id !== '') {
          $this.router.navigateByUrl('/templates/' + $this.first_template_id + '/build');
        }
      })
      .onexit(function () {
        const sidebar = $('#sidebar-wrapper');
        if (sidebar.hasClass('hovered')) {
          sidebar.removeClass('hovered');
        }
      });
  }

  convertDate(date) {
    return moment(date).format('DD/MM/YY');
  }

  sendTemplate(id) {
    let link = 'secure';

    if (this.authUser.brandid === '12' || this.authUser.brandid === '19') {
      link = 'rwg';
    }
    if (this.authUser.brandid === '13') {
      link = 'rhcompass';
    }
    if (this.authUser.brandid === '15') {
      link = 'rhcorporate';
    }

    if (id === 2) {
      swal({
        title: 'Send',
        type: 'info',
        html:
          // tslint:disable-next-line:max-line-length
          '<a onclick="onClickSend(\'' + link + "', " + "'mydesktop.com.au/cgi-bin/clients/agents/propsearch2.cgi'" + ')" class="btn btn-primary">Send</a>',
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
      });
    } else if (id === 3) {
      swal({
        title: 'Send',
        type: 'info',
        html:
          // tslint:disable-next-line:max-line-length
          '<a onclick="onClickSend(\'' + link + "', " + "'mydesktop.com.au/cgi-bin/clients/agents/version6/quickenquiry/managev2.cgi'" + ')" class="btn btn-primary">Send</a>',
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
      });
    } else {
      swal({
        title: 'Who do you want to send to?',
        type: 'info',
        html:
          // tslint:disable-next-line:max-line-length
          '<a onclick="onClickSend(\'' + link + "', " + "'mydesktop.com.au/cgi-bin/clients/agents/version6/addressbook/addressbook.cgi'" + ')" class="btn btn-primary">A single contact</a> &nbsp; <a onclick="onClickSend(\'' + link + "', " + "'mydesktop.com.au/cgi-bin/clients/agents/version6/marketreport4/manage.cgi'" + ')" class="btn btn-info">Multiple Contacts</a>',
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
      });
    }
  }

  sendTemplateVault(template) {
    this.vaultService.httpSendTemplate({
      title: template.title,
      subject: template.title,
      content: '',
      propertyTemplate: false,
      template_id: template.ID,
    });
    this.loading = true;
  }

  onFeedback() {
    swal({
      title: 'We would love to hear from you about the new MyDesign',
      imageUrl: 'http://mydesign3.wbclients.com/mydesign/assets/images/message-square.svg',
      imageWidth: 300,
      imageHeight: 100,
      showConfirmButton: false,
      html:
        '<p>Please choose to provide either Feedback or log a Support Ticket</p>' +
        // tslint:disable-next-line:max-line-length
        '<a class="btn btn-success btn-bh-feedback">Send Feedback</a> &nbsp; <a href="' +
        this.supportLink +
        '" target="_blank" class="btn btn-info btn-support-log">Log Support Ticket</a>',
    });

    jQuery('.btn-bh-feedback').click(function () {
      swal.close();
      _bugHerd.win.bugherd.applicationView.anonymousbar.toggleOptions();
    });
  }

  onChangeShare(el: any) {
    this.selected_share = el.target.value;

    if (this.selected_share === 'offices') {
      setTimeout(() => {
        this.onShareToClient();
      }, 200);
    }

    if (this.selected_share === 'subgroup') {
      setTimeout(() => {
        this.onShareToSubGroup();
      }, 200);
    }

    if (this.selected_share === 'agent') {
      this.onShareToUser();
    }
  }

  onSaveCheck() {
    const $this = this;
    const data = [];
    let key_name = '';

    if (this.selected_share === 'subgroup') {
      key_name = 'client_id';
    }

    if (this.selected_share === 'agent') {
      key_name = 'user_id';
    }

    if (this.selected_share === 'subgroup' || this.selected_share === 'agent') {
      $('#shareTo input:checked').each(function () {
        data.push({
          template_id: $this._tmp_template.ID,
          [key_name]: $(this).val(),
        });
      });
    }

    if (this.selected_share === 'offices') {
      $('.officeClient').each(function () {
        const clientId = $(this).val();
        if ($(this).is(':checked')) {
          data.push({
            template_id: $this._tmp_template.ID,
            client_id: $(this).val(),
          });
        }
      });

      this.selectedUsers.forEach(function (value) {
        data.push({
          template_id: $this._tmp_template.ID,
          user_id: value,
        });
      });
    }

    const shareValues = {
      template_id: this._tmp_template.ID,
      records: data,
    };

    this.templateService.httpShareTemplate(this.selected_share, shareValues);
    this.loading = true;
  }

  onScroll() {
    if (this.templates.length < this.template_count) {
      this.loading = true;
      this.offset += 1;

      this.queryTemplates();
    }
  }

  onChangeSort(val) {
    this.current_sort_by = val;
    this.offset = 0;

    this.cacheFilters();
    this.queryTemplates();
  }

  onChangeTemplateFilter(val) {
    this.current_template_filter = val;
    this.offset = 0;

    this.cacheFilters();
    this.queryTemplates();
  }

  onChangeAgentFilter(val) {
    this.current_agent_filter = val;
    this.offset = 0;

    this.cacheFilters();
    this.queryTemplates();
  }

  onClickSend(link, val) {
    if (link !== 'secure') {
      window.location.href = 'http://' + link + '.' + val;
    } else {
      swal({
        title: 'I am signed in via',
        type: 'info',
        html:
          // tslint:disable-next-line:max-line-length
          '<a href="http://login.' + val + '" target="_blank" class="btn btn-primary">MyDesktop</a> &nbsp; <a href="http://secure.' + val + '" target="_blank" class="btn btn-info">Domain Group</a>',
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
      });
    }
  }

  openMenu(type, template) {
    this.type = type;
    this.template = template;
    if (this.template.category.slug === 'enewsletter' || this.template.category.slug === 'quick-enquiry' || this.template.category.slug === 'email-signature') {
      $('.btn-send').removeClass('hidden');
    } else {
      $('.btn-send').addClass('hidden');
    }
  }

  generateCampaign() {
    this.campaignService.httpBuildTemplates(this.listing);
    this.loading = true;
  }

  exportCampaign() {
    this.campaignService.httpExportCampaign(this.listing.campaign_id);
    this.loading = true;
  }

  getTemplates() {
    this.campaignService.listing = this.listing;
    this.campaignService.httpGenerateCampaignTemplates(this.listing);
    this.loading = true;
  }

  removeFromCampaign(template) {
    const $this = this;
    swal({
      title: 'Are you sure you want to remove this template on this campaign?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Remove',
    }).then(function () {
      $this.loading = true;
      swal('Removed!', 'Your template has been removed from this campaign.', 'success');
      $this.ID = template.ID;

      $this.campaignService.httpRemoveListingFromCampaign($this.listing.campaign_id, template.parent_id, template.ID);
    });
  }

  addTemplateToCampaign(templateId) {
    this.templateId = templateId;

    this.campaignService.httpGetCampaigns({
      type: 'custom',
    });
    this.loading = true;
  }

  onaddTemplate() {
    const templateFormValues = this.campaignTemplateForm.value;

    templateFormValues['client_id'] = this.authUser.client.ID;
    templateFormValues['group_id'] = this.authUser.client.group_id;
    templateFormValues['user_id'] = this.authUser.user.ID;
    templateFormValues['template_id'] = this.templateId;
    templateFormValues['categories'] = [templateFormValues.category];

    delete templateFormValues.category;

    this.campaignService.httpPostCampaignTemplate(templateFormValues);
    this.loading = true;
  }

  refreshTemplates() {
    this.loading = true;
    this.templateService.httpRefreshTemplates(this.listing.campaign_id);
  }

  goToEdit(template) {
    if (this.authUser.client.ID !== template.client_id) {
      return false;
    }

    return this.router.navigateByUrl(`/templates/${template.ID}/build`);
  }

  sendDesignly(template) {
    return (window.location.href = `${this.frontService.appConfig.DESIGNLYMAIL_URL}/campaigns/create?templateid=${template.ID}`);
  }

  loadMasterTemplates() {
    this.loadedMasterTemplatesCount = 0;
    if (this.authUser.client.group.master === null) {
      swal('Error', 'Contact Designly', 'error');
      return false;
    }
    const $this = this;
    const ownedMasterTemplates = this.templates.filter((template) => template.is_master_template).length;

    // if (!ownedMasterTemplates) {
    swal({
      title: 'Are you sure you want to Load Master Templates?',
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Load Templates',
    })
      .then(function () {
        $this.templateService.httpLoadMasterTemplates($this.authUser.client.group.master.ID);
        $this.loading = true;
      })
      .catch(swal.noop);
    // }

    // if (ownedMasterTemplates) {
    //   swal({
    //     title: 'Caution',
    //     text: 'It looks like you have existing Master Templates with the same name that will be overwritten',
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Overwrite'
    //   }).then(function () {
    //     $this.templateService.httpLoadMasterTemplates($this.authUser.client.group.master.ID);
    //     $this.loading = true;
    //   }).catch(swal.noop);
    // }
  }

  createCustomCategory() {
    this.customCategoryForm.reset();
    this.customCategory = {
      ID: null,
      title: null,
    };

    $('#newCustomCategory').modal('show');
  }

  onSubmitCustomCategory() {
    const customCategoryFormValues = this.customCategoryForm.value;

    if (this.customCategoryForm.valid) {
      if (this.customCategory.ID !== null) {
        // tslint:disable-next-line: max-line-length
        this.customCategoryService.httpUpdate(this.customCategory.ID, customCategoryFormValues['name'], this.customCategoryIcon.nativeElement.files);
      } else {
        if (this.customCategoryIcon.nativeElement.files.length === 0) {
          // tslint:disable-next-line: max-line-length
          const avatar_url = 'https://ui-avatars.com/api/?name=' + customCategoryFormValues['name'] + '&background=ffffff&size=220&color=344651';

          fetch(avatar_url)
            .then((res) => res.blob())
            .then((blob) => {
              const objectURL = URL.createObjectURL(blob);

              const filename = customCategoryFormValues['name'].replace(/\s+/g, '-').toLowerCase() + '-' + new Date().getTime() + '.jpg';

              let files: any;
              files = [new File([blob], filename, { lastModified: new Date().getTime(), type: blob.type })];

              this.customCategoryIcon.nativeElement.files = this.FileListItems(files);
              // console.log(this.customCategoryIcon.nativeElement.files);
              this.customCategoryService.httpAdd(customCategoryFormValues['name'], this.customCategoryIcon.nativeElement.files);
            });

          // swal('Icon is required', '', 'error');

          // return false;
        } else {
          this.customCategoryService.httpAdd(customCategoryFormValues['name'], this.customCategoryIcon.nativeElement.files);
        }
      }
      this.loading = true;
    }
  }

  deleteCustomCategory(id: number) {
    const $this = this;
    swal({
      title: 'Are you sure you want to delete this custom category?',
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete!',
    })
      .then(function () {
        $this.customCategoryService.httpDelete(id);
        $this.loading = true;
      })
      .catch(swal.noop);
  }

  transformTitle(ID: number, title: string) {
    switch (ID) {
      case 4:
      case 8:
        title = 'Brochures / Window Cards / DL’s';
        break;
      case 5:
      case 10:
        title = 'Property Guide / MyGuide / Stocklist';
        break;

      case 6:
        title = 'Social Media';
        break;

      case 9:
        title = 'Signboard';
        break;

      case 10:
        title = 'PDF Flyers';
        break;

      case 11:
        title = 'Match Properties';
        break;
    }

    return title;
  }

  onDisablePopupModal() {
    if (this.checkIfDontShowPopupModal()) {
      const user = {
        hide_popup: 1,
      };
      this.userService.httpPutUserByAgentID(this.frontService.authService.auth.agentid, user);
    }
  }

  onPopupModalFindOutMore() {
    if (this.checkIfDontShowPopupModal()) {
      const user = {
        hide_popup: 1,
      };

      this.userService.httpPutUserByAgentID(this.frontService.authService.auth.agentid, user);
    }

    window.open(this.popup.findout_url, '_blank');
  }

  checkIfDontShowPopupModal() {
    return $('#showCheckBoxPopupModal').is(':checked');
  }

  onTemplateScroll(e) {
    const currentSlide = e.currentSlide;

    this.selectedQuickDesignGroupTemplate = {
      ID: this.quickDesignGroupTemplates[currentSlide]['ID'],
      title: this.quickDesignGroupTemplates[currentSlide]['title'],
      master: this.quickDesignGroupTemplates[currentSlide]['master'],
      client_id: this.quickDesignGroupTemplates[currentSlide]['client_id'],
      agent: this.quickDesignGroupTemplates[currentSlide]['created_by'],
      modified: this.quickDesignGroupTemplates[currentSlide]['updated_at'],
      description: this.quickDesignGroupTemplates[currentSlide]['description'],
      quickdesign_id: this.quickDesignGroupTemplates[currentSlide]['quickdesign_id']
    };
  }

  removeFromQuickDesign(ID: number, isTemplateList = false, templateId = 0) {
    const $this = this;
    swal({
      title: 'Are you sure you want to remove this template from Quick Design?',
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'REMOVE!'
    }).then(function () {
      $this.quickDesignService.httpDelete(ID);
      $this.loading = true;
      $('#createTemplate').modal('hide');
      if (isTemplateList) {
        const templateIndex = $this.templates.findIndex(g => g.ID === templateId);
        $this.templates[templateIndex].quickdesign = null;
      }
    }).catch(swal.noop);
  }

  showToCategory(category: string, template: any) {
    if (typeof template !== 'undefined' && template) {
      const emailCategories = [1, 2, 3, 7, 11, 13];
      const printableCategories = [4, 5, 8, 10];
      const socialMediaCategories = [6];
      const signboardCategories = [9];

      if (category === 'emails' && emailCategories.includes(template.category.ID)) {
        return true;
      }

      if (category === 'printables' && printableCategories.includes(template.category.ID)) {
        return true;
      }

      if (category === 'socialmedia' && socialMediaCategories.includes(template.category.ID)) {
        return true;
      }

      if (category === 'signboards' && signboardCategories.includes(template.category.ID)) {
        return true;
      }
    }
    return false;
  }

  onCreateQuickDesignGroup(reference: string, category: string) {
    this.temporaryQuickDesignGroupData = {
      reference: reference,
      category: category,
    };

    this.quickDesignGroup = {
      ID: null,
      title: null,
    };
    this.quickDesignGroupForm.reset();

    $('#createQuickDesignGroup').modal('show');
  }

  onSubmitQuickDesignGroup() {
    const quickDesignGroupValues = this.quickDesignGroupForm.value;

    if (this.quickDesignGroupForm.valid) {
      if (this.quickDesignGroup.ID !== null) {
        // tslint:disable-next-line: max-line-length
        this.quickDesignService.httpUpdateGroup(this.quickDesignGroup.ID, quickDesignGroupValues, this.quickDesignGroupIcon.nativeElement.files);
      }

      if (this.quickDesignGroup.ID === null) {
        quickDesignGroupValues['reference'] = this.temporaryQuickDesignGroupData.reference;
        quickDesignGroupValues['category'] = this.temporaryQuickDesignGroupData.category;

        this.quickDesignService.httpAddGroup(quickDesignGroupValues, this.quickDesignGroupIcon.nativeElement.files);
      }

      this.loading = true;
    }
  }

  getGroupTemplates(ID: number, category: string) {
    this.loading = true;
    this.quickDesignService.httpGetGroupTemplates(ID);

    switch (category) {
      case 'printables':
        this.selectedGroupCategory = 'Printable';
        break;

      case 'socialmedia':
        this.selectedGroupCategory = 'Social Media';
        break;

      case 'signboards':
        this.selectedGroupCategory = 'Signboard';
        break;

      default:
        this.selectedGroupCategory = 'Email';
        break;
    }
  }

  onEditCategory(ID: number, title: string) {
    this.customCategory = {
      ID: ID,
      title: title,
    };

    this.customCategoryForm.patchValue({
      name: title,
    });

    $('#newCustomCategory').modal('show');
  }

  onOpenSingleTemplate(template, clientId, description, id) {
    let agentName = '';

    if (typeof template.user !== 'undefined' && template.user) {
      agentName = `${template.user.firstname} ${template.user.lastname}`;
    }

    this.quickDesignGroupTemplates = [
      {
        ID: template.ID,
        title: template.title,
        thumbnail: `templates/quick-designs/${template.thumbnail}`,
        updated_at: template.modified,
        created_by: agentName,
        master: template.master,
        client_id: clientId,
        description: description,
        quickdesign_id: id
      }
    ];

    this.selectedQuickDesignGroupTemplate = {
      ID: this.quickDesignGroupTemplates[0]['ID'],
      title: this.quickDesignGroupTemplates[0]['title'],
      master: this.quickDesignGroupTemplates[0]['master'],
      client_id: clientId,
      agent: this.quickDesignGroupTemplates[0]['created_by'],
      modified: this.quickDesignGroupTemplates[0]['updated_at'],
      description: this.quickDesignGroupTemplates[0]['description'],
      quickdesign_id: this.quickDesignGroupTemplates[0]['quickdesign_id']
    };

    $('#createTemplate').modal('show');
    setTimeout(() => {
      $('.slick-active').removeAttr('style');
      this.slickModal.slickGoTo(0);
    }, 100);
  }

  onRemoveCustomCategory(ID: number) {
    const $this = this;
    swal({
      title: 'Are you sure you want to delete this category?',
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete!',
    })
      .then(function () {
        $this.customCategoryService.httpDelete(ID);
        $this.loading = true;
      })
      .catch(swal.noop);
  }

  onEditQuickDesignGroup(ID: number, title: string) {
    this.quickDesignGroup = {
      ID: ID,
      title: title,
    };

    this.quickDesignGroupForm.patchValue({
      name: title,
    });

    $('#createQuickDesignGroup').modal('show');
  }

  onDeleteQuickDesignGroup(ID: number) {
    const $this = this;
    swal({
      title: 'Are you sure you want to delete this folder?',
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete!',
    })
      .then(function () {
        $this.quickDesignService.httpDeleteGroup(ID);
        $this.loading = true;
      })
      .catch(swal.noop);
  }

  showReferenceOption(category: string) {
    if (typeof this.template !== 'undefined') {
      const emailCategories = [1, 2, 3, 7, 11, 13];
      const printableCategories = [4, 5, 8, 10];
      const socialMediaCategories = [6];
      const signboardCategories = [9];
      const pricefinderCategories = [14];

      if (category === 'emails' && emailCategories.includes(this.template.category.ID)) {
        if (!this.quickDesignOptionLoaded) {
          this.quickDesignOptionLoaded = true;
          this.quickDesignForm.patchValue({
            reference: 'emails',
          });

          this.quickDesignService.httpGetGroupsByCategory('emails', 'emails');
        }
        return true;
      }

      if (category === 'printables' && printableCategories.includes(this.template.category.ID)) {
        if (!this.quickDesignOptionLoaded) {
          this.quickDesignOptionLoaded = true;
          this.quickDesignForm.patchValue({
            reference: 'printables',
          });

          this.quickDesignService.httpGetGroupsByCategory('printables', 'printables');
        }

        return true;
      }

      if (category === 'socialmedia' && socialMediaCategories.includes(this.template.category.ID)) {
        if (!this.quickDesignOptionLoaded) {
          this.quickDesignOptionLoaded = true;
          this.quickDesignForm.patchValue({
            reference: 'socialmedia',
          });

          this.quickDesignService.httpGetGroupsByCategory('socialmedia', 'socialmedia');
        }

        return true;
      }

      if (category === 'signboards' && signboardCategories.includes(this.template.category.ID)) {
        if (!this.quickDesignOptionLoaded) {
          this.quickDesignOptionLoaded = true;
          this.quickDesignForm.patchValue({
            reference: 'signboards',
          });

          this.quickDesignService.httpGetGroupsByCategory('signboards', 'signboards');
        }

        return true;
      }

      if (category === 'pricefinder' && pricefinderCategories.includes(this.template.category.ID)) {
        if (!this.quickDesignOptionLoaded) {
          this.quickDesignOptionLoaded = true;
          this.quickDesignForm.patchValue({
            reference: 'pricefinder',
          });

          this.quickDesignService.httpGetGroupsByCategory('pricefinder', 'pricefinder');
        }

        return true;
      }
    }
    return false;
  }

  saveAsQuickDesign(template) {
    this.quickDesignOptionLoaded = false;
    this.template = template;
    this.quickDesignForm.patchValue({
      template_id: template.ID,
    });

    $('#saveToCategory').modal('show');
  }

  onSaveToQuickDesign() {
    const quickDesignFormValues = this.quickDesignForm.value;

    if (this.quickDesignForm.valid) {
      this.quickDesignService.httpAdd(quickDesignFormValues, this.quickDesignIcon.nativeElement.files);
      this.loading = true;
    }
  }

  onSelectedCustomCategories(value: string) {
    const emailCategories = [1, 2, 3, 7, 11, 13];
    const printableCategories = [4, 5, 8, 10];
    const socialMediaCategories = [6];
    const signboardCategories = [9];

    this.quickDesignForm.patchValue({
      quick_design_group_id: '',
    });

    this.loading = true;

    if (value === 'emails') {
      this.quickDesignService.httpGetGroupsByCategory('emails', 'emails');
      return true;
    }

    if (value === 'printables') {
      this.quickDesignService.httpGetGroupsByCategory('printables', 'printables');
      return true;
    }

    if (value === 'socialmedia') {
      this.quickDesignService.httpGetGroupsByCategory('socialmedia', 'socialmedia');
      return true;
    }

    if (value === 'signboards') {
      this.quickDesignService.httpGetGroupsByCategory('signboards', 'signboards');
      return true;
    }

    if (emailCategories.includes(this.template.category.ID)) {
      this.quickDesignService.httpGetGroupsByCategory(value, 'emails');
      return true;
    }

    if (printableCategories.includes(this.template.category.ID)) {
      this.quickDesignService.httpGetGroupsByCategory(value, 'printables');
      return true;
    }

    if (socialMediaCategories.includes(this.template.category.ID)) {
      this.quickDesignService.httpGetGroupsByCategory(value, 'socialmedia');
      return true;
    }

    if (signboardCategories.includes(this.template.category.ID)) {
      this.quickDesignService.httpGetGroupsByCategory(value, 'signboards');
      return true;
    }
  }

  FileListItems(files) {
    const b = new ClipboardEvent('').clipboardData || new DataTransfer();
    for (let i = 0, len = files.length; i < len; i++) {
      b.items.add(files[i]);
      return b.files;
    }
  }

  randomColor() {
    const hex = Math.floor(Math.random() * 0xffffff);
    const color = hex.toString(16);

    return color;
  }

  invertHex(hex: string) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }

    if (hex.length !== 6) {
      console.warn('Hex color must be six hex numbers in length.');
      return '#' + hex;
    }

    hex = hex.toUpperCase();
    const splitNum = hex.split('');
    let resultNum = '';
    const simpleNum = 'FEDCBA9876'.split('');
    const complexNum = {
      A: '5',
      B: '4',
      C: '3',
      D: '2',
      E: '1',
      F: '0',
    };

    for (let i = 0; i < 6; i++) {
      if (!isNaN(Number(splitNum[i]))) {
        resultNum += simpleNum[splitNum[i]];
      } else if (complexNum[splitNum[i]]) {
        resultNum += complexNum[splitNum[i]];
      } else {
        console.warn('Hex colors must only include hex numbers 0-9, and A-F');
        return '#' + hex;
      }
    }

    return resultNum;
  }

  onShareCategory(ID: number) {
    const $this = this;
    swal({
      title: 'Are you sure you want to share this category?',
      text: 'This will be visible across the group once shared',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Share!',
    })
      .then(function () {
        $this.customCategoryService.httpShare(ID, 1);
        $this.loading = true;
      })
      .catch(swal.noop);
  }

  onUnShareCategory(ID: number) {
    const $this = this;
    swal({
      title: 'Are you sure you want to unshare this category?',
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Unshare!',
    })
      .then(function () {
        $this.customCategoryService.httpShare(ID, 0);
        $this.loading = true;
      })
      .catch(swal.noop);
  }

  onReplaceQuickDesignIcon(type: string, ID: number) {
    $('#createTemplate').modal('hide');
    this.replaceQuickDesignIconType = type;
    this.replaceQuickDesignIconID = ID;

    this.replaceQuickDesignIconInput.nativeElement.value = '';
    $('#replaceQuickDesignIcon').modal('show');
  }

  saveReplaceQuickDesignIcon() {
    const files = this.replaceQuickDesignIconInput.nativeElement.files;

    if (files.length === 0) {
      swal('Icon not found', '', 'error');
    }

    this.quickDesignService.httpReplaceIcon(this.replaceQuickDesignIconType, this.replaceQuickDesignIconID, files);
    this.loading = true;
    $('#replaceQuickDesignIcon').modal('hide');
  }

  favourite(ID: number, status = 0) {
    const templateValues = <any>{
      is_favourite: status,
    };

    this.templateService.httpPutTemplate(ID, templateValues);
  }

  queryTemplates() {
    let queryType = 'dashboard';
    let templateQuery = <any>{
      user_agent_id: `${this.authUser.user.ID},${this.authUser.user.agent_id}`,
      client_id: this.authUser.client.ID,
      group_id: this.authUser.client.group_id,
      is_active: '1',
      offset: this.offset,
      quickdesign: 0,
      dashboard: true,
    };

    if (this.currentPageType === 'group' || this.currentPageType === 'client' || this.currentPageType === 'subgroup') {
      if (this.currentPageType === 'group') {
        queryType = 'share-to-clients';
      }

      if (this.currentPageType === 'client') {
        queryType = 'share-to-users';
      }

      if (this.currentPageType === 'subgroup') {
        queryType = 'subgroup';
      }

      templateQuery = <any>{
        client_id: this.authUser.client.ID,
        is_active: 1,
      };

      if (this.currentPageType === 'client') {
        templateQuery.user_agent_id = `${this.authUser.agentid}`;
      }
    }

    // Search Filters
    if (this.searchKeyword !== '') {
      templateQuery.keyword = this.searchKeyword;
    }

    if (this.current_template_filter !== '' && this.current_template_filter !== 'all' && this.current_template_filter !== 'quick-design') {
      templateQuery.category_id = this.current_template_filter;
    }

    if (this.current_template_filter === 'quick-design') {
      delete templateQuery.category_id;
      templateQuery.quickdesign = 1;
    }

    if (queryType === 'dashboard') {
      if (this.current_agent_filter !== '' && this.current_agent_filter !== 'all') {
        templateQuery.agent_select = true;
        templateQuery.user_agent_id = `${this.authUser.user.ID},${this.current_agent_filter}`;
      }

      if (this.current_agent_filter === this.authUser.agentid) {
        delete templateQuery.agent_select;
        templateQuery.user_agent_id = `${this.authUser.user.ID},${this.authUser.agentid}`;
      }
    }

    if (this.page === 'campaign') {
      delete templateQuery.user_agent_id;
      delete templateQuery.client_id;
      delete templateQuery.group_id;

      templateQuery.campaign_id = this.listing.campaign_id;
      templateQuery.category = this.listing.campaign_category;

      if (this.campaignService.isCustom) {
        templateQuery.category = `custom_${this.campaignService.campaignId}`;
      }
    }

    if (this.currentPageType === 'archived') {
      if (this.authUser.client.group_id === 12 || this.authUser.client.group_id === 19) {
        delete templateQuery.user_agent_id;
      }

      templateQuery.is_active = 0;
    }

    if (this.currentPageType === 'favourite') {
      templateQuery.is_favourite = 1;
    }

    if (this.currentPageType === 'quickdesign') {
      this.current_template_filter = 'quick-design';
      templateQuery.quickdesign = 1;
    }

    if (typeof this.currentPageType !== 'undefined' && this.isUuid(this.currentPageType)) {
      delete templateQuery.user_agent_id;
      delete templateQuery.dashboard;
      templateQuery.folder = this.currentPageType;
    }

    if (this.current_agent_filter === 'all' && (this.authUser.client.allow_agent_settings === 1 || this.authUser.is_admin || this.authUser.client.group_id === 12 || this.authUser.client.group_id === 19)) {
      delete templateQuery.user_agent_id;
    }

    this.templateService.httpGetTemplates(templateQuery, queryType, this.current_sort_by);
  }

  isUuid(s: String) {
    const match = s.match(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);

    if (match === null) {
      return false;
    }

    return true;
  }

  addToFolder(template) {
    this.folderService.httpGetAll();
    this.loading = true;
    this.template = template;

    $('#addFolder').modal('show');
  }

  moveToFolder(template) {
    this.folderService.httpGetAll();
    this.loading = true;
    this.template = template;

    $('#moveFolder').modal('show');
  }

  removeFromFolder(template) {
    this.folderService.httpRemoveTemplate({
      uuid: this.currentPageType,
      template_id: template.ID,
    });

    this.loading = true;
  }

  onAddToFolder() {
    const addFolderFormValues = this.addFolderForm.value;

    addFolderFormValues['template_id'] = this.template.ID;

    if (this.addFolderForm.valid) {
      this.folderService.httpAddTemplate(addFolderFormValues);
      this.loading = true;
    }
  }

  onMoveToFolder() {
    const moveFolderFormValues = this.moveFolderForm.value;

    moveFolderFormValues['template_id'] = this.template.ID;
    moveFolderFormValues['ID'] = this.template.folder_templates.ID;

    if (this.moveFolderForm.valid) {
      this.folderService.httpMoveTemplate(this.template.folder_templates.ID, moveFolderFormValues);
      this.loading = true;
    }
  }

  editTemplateQuickDesign(id) {
    $('#createTemplate').modal('hide');
    $('.editTemplate').attr('href', '/templates/' + id + '/build');
  }

  onImgError(event) {
    $(event.target).closest('.slide').addClass('no-image');
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === 'move') {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDrop(event: DndDropEvent, list?: any[]) {
    if (list && (event.dropEffect === 'copy' || event.dropEffect === 'move')) {
      let index = event.index;

      if (typeof index === 'undefined') {
        index = list.length;
      }

      list.splice(index, 0, event.data);

      setTimeout(() => {
        const newSortValues = list.map((qd) => {
          return {
            ID: qd.ID,
            type: qd.type,
          };
        });

        this.quickDesignService.httpUpdateSort({
          sort: newSortValues,
        });
      });
    }
  }

  onCheckReorder(event) {
    if (event.currentTarget.checked) {
      this.is_reorder = true;
    } else {
      this.is_reorder = false;
    }
  }

  selectOffice(event, officeId) {
    event.stopPropagation();

    let $this = this;

    if ($('#office' + officeId).is(':checked')) {
      jQuery('#shareTo')
        .find('.users' + officeId)
        .prop('checked', true);
    } else {
      jQuery('#shareTo')
        .find('.users' + officeId)
        .prop('checked', false);
    }

    if ($('#user' + officeId).is(':checked')) {
      jQuery('#shareTo')
        .find('#office' + officeId)
        .prop('checked', false);
    }
  }

  selectUser(event, userId, officeId) {
    event.stopPropagation();

    if ($('#users-' + officeId + '-' + userId).is(':checked')) {
      this.selectedUsers.push(userId);
    } else {
      jQuery('#office' + officeId).prop('checked', false);
      this.selectedUsers = $.grep(this.selectedUsers, function (value) {
        return value !== userId;
      });
    }
  }

  cacheFilters() {
    const cacheFilter = {
      sort: this.current_sort_by,
      template: this.current_template_filter,
      agent: this.current_agent_filter,
      keyword: this.templateTitleSearchInput.value,
    };

    localStorage.setItem(`${this.currentPageType}-filter`, JSON.stringify(cacheFilter));
  }

  showUsers(clientId) {
    this.loading = true;
    this.clientUsers = [];
    $('.panel-body').addClass('hidden');

    this.userService.httpGetUsers({ client_id: clientId });

    if ($('#office' + clientId).is(':checked')) {
      this.selectSharedClient.push(clientId);
    } else {
      this.selectSharedClient = $.grep(this.selectSharedClient, function (value) {
        return value !== clientId;
      });
    }
  }

  onEditDescription(id: number, description: string) {
    $('#createTemplate').modal('hide');
    this.edit_title_id = id;
    this.edit_description = description;

    $('#editDescription').modal('show');
  }

  ngAfterViewInit() {
    if (this.authUser.client.enable_pro_ui && !this.authUser.user.subscription && !this.authUser.client.subscription) {
      const propPopup = localStorage.getItem('propPopup');

      if (!propPopup) {
        setTimeout(() => {
          $('#proVersionModal').modal('show');
        }, 1000);

        localStorage.setItem('propPopup', 'shown');
      }
    }
  }
}
