<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">Elements - {{ category_name }}</h2>

    <button *ngIf="block_id !== 0" (click)="newBlockElement()" type="button" class="btn-admin"><i class="icon feather icon-plus"></i><span>Add Element</span></button>
  </div>
</div>
<div id="box" class="row">
  <div class="groupBox col-md-4">
    <form [formGroup]="selectGroupForm">
      <label for="sgf_group_id">Filter by Group</label>
      <ng-select id="sgf_group_id" formControlName="group_id" [(ngModel)]="group_id" (change)="onChangeGroup($event)">
        <ng-option value="" disabled>Select group</ng-option>
        <ng-option value="0">All</ng-option>
        <ng-option *ngFor="let group of groups" [value]="group.ID">{{ group.title }}</ng-option>
      </ng-select>
    </form>
  </div>
  <div class="col-md-12">
    <div class="table-box admin-tabs">
      <!-- Nav tabs -->
      <ul class="nav nav-tabs" role="tablist">
        <li *ngFor="let block of blocks; let i = index" [ngClass]="block_id === block.ID ? 'active' : ''" role="presentation">
          <a (click)="setBlockID(block.ID)" href="#{{ block.slug }}" role="tab" data-bs-toggle="tab">{{ block.title }}</a>
        </li>
      </ul>
      <div class="tab-content">
        <table id="datatable-elements-{{ block_id }}-group-{{ group_id }}" class="datatable-elements-{{ category }} table table-striped table-borderless table-hover" cellspacing="0" width="100%" style="width: 100%">
          <thead>
            <tr>
              <th>ID</th>
              <th data-priority="1">Provider</th>
              <th data-priority="3" width="10%">Title</th>
              <th data-priority="5">Group</th>
              <th>Media Attribute</th>
              <th>Department</th>
              <th>Client</th>
              <th>Child Elements</th>
              <th>Date Added</th>
              <th>Date Modified</th>
              <th data-priority="4">Status</th>
              <th data-priority="2" style="text-align: right">Actions</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngFor="let element of elements">
                <td>{{ element.title }}</td>
                <td>{{ element.group.title }}</td>
                <td *ngIf="element.media_attribute">{{ element.media_attribute.slug | uppercase }}</td>
                <td *ngIf="!element.media_attribute"></td>
                <td>{{ element.department.title }}</td>
                <td>{{ element.client_id === 0 ? 'All' : element.client.company_name }}</td>
                <td>{{ element.created | date:'dd.MM.yy' }}</td>
                <td>{{ element.modified | date:'dd.MM.yy' }}</td>
                <td><span class="label label-success">{{ element.is_active ? 'Active' : 'Deactivated' }}</span></td>
                <td style="text-align: right">
                  <button routerLink="{{ element.ID }}/edit" type="button" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
                  <button routerLink="{{ element.ID }}/html" type="button" class="btn-table" title="HTML"><i class="icon feather icon-file-text"></i></button>
                  <button routerLink="{{ element.ID }}/edit/duplicate" type="button" class="btn-table" title="Duplicate"><i class="icon feather icon-copy"></i></button>
                  <button (click)="onPreview(element.ID)" data-bs-target="#previewElement" data-bs-toggle="modal" type="button" class="btn-table" title="Preview"><i class="icon feather icon-eye"></i></button>
                  <button (click)="onDelete(element.ID)" type="button" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
                </td>
              </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div #previewModal id="previewElement" class="modal fade edit-box preview" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <button type="button" #previewElementModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">
        <img src="" class="img-responsive" />
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
