import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';
import { Auth } from '../../../models/auth.model';
import { Client } from '../../../models/client.model';
import { ClientService } from '../../../services/client.service';
import { FrontService } from '../../front.service';

@Component({
  selector: 'app-settings-integrations',
  templateUrl: './settings-integrations.component.html',
  styleUrls: ['./settings-integrations.component.scss']
})
export class SettingsIntegrationsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  authUser: Auth;

  loading = false;

  clientIntegrationsForm: FormGroup;

  constructor(
    private frontService: FrontService,
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.authUser = this.frontService.authService.auth;

    this.clientService.onUpdateIntegrations
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              window.location.reload();
            }

            if (response.status === 'error') {
              swal({
                title: 'Failed to update Integrations',
                text: 'Contact Dev Team',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.initForm();
  }

  initForm() {
    this.clientIntegrationsForm = new FormGroup({
      propps_app_id: new FormControl(this.authUser.client.propps_app_id),
      pricefinder_username: new FormControl(this.authUser.client.pricefinder_username),
      pricefinder_password: new FormControl(''),
      google_maps_api_key: new FormControl(this.authUser.client.google_maps_api_key),
      google_tag_id: new FormControl(this.authUser.client.google_tag_id)
    });
  }

  onSubmit() {
    const clientIntegrationsFormValues = this.clientIntegrationsForm.value;

    if (this.clientIntegrationsForm.valid) {
      this.clientService.httpUpdateIntegrations(this.authUser.client.ID, clientIntegrationsFormValues);
      this.loading = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
