import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DepartmentService } from '../../../services/department.service';
import { AdminService } from '../../admin.service';
import { Department } from '../../../models/department.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

declare var swal: any;

@Component({
  selector: 'app-setting-admin-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit, OnDestroy {
  loading = false;
  departments: Department[] = [];

  departmentForm: FormGroup;

  subscription: Subscription;
  subscriptionResponse: Subscription;

  @ViewChild('newDepartmentModalClose', { static: true }) newDepartmentModalClose: ElementRef;

  constructor(private adminService: AdminService,
    private departmentService: DepartmentService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.loading = true;
    this.subscription = this.departmentService.departmentsChanged.subscribe(
      (departments: Department[]) => {
        if (typeof departments !== 'undefined') {
          this.loading = false;
          this.departments = departments;
        }
      }
    );

    this.subscriptionResponse = this.departmentService.departmentOperation.subscribe(
      (department: Department) => {
        if ( typeof department.ID !== 'undefined' ) {
          this.loading = true;
          this.departmentService.httpGetDepartments();
        }
      }
    );

    this.initForm();

    this.departmentService.httpGetDepartments();
  }

  private initForm() {
    this.departmentForm = new FormGroup({
      'title': new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    if (this.departmentForm.valid) {
      this.loading = true;
      const departmentValues = this.departmentForm.value;

      this.departmentService.httpPostDepartment(departmentValues);

      swal(
        'Added!',
        'Department added successfully',
        'success'
      ).then(setTimeout(
          () => {
            this.departmentForm.reset();
            this.newDepartmentModalClose.nativeElement.click();
          }, 200
        )
      );

    }
  }

  onDelete(id: number) {
    this.loading = true;
    this.departmentService.httpDeleteDepartment(id);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionResponse.unsubscribe();
  }

}
