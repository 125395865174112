import { Component, OnInit, OnDestroy } from '@angular/core';
import { ClientService } from '../../../../services/client.service';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService } from '../../../../services/account.service';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-users-dashboard',
  templateUrl: './users-dashboard.component.html',
  styleUrls: ['./users-dashboard.component.scss']
})
export class UsersDashboardComponent implements OnInit, OnDestroy {
  loading = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  clientId: number;
  accounts = [];

  client: any;
  users = [];
  constructor(
    private clientService: ClientService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        this.clientId = +params['id'];

        this.clientService.httpGetClient(this.clientId);
        this.loading = true;
      }
    );

    this.userService.usersChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.loading = false;
            this.users = response.data;
          }
        }
      }
    );

    this.clientService.clientChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (client: any) => {
        if (typeof client !== 'undefined' && typeof client.ID !== 'undefined') {
          this.userService.httpGetUsers({
            client_id: client.ID
          });

          this.client = client;
        }
      }
    );

    this.userService.userOperation
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.ID !== 'undefined') {
            this.userService.httpGetUsers({
              client_id: this.client.ID
            });
          }
        }
      }
    );
  }

  resetFirstLogin(ID: number) {
    this.userService.httpPutUser(ID, <any>{
      is_first: 1,
      template_popup: 0
    });

    this.loading = true;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
