import { Component, OnInit } from '@angular/core';
import { Menu } from '../../../models/menu.model';
import { Subject } from 'rxjs';
import { BlacklistService } from '../../../services/blacklist.service';
import { AdminService } from '../../admin.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-blacklists-dashboard',
  templateUrl: './blacklists-dashboard.component.html',
  styleUrls: ['./blacklists-dashboard.component.scss']
})
export class BlacklistsDashboardComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject;
  loading = false;
  menus: Menu[] = [];
  page = 1;

  blacklists: any;

  constructor(
    private adminService: AdminService,
    private blacklistService: BlacklistService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.adminService.loadMenu(this.menus);

    this.blacklistService.httpGetBlacklists();

    this.blacklistService.blacklistGetAll
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.loading = false;
          this.blacklists = response.data;
        }
      }
    );

    this.blacklistService.blacklistDelete
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.loading = false;

          Swal(
            'Deleted',
            '',
            'success'
          );

          this.blacklistService.httpGetBlacklists();
        }
      }
    );
  }

  onDelete(id) {
    const $this = this;
    Swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.blacklistService.httpDeleteBlacklist(id);
        $this.loading = true;
        Swal(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
      }
    }).catch(Swal.noop);
  }

}
