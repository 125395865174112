import { Component, OnInit } from '@angular/core';

import { AdminService } from '../../admin.service';
import { ChangelogService } from '../../../services/changelogs.service';
import { Changelog } from '../../../models/changelog.model';
import { Subscription } from 'rxjs';

declare var swal: any;
@Component({
  selector: 'app-changelog-dashboard',
  templateUrl: './changelog-dashboard.component.html',
  styleUrls: ['./changelog-dashboard.component.scss']
})
export class ChangelogDashboardComponent implements OnInit {
  loading = true;
  changelogs: Changelog[];

  subscription: Subscription;

  constructor(private changelogService: ChangelogService, private adminService: AdminService) { }

  ngOnInit() {

    this.changelogService.httpGetChangelogs();

    this.subscription = this.changelogService.changelogsChanged.subscribe(
      (Changelogs: Changelog[]) => {
        if(typeof Changelogs != 'undefined'){
          this.changelogs = Changelogs;
          this.loading = false;
        }
      }
    );
  }

  htmlToPlaintext(text) {
    let content = text ? String(text).replace(/<[^>]+>/gm, '') : '';

    return content.substring(0, 60);
  }

  visibilityToText(code){
    return (code == 0 ? 'Visible' : 'Hidden');
  }

  onDelete(id) {
    const $this = this;

    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;
        $this.changelogService.httpDeleteChangelog(id);
      }
    }).catch(swal.noop);
  }

}
