import { keys } from './keys';

export const environment = {
  app: 'Designly',
  htmlEndpoint: 'https://stage-raywhite.designly.com.au',
  apiEndpoint: 'https://uat-api.designly.com.au',
  s3BucketUrl: 'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/',
  particaEndpoint: 'https://api.partica.online',
  particaViewerEndpoint: 'https://viewer.cdn.partica.online/viewer',
  particaArticleEndpoint: 'https://articles.partica.online',
  vaultClientId: keys.VAULT_CLIENT_ID,
  particaPartnerId: keys.PARTICA_LIVE_PARTNER_ID,
  slackWebHook: keys.SLACK_WEBHOOK,
  domainClientId: keys.DOMAIN_CLIENT_ID,
  vaultUkClientId: keys.VAULT_UK_CLIENT_ID,
  isSignifi: 'no',
  isRaywhite: 'no',
  isUk: false,
  production: true,
  skin: 'designly',
  crmUrl: 'https://crm.designly.com.au',
  crmEndpoint: 'https://api.crm.designly.com.au'
};
