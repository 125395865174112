<div class="create-campaigns text-center">
  <div class="create-campaigns__custom_campaign">
    <button href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#newCampaign"><i class="icon feather icon-plus"></i> Create Custom Campaign</button>
  </div>
  <div class="create-campaigns__main">
    <p class="create-campaigns__title">Create a campaign</p>
    <div class="create-campaigns__search_box">
      <div *ngIf="authUser?.provider !== 'domain'" class="create-campaigns__select select-custom">
        <select #selectCampaign (change)="onCategoryChange(selectCampaign.value)">
          <option>Sales</option>
          <option>Rental</option>
          <option *ngIf="authUser?.provider === 'vaultre'">Commercial</option>
          <option *ngIf="authUser?.provider === 'vaultre'">Commercial Lease</option>
          <option *ngIf="authUser?.provider === 'vaultre'">Rural</option>
          <option *ngIf="authUser?.provider === 'vaultre'">Land</option>
          <option>Sold</option>
          <option *ngIf="authUser?.provider === 'vaultre'">Leased</option>
          <option *ngIf="authUser?.provider === 'vaultre'">Under Offer/Conditional</option>
        </select>
      </div>
      <div *ngIf="authUser?.provider === 'domain'" class="row">
        <div class="col-md-3">
          <div class="create-campaigns__select">
            <select #selectCampaign (change)="onCategoryChange(selectCampaign.value)">
              <option>Sales</option>
              <option>Rental</option>
              <option>Share</option>
              <option>Sold</option>
              <option value="NewHomes">New Homes</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <input [(ngModel)]="keyword" (keyup)="onKeywordChange()" type="text" class="create-campaigns__input" placeholder="Search" />
        </div>
      </div>
    </div>

    <p class="text-white" *ngIf="authUser?.provider === 'domain'"><small>Search a listing to create a campaign</small></p>
  </div>
</div>
<div class="homepage2 app-media campaigns-items">
  <div class="page-content your-templates">
    <h3 class="pull-left" for="chevronMain">
      <button data-bs-toggle="collapse" [attr.data-bs-target]="'#current-' + currentCategory" type="button" class="btn-link btn-link-chevron" aria-expanded="true">{{ currentCategory }} <i class="icon feather icon-chevron-up js-rotate-if-collapsed"></i></button> <button *ngIf="isMaster" (click)="onShare('')" type="button" class="btn-link"><i class="icon feather icon-share-2"></i></button>
    </h3>
    <select #sortAgent class="sort-agents pull-right" (change)="onChangeAgent(sortAgent.value)" [(ngModel)]="selectedAgent">
      <option value="all">All Agents</option>
      <option *ngFor="let changeAgent of shareListUsers">{{ changeAgent?.firstname }} {{ changeAgent?.lastname }}</option>
    </select>
    <select #sortSelect class="sort-filters pull-right" (change)="onSort(sortSelect.value)">
      <option value="modifydateDesc">Sort by Newest</option>
      <option value="modifydateAsc">Sort by Oldest</option>
    </select>
    <h3 *ngIf="listings.length === 0" id="noresult">No results found</h3>
    <!-- <a (click)="onFeedback()" id="my_feedback" class="btn-feedback">Send Feedback</a> -->
    <div id="current-{{ currentCategory }}" class="media-list padding-bottom-50 is-list in">
      <ul class="blocks blocks-100 blocks-xlg-6 blocks-lg-4 blocks-md-4 blocks-ms-2 blocks-xs-2" data-plugin="animateList" data-child=">li">
        <li *ngIf="listings.length > 0">
          <div class="media-item campaign-header">
            <div class="row listing-items campaigns-title">
              <div class="col-md-3 text-left">Address</div>
              <div class="col-md-2 listing-headline text-left">Heading</div>
              <div class="col-md-1 text-left">No. Templates</div>
              <div class="col-md-2 text-left">Agent</div>
            </div>
          </div>
        </li>
        <li *ngFor="let listing of listings | agent: selectedAgent" class="animation-scale-up" style="animation-fill-mode: backwards; animation-duration: 250ms; animation-delay: 0ms">
          <div class="media-item">
            <div class="row listing-items">
              <div class="col-md-3 hide-overflow text-left" (click)="viewListingCampaign(listing.id)">
                <p>{{ listing.displayaddress }}</p>
              </div>
              <div class="col-md-2 listing-headline text-left" (click)="onEditHeading(listing)">
                <p class="listing-headline__heading">{{ listing.heading }}</p>
                <p class="listing-headline__edit-text"><span class="icon feather icon-edit"></span></p>
              </div>
              <div class="col-md-1 text-left" (click)="viewListingCampaign(listing.id)">
                <p>{{ listing.campaign_template_count }}</p>
              </div>
              <div class="col-md-2 text-left" (click)="viewListingCampaign(listing.id)">
                <div class="media media-xs">
                  <div class="media-body">
                    <p class="media-heading gray4-color">{{ listing?.agent1?.firstname }} {{ listing?.agent1?.lastname }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-right">
                <ul class="list-inline text-right">
                  <li>
                    <!-- <button class="btn-admin btn-admin-primary btn-generate" type="button"
                      (click)="generateCampaign(listing)"><i class="icon feather icon-repeat" aria-hidden="true"></i>
                      &nbsp; Generate Campaign</button> -->
                    <button class="btn-admin btn-admin-primary btn-generate" type="button" (click)="getTemplates(listing)"><i class="icon feather icon-repeat" aria-hidden="true"></i> &nbsp; Generate Campaign</button>
                    <button class="btn-admin btn-admin-primary btn-generate" type="button" (click)="exportCampaign(listing.id, listing.campaign_id)"><i class="icon feather icon-download" aria-hidden="true"></i></button>
                    <button class="btn-admin btn-admin-primary btn-generate" type="button" (click)="onAddListingCampaign(listing)"><i class="icon feather icon-plus" aria-hidden="true"></i></button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div *ngFor="let key of Object.keys(customListings)" class="page-content your-templates custom-campaigns-list">
    <h3>
      <button data-bs-toggle="collapse" [attr.data-bs-target]="'#current-' + key" type="button" class="btn-link btn-link-chevron" aria-expanded="true">{{ customListings[key].name }} <i class="icon feather icon-chevron-up js-rotate-if-collapsed"></i></button> <button (click)="onDelete(customListings[key].id)" class="btn-link btn-delete-campaign"><i class="icon feather icon-trash"></i></button>
    </h3>
    <!-- <h3 *ngIf="customListings[key].listings.length === 0" id="noresult">No results found</h3> -->
    <!-- <a (click)="onFeedback()" id="my_feedback" class="btn-feedback">Send Feedback</a> -->
    <div id="current-{{ key }}" class="media-list padding-bottom-50 is-list in">
      <ul class="blocks blocks-100 blocks-xlg-6 blocks-lg-4 blocks-md-4 blocks-ms-2 blocks-xs-2" data-plugin="animateList" data-child=">li">
        <li>
          <div class="media-item campaign-header">
            <div class="row listing-items campaigns-title">
              <div class="col-md-3 text-left">Address</div>
              <div class="col-md-1 text-left">Status</div>
              <div class="col-md-2 listing-headline text-left">Heading</div>
              <div class="col-md-1 text-left overflow-hidden">No. Templates</div>
              <div class="col-md-2 text-left">Agent</div>
            </div>
          </div>
        </li>
        <li *ngIf="customListings[key].listings.length === 0" class="animation-scale-up" style="animation-fill-mode: backwards; animation-duration: 250ms; animation-delay: 0ms">
          <div class="media-item noresult-list">
            <div class="row listing-items">
              <div class="col-md-3 text-left">
                <p>No Campaign Found</p>
              </div>
            </div>
          </div>
        </li>
        <li *ngFor="let listing of customListings[key].listings" class="animation-scale-up" style="animation-fill-mode: backwards; animation-duration: 250ms; animation-delay: 0ms">
          <div class="media-item">
            <div class="row listing-items">
              <div class="col-md-3 text-left" (click)="viewListingCampaign(listing.id, 'custom', customListings[key].id)">
                <p>{{ listing.displayaddress }}</p>
              </div>
              <div class="col-md-1 text-left" (click)="viewListingCampaign(listing.id, 'custom', customListings[key].id)">
                <p>{{ listing.leasesale }}</p>
              </div>
              <div class="col-md-2 listing-headline text-left" (click)="onEditHeading(listing)">
                <p class="listing-headline__heading">{{ listing.heading }}</p>
                <p class="listing-headline__edit-text"><span class="icon feather icon-edit"></span></p>
              </div>
              <div class="col-md-1 text-left" (click)="viewListingCampaign(listing.id, 'custom', customListings[key].id)">
                <p>{{ listing.campaign_template_count }}</p>
              </div>
              <div class="col-md-2 text-left" (click)="viewListingCampaign(listing.id, 'custom', customListings[key].id)">
                <div class="media media-xs">
                  <div class="media-body">
                    <p class="media-heading gray4-color">{{ listing?.agent1?.firstname }} {{ listing?.agent1?.lastname }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-3 text-left">
                <ul class="list-inline text-right">
                  <li>
                    <button class="btn-admin btn-admin-primary btn-generate" type="button" (click)="getTemplates(listing, 'custom')"><i class="icon feather icon-repeat" aria-hidden="true"></i> &nbsp; Generate Campaign</button>
                    <button class="btn-admin btn-admin-primary btn-generate" type="button" (click)="exportCampaign(listing.id, customListings[key].id)"><i class="icon feather icon-download" aria-hidden="true"></i></button>
                    <button class="btn-admin btn-admin-primary btn-generate" type="button" (click)="removeListingFromCampaign(listing.campaign_property_id)"><i class="icon feather icon-trash" aria-hidden="true"></i></button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div #newCampaign id="newCampaign" class="modal fade edit-box" role="dialog" aria-labelledby="newCampaignLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #newCampaignModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 *ngIf="!editMode" class="modal-title" id="newCampaignLabel">Add Campaign</h4>
        <h4 *ngIf="editMode" class="modal-title" id="newCampaignLabel">Edit Campaign</h4>
      </div>
      <form (ngSubmit)="onSubmit()" [formGroup]="campaignForm" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group d-flex">
            <label for="name" class="col-sm-2">Title</label>
            <div class="col-sm-8">
              <input formControlName="name" type="text" class="form-control" id="name" autocomplete="off" autofocus />
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="submit" [disabled]="!campaignForm.valid" class="btn-admin btn-admin-primary me-2">Save</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div #editHeading id="editHeading" class="modal fade edit-box" role="dialog" aria-labelledby="editHeadingLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #editHeadingModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="editHeadingLabel">Edit Heading</h4>
      </div>
      <form (ngSubmit)="onSubmitEditHeading()" [formGroup]="headingForm" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group d-flex">
            <label for="name" class="col-sm-2">Headline</label>
            <div class="col-sm-8">
              <input formControlName="headline" type="text" class="form-control" id="name" autocomplete="off" autofocus />
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="submit" [disabled]="!headingForm.valid" class="btn-admin btn-admin-primary me-2">Save</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div #generateCampaignModal id="generateCampaign" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <app-campaign-generate></app-campaign-generate>
      </div>
    </div>
  </div>
</div>

<div #addListing id="addListing" class="modal fade edit-box" role="dialog" aria-labelledby="addListingLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #addListingodalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="addListingLabel">Add Listing to Custom Campaign</h4>
      </div>
      <form (ngSubmit)="onAddListing()" [formGroup]="campaignListingForm" class="form-horizontal">
        <div class="modal-body">
          <div class="form-group d-flex">
            <label for="name" class="col-sm-2">Campaigns</label>
            <div class="col-sm-8">
              <select formControlName="campaign_id" class="form-control">
                <option value="">Select Campaign</option>
                <option *ngFor="let campaign of campaigns" value="{{ campaign.ID }}">{{ campaign.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="submit" [disabled]="!campaignListingForm.valid" class="btn-admin btn-admin-primary me-2">Save</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div #shareToClient id="shareTo" class="modal fade edit-box" tabindex="-1" role="dialog" aria-labelledby="shareToClientLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #shareToModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="shareToClientLabel">
          Share to
          <select>
            <option *ngIf="authUser.can_share && isMaster" value="offices">Offices</option>
          </select>
        </h4>
      </div>
      <form *ngIf="selected_share === 'offices'" (ngSubmit)="onSaveCheck()" [formGroup]="shareToClientForm" class="form-horizontal">
        <input formControlName="ID" type="hidden" [(ngModel)]="shared_template_id" class="form-control" id="id" autocomplete="off" />
        <div class="modal-body">
          <div class="form-group d-flex flex-wrap">
            <div *ngFor="let sharedClient of shareListClients" class="col-sm-6">
              <div class="checkbox">
                <label> <input type="checkbox" [value]="sharedClient.ID" /> {{ sharedClient.name }} </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-block">
          <button type="submit" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>

          <button type="button" (click)="onClear()" class="btn-admin pull-right">Clear</button>
          <button type="button" (click)="onSelectAll()" class="btn-admin btn-admin-primary pull-right">Select All</button>
        </div>
      </form>
      <form *ngIf="selected_share === 'subgroup'" (ngSubmit)="onSaveCheck()" [formGroup]="shareToSubgroupForm" class="form-horizontal">
        <input formControlName="ID" type="hidden" [(ngModel)]="shared_template_id" class="form-control" id="id" autocomplete="off" />
        <div class="modal-body">
          <div class="form-group d-flex flex-wrap">
            <div *ngFor="let subgroup of subgroups" class="col-sm-6">
              <div class="checkbox">
                <label> <input type="checkbox" [value]="subgroup.ID" /> {{ subgroup.name }} </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-block">
          <button type="submit" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>

          <button type="button" (click)="onClear()" class="btn-admin pull-right">Clear</button>
          <button type="button" (click)="onSelectAll()" class="btn-admin btn-admin-primary pull-right">Select All</button>
        </div>
      </form>
      <form *ngIf="selected_share === 'agent'" (ngSubmit)="onSaveCheck()" [formGroup]="shareToUserForm" class="form-horizontal">
        <input formControlName="ID" type="hidden" [(ngModel)]="shared_template_id" class="form-control" id="id" autocomplete="off" />
        <div class="modal-body">
          <div class="form-group d-flex flex-wrap">
            <div *ngFor="let sharedUser of shareListUsers" class="col-sm-6">
              <div class="checkbox">
                <label> <input type="checkbox" [value]="sharedUser.ID" /> {{ sharedUser.firstname + ' ' + sharedUser.lastname }} </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-block">
          <button type="submit" class="btn-admin btn-admin-primary me-2">Submit</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>

          <button type="button" (click)="onClear()" class="btn-admin pull-right">Clear</button>
          <button type="button" (click)="onSelectAll()" class="btn-admin btn-admin-primary pull-right">Select All</button>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
