<div class="col-md-12 heading text-end">
    <h2 class="pull-left mt-0">Subscriptions</h2>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="panel panel-box-shadow">
            <div class="panel-body container-fluid">
                <div class="subscriptions-wrapper">
                    <p class="manage-text">Manage your subscription here.</p>
                    <div class="d-flex subscription-details">
                        <div *ngIf="user?.subscription">
                            <p class="heading-text"><strong>My Subscription Details:</strong></p>
                            <p>Current subscription: <span class="subscription-plan">Designly Pro</span></p>
                            <p>Valid Until: <span>{{nextDueDate}}</span></p>
                            <p>Subscription Features: <span
                                    *ngFor="let product of user?.subscription?.products">{{product?.product?.name}}</span>
                            </p>
                        </div>
                        <div *ngIf="!user?.subscription">
                            <p class="heading-text"><strong>My Subscription Details:</strong></p>
                            <p>Current subscription: <span class="subscription-plan">Designly Light</span></p>
                            <p>Valid Until: <span>Forever</span></p>
                            <p>Subscription Features: <span>Not Applicable</span></p>
                        </div>
                        <div *ngIf="client?.subscription">
                            <p class="heading-text"><strong>Office Subscription Details:</strong></p>
                            <p>Current subscription: <span class="subscription-plan">Designly Pro</span></p>
                            <p>Valid Until: <span>{{nextDueDate}}</span></p>
                            <p>Subscription Features: <span
                                    *ngFor="let product of client?.subscription?.products">{{product?.product?.name}}</span>
                            </p>
                        </div>
                        <div *ngIf="!client?.subscription">
                            <p class="heading-text"><strong>Office Subscription Details:</strong></p>
                            <p>Current subscription: <span class="subscription-plan">Designly Light</span></p>
                            <p>Valid Until: <span>Forever</span></p>
                            <p>Subscription Features: <span>Not Applicable</span></p>
                        </div>
                    </div>
                    <div class="subscriptions-package">
                        <div class="d-flex flex-wrap subscriptions-package-list">
                            <div class="package-item card">
                                <div class="card-body">
                                    <p class="package-name">light</p>
                                    <p class="package-amount"><span class="price-text">$0</span> per month</p>
                                    <div class="divider"></div>
                                    <div class="package-details">
                                        <p>All the standard features of Designly you know and love</p>
                                    </div>
                                    <button *ngIf="!user?.subscription" class="btn btn-light btn-lg w-100" disabled>Your
                                        subscription</button>
                                </div>
                            </div>
                            <div class="agent-subscription package-item card">
                                <div class="card-body">
                                    <form [formGroup]="agentSubscriptionForm" (ngSubmit)="onAgentSubscribe()">
                                        <p class="package-name pro"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="18.09" height="19.61" viewBox="0 0 18.09 19.61">
                                                <g id="Group_2517" data-name="Group 2517"
                                                    transform="translate(-13.226 -18.625)">
                                                    <rect id="Rectangle_1209" data-name="Rectangle 1209" width="15.389"
                                                        height="3.065" rx="0.5" transform="translate(14.067 35.17)"
                                                        fill="#fac656" />
                                                    <path id="Path_1028" data-name="Path 1028"
                                                        d="M26.12,24.3a.668.668,0,0,1-.984-.247l-2.171-4.468L21.709,17l-1.256,2.585-2.171,4.468a.668.668,0,0,1-.984.247l-3.584-2.529a.661.661,0,0,0-1.043.617l1.421,9.57a.656.656,0,0,0,.655.553H28.67a.656.656,0,0,0,.655-.553l1.421-9.57a.661.661,0,0,0-1.043-.617Z"
                                                        transform="translate(0.562 1.623)" fill="#fac656" />
                                                </g>
                                            </svg> pro</p>
                                        <p class="package-amount"><span
                                                class="price-text">${{agentSubscriptionPrice}}</span> per month</p>
                                        <div class="divider"></div>
                                        <div class="package-details">
                                            <ng-container *ngFor="let product of products">
                                                <div *ngIf="product?.product_type === 'agent'"
                                                    class="d-flex justify-content-between">
                                                    <div><strong>{{product?.name}} <span data-tooltip="Tooltip text here" data-tooltip-pos="top"><i class="icon feather icon-help-circle"></i></span></strong>
                                                    </div>
                                                    <div>${{product?.price}}/month</div>
                                                    <div>
                                                        <label class="switch">
                                                            <input class="agent-checkbox"
                                                                [class.d-agent-product-bundle]="product?.bundle"
                                                                [value]="product?.UID" type="checkbox"
                                                                (change)="onAgentCheckChange($event)"
                                                                [checked]="userProducts.includes(product?.UID)">
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <button type="button" *ngIf="agentNewProducts?.length"
                                            class="btn btn-primary btn-lg w-100 mt-3" data-bs-toggle="modal"
                                            data-bs-target="#updateSubscriptionModal"
                                            (click)="openUpdateSubscription('agent')">Update Subscription</button>
                                        <button *ngIf="user?.subscription" class="btn btn-light btn-lg w-100 mt-3"
                                            disabled>Your subscription</button>
                                        <button *ngIf="!user?.subscription" type="submit"
                                            class="btn btn-primary btn-lg w-100 mt-3">Upgrade</button>
                                        <button *ngIf="user?.subscription" (click)="cancelSubscription('agent')"
                                            type="button" class="btn btn-danger btn-lg w-100 mt-3">Cancel</button>
                                    </form>
                                </div>
                            </div>
                            <div class="package-item card">
                                <div class="card-body">
                                    <form [formGroup]="agencySubscriptionForm" (ngSubmit)="onAgencySubscribe()">
                                        <p class="package-name office-pro"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="18.09" height="19.61" viewBox="0 0 18.09 19.61">
                                                <g id="Group_2517" data-name="Group 2517"
                                                    transform="translate(-13.226 -18.625)">
                                                    <rect id="Rectangle_1209" data-name="Rectangle 1209" width="15.389"
                                                        height="3.065" rx="0.5" transform="translate(14.067 35.17)"
                                                        fill="#fac656" />
                                                    <path id="Path_1028" data-name="Path 1028"
                                                        d="M26.12,24.3a.668.668,0,0,1-.984-.247l-2.171-4.468L21.709,17l-1.256,2.585-2.171,4.468a.668.668,0,0,1-.984.247l-3.584-2.529a.661.661,0,0,0-1.043.617l1.421,9.57a.656.656,0,0,0,.655.553H28.67a.656.656,0,0,0,.655-.553l1.421-9.57a.661.661,0,0,0-1.043-.617Z"
                                                        transform="translate(0.562 1.623)" fill="#fac656" />
                                                </g>
                                            </svg> office pro</p>
                                        <p class="package-amount"><span
                                                class="price-text">${{agencySubscriptionPrice}}</span> per month</p>
                                        <div class="divider"></div>
                                        <div class="package-details">
                                            <ng-container *ngFor="let product of products">
                                                <div *ngIf="product?.product_type === 'agency'"
                                                    class="d-flex justify-content-between">
                                                    <div><strong>{{product?.name}} <span data-tooltip="Tooltip text here" data-tooltip-pos="top"><i class="icon feather icon-help-circle"></i></span></strong>
                                                    </div>
                                                    <div>${{product?.price}}/month</div>
                                                    <div>
                                                        <label class="switch">
                                                            <input class="agency-checkbox"
                                                                [class.d-agency-product-bundle]="product?.bundle"
                                                                [value]="product?.UID" type="checkbox"
                                                                (change)="onAgencyCheckChange($event)"
                                                                [checked]="agencyProducts.includes(product?.UID)">
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <p *ngIf="!client?.subscription" class="text-center mt-2 mb-4">Take your
                                                office to the next level with
                                                office
                                                wide Access to <strong>Designly Pro</strong></p>
                                        </div>
                                        <button *ngIf="!client?.subscription"
                                            class="btn btn-dark btn-lg w-100">Upgrade</button>
                                        <ng-container *ngIf="client?.subscription">
                                            <button type="button" *ngIf="agencyNewProducts?.length"
                                                data-bs-toggle="modal" data-bs-target="#updateSubscriptionModal"
                                                class="btn btn-dark btn-lg w-100"
                                                (click)="openUpdateSubscription('agency')">Update Subscription</button>
                                            <button class="btn btn-light btn-lg w-100 mt-3" disabled>Office
                                                subscription</button>
                                            <button (click)="cancelSubscription('agency')" type="button"
                                                class="btn btn-danger btn-lg w-100 mt-3">Cancel</button>
                                        </ng-container>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="billingModal" tabindex="-1" aria-labelledby="billingModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="billingForm" (ngSubmit)="createWhmcsProfile()">
                <div class="modal-header">
                    <h5 class="modal-title" id="billingModalLabel">Billing Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row g-3">
                        <div class="col-sm-6">
                            <label for="firstName" class="form-label">First name <small
                                    class="text-danger">*</small></label>
                            <input formControlName="firstname" type="text" class="form-control" id="firstName"
                                placeholder="" value="" required="">
                        </div>

                        <div class="col-sm-6">
                            <label for="lastName" class="form-label">Last name <small
                                    class="text-danger">*</small></label>
                            <input formControlName="lastname" type="text" class="form-control" id="lastName"
                                placeholder="" value="" required="">
                        </div>

                        <div class="col-12">
                            <label for="email" class="form-label">Email <small class="text-danger">*</small></label>
                            <input formControlName="email" type="email" class="form-control" id="email"
                                placeholder="you@example.com">
                        </div>

                        <div class="col-12">
                            <label for="address" class="form-label">Address <small class="text-danger">*</small></label>
                            <input formControlName="address1" type="text" class="form-control" id="address"
                                placeholder="1234 Main St" required="">
                        </div>

                        <div class="col-12">
                            <label for="address2" class="form-label">Address 2 <span
                                    class="text-muted">(Optional)</span></label>
                            <input formControlName="address2" type="text" class="form-control" id="address2"
                                placeholder="Apartment or suite">
                        </div>

                        <div class="col-12">
                            <label for="phoneNumber" class="form-label">Phone Number <small
                                    class="text-danger">*</small></label>
                            <input formControlName="phonenumber" type="text" class="form-control" id="phoneNumber"
                                placeholder="" value="" required="">
                        </div>

                        <div class="col-md-5">
                            <label for="city" class="form-label">Suburb <small class="text-danger">*</small></label>
                            <input formControlName="city" type="text" class="form-control" id="city" placeholder=""
                                required="">
                        </div>

                        <div class="col-md-7">
                            <label for="state" class="form-label">State <small class="text-danger">*</small></label>
                            <select formControlName="state" class="form-select" id="state" required="">
                                <option value="">Choose...</option>
                                <option>Australian Capital Territory</option>
                                <option>New South Wales</option>
                                <option>Northern Territory</option>
                                <option>Queensland</option>
                                <option>South Australia</option>
                                <option>Tasmania</option>
                                <option>Victoria</option>
                                <option>Western Australia</option>
                            </select>
                        </div>

                        <div class="col-md-5">
                            <label for="zip" class="form-label">Post Code <small class="text-danger">*</small></label>
                            <input formControlName="postcode" type="text" class="form-control" id="zip" placeholder=""
                                required="">
                        </div>

                        <div class="col-md-7">
                            <label for="country" class="form-label">Country <small class="text-danger">*</small></label>
                            <select formControlName="country" class="form-select" id="country" required="">
                                <option value="">Choose...</option>
                                <option value="AU">Australia</option>
                                <option value="NZ">New Zealand</option>
                            </select>
                        </div>

                        <hr />
                        <div class="col-md-6">
                            <label for="cc-name" class="form-label">Promo Code</label>
                            <input formControlName="promocode" type="text" class="form-control" id="promo-code">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn-admin btn-admin-primary me-2">Submit</button>
                    <button type="button" class="btn-admin" data-bs-dismiss="modal">Close</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="creditCardModal" tabindex="-1" aria-labelledby="creditCardModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="creditcardForm" (ngSubmit)="subscribe()">
                <div class="modal-header">
                    <h5 class="modal-title" id="creditCardModalLabel">Credit Card Payment</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <h4>To complete your subscription, enter your card Details</h4>
                    <hr />
                    <div class="row gy-3">
                        <div class="col-md-6">
                            <label for="cc-name" class="form-label">Name on card <small
                                    class="text-danger">*</small></label>
                            <input formControlName="ccname" type="text" class="form-control" id="cc-name" placeholder=""
                                required="">
                            <small class="text-muted">Full name as displayed on card</small>
                        </div>

                        <div class="col-md-6">
                            <label for="cc-number" class="form-label">Credit card number <small
                                    class="text-danger">*</small></label>
                            <input formControlName="ccnumber" type="text" class="form-control" id="cc-number"
                                placeholder="" required="">
                        </div>

                        <div class="col-md-4">
                            <label for="cc-expiration" class="form-label">Expiration <small
                                    class="text-danger">*</small></label>
                            <select formControlName="ccMonthExpiry" class="form-control" id="cc-expiration-month">
                                <option value="01">January</option>
                                <option value="02">February</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <small class="text-muted">Month</small>
                        </div>

                        <div class="col-md-3">
                            <label for="cc-expiration" class="form-label">&nbsp;</label>
                            <select formControlName="ccYearExpiry" class="form-control" id="cc-expiration-year">
                                <option *ngFor="let ccExpiryYear of ccExpiryYears" [value]="ccExpiryYear">
                                    {{ccExpiryYear}}</option>
                            </select>
                            <small class="text-muted">Year</small>
                        </div>

                        <div class="col-md-3">
                            <label for="cc-cvv" class="form-label">CVV <small class="text-danger">*</small></label>
                            <input formControlName="ccCvn" type="text" class="form-control" id="cc-cvv" placeholder=""
                                required="">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn-admin btn-admin-primary me-2">Upgrade</button>
                    <button type="button" class="btn-admin" data-bs-dismiss="modal">Close</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="updateSubscriptionModal" tabindex="-1" aria-labelledby="updateSubscriptionModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="updateSubscriptionModalLabel">Update Subscription</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <ng-container *ngIf="isAgentSubscription">
                    <h3>Current Subscription</h3>
                    <p *ngFor="let product of user?.subscription?.products">{{product?.product?.name}}
                        <strong>(${{product?.product?.price}})</strong></p>
                    <h3>New Subscription</h3>
                    <p *ngFor="let agentNewProduct of agentNewProducts">{{product(agentNewProduct)?.name}}
                        <strong>(${{product(agentNewProduct)?.price}})</strong></p>
                </ng-container>

                <ng-container *ngIf="isAgencySubscription">
                    <h3>Current Office Subscription</h3>
                    <p *ngFor="let product of client?.subscription?.products">{{product?.product?.name}}
                        <strong>(${{product?.product?.price}})</strong></p>
                    <h3>New Office Subscription</h3>
                    <p *ngFor="let agencyNewProduct of agencyNewProducts">{{product(agencyNewProduct)?.name}}
                        <strong>(${{product(agencyNewProduct)?.price}})</strong></p>
                </ng-container>

                <hr />
                <div class="col-md-6">
                    <label for="cc-name" class="form-label">Promo Code</label>
                    <input type="text" class="form-control" id="update-promo-code"
                        [(ngModel)]="updateProductsPromoCode">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn-admin btn-admin-primary me-2"
                    (click)="updateSubscription()">Update</button>
                <button type="button" class="btn-admin" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<ngx-loading [show]="loading"></ngx-loading>