import { HttpParams,  HttpClient,   HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Template } from '../models/template.model';
import 'rxjs/Rx';
import { APP_CONFIG } from '../app.config';

@Injectable()
export class TemplateService {
  templatesLatestChanged = new Subject<Template[]>();
  templatesChanged = new Subject<Template[]>();
  templateGetAll = new Subject<Template[]>();
  templateChanged = new Subject<Template>();
  templateOperation = new Subject<Template>();
  templateBuild = new Subject<Template>();
  templateDuplicate = new Subject<Template>();
  templateCreation = new Subject<Template>();
  templateDeletion = new Subject<Template>();
  templatesDeletion = new Subject<Template>();
  templateRefresh = new Subject();
  templateLoadMasterTemplates = new Subject();

  templateShare = new Subject<any>();
  templatePreloaded = new Subject<any>();

  templateTestEmail = new Subject();
  templateBuildError = new Subject();
  private templates: Template[] = [];

  // New Subscriptions
  onPutTemplate = new Subject();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient
  ) {}

  setTemplates(templates: Template[]) {
    this.templates = templates;
    this.templatesChanged.next(this.templates.slice());
  }

  getTemplates() {
    return this.templates.slice();
  }

  setLatestTemplates(templates: Template[]) {
    this.templates = templates;
    this.templatesLatestChanged.next(this.templates.slice());
  }

  getLatestTemplates() {
    return this.templates.slice();
  }

  getIdIndex(id: number) {
    return this.templates.findIndex(g => g.ID === id);
  }

  httpGetTemplates(params: any = [], columns: string = '', order: string = '') {

    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (let p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/templates',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (typeof response.body !== 'undefined' && response.body != null) {
            return response.body;
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.templateGetAll.next(response);
      },
      (response: any) => {
        this.templateGetAll.next(response.error);
      }
    );
  }

  httpGetTemplate(ID: number) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/templates/${ID}`)
    .subscribe(
      (response: any) => {
        this.templateChanged.next(response);
      },
      (response: any) => {
        this.templateChanged.next(response.error);
      }
    );
  }

  httpGetLatestTemplate(params: any = [], columns: string = '', order: string = '') {
    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (let p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/templates/latest',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Template[]) => {
        this.setLatestTemplates(response);
      }
    );
  }

  httpPostTemplate(data: Template) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/templates',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.templateCreation.next(response);
        // this.templateOperation.next(response);
      }
    );
  }

  httpPutTemplate(id: number, data: Template) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/templates/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.templateOperation.next(response);
      }
    );
  }

  httpPutTemplateNew(id: number, data: any) {
    this.httpClient.put(`${this.appConfig.API_ENDPOINT}/templates/${id}`, data)
    .subscribe(
      (response: any) => {
        this.onPutTemplate.next(response);
      },
      (response: any) => {
        this.onPutTemplate.next(response.error);
      }
    );
  }

  httpBuildTemplate(id: number, data: Template) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/templates/' + id + '/build',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.templateBuild.next(response);
      },
      (response: any) => {
        this.templateBuildError.next(response);
      }
    );
  }

  httpDuplicateTemplate(id: number, data: Template) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/templates/' + id + '/duplicate',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.templateDuplicate.next(response);
      }
    );
  }

  httpDeleteTemplate(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/templates/' + id
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.templateDeletion.next(response);
      }
    );
  }

  httpShareTemplate(type, data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/templates/share/' + type,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.templateShare.next(response);
      },
      (response: any) => {
        this.templateShare.next(response.error);
      }
    );
  }

  httpRefreshTemplates(campaignId: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/templates/refresh/' + campaignId,
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.templateRefresh.next(response);
      },
      (response: any) => {
        this.templateRefresh.next(response.error);
      }
    );
  }

  httpAddPreloaded(data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/templates/preloaded',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.templatePreloaded.next(response);
      },
      (response: any) => {
        this.templatePreloaded.next(response.error);
      }
    );
  }

  httpLoadMasterTemplates(id: number) {
    const req = new HttpRequest(
      'GET',
      `${this.appConfig.API_ENDPOINT}/templates/master/${id}`,
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.templateLoadMasterTemplates.next(response);
      },
      (response: any) => {
        this.templateLoadMasterTemplates.next(response.error);
      }
    );
  }

  httpTestEmail(ID: number, data: any) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/templates/email/${ID}`, data)
    .subscribe(
        (response: any) => {
          this.templateTestEmail.next(response);
        },
        (response: any) => {
          this.templateTestEmail.next(response.error);
        }
    );
  }

  httpDeleteTemplates(type: string) {
    return this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/templates/delete/${type}`)
    .subscribe(
        (response: any) => {
            this.templatesDeletion.next(response);
        },
        (response: any) => {
            this.templatesDeletion.next(response.error);
        }
    );
  }

}

