import {HttpClient,   HttpRequest} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';
import { APP_CONFIG } from '../app.config';

@Injectable()
export class CustomCategoryService {
    onGetAll = new Subject();
    onAdd  = new Subject();
    onDelete = new Subject();
    onAddTemplate = new Subject();
    onRemoveTemplate = new Subject();
    onDuplicateTemplate = new Subject();
    onPut = new Subject();
    onShare = new Subject();

    constructor(
        @Inject(APP_CONFIG) private appConfig,
        private httpClient: HttpClient
    ) { }

    httpGetAll(_params: any = []) {
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/customcategories`)
        .subscribe(
            (response: any) => {
                this.onGetAll.next(response);
            },
            (response: any) => {
                this.onGetAll.next(response.error);
            }
        );
    }

    httpAdd(name: string, files = []) {
        const formData = new FormData();

        formData.append('name', name);
        if (files.length > 0) {
            for (const file of files) {
                formData.append('file', file, file.name);
            }
        }

        const req = new HttpRequest(
            'POST',
            `${this.appConfig.API_ENDPOINT}/customcategories`,
            formData
        );

        return this.httpClient.request(req)
        .map(
            (response: any) => {
                if (response) {
                    if (response.body) {
                        if (response.body.status === 'success') {
                            return response.body;
                        }
                    }
                }
            }
        )
        .subscribe(
            (response: any) => {
                this.onAdd.next(response);
            },
            (response: any) => {
                this.onAdd.next(response.error);
            }
        );
    }

    httpUpdate(ID: number, name: string, files = []) {
        const formData = new FormData();

        formData.append('name', name);
        if (files.length > 0) {
            for (const file of files) {
                formData.append('file', file, file.name);
            }
        }

        const req = new HttpRequest(
            'POST',
            `${this.appConfig.API_ENDPOINT}/customcategories/${ID}`,
            formData
        );

        return this.httpClient.request(req)
        .map(
            (response: any) => {
                if (response) {
                    if (response.body) {
                        if (response.body.status === 'success') {
                            return response.body;
                        }
                    }
                }
            }
        )
        .subscribe(
            (response: any) => {
                this.onPut.next(response);
            },
            (response: any) => {
                this.onPut.next(response.error);
            }
        );
    }

    httpDelete(customCategoryId: number) {
        return this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/customcategories/${customCategoryId}`)
        .subscribe(
            (response: any) => {
                this.onDelete.next(response);
            },
            (response: any) => {
                this.onDelete.next(response.error);
            }
        );
    }

    httpAddTemplate(templateId: number, customCategoryId: number, files = []) {
        const formData = new FormData();

        if (files.length > 0) {
            for (const file of files) {
                formData.append('file', file, file.name);
            }
        }

        const req = new HttpRequest(
            'POST',
            `${this.appConfig.API_ENDPOINT}/customcategories/template/${templateId}/${customCategoryId}`,
            formData
        );

        return this.httpClient.request(req)
        .map(
            (response: any) => {
                if (response) {
                    if (response.body) {
                        if (response.body.status === 'success') {
                            return response.body;
                        }
                    }
                }
            }
        )
        .subscribe(
            (response: any) => {
                this.onAddTemplate.next(response);
            },
            (response: any) => {
                this.onAddTemplate.next(response.error);
            }
        );
    }

    httpRemoveTemplate(templateId: number) {
        return this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/customcategories/template/${templateId}`)
        .subscribe(
            (response: any) => {
                this.onRemoveTemplate.next(response);
            },
            (response: any) => {
                this.onRemoveTemplate.next(response.error);
            }
        );
    }

    httpDuplicateTemplate(customCategoryTemplate: string, data: any) {
        return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/customcategories/duplicate/${customCategoryTemplate}`, data)
        .subscribe(
            (response: any) => {
                this.onDuplicateTemplate.next(response);
            },
            (response: any) => {
                this.onDuplicateTemplate.next(response.error);
            }
        );
    }

    httpShare(ID: number, status: number) {
        return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/customcategories/${ID}/${status}`, {})
        .subscribe(
            (response: any) => {
                this.onShare.next(response);
            },
            (response: any) => {
                this.onShare.next(response.error);
            }
        );
    }
}
