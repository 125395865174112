import { Injectable, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Menu } from '../models/menu.model';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG } from '../app.config';
import { AuthService } from '../services/auth.service';
import { Auth } from '../models/auth.model';
import { Subscription } from 'rxjs';
import { Client } from '../models/client.model';

@Injectable()
export class ImageLibraryService {
  public is_builder = false;
  public is_admin = false;
  public id = 1;
  public gid = 1;
  public client: Client;
  public is_on_library = false;
  authUser: Auth;
  // sid: number;
  // gid: number;
  
  
  fileChanged = new Subject<any>();
  adminChanged = new Subject<any>();
  adminCloseChanged = new Subject<any>();
  onCloseLibrary = new Subject();

  onUnsplashInsert = new Subject();
  onBuilderOpen = new Subject();

  constructor() {}

  onInsert(file){
    this.fileChanged.next(file);
  }

  insertUnsplash(links: any) {
    this.onUnsplashInsert.next(links);
  }

  onAdmin(client){
    this.adminChanged.next(client);
  }

  onAdminClose(){
    let is_close = true
    this.adminCloseChanged.next(is_close);
  }

  openImageLibrary() {
    this.onBuilderOpen.next(true);
  }
}
