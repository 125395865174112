import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { WebsiteService } from '../../../services/website.service';
import { Website } from '../../../models/website.model';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-website-dashboard',
  templateUrl: './website-dashboard.component.html',
  styleUrls: ['./website-dashboard.component.scss']
})
export class WebsiteDashboardComponent implements OnInit {
  loading = false;
  type: String;

  websites: Website[];

  websitesSubscription: Subscription;
  websiteDeleteSubscription: Subscription;

  constructor(
    private websiteService: WebsiteService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        this.type = params['type'];

        if ( this.type !== 'undefined' ) {
          this.websiteService.httpGetWebsites({
            type: this.type
          });
          this.loading = true;
        }

      }
    );

    this.websitesSubscription = this.websiteService.websiteGetAll.subscribe(
      (websites: any) => {
        if ( typeof websites !== 'undefined' && websites.status === 'success' ) {
          this.loading = false;
          this.websites = websites.data;
        }
      }
    );

    this.websiteDeleteSubscription = this.websiteService.websiteDelete.subscribe(
      (website: any) => {
        if ( typeof website !== 'undefined' && website.status === 'success' ) {
          this.websiteService.httpGetWebsites({
            type: this.type
          });
        }
      }
    );
  }

  formatTitle(type) {
    if ( type === 'agency-website' ) {
      return 'Agency Website';
    }

    if ( type === 'agent-only-website' ) {
      return 'Agent Only Website';
    }

    if ( type === 'lead-generating-website' ) {
      return 'Lead Generating Website';
    }

    return '';
  }

  onDelete(ID: number) {
    this.websiteService.httpDeleteWebsite(ID);
    this.loading = true;
  }

}
